import {
  MailOutlined,
  UnorderedListOutlined,
  LoginOutlined,
  LockOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomScrollbars from "../../../../components/common/custom-scrollbar";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import "./index.scss";

const EmailSidebar = () => {
  const [filter, setFilter] = useState(0);
  const { t } = useTranslation();

  const filterOptions = [
    {
      id: 1,
      name: t("admin.allCategories"),
      icon: <UnorderedListOutlined />,
    },
    {
      id: 2,
      name: t("admin.signUpValidation"),
      icon: <LoginOutlined />,
    },
    {
      id: 3,
      name: t("admin.resetPassword"),
      icon: <LockOutlined />,
    },
    {
      id: 4,
      name: t("admin.remindBudget"),
      icon: <WalletOutlined />,
    },
  ];

  return (
    <div className="email-sidebar">
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-module-logo">
            <div className="gx-mr-3">
              <MailOutlined className="email-icon" />
            </div>
            <span>{t("admin.emailTemplate")}</span>
          </div>
        </div>

        <div className="gx-module-side-content">
          <CustomScrollbars className="gx-module-side-scroll">
            <div className="gx-module-add-task">
              <Link to={ROUTE_PATHS.addNewEmailTemplate}>
                <Button
                  className="gx-btn-block ant-btn"
                  type="primary"
                  aria-label="add"
                >
                  <i className="icon icon-add gx-mr-2" />
                  <span>{t("admin.addNewTemplate")}</span>
                </Button>
              </Link>
            </div>
            <div className="gx-module-side-nav">
              <ul className="gx-module-nav">
                {filterOptions.map((option) => (
                  <li key={option.id} className="gx-nav-item">
                    <span
                      onClick={() => setFilter(option.id)}
                      className={`gx-link ${
                        filter === option.id ? "active" : ""
                      }`}
                    >
                      <span className="filter-icon">{option.icon}</span>
                      <span>{option.name}</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    </div>
  );
};

export default EmailSidebar;
