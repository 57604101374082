import { Avatar, Button, Col, Form, Input, Row, Upload, UploadProps } from "antd";

import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { UserApi } from "../../../apis/user.api";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserActions } from "../../../hooks/useUserActions";
import { ROUTE_PATHS } from "../../../constants/router.constants";

const FormItem = Form.Item;

const Complete = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userActions = useUserActions();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);

  async function formSubmit(values: any) {
   try {
    setLoading(true)
    const file = fileList?.[0]?.originFileObj;
    const url = fileList?.[0].url

    const submitData = {
      ...location.state,
      fullName: values.fullName,
      file: Object.assign(file, {url})
    }

    await userActions["completeSignUp" as keyof typeof userActions](submitData as any);
    navigate(ROUTE_PATHS.Home);

    setLoading(false)

   } catch (error) {
    setLoading(false)
    
   }

  }
  

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const beforeUpload = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList((prev: any) => [{ ...prev[0], url: reader.result }]);
    };

    return false;
  };

  return (
    <div className="complete">
      <h2>{t("complete.title")}</h2>
      <div className="desc">{t("complete.desc")}</div>

      <Form
        initialValues={{ remember: true }}
        name="signInForm"
        className="gx-signin-form gx-form-row0 form-hidden-label sign-in-page"
        onFinish={formSubmit}
      >
        <Row className="row">
          <Col style={{paddingRight: 16, paddingLeft: 0}} lg={8} md={8} sm={24} xs={24}>
            <FormItem
              name="avatar"
            >
              <Upload
                maxCount={1}
                showUploadList={false}
                accept='image/png, image/jpeg'
                listType="picture-card"
                beforeUpload={beforeUpload}
                onChange={onChange}
                className="upload-avatar"
              >
                <div className="avatar">
                  <Avatar icon={<UserOutlined />} className="img" size="large" src={fileList?.[0]?.url} alt="img" />
                  <div className="edit-icon">
                    <EditOutlined />
                  </div>
                </div>
              </Upload>
            </FormItem>
          </Col>

          <Col style={{padding: 0}} lg={16} md={16} sm={24} xs={24}>
            <FormItem
              name="fullName"
              rules={[{ required: true, message: t("complete.yourNameRequired") }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t("complete.yourName")}
              />
            </FormItem>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            className="gx-mb-0"
            htmlType="submit"
            loading={loading}
          >
            {t("common.complete")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Complete;
