const ServiceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={38} height={34} viewBox="0 0 38 34" fill="none">
            <path d="M35.875 0.125H2.125C1.62818 0.126484 1.15212 0.324504 0.800814 0.675814C0.449504 1.02712 0.251484 1.50318 0.25 2V23.25C0.251484 23.7468 0.449504 24.2229 0.800814 24.5742C1.15212 24.9255 1.62818 25.1235 2.125 25.125H13.925L13.4438 29.5H10.875C10.7092 29.5 10.5503 29.5658 10.4331 29.6831C10.3158 29.8003 10.25 29.9592 10.25 30.125V33.25C10.25 33.4158 10.3158 33.5747 10.4331 33.6919C10.5503 33.8092 10.7092 33.875 10.875 33.875H27.125C27.2908 33.875 27.4497 33.8092 27.5669 33.6919C27.6842 33.5747 27.75 33.4158 27.75 33.25V30.125C27.75 29.9592 27.6842 29.8003 27.5669 29.6831C27.4497 29.5658 27.2908 29.5 27.125 29.5H23.9312L23.45 25.125H35.875C36.3718 25.1235 36.8479 24.9255 37.1992 24.5742C37.5505 24.2229 37.7485 23.7468 37.75 23.25V2C37.7485 1.50318 37.5505 1.02712 37.1992 0.675814C36.8479 0.324504 36.3718 0.126484 35.875 0.125ZM26.5 30.75V32.625H11.5V30.75H26.5ZM14.7 29.5L15.1875 25.125H22.1875L22.675 29.5H14.7ZM36.5 23.25C36.5 23.4158 36.4342 23.5747 36.3169 23.6919C36.1997 23.8092 36.0408 23.875 35.875 23.875H2.125C1.95924 23.875 1.80027 23.8092 1.68306 23.6919C1.56585 23.5747 1.5 23.4158 1.5 23.25V22.625H36.5V23.25ZM36.5 21.375H1.5V2C1.5 1.83424 1.56585 1.67527 1.68306 1.55806C1.80027 1.44085 1.95924 1.375 2.125 1.375H35.875C36.0408 1.375 36.1997 1.44085 36.3169 1.55806C36.4342 1.67527 36.5 1.83424 36.5 2V21.375Z" fill="url(#paint0_linear_1256_26605)" />
            <path d="M12.75 15.75H15.25C15.4158 15.75 15.5747 15.6842 15.6919 15.5669C15.8092 15.4497 15.875 15.2908 15.875 15.125V11.375C15.875 11.2092 15.8092 11.0503 15.6919 10.9331C15.5747 10.8158 15.4158 10.75 15.25 10.75H13.375V10.125C13.375 8.63316 13.9676 7.20242 15.0225 6.14752C16.0774 5.09263 17.5082 4.5 19 4.5C20.4918 4.5 21.9226 5.09263 22.9775 6.14752C24.0324 7.20242 24.625 8.63316 24.625 10.125V10.75H22.75C22.5842 10.75 22.4253 10.8158 22.3081 10.9331C22.1908 11.0503 22.125 11.2092 22.125 11.375V15.125C22.125 15.2908 22.1908 15.4497 22.3081 15.5669C22.4253 15.6842 22.5842 15.75 22.75 15.75H23.375V17C23.375 17.1658 23.3092 17.3247 23.1919 17.4419C23.0747 17.5592 22.9158 17.625 22.75 17.625H20.875V17C20.875 16.8342 20.8092 16.6753 20.6919 16.5581C20.5747 16.4408 20.4158 16.375 20.25 16.375H17.75C17.5842 16.375 17.4253 16.4408 17.3081 16.5581C17.1908 16.6753 17.125 16.8342 17.125 17V18.875C17.125 19.0408 17.1908 19.1997 17.3081 19.3169C17.4253 19.4342 17.5842 19.5 17.75 19.5H20.25C20.4158 19.5 20.5747 19.4342 20.6919 19.3169C20.8092 19.1997 20.875 19.0408 20.875 18.875H22.75C23.2473 18.875 23.7242 18.6775 24.0758 18.3258C24.4275 17.9742 24.625 17.4973 24.625 17V15.75H25.25C25.4158 15.75 25.5747 15.6842 25.6919 15.5669C25.8092 15.4497 25.875 15.2908 25.875 15.125V10.125C25.875 8.30164 25.1507 6.55295 23.8614 5.26364C22.572 3.97433 20.8234 3.25 19 3.25C17.1766 3.25 15.428 3.97433 14.1386 5.26364C12.8493 6.55295 12.125 8.30164 12.125 10.125V15.125C12.125 15.2908 12.1908 15.4497 12.3081 15.5669C12.4253 15.6842 12.5842 15.75 12.75 15.75ZM19.625 18.25H18.375V17.625H19.625V18.25ZM24.625 14.5H23.375V12H24.625V14.5ZM14.625 14.5H13.375V12H14.625V14.5Z" fill="url(#paint1_linear_1256_26605)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26605" x1="0.25" y1="12.7183" x2="37.9487" y2="22.2818" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26605" x1="12.125" y1="9.31343" x2="26.3081" y2="12.0535" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ServiceIcon;