import React from "react";
import { Route, Routes } from "react-router-dom";

import Error404 from "./pages/404";
import AuthLayout from "./components/layouts/auth-layout";
import MainLayout from "./components/layouts/main-layout";
import ProtectedRoute from "./components/router-protected";

import { ROUTE_PATHS } from "./constants/router.constants";

import { authRouters, mainRouters } from "./routing";

import "./App.css";

function App() {

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {authRouters
          .filter((p) => p.component)
          .map(({ href, component: Component }) => (
            <Route
              key={href}
              path={href}
              element={
                <React.Suspense>
                  <ProtectedRoute redirectTo={ROUTE_PATHS.Home}>
                    <Component />
                  </ProtectedRoute>
                </React.Suspense>
              }
            />
          ))}
      </Route>

      <Route element={<MainLayout />}>
        {mainRouters
          .filter((p) => p.component)
          .map(({ href, component: Component, permissions }) => (
            <Route
              key={href}
              path={href}
              element={
                <React.Suspense>
                  <ProtectedRoute redirectTo={ROUTE_PATHS.signIn}>
                    <Component />    
                  </ProtectedRoute>
                </React.Suspense>
              }
            />
          ))}
      </Route>

      <Route path={ROUTE_PATHS.notFound} element={<Error404 />}></Route>
    </Routes>
  );
}

export default App;
