const WeddingCardIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={28} height={38} viewBox="0 0 28 38" fill="none">
            <path d="M26.5 4.11256V2.32506C26.5002 2.05514 26.442 1.78839 26.3293 1.5431C26.2167 1.29781 26.0523 1.0798 25.8474 0.904047C25.6426 0.728291 25.4021 0.598947 25.1426 0.524898C24.883 0.450848 24.6105 0.433846 24.3438 0.47506L0.78125 4.00631V4.01881C0.634387 4.0386 0.49966 4.11091 0.401994 4.22236C0.304329 4.33382 0.250334 4.47687 0.25 4.62506V35.8751C0.251484 36.3719 0.449504 36.8479 0.800814 37.1992C1.15212 37.5506 1.62818 37.7486 2.125 37.7501H25.875C26.3718 37.7486 26.8479 37.5506 27.1992 37.1992C27.5505 36.8479 27.7485 36.3719 27.75 35.8751V5.87506C27.748 5.48844 27.6269 5.11182 27.4033 4.79645C27.1796 4.48108 26.8642 4.24229 26.5 4.11256ZM24.5312 1.70631C24.6203 1.6928 24.7112 1.6987 24.7978 1.7236C24.8843 1.7485 24.9645 1.79181 25.0328 1.85058C25.101 1.90934 25.1558 1.98216 25.1933 2.06406C25.2308 2.14596 25.2501 2.23499 25.25 2.32506V4.00006H9.25625L24.5312 1.70631ZM26.5 35.8751C26.5 36.0408 26.4342 36.1998 26.3169 36.317C26.1997 36.4342 26.0408 36.5001 25.875 36.5001H2.125C1.95924 36.5001 1.80027 36.4342 1.68306 36.317C1.56585 36.1998 1.5 36.0408 1.5 35.8751V5.25006H25.875C26.0408 5.25006 26.1997 5.31591 26.3169 5.43312C26.4342 5.55033 26.5 5.7093 26.5 5.87506V35.8751Z" fill="url(#paint0_linear_1256_26625)" />
            <path d="M9.625 25.25H18.375V26.5H9.625V25.25Z" fill="url(#paint1_linear_1256_26625)" />
            <path d="M6.5 30.25H24V31.5H6.5V30.25Z" fill="url(#paint2_linear_1256_26625)" />
            <path d="M6.5 27.75H24V29H6.5V27.75Z" fill="url(#paint3_linear_1256_26625)" />
            <path d="M6.5 32.75H24V34H6.5V32.75Z" fill="url(#paint4_linear_1256_26625)" />
            <path d="M17.75 7.75C16.9947 7.75036 16.2523 7.94627 15.5952 8.31865C14.938 8.69104 14.3885 9.22722 14 9.875C13.5075 9.05537 12.76 8.41968 11.8718 8.06519C10.9837 7.7107 10.004 7.65695 9.0824 7.91215C8.16085 8.16735 7.34828 8.71745 6.76903 9.47828C6.18979 10.2391 5.87576 11.1688 5.875 12.125C5.875 16.7563 13.3062 22.3875 13.625 22.625C13.7332 22.7061 13.8648 22.75 14 22.75C14.1352 22.75 14.2668 22.7061 14.375 22.625C14.6937 22.3875 22.125 16.7563 22.125 12.125C22.1237 10.9651 21.6623 9.85305 20.8421 9.03287C20.0219 8.21268 18.9099 7.75132 17.75 7.75ZM14 21.3312C11.775 19.5813 7.125 15.2688 7.125 12.125C7.125 11.2962 7.45424 10.5013 8.04029 9.91529C8.62634 9.32924 9.4212 9 10.25 9C11.0788 9 11.8737 9.32924 12.4597 9.91529C13.0458 10.5013 13.375 11.2962 13.375 12.125C13.375 12.2908 13.4408 12.4497 13.5581 12.5669C13.6753 12.6842 13.8342 12.75 14 12.75C14.1658 12.75 14.3247 12.6842 14.4419 12.5669C14.5592 12.4497 14.625 12.2908 14.625 12.125C14.625 11.2962 14.9542 10.5013 15.5403 9.91529C16.1263 9.32924 16.9212 9 17.75 9C18.5788 9 19.3737 9.32924 19.9597 9.91529C20.5458 10.5013 20.875 11.2962 20.875 12.125C20.875 15.6 15.4812 20.15 14 21.3312Z" fill="url(#paint5_linear_1256_26625)" />
            <path d="M4 27.75H5.25V29H4V27.75Z" fill="url(#paint6_linear_1256_26625)" />
            <path d="M4 30.25H5.25V31.5H4V30.25Z" fill="url(#paint7_linear_1256_26625)" />
            <path d="M4 32.75H5.25V34H4V32.75Z" fill="url(#paint8_linear_1256_26625)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26625" x1="0.25" y1="14.3699" x2="28.8639" y2="19.1868" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26625" x1="9.625" y1="25.7164" x2="12.2591" y2="29.9263" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_26625" x1="6.5" y1="30.7164" x2="8.16932" y2="36.0523" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_26625" x1="6.5" y1="28.2164" x2="8.16932" y2="33.5523" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_26625" x1="6.5" y1="33.2164" x2="8.16932" y2="38.5523" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_26625" x1="5.875" y1="13.347" x2="22.26" y2="17.3997" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_26625" x1={4} y1="28.2164" x2="5.27123" y2="28.5067" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint7_linear_1256_26625" x1={4} y1="30.7164" x2="5.27123" y2="31.0067" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint8_linear_1256_26625" x1={4} y1="33.2164" x2="5.27123" y2="33.5067" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>

    )
};

export default WeddingCardIcon;