import confirm from "antd/lib/modal/confirm";
import { Button, Dropdown, Form, Menu } from "antd";
import { FunctionComponent, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MoreOutlined, WalletOutlined } from "@ant-design/icons";

import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import EditInfoForm from "../overview/edit-info-form";
import showNotification from "../../../../components/common/notification";
import { useUserDetailContext } from "../../../../pages/users/user-detail";
import { UserAvatar } from "../../../../components/common/app-avatar/app-avatar";

import { UserApi } from "../../../../apis/user.api";
import { generateImage } from "../../../../utils/app.utils";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

import "./index.scss";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";
import { RoleEnum } from "../../../../enums/app.enum";
import ProtectedAction from "../../../../components/action-protected";
import { useUserActions } from "../../../../hooks/useUserActions";
interface ProfileHeaderProps {
  canEdit: boolean;
  onSave?: (value: any) => void;
}

const ProfileHeader: FunctionComponent<ProfileHeaderProps> = ({ onSave, canEdit }) => {
  const { currencyConfig, convertNumericToFormattedAmount } = useCurrencyConfig()

  // ultils
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userId } = params;

  // state data
  const [ loading, setLoading ] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

  // context
  const { isAccount, userData } = useUserDetailContext();
  const userAction = useUserActions();

  const userIdData = useMemo(() => {
    if (userId) {
      return userId;
    }

    if (isAccount) {
      return userData.id
    }

  }, [userId, userData, isAccount]);

  const permissionDeleteUsers = useMemo(() => {
    return [
      RoleEnum.Mod,
      RoleEnum.Admin,
      RoleEnum.Accountant,
    ]
  }, []);

  const enableDeleteDeactiveUser = useMemo(() => {
    return !permissionDeleteUsers.includes(userData.userRole)
  }, [permissionDeleteUsers, userData]);

  const showActionSetting = useMemo(() => {
    if (!!isAccount) {
      return [RoleEnum.User]
    }

    if (enableDeleteDeactiveUser) {
      return []
    }

    return []
  }, [isAccount, enableDeleteDeactiveUser]);

  const handleCloseModal = () => {
    setIsOpenEdit(false);
  };

  const handleOpenEditModal = () => {
    setIsOpenEdit(true);
  };

  const onEditBasicInfo = async (values: any) => {
    try {
      setLoading(true);

      const payload = {
        file: values.file,
        fullName: values.fullName,
      }

      const result = await UserApi.changeAvatar(payload);

      if (result?.status === 200) {
        if (onSave) {
          onSave({fullName: values.fullName, avatar: values.file ? result.data: userData?.avatar});
        };
        setIsOpenEdit(false);
      };

    } finally {
      setLoading(false);
    };
  };
  
  const handleDeactive = async () => {
    await UserApi.deactiveUser([userIdData]);
  };

  const onDeleteAccount = async () => {
    deleteAccount()
  };

  const deleteAccount = async () => {
    try {
      setLoading(true);

      const result = await UserApi.deleteAccount();

      if (result?.status === 200) {
        userAction.logout();
      };

    } finally {
      setLoading(false);
    };
  };

  const showConfirm = () => {
    confirm({
      type: "warning",
      okType: "danger",
      okText: t("common.delete"),
      icon: <ExclamationCircleOutlined />,
      title: t("user.confirmDeleteAccount"),

      okButtonProps: {
        type: "primary",
        danger: true,
        loading: loading,
      },

      onOk() {
        onDeleteAccount();
      }
    });
  };

  const confirmDeactive = () => {
    confirm({
      type: "warning",
      okType: "danger",
      okText: t("common.deactivate"),
      icon: <ExclamationCircleOutlined />,
      title: t("user.confirmDeactivateAccount"),

      okButtonProps: {
        type: "primary",
        danger: true,
        loading: loading,
      },

      onOk() {
        handleDeactive();
      }
    })
  };

  const menu = (
    <Menu style={{ marginTop: 8, width: 120 }}>
      <Menu.Item onClick={confirmDeactive} key="1">
        {t("common.deactivate")}
      </Menu.Item>

      <Menu.Item onClick={showConfirm} style={{ color: "#F5222D" }} key="3">
        {t("common.delete")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="gx-profile-banner profile-header">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top profile-header__top">
          <div className="user-head-info">
            <UserAvatar
              src={generateImage(userData.avatar)}
              className=" profile-header__top--avatar"
            />

            <div className="-info profile-header__top--info">
              <div className="info__name">
                {userData.fullName}

                {canEdit && <span onClick={handleOpenEditModal} className="edit-icon">
                  <EditOutlined />
                </span>}
              </div>

              <div className="gx-mb-0 gx-fs-lg info__balance">
                <WalletOutlined />
                <span className="info__balance--number">{convertNumericToFormattedAmount(userData.budget || 0)} {currencyConfig.currency} </span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <ProtectedAction roles={showActionSetting}>
        <Dropdown placement="bottomRight" overlay={menu} trigger={["click"]}>
          <Button className="menu-btn" icon={<MoreOutlined />} />
        </Dropdown>
      </ProtectedAction>

      <EditInfoForm
        open={isOpenEdit}
        confirmLoading={loading}
        initialData={{ avatar: userData.avatar, fullName: userData.fullName }}
        
        onSave={onEditBasicInfo}
        onCancel={handleCloseModal}
      />
    </div>
  );
};

export default ProfileHeader;
