import { Button, Col, Divider } from "antd";
import { useTranslation } from "react-i18next";

import "./index.scss";
import WebsiteThemeDetailPage from "../theme-detail";
import { useMemo } from "react";
import { NUMBER_UTIL } from "../../../utils/number.utils";
import ImageLoad from "../../../components/common/image-load";
import { IMAGE_DEFAULT_TYPE } from "../../../constants/app-constants";

interface Props {
  data?: any;
  onCreate?: () => void;
  onPublish?: () => void;
}

const SiteComplete = (props: Props) => {
  const { t } = useTranslation();

  const { data, onPublish, onCreate } = props;
  const { name, planName, plan, domain, isHaveDomain, themeId, siteThumbnail } = data;

  const websiteUrlThumbnail = useMemo(() => {
    return URL.createObjectURL(siteThumbnail?.[0]?.originFileObj);
  }, [siteThumbnail]);

  return (
    <div className="site-complete">
      <div className="site-complete__content">
        <Col lg={15} md={13} xs={24} className="site-complete__info">
          <div className="site-complete__info--body info-wrapper">
            <div className="info-wrapper__img">
              <ImageLoad type={IMAGE_DEFAULT_TYPE.website} src={websiteUrlThumbnail} alt="img" />
            </div>

            <div className="info-wrapper__content">
              <p className="info-wrapper__content--name">{name}</p>
              {isHaveDomain && (
                <p className="info-wrapper__content--domain">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={domain}
                  >
                    {domain}
                  </a>
                </p>
              )}

              <p className="info-wrapper__content--plan-name">
                {t("website.plan")}:
                <span className="info-wrapper__content--plan-name-value">
                  {planName}
                </span>
                <span className="info-wrapper__content--plan-name-value">
                  {`(${t('website.plan-price', {
                    price: NUMBER_UTIL.formatInput(plan.cost)
                  })})`}
                </span>
              </p>

              <p className="info-wrapper__content--status">
                {t("website.demo")} / {t("website.running")}:{" "}
                <span className="info-wrapper__content--status-value">Running</span>
              </p>

            </div>
          </div>
        </Col>

        <Col lg={9} md={11} xs={24} className="action">

          <Button onClick={onCreate} type="default">
            {t("common.save")}
          </Button>
          <Button type="primary" onClick={onPublish}>{t("common.publish")}</Button>
        </Col>
      </div>

      <Divider />

      <div className="site-complete__theme-detail">
        <WebsiteThemeDetailPage themeId={themeId} planId={plan.id} />
      </div>

    </div>
  );
};

export default SiteComplete;
