import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Pagination, Row } from "antd";

import { sampleDataSelector } from "../../../../states/theme";
import { PAGE_SIZE } from "../../../../constants/app-constants";
import { PaginationType } from "../../../../models/common.model";

import "./index.scss";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import ThemeCardGrid from "../../theme-card/grid";
import { getUserInfoSelector } from "../../../../states/auth";
import TableNoData from "../../../../components/common/table/table-no-data";
interface SampleListProps {
  loading?: boolean;
  pagination?: PaginationType;
};

const ThemeSampleDataList: FunctionComponent<SampleListProps> = ({ pagination, loading }) => {
  const { t } = useTranslation();

  // recoil
  const userInfo = useRecoilValue(getUserInfoSelector);
  const sampleDatas = useRecoilValue(sampleDataSelector);

  const isUserRole = useMemo(() => {
    return userInfo?.role === 'User';
  }, [userInfo]);

  const redirectLink = (themeId: string | number, isApproved: boolean) => {
    const userRedirectLink = !isUserRole ? ROUTE_PATHS.reviewThemeSampleData.replace(':siteId', themeId.toString()) : ''

    if (isApproved) {
      return ROUTE_PATHS.themesSampleDataDetail.replace(':siteId', themeId.toString())
    }

    return userRedirectLink
  };

  return (
    <div className="theme-sample-data-list">
      {!!sampleDatas.records?.length && <Row gutter={[12, 12]}>
        {sampleDatas.records.map((item: any) => (
          <Col key={item.id} lg={6} md={8} sm={12} xs={24}>
            <ThemeCardGrid data={item} link={redirectLink(item.id, item.isApproved)} isFree={item?.isFree}/>
          </Col>
        ))}
      </Row>}

      {!sampleDatas.records?.length && (
        <TableNoData>
          <>
            <p>
              {t("theme.no-theme-description")}
            </p>

            <Link to={ROUTE_PATHS.uploadThemeSampleData}>
              <Button type="primary">
                {t("theme.upload-theme")}
              </Button>
            </Link>
          </>
        </TableNoData>
      )}

      {!!pagination?.total &&
        <div className="pagination">
          <Pagination {...pagination} />
        </div>}
    </div>
  );
};

ThemeSampleDataList.defaultProps = {
  pagination: { pageSize: PAGE_SIZE, current: 1, total: 0, onChange: () => null },
};

export default ThemeSampleDataList;
