import React from "react";

const TicketIcon = () => {
  return (
    <svg
      width="44"
      height="33"
      viewBox="0 0 44 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.3125 13.375C42.7269 13.375 43.1243 13.2104 43.4174 12.9174C43.7104 12.6243 43.875 12.2269 43.875 11.8125V4C43.875 3.1712 43.5458 2.37634 42.9597 1.79029C42.3737 1.20424 41.5788 0.875 40.75 0.875H3.25C2.4212 0.875 1.62634 1.20424 1.04029 1.79029C0.45424 2.37634 0.125 3.1712 0.125 4V11.8125C0.125 12.2269 0.28962 12.6243 0.582646 12.9174C0.875671 13.2104 1.2731 13.375 1.6875 13.375C2.5163 13.375 3.31116 13.7042 3.89721 14.2903C4.48326 14.8763 4.8125 15.6712 4.8125 16.5C4.8125 17.3288 4.48326 18.1237 3.89721 18.7097C3.31116 19.2958 2.5163 19.625 1.6875 19.625C1.2731 19.625 0.875671 19.7896 0.582646 20.0826C0.28962 20.3757 0.125 20.7731 0.125 21.1875V29C0.125 29.8288 0.45424 30.6237 1.04029 31.2097C1.62634 31.7958 2.4212 32.125 3.25 32.125H40.75C41.5788 32.125 42.3737 31.7958 42.9597 31.2097C43.5458 30.6237 43.875 29.8288 43.875 29V21.1875C43.875 20.7731 43.7104 20.3757 43.4174 20.0826C43.1243 19.7896 42.7269 19.625 42.3125 19.625C41.4837 19.625 40.6888 19.2958 40.1028 18.7097C39.5167 18.1237 39.1875 17.3288 39.1875 16.5C39.1875 15.6712 39.5167 14.8763 40.1028 14.2903C40.6888 13.7042 41.4837 13.375 42.3125 13.375ZM40.75 22.5469V29H29.8125V24.3125H26.6875V29H3.25V22.5469C4.58749 22.1973 5.77135 21.4142 6.61629 20.32C7.46122 19.2258 7.91956 17.8824 7.91956 16.5C7.91956 15.1176 7.46122 13.7742 6.61629 12.68C5.77135 11.5858 4.58749 10.8027 3.25 10.4531V4H26.6875V8.6875H29.8125V4H40.75V10.4531C39.4125 10.8027 38.2286 11.5858 37.3837 12.68C36.5388 13.7742 36.0804 15.1176 36.0804 16.5C36.0804 17.8824 36.5388 19.2258 37.3837 20.32C38.2286 21.4142 39.4125 22.1973 40.75 22.5469Z"
        fill="white"
      />
    </svg>
  );
};

export default TicketIcon;
