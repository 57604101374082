enum CharactersPositionType {
  front = "front",
  behind = "behind",
}

export const AmountFormat = {
  thousandSeparator: ",",
  decimalSeparator: ".",
  allowedDecimals: 0,
  currencyCharacters: "$",
  charactersPosition: CharactersPositionType.front,
};

const padding = "000000";

const convertNumericToFormattedAmount = (
  value?: number,
  allowedDecimals: number = AmountFormat.allowedDecimals
): string => {
  const { currencyCharacters } = AmountFormat;
  if (value && value.toString().includes(currencyCharacters)) {
    return convertNumericToFormattedString(value, allowedDecimals);
  }

  return (
    currencyCharacters + convertNumericToFormattedString(value, allowedDecimals)
  );
};

const convertNumericToFormattedString = (
  value?: number,
  allowedDecimals: number = AmountFormat.allowedDecimals
): string => {
  if (!value) {
    value = 0;
  }

  if (isNaN(value)) {
    return value.toString();
  }

  const numericAmount = Number(value).toFixed(allowedDecimals).toString();

  const { thousandSeparator, decimalSeparator } = AmountFormat;

  let [integer, fraction = ""] = numericAmount.split(".");

  fraction =
    allowedDecimals > 0
      ? decimalSeparator + (fraction + padding).substring(0, allowedDecimals)
      : "";

  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  //if (integer == "") integer = "0";
  return integer + fraction;
};

const formatInput = (value: string | number | undefined) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const parserInput = (value: string | number | undefined) => {
  return `${value}`!.replace(/\$\s?|(,*)/g, "");
};

const formatPhoneNumber = (value: string | number | undefined) => {
  if (!value) {
    return ''
  }

  if (value >= -1 && value <= 1) {
    return `0${value}`;
  }
  return value?.toString();
}

const converNumberToPercentage = (
  value: number | undefined,
  allowedDecimals: number = AmountFormat.allowedDecimals
) => {
  if (!value) {
    value = 0;
  }

  if (isNaN(value)) {
    return value.toString();
  }

  return convertNumericToFormattedString(value, allowedDecimals) + "%";
};

export const NUMBER_UTIL = {
  parserInput,
  formatInput,
  formatPhoneNumber,
  converNumberToPercentage,
  convertNumericToFormattedAmount,
  convertNumericToFormattedString,
};

export const formatVND = (amount: string | number) => {
  const money = amount.toLocaleString("vi-VN", {
    // style: "currency",
    currency: "VND",
  });
  return money;
};
