import { Card, Rate } from "antd";
import { t } from "i18next";
import moment from "moment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import './index.scss';

interface ReviewItemProps {
    review: Value;
};

type Value = {
    rate: number;
    comment: string,
    userName: string,
    created: string,
};

const ReviewHeader: FunctionComponent<any> = ({ name, rate }) => {
    const { t } = useTranslation();
    return (
        <div className="user-rating">
            <div>
                <Rate disabled className="user-rating-star" value={rate} />
            </div>
            <div className="by-user">
                {t("common.by")} <span className="by-user__name">{name}</span>
            </div>
        </div>
    );
};

const ReviewItem: FunctionComponent<ReviewItemProps> = ({ review }) => {

    const calculateTimeAgo = (date : string) => {
        const localDateTime = moment.utc(date).unix() ;

        const year = moment().diff(moment.unix(localDateTime), "years");
        const month = moment().diff(moment.unix(localDateTime), "months");
        const day = moment().diff(moment.unix(localDateTime), "days");
        const hour = moment().diff(moment.unix(localDateTime), "hours");
        const minute = moment().diff(moment.unix(localDateTime), "minutes");
        const second = moment().diff(moment.unix(localDateTime), "seconds");

        if (!!year) {
            return t('theme.yearAgo', { year: year });
        }

        if (!!month) {
            return t('theme.monthAgo', { month: month });
        }

        if (!!day) {
            return t('theme.dayAgo', { day: day });
        };

        if (!!hour) {
            return t('theme.hourAgo', { hour: hour});
        };

        if (!!minute) {
            return t('theme.minuteAgo', { minute: minute});
        };

        if (second > 10) {
            return t('theme.secondAgo', { second: second});
        };

        return t('theme.oneMoment');
    };

    return (
        <Card
            type="inner"
            className="review-item"
            title={<ReviewHeader name={review.userName} rate={review.rate} />}
            extra={<span className="review-time">{calculateTimeAgo(review.created)}</span>}
        >
            {review.comment}
        </Card>
    );
};

export default ReviewItem;
