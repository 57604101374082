import React from "react";

const StorageIcon = () => {
  return (
    <svg
      width={54}
      height={54}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1021_1608)">
        <path
          d="M27.0002 13.5C15.9784 13.5 4.82153 11.1815 4.82153 6.74999C4.82153 2.31843 15.9783 0 27.0002 0C38.022 0 49.1788 2.31843 49.1788 6.74999C49.1788 11.1815 38.0219 13.5 27.0002 13.5ZM27.0002 1.92857C14.6387 1.92857 6.7502 4.78376 6.7502 6.74999C6.7502 8.71621 14.6387 11.5714 27.0002 11.5714C39.3616 11.5714 47.2501 8.71621 47.2501 6.74999C47.2501 4.78376 39.3616 1.92857 27.0002 1.92857Z"
          fill="#3F8DD8"
        />
        <path
          d="M27.0002 54C15.9784 54 4.82153 51.6816 4.82153 47.25V33.75C4.82153 33.2175 5.25328 32.7858 5.78582 32.7858C6.31835 32.7858 6.7501 33.2175 6.7501 33.75V47.25C6.7501 49.2162 14.6386 52.0714 27.0001 52.0714C39.3615 52.0714 47.25 49.2162 47.25 47.25V33.75C47.25 33.2175 47.6818 32.7858 48.2143 32.7858C48.7468 32.7858 49.1786 33.2175 49.1786 33.75V47.25C49.1787 51.6816 38.0219 54 27.0002 54Z"
          fill="#3F8DD8"
        />
        <path
          d="M27.0002 40.5C15.9784 40.5 4.82153 38.1816 4.82153 33.75V20.25C4.82153 19.7174 5.25328 19.2857 5.78582 19.2857C6.31835 19.2857 6.7501 19.7174 6.7501 20.25V33.7499C6.7501 35.7162 14.6386 38.5714 27.0001 38.5714C39.3615 38.5714 47.25 35.7162 47.25 33.7499V20.25C47.25 19.7174 47.6818 19.2857 48.2143 19.2857C48.7468 19.2857 49.1786 19.7174 49.1786 20.25V33.7499C49.1787 38.1816 38.0219 40.5 27.0002 40.5Z"
          fill="#3F8DD8"
        />
        <path
          d="M27.0002 27C15.9784 27 4.82153 24.6816 4.82153 20.2501V6.74999C4.82153 6.21746 5.25328 5.78571 5.78582 5.78571C6.31835 5.78571 6.7501 6.21746 6.7501 6.74999V20.25C6.7501 22.2163 14.6386 25.0714 27.0001 25.0714C39.3615 25.0714 47.25 22.2162 47.25 20.25V6.74999C47.25 6.21746 47.6818 5.78571 48.2143 5.78571C48.7468 5.78571 49.1786 6.21746 49.1786 6.74999V20.25C49.1787 24.6816 38.0219 27 27.0002 27Z"
          fill="#3F8DD8"
        />
        <path
          d="M44.3573 17.3571C44.8899 17.3571 45.3216 16.9254 45.3216 16.3928C45.3216 15.8603 44.8899 15.4285 44.3573 15.4285C43.8248 15.4285 43.3931 15.8603 43.3931 16.3928C43.3931 16.9254 43.8248 17.3571 44.3573 17.3571Z"
          fill="#3F8DD8"
        />
        <path
          d="M44.3573 30.8572C44.8899 30.8572 45.3216 30.4255 45.3216 29.8929C45.3216 29.3603 44.8899 28.9286 44.3573 28.9286C43.8248 28.9286 43.3931 29.3603 43.3931 29.8929C43.3931 30.4255 43.8248 30.8572 44.3573 30.8572Z"
          fill="#3F8DD8"
        />
        <path
          d="M44.3573 44.3572C44.8899 44.3572 45.3216 43.9254 45.3216 43.3929C45.3216 42.8603 44.8899 42.4286 44.3573 42.4286C43.8248 42.4286 43.3931 42.8603 43.3931 43.3929C43.3931 43.9254 43.8248 44.3572 44.3573 44.3572Z"
          fill="#3F8DD8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1021_1608">
          <rect width={54} height={54} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StorageIcon;
