const BudgetIconNew = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50" fill="none">
            <path d="M6.52539 15.0547L20.6041 0.975978L24.2691 4.64092" stroke="#00B378" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.9434 14.9676L27.9348 0.976173L42.571 15.6125L42.5595 15.624" stroke="#00B378" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.66016 30.5557V45.1172C1.66016 47.2745 3.40908 49.0234 5.56641 49.0234H40.5273C42.6847 49.0234 44.4336 47.2745 44.4336 45.1172V38.2894" stroke="#00B378" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44.4336 25.7892V18.9615C44.4336 16.804 42.6847 15.0552 40.5273 15.0552H5.56641C3.40908 15.0552 1.66016 16.804 1.66016 18.9615V31.0186" stroke="#00B378" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M48.3398 25.7891H28.6133C25.1615 25.7891 22.3633 28.5873 22.3633 32.0391C22.3633 35.4908 25.1615 38.2891 28.6133 38.2891H48.3398V25.7891Z" stroke="#00B378" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.5664 32.0391C30.5664 30.9604 29.692 30.0859 28.6133 30.0859C27.5346 30.0859 26.6602 30.9604 26.6602 32.0391C26.6602 33.1178 27.5346 33.9922 28.6133 33.9922C29.692 33.9922 30.5664 33.1178 30.5664 32.0391Z" stroke="#00B378" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
};

export default BudgetIconNew;