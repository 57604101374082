import { Button, Upload } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import './index.scss';

import { UploadOutlined } from "@ant-design/icons";

const uploadFileConfig = {
    maxCount: 1,
    beforeUpload: () => false,
};

interface UploadFileProps {
    [key : string]: any
};

const UploadFiles: FunctionComponent<UploadFileProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Upload {...uploadFileConfig} {...props} className="upload-file">
            <Button>
                <UploadOutlined /> {t('common.clickToUpload')}
            </Button>
        </Upload>
    );
};

export default UploadFiles;
