import { FunctionComponent, ReactComponentElement, useCallback } from "react";
import { QuestionOutlined } from "@ant-design/icons";

import SpaIcon from "./icons/SpaIcon";
import FoodIcon from "./icons/FoodIcon";
import SaleIcon from "./icons/SaleIcon";
import NewsIcon from "./icons/NewsIcon";
import EventIcon from "./icons/EventIcon";
import TravelIcon from "./icons/TravelIcon";
import FashionIcon from "./icons/FashionIcon";
import ServiceIcon from "./icons/ServiceIcon";
import RealEstate from "./icons/RealEstateIcon";
import BusinessIcon from "./icons/BusinessIcon";
import CosmeticIcon from "./icons/CosmeticIcon";
import DropShipIcon from "./icons/DropShipIcon";
import BlogPostIcon from "./icons/BlogPostIcon";
import FurnitureIcon from "./icons/FurnitureIcon";
import EducationIcon from "./icons/EducationIcon";
import TechnologyIcon from "./icons/TechnologyIcon";
import MomAndBabyIcon from "./icons/MomAndBabyIcon";
import RestauranceIcon from "./icons/RestauranceIcon";
import WeddingCardIcon from "./icons/WeddingCardIcon";
import LandingPageIcon from "./icons/LandingPageIcon";
import PresentationIcon from "./icons/PresentationIcon";
import DefaultCategoryIcon from "./icons/DefaultIcon";

interface IconType {
    [key: string]: ReactComponentElement<any>;
 }

const IconPlugins: IconType = {
    'spa': <SpaIcon/>,
    'food': <FoodIcon/>,
    'sale': <SaleIcon/>,
    'news': <NewsIcon/>,
    'event': <EventIcon/>,
    'blog': <BlogPostIcon/>,
    'travel': <TravelIcon/>,
    'fashion': <FashionIcon/>,
    'service': <ServiceIcon/>,
    'realEstate': <RealEstate/>,
    'business': <BusinessIcon/>,
    'cosmetic': <CosmeticIcon/>,
    'furniture': <FurnitureIcon/>,
    'education': <EducationIcon/>,
    'technology': <TechnologyIcon/>,
    'momandbaby': <MomAndBabyIcon/>,
    'landingPage': <LandingPageIcon/>,
    'restaurant': <RestauranceIcon/>,
    'PODandDropship': <DropShipIcon/>,
    'weddingCard': <WeddingCardIcon/>,
    'default': <DefaultCategoryIcon/>,
    'presentation': <PresentationIcon/>,
};

interface IconProps {
    icon: string; 
}

const CategoryIcon: FunctionComponent<IconProps> = ({ icon }) => {

    const IconComponent = useCallback(() => {
        return IconPlugins[icon] ?? <QuestionOutlined />
    }, [icon])

    return <IconComponent/>;
};

export default CategoryIcon;