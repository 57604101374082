import { Button, Modal } from "antd";
import classNames from "classnames";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ProtectedAction from "../../../../components/action-protected";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { RoleEnum } from "../../../../enums/app.enum";
import { getUserInfoSelector } from "../../../../states/auth";
import { formatVND } from "../../../../utils/number.utils";
import confirmReasonDeactivated from "../../deactivated-modal";

import "./index.scss";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";

interface ThemeDetailActionProps {
  price: number;
  isFree: boolean;
  loading?: boolean;
  isSuccess?: boolean;
  isApprove?: boolean;
  isDeactivated: boolean;
  siteId?: number | string;
  authorId?: number | string;
  categoryId?: number | string;

  onCreate?: () => void;
  onDelete?: () => void;
  onActive?: () => void;
  onApprove?: () => void;
  onReject?: (value: any) => void;
  onDeactive?: (reason: string) => void;
}

const confirm = Modal.confirm;

const ThemeDetailAction: FunctionComponent<ThemeDetailActionProps> = (
  props
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const { price, siteId, categoryId, authorId, loading, onActive, onApprove, onDelete, onDeactive, isDeactivated, isFree } = props;

  const { currencyConfig, convertNumericToFormattedAmount} = useCurrencyConfig();

  // recoil
  const userInfo = useRecoilValue(getUserInfoSelector);

  const isOwnCreateSite = useMemo(() => {
    return authorId === userInfo?.userId;
  }, [authorId]);

  const handleEditButtonClick = () => {
    if (siteId) {
      navigate(ROUTE_PATHS.editSiteOverviewUpload.replace(':siteId', (siteId.toString())));
    };
  };

  const handleDeactiveButtonClick = () => {

    const handleGetReason = (reason: any) => {
      onDeactive?.(reason.replaceAll('\n', '%0A'));
    }

    confirm({
      ...confirmReasonDeactivated(handleGetReason),
      title: t('theme.confirmDeactivateTheme'),
      okButtonProps: { loading: loading },
      okType: 'primary'
    });
  };

  const handleActiveButtonClick = () => {
    onActive?.();
  };

  const handleDeleteButtonClick = () => {
    Modal.confirm({
      title: t('common.confirmQuestion'),

      onOk: onDelete,
    });
  };

  const handleCreateButtonClick = () => {
    navigate(ROUTE_PATHS.websiteCreateSites, {
      state: {
        site: state.site,
        themeSiteId: siteId,
        categoryId: categoryId,
      }
    });
  };

  const handleApproveButtonClick = () => {
    onApprove?.();
  };

  return (
    <div className="gx-text-center theme-detail__wrapper">
      <div className="theme-detail__info">
        <span className="theme-detail__info--price">
          {!isFree ? convertNumericToFormattedAmount(price) : 0} {currencyConfig.currency}
        </span>
        <span className="theme-detail__info--unit">/ {t("theme.website")}</span>
      </div>

      <ProtectedAction roles={[RoleEnum.User]}>
        <div className="theme-detail__group">
          <div className="gx-module-list-content theme-detail__button-group">
            <Button
              onClick={handleCreateButtonClick}
              type="primary"
              disabled={!!isDeactivated}
              className="theme-detail__button gx-mt-3 gx-mb-0"
            >
              {t("theme.createWebsite")}
            </Button>
          </div>

          {isOwnCreateSite && (
            <div className="theme-detail__secondary-button-group">
              <Button
                onClick={handleEditButtonClick}
                className="theme-detail__button"
              >
                {t("common.edit")}
              </Button>

              {!isDeactivated && <Button
                danger
                loading={loading}
                onClick={handleDeactiveButtonClick}
                className="theme-detail__button"
              >
                {t("theme.deactivate")}
              </Button>}

              {!!isDeactivated && <Button
                danger
                loading={loading}
                onClick={handleActiveButtonClick}
                className="theme-detail__button"
              >
                {t("common.activate")}
              </Button>}
            </div>
          )}
        </div>
      </ProtectedAction>

      <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.Admin]}>
        <div className="theme-detail__group">
          <div className="gx-module-list-content theme-detail__button-group">
            <Button
              type="primary"
              disabled={!!isDeactivated}
              onClick={handleCreateButtonClick}
              className="theme-detail__button ant-btn ant-btn-primary gx-mt-3 gx-mb-0"
            >
              {t("theme.createWebsite")}
            </Button>
          </div>

          {isOwnCreateSite && (
            <div className="theme-detail__secondary-button-group">
              <Button
                onClick={handleEditButtonClick}
                className="theme-detail__button"
              >
                {t("common.edit")}
              </Button>

              {!isDeactivated && <Button
                danger
                loading={loading}
                onClick={handleDeactiveButtonClick}
                className="theme-detail__button"
              >
                {t("theme.deactivate")}
              </Button>}

              {!!isDeactivated && <Button
                danger
                loading={loading}
                onClick={handleActiveButtonClick}
                className="theme-detail__button"
              >
                {t("common.activate")}
              </Button>}
            </div>
          )}
        </div>
      </ProtectedAction>

      {/* <ProtectedAction
        roles={[
          RoleEnum.Admin,
          RoleEnum.Accountant,
        ]}
      >
        <div className="gx-module-list-content theme-detail__button-group">
          <Button
            onClick={onActive}
            type="primary"
            className="theme-detail__button-item ant-btn ant-btn-primary gx-mt-3 gx-mb-0 gx-mr-0"
          >
            {t("common.activate")}
          </Button>
          <Button
            onClick={onDelete}
            ghost
            className="theme-detail__button-item ant-btn-danger gx-mt-3 gx-mb-0"
          >
            {t("common.delete")}
          </Button>
        </div>
      </ProtectedAction> */}
    </div>
  );
};

ThemeDetailAction.defaultProps = {
  siteId: "",
  price: 0,

  // onEdit: () => null,
  onCreate: () => null,
  onDelete: () => null,
  onActive: () => null,
};

export default ThemeDetailAction;
