import { useEffect, useMemo, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Card, Table } from "antd";

import { UserApi } from "../../../apis/user.api";

import TableNoData from "../../../components/common/table/table-no-data";

import { UserOutlined } from "@ant-design/icons";

import "./index.scss";
import useCurrencyConfig from "../../../hooks/useCurrencyConfig";
import { generateImage } from "../../../utils/app.utils";

const UserTable = () => {
  const { t } = useTranslation();
  const { currencyConfig,
    convertNumericToFormattedAmount } = useCurrencyConfig();

  // state data
  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [remindLoading, setRemindLoading] = useState<boolean>(false);
  const [ remindUserId, setRemindUser ] = useState<number | string>(0);

  const validUsers = useMemo(() => {
      return tableData.filter((user: any) => user?.numOfSites > 0);
  }, [tableData]);

  const getUserOverviewTable = async () => {
    try {
      setIsLoading(true);

      const params = {
        pageIndex: 1,
        pageSize: 10,
      };
      const response = await UserApi.getUserOverviewTable(params);

      if (response?.data) {
        setTableData(response.data.records);
      };
      
    } finally {
      setIsLoading(false);
    }
  };

  const renderButton = (isStatus: boolean, data: any) => {
    if (!isStatus) {
      return (
        <Button loading={remindLoading && remindUserId === data.id} type="primary" className="remind__button remind__button-remind" onClick={() => handleRemindButtonClick(data.id)}>{t("common.remind")}</Button>
      )
    };

    return <Button className="remind__button remind__button-reminded" disabled>{t('common.reminded')}</Button>
  };

  const handleRemindButtonClick = async (userId: number | string) => {
    try {
      setRemindUser(userId);
      setRemindLoading(true);
      const result = await UserApi.remindUserOverviewTable(userId);

      if (result?.data) {
        const userPosition = tableData.findIndex(data => data.id === userId);

        if (userPosition === -1) {
          return;
        }

        const userById = tableData[userPosition];
        userById.isReminded = true;

        tableData.splice(userPosition, 1, userById);
      };
      
    } finally {
      setRemindLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "avartar",
      key: "avartar",
      render: (text) =>
        text ? (
          <Avatar src={generateImage(text)} alt="avatar" className="user-avatar" />
        ) : (
          <Avatar size="small" icon={<UserOutlined />} />
        ),
      width: 50,
    },
    {
      title: t("user.user"),
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: t("user.noOfSite"),
      dataIndex: "numOfSites",
      key: "numOfSites",
    },
    {
      title: t("user.budget"),
      dataIndex: "budget",
      key: "budget",
      render: (text) => <span>{convertNumericToFormattedAmount(text)} {currencyConfig.currency}</span>,
    },

    {
      title: t("website.expiryDate"),
      dataIndex: "remainingDays",
      key: "remainingDays",
      render: (text) => <span>{t('website.number-days-left', { number: text})}</span>,
    },

    {
      key: "isReminded",
      dataIndex: "isReminded",
      render: (text, records) => (
        <div className="remind">
          {renderButton(text, records)}
        </div>
      ),
      width: 150,
    },
  ];

  useEffect(() => {
    getUserOverviewTable();
  }, []);

  return (
    <div className="user-overview-table">
      <Card
        title={t("user.users")}
        bordered
        className="gx-card-widget user-overview-table__wrapper"
      >
        {(!!isLoading || validUsers.length > 0) ? (
          <Table
            className="gx-table-responsive user-overview-table__content"
            size="small"
            bordered={false}
            columns={columns}
            pagination={false}
            scroll={{ x: 600 }}
            loading={isLoading}
            dataSource={validUsers}
          />
        ) : (
          <TableNoData />
        )}
      </Card>
    </div>
  );
};

export default UserTable;
