import { Col, Row } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    HistoryOutlined, PlayCircleOutlined,
    StopOutlined,
    ClusterOutlined,
    DashboardOutlined,
    TeamOutlined
} from "@ant-design/icons";

import { useRecoilValue } from "recoil";
import { RoleEnum } from "../../../enums/app.enum";
import CostIcon from "../../../assets/icons/CostIcon";
import { WebsiteApi } from "../../../apis/website.api";
import UserTable from "../../../views/users/user-table";
import TicketIcon from "../../../assets/icons/TicketIcon";
import SiteTypes from "../../../views/dashboard/site-type";
import { getUserInfoSelector } from "../../../states/auth";
import RevenueIcon from "../../../assets/icons/RevenueIcon";
import BudgetIconNew from "../../../assets/icons/BudgetIconNew";
import useCurrencyConfig from "../../../hooks/useCurrencyConfig";
import WelcomeCard from "../../../components/common/welcome-card";
import ProtectedAction from "../../../components/action-protected";
import SiteVisit from "../../../views/dashboard/site-type/site-visit";
import SiteGrowth from "../../../views/dashboard/overview/site-growth";
import ReviewListTable from "../../../views/dashboard/overview/review-list";
import ThemeIcon from "../../../views/dashboard/overview/icons/themes-icon";
import RenewWebsite from "../../../views/dashboard/overview/renew-websites";
import IconWithTextCard from "../../../components/common/icon-with-text-card";
import IconWithTextCardInUser from "../../../components/common/icon-with-text-card-user";
import { BudgetIcon, DataStructureIcon, PluginIcon, StorageIcon } from "../../../assets/icons";


const defaultValues = {
    revenue: 0,
    storage: "",
    userBudget: 0,
    totalWebSites: 0,
    trafficRaise: "",
    totalDemoWebsites: 0,
    totalRenewWebsites: 0,
    webSiteChartModel: {},
    siteGrowthLastWeek: "",
    siteGrowthLastYear: "",
    totalRuningWebsites: 0,
    siteGrowthLastMonth: "",
    totalExpiredWebsites: 0,
};

const DashboardOverviewPage: FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const { currencyConfig, convertNumericToFormattedAmount } = useCurrencyConfig();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [budgetDetail, setBudgetDetail] = useState<any>(null);

    // recoil
    const userInfo = useRecoilValue(getUserInfoSelector);

    const [overview, setOverview] = useState<any>(defaultValues);

    const loadDashboardData = async () => {
        const response = await WebsiteApi.dashboardOverview();

        setOverview(response.data);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        loadDashboardData();
    }, []);

    return (
        <>
            <Row>
                <Col xs={24}>
                    <div className="gx-card">
                        <div className="gx-card-body">
                            <Row gutter={[6, 6]}>
                                <Col xl={8} lg={9} md={9} sm={24} xs={24}>
                                    <WelcomeCard
                                        unit={overview.totalWebSites < 2 ? t('website.website') : t('website.websites')}
                                        number={overview.totalWebSites}
                                    />
                                </Col>

                                <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={24}
                                    className="gx-visit-col"
                                >
                                    <SiteVisit data={overview.webSiteChartModel} />
                                </Col>

                                <Col
                                    xl={4}
                                    lg={4}
                                    md={4}
                                    sm={12}
                                    xs={24}
                                    className="gx-visit-col"
                                >
                                    <SiteTypes />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <SiteGrowth
                        lastWeek={overview.siteGrowthLastWeek}
                        lastMonth={overview.siteGrowthLastMonth}
                        lastYear={overview.siteGrowthLastYear}
                    />
                </Col>

                <Col span={24}>
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard
                                cardColor="blue"
                                icon={<TicketIcon />}
                                subTitle={t("website.demo")}
                                numberWebsite={overview.totalDemoWebsites}
                            />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard
                                cardColor="teal"
                                icon={<PlayCircleOutlined />}
                                subTitle={t("website.running")}
                                numberWebsite={overview.totalRuningWebsites}
                            />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard
                                cardColor="orange"
                                icon={<HistoryOutlined />}
                                subTitle={t("website.renew")}
                                numberWebsite={overview.totalRenewWebsites}
                            />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard
                                cardColor="red"
                                icon={<StopOutlined />}
                                subTitle={t("website.expired")}
                                numberWebsite={overview.totalExpiredWebsites}
                            />
                        </Col>
                    </Row>
                </Col>

                <ProtectedAction roles={[RoleEnum.User]}>
                    <Col span={24}>
                        <Row>
                            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<BudgetIconNew />}
                                    numberWebsite={convertNumericToFormattedAmount(overview?.userBudget || 0)}
                                    subTitle={`Budget (${currencyConfig.currency})`}
                                />
                            </Col>
                            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<BudgetIcon />}
                                    numberWebsite={convertNumericToFormattedAmount(overview?.userCommission || 0)}
                                    subTitle={`Commission (${currencyConfig.currency})`}
                                />
                            </Col>
                            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<StorageIcon />}
                                    numberWebsite={overview?.storage || '0 GB'}
                                    subTitle={t("website.storage")}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ProtectedAction>

                <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.Accountant]}>
                    <Col span={24}>
                        <Row>
                            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<RevenueIcon />}
                                    numberWebsite={convertNumericToFormattedAmount(overview?.revenue || 0)}
                                    subTitle={`Revenue (${currencyConfig.currency})`}
                                />
                            </Col>
                            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<CostIcon />}
                                    numberWebsite={convertNumericToFormattedAmount(overview?.cost || 0)}
                                    subTitle={`Cost (${currencyConfig.currency})`}
                                />
                            </Col>
                            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<StorageIcon />}
                                    numberWebsite={overview?.storage || '0 GB'}
                                    subTitle={t("website.storage")}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ProtectedAction>

                <ProtectedAction roles={[RoleEnum.User]}>
                    <Col span={24}>
                        <Row>
                            <Col span={24} md={8}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<ThemeIcon />}
                                    numberWebsite={overview.totalThemes}
                                    subTitle={overview.totalThemes < 2 ? t('dashboard.theme') : t('dashboard.theme_plurals')}
                                />
                            </Col>

                            <Col span={24} md={8}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    subTitleColor="grey"
                                    icon={<PluginIcon />}
                                    numberWebsite={overview.totalPlugins}
                                    subTitle={overview.totalPlugins < 2 ? t("dashboard.plugin") : t("dashboard.plugin_plurals")}
                                />
                            </Col>


                            <Col span={24} md={8}>
                                <IconWithTextCard
                                    iconSize={0}
                                    numberColor="black"
                                    numberWebsite={overview.totalUsers}
                                    subTitleColor="grey"
                                    icon={<DataStructureIcon />}
                                    subTitle={t("theme.sampleData")}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ProtectedAction>

                <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.Accountant]}>
                    <Col span={24}>
                        <Row>
                            <Col xl={8} lg={8} md={8} xs={24}>
                                <IconWithTextCardInUser
                                    cardColor="orange"
                                    icon={<DashboardOutlined />}
                                    subTitle={overview.totalAdmins < 2 ? t("user.admins") : t("user.admins-plurals")}
                                    number={overview.totalAdmins}
                                />
                            </Col>
                            <Col xl={8} lg={8} md={8} xs={24}>
                                <IconWithTextCardInUser
                                    cardColor="blue"
                                    icon={<ClusterOutlined />}
                                    subTitle={overview.totalMods < 2 ? t("user.moderator") : t("user.moderator-plurals")}
                                    number={overview.totalMods}
                                />
                            </Col>
                            <Col xl={8} lg={8} md={8} xs={24}>
                                <IconWithTextCardInUser
                                    cardColor="purple"
                                    icon={<TeamOutlined />}
                                    subTitle={overview.totalUsers < 2 ? t("user.users") : t("user.users-plurals")}
                                    number={overview.totalUsers}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ProtectedAction>

                <ProtectedAction roles={[RoleEnum.User]}>
                    <Col span={24}>
                        <RenewWebsite />
                    </Col>
                </ProtectedAction>

                <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.Accountant]}>
                    <Col span={24}>
                        <UserTable />
                    </Col>
                </ProtectedAction>

                <ProtectedAction roles={[RoleEnum.Accountant]}>
                    <Col span={24}>
                        <ReviewListTable />
                    </Col>
                </ProtectedAction>

            </Row>
{/* 
            <BudgetModal
                destroyOnClose
                open={showModal}
                data={budgetDetail?.budgets}

                onCancel={handleModalClose}
                onReject={handleModalClose}
            /> */}
        </>
    );
};

export default DashboardOverviewPage;
