import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row } from "antd";
import Metrics from "../metrics";
import i18n from "../../../utils/i18n";

interface Props {
  id: string;
  title: string;
  color?: string;
  percent: string;
  status?: string ;
  toColor?: string;
  cardTitle: string;
  trafficData: any[];
  fromColor?: string;
}

const GrowthCard = (props: Props) => {
  const { fromColor, toColor, id, color, percent, cardTitle, status } = props;

  return (
    <Metrics styleName={`gx-card-full`} title={<span style={{color: "#262626", fontWeight: 500}}>{cardTitle}</span>}>
      <Row>
        <Col lg={9} md={24} sm={9} xs={9}>
          <div className="gx-pb-4 gx-pl-4 gx-pt-4">
            <h2
              style={{ color: color || "#000" }}
              className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-chart-up gx-text-nowrap"
            >
              <span style={{fontWeight: 700}}>{percent}</span>
              
              <i className={`icon icon-menu-${status} gx-fs-xxl`} />
            </h2>
            <p className="gx-mb-0 gx-text-grey">{props.title}</p>
          </div>
        </Col>
        <Col lg={15} md={24} sm={15} xs={15}>
          <ResponsiveContainer width="100%" height={103}>
            <AreaChart
              data={props.trafficData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip
                labelFormatter={(index) => props.trafficData[index].date}
              />
              <defs>
                <linearGradient id={id} x1="0" y1="0" x2="1" y2="0">
                  <stop offset="5%" stopColor={fromColor} stopOpacity={0.9} />
                  <stop offset="95%" stopColor={toColor} stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                strokeWidth={0}
                stackId="2"
                stroke="#867AE5"
                fill={`url(#${id})`}
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Metrics>
  );
};

GrowthCard.defaultProps = {
  status: 'up',
  cardTitle: i18n.t("website.siteGrowth"),
};

export default GrowthCard;
