import { Navigate, useLocation } from "react-router-dom";

import LocalUtils from "../../utils/local.utils";
import { authRouters, mainRouters } from "../../routing";
import { LOCAL_COOKIE_KEY } from "../../constants/app-constants";

interface ProtectedRouteProps {
  children: JSX.Element;
  redirectTo: string;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const location = useLocation();

  const userInfo = LocalUtils.getUserInfo();
  const isLogin = LocalUtils.getCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN);
  const isAuthLayout = authRouters.map(route => route.href).includes(location.pathname);
  const isMainLayout = mainRouters.map(route => route.href).includes(location.pathname);

  if (!userInfo && !isLogin && !isAuthLayout && !!isMainLayout) {
    return (
      <Navigate
          to={props.redirectTo}
          state={{ redirectUrl: location.pathname }}
          replace
      />
    )
  };

  if (!!userInfo && isAuthLayout && !isMainLayout) {
    return (
      <Navigate
          to={props.redirectTo}
          replace
      />
    )
  };

  return props.children;
};

export default ProtectedRoute;
