import { Col, Row } from "antd";
import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";

import { UserApi } from "../../../apis/user.api";
import { getUserInfoSelector } from "../../../states/auth";

import UserAbout from "../../../views/users/user-detail/about";
import ProfileHeader from "../../../views/users/user-detail/profile-header";
import paymentApis from "../../../apis/payment.api";
import { ROUTE_PATHS } from "../../../constants/router.constants";

interface UserDetailProps { };

const UserDetailContext = createContext<any>({});

const UserDetailPage: FunctionComponent<UserDetailProps> = () => {
  // ultils
  const params = useParams();
  const { userId } = params;
  const navigate = useNavigate();

  // state data
  const [userDetail, setUserDetail] = useState<any>({});
  const [paymentAccounts, setPaymentAccounts] = useState<any>([]);
  const [temporityPaymentAccounts, setTenporityPaymentAccounts] = useState<any>([]);

  // recoil
  const [userInfo, setUserInfo] = useRecoilState(getUserInfoSelector);

  const userInfoId = useMemo(() => {
    if (userId) {
      return userId;
    };

    return userInfo?.userId;
  }, [userId, userInfo]);

  const isMyAccount = useMemo(() => {
    if (userId) {
      return false;
    };

    return true;
  }, [userId]);

  const handleSaveButtonClick = (values: any) => {
    setUserDetail({
      ...userDetail,
      avatar: values.avatar,
      fullName: values.fullName,
    });

    if (isMyAccount) {
      setUserInfo({
        ...userInfo,
        avatar: values.avatar,
        fullName: values.fullName,
      });
    }

  };

  const loadUserDetail = async () => {
    try {
      const result = await UserApi.getUserDetail(userInfoId);
  
      if (result?.data) {
        setUserDetail(result.data);
      };
    } catch(error: any) {
      if (error?.response?.status === 404) {
        navigate(ROUTE_PATHS.notFound)
      }
    }
  };

  const loadUserPaymentAccount = async () => {
    const response = await paymentApis.getUserPaymentAccount({ userId: userInfoId });

    const cloneData = Array.isArray(response?.data) ? response.data.map((item: any) => Object.assign({}, item)) : [];

    setPaymentAccounts(cloneData);


    setTenporityPaymentAccounts(response?.data || []);
  };

  const getPaymentAccountById = (accountId: number) => {
    const position = paymentAccounts.findIndex((paymentAccount: any) => paymentAccount.id === accountId);
    const paymentAccount = paymentAccounts?.[position] || {};

    return {
      position,
      paymentAccount,
    }
  };
  
  const addNewPaymentAccount = async (paymentAccount : any) => {
    const response = await paymentApis.createNewUserPaymentAccount(paymentAccount);

    if (response?.data) {
      loadUserPaymentAccount();
    }
  };

  const deleteAccount = (account: any) => {
    const clonePaymentAccounts = [...paymentAccounts];
    const {position, paymentAccount } = getPaymentAccountById(account?.id);

    if (position !== -1) {
      const deletedAccount = Object.assign(paymentAccount, { isDelete: true });
      clonePaymentAccounts.splice(position, 1, deletedAccount);

      setPaymentAccounts(clonePaymentAccounts);
    }
  };

  const updateBankAccount = async (account: any) => {
    const payload = paymentAccounts?.reduce((changePayment: Array<any>, currentPayment: any) => {
      const payment = {
        id: currentPayment.id,
        isDefault: currentPayment.isDefault,
        isDeleted: currentPayment.isDelete || false,
      }

      changePayment.push(payment);

      return changePayment;
    }, []);

    await paymentApis.updateStatusUserPaymentAccount(payload);
  };

  const changeDefaultAccountStatus = async ({defaultStatus, bankAccount} : any) => {
    const clonePaymentAccounts = [...paymentAccounts];
    
    if (defaultStatus) {
      const defaultAccountPosition = clonePaymentAccounts?.findIndex((account: any) => account.isDefault === true);
      if (defaultAccountPosition !== -1) {
        clonePaymentAccounts.splice(defaultAccountPosition, 1, Object.assign(clonePaymentAccounts[defaultAccountPosition], { isDefault: false }));
      }
    }

    const {position, paymentAccount } = getPaymentAccountById(bankAccount?.id);

    if (position !== -1) {
      const deletedAccount = Object.assign(paymentAccount, { isDefault: defaultStatus });
      clonePaymentAccounts.splice(position, 1, deletedAccount);

      setPaymentAccounts(clonePaymentAccounts);
    }
  };

  const cancelUpdatePaymentAccount = () => {
    const cloneData = temporityPaymentAccounts.map((item: any) => Object.assign({}, item));
    setPaymentAccounts(cloneData);
  };

  useEffect(() => {
    if (userInfoId) {
      loadUserDetail();
      loadUserPaymentAccount();
    };

  }, [userInfoId]);

  return (
    <UserDetailContext.Provider
      value={{
        userData: userDetail,
        isAccount: isMyAccount,
        userInfoId: userInfoId,
        paymentAccounts: paymentAccounts,

        setUserDetail,
        deleteAccount,
        updateBankAccount,
        addNewPaymentAccount,
        changeDefaultAccountStatus,
        cancelUpdatePaymentAccount,
      }}
    >
      <ProfileHeader
        canEdit={isMyAccount}
        onSave={handleSaveButtonClick}
      />

      <div className="gx-profile-content">
        <Row>
          <Col span={24}>
            <UserAbout />
          </Col>
        </Row>
      </div>
    </UserDetailContext.Provider>
  );
};

export default UserDetailPage;

export const useUserDetailContext = () => useContext(UserDetailContext);