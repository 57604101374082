import { Button, Carousel } from "antd";
import { FunctionComponent, useRef } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import './index.scss';
import { generateImage } from "../../../utils/app.utils";

interface ThemeCarouselProps {
    images?: Array<any>;
};

const ThemeCarousel: FunctionComponent<ThemeCarouselProps> = ({ images }) => {

    const slider = useRef<any>(null);

    return (
        <div className="carousel">
            <Button
                onClick={() => slider.current.prev()}
                className="left-btn"
                icon={<LeftOutlined />}
            />

            <Carousel
                effect="fade"
                ref={slider}
            >

                {images?.map(image => {
                    return (
                        <div key={image} className="image__wrapper">
                            <img
                                src={generateImage(image)}
                                className="image__item"
                            />
                        </div>
                    )
                })}

            </Carousel>
            
            <Button
                onClick={() => slider.current.next()}
                className="right-btn"
                icon={<RightOutlined />}
            />
        </div>
    );
};

ThemeCarousel.defaultProps = {
    images: [],
};

export default ThemeCarousel;
