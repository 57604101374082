import moment from "moment";
import { Avatar, Card } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserOutlined } from "@ant-design/icons";

import { UserApi } from "../../../apis/user.api";
import { FORMAT_DATE_TIME } from '../../../constants/app-constants';

import "./index.scss";

const NewUserCard = () => {
  const { t } = useTranslation();

  // state data
  const [todayUser, setTodayUser] = useState<any[]>([]);
  const [yesterdayUser, setYesterdayUser] = useState<any[]>([]);

  const getTodayUser = async () => {
    const today = moment().format(FORMAT_DATE_TIME.ymd);

    const result = await loadUserByDate(today);

    if (result?.data) {
      setTodayUser(result.data.records);
    };
  };

  const getYesterdayUser = async () => {
    const yesterday = moment().add(-1, "days").format(FORMAT_DATE_TIME.ymd);

    const result = await loadUserByDate(yesterday);

    if (result?.data) {
      setYesterdayUser(result.data.records);
    };
  };

  const loadUserByDate = async (date: string) => {
    const result = await UserApi.getLatestUser({ date });

    return result;
  };

  useEffect(() => {
    Promise.all([
      getTodayUser(),
      getYesterdayUser(),
    ]);
  }, []);

  return (
    <div className="new-user-card">
      <Card
        title={<div className="new-user-title">{t("user.newUserTitle")}</div>}
        bordered
        className="gx-card-widget"
      >
        <div>
          <p className="today">
            {t("user.today")} ({todayUser.length} {t("user.newUsers")})
          </p>
          <User data={todayUser} />
          <br />
        </div>
        <div>
          <p>
            {t("user.yesterday")} ({yesterdayUser.length} {t("user.newUsers")})
          </p>
          <User data={yesterdayUser} />
        </div>
      </Card>
    </div>
  );
};

export default NewUserCard;

interface Props {
  data: any;
}

const User = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  return data.map((item: any) => (
    <div className="user">
      <div>
        {item.avatar ? (
          <img
            src={item.avatar}
            className="image-in-new-user-card"
            alt={item.fullName}
          />
        ) : (
          <Avatar
            size="large"
            className="image-in-new-user-card"
            icon={<UserOutlined />}
          />
        )}
      </div>
      <div>
        <div className="username">{item.fullName}</div>
        <p className="registered">
          {t("user.registered")}: {moment(item.createAt).format("DD-MM-YYYY")}
        </p>
      </div>
    </div>
  ));
};
