const EducationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={35} viewBox="0 0 40 35" fill="none">
            <path d="M20 20.0062C19.9082 20.0062 19.8175 19.9859 19.7344 19.9469L0.359362 10.8312C0.251989 10.7808 0.1612 10.7008 0.0976072 10.6006C0.0340142 10.5004 0.000244141 10.3842 0.000244141 10.2656C0.000244141 10.147 0.0340142 10.0308 0.0976072 9.9306C0.1612 9.83044 0.251989 9.75045 0.359362 9.69998L19.7344 0.581227C19.8175 0.542204 19.9082 0.521973 20 0.521973C20.0918 0.521973 20.1825 0.542204 20.2656 0.581227L39.6406 9.69998C39.748 9.75045 39.8388 9.83044 39.9024 9.9306C39.966 10.0308 39.9997 10.147 39.9997 10.2656C39.9997 10.3842 39.966 10.5004 39.9024 10.6006C39.8388 10.7008 39.748 10.7808 39.6406 10.8312L20.2656 19.9469C20.1825 19.9859 20.0918 20.0062 20 20.0062ZM2.09374 10.2656L20 18.7031L37.9062 10.2656L20 1.83748L2.09374 10.2656Z" fill="url(#paint0_linear_1256_26950)" />
            <path d="M7.5 21C7.33424 21 7.17527 20.9342 7.05806 20.8169C6.94085 20.6997 6.875 20.5408 6.875 20.375V13.8125C6.875 13.6467 6.94085 13.4878 7.05806 13.3706C7.17527 13.2533 7.33424 13.1875 7.5 13.1875C7.66576 13.1875 7.82473 13.2533 7.94194 13.3706C8.05915 13.4878 8.125 13.6467 8.125 13.8125V20.375C8.125 20.5408 8.05915 20.6997 7.94194 20.8169C7.82473 20.9342 7.66576 21 7.5 21Z" fill="url(#paint1_linear_1256_26950)" />
            <path d="M32.5 21C32.3342 21 32.1753 20.9342 32.0581 20.8169C31.9408 20.6997 31.875 20.5408 31.875 20.375V13.8125C31.875 13.6467 31.9408 13.4878 32.0581 13.3706C32.1753 13.2533 32.3342 13.1875 32.5 13.1875C32.6658 13.1875 32.8247 13.2533 32.9419 13.3706C33.0592 13.4878 33.125 13.6467 33.125 13.8125V20.375C33.125 20.5408 33.0592 20.6997 32.9419 20.8169C32.8247 20.9342 32.6658 21 32.5 21Z" fill="url(#paint2_linear_1256_26950)" />
            <path d="M20 27.4156C12.9312 27.4156 6.875 23.5438 6.875 20.375C6.875 20.2092 6.94085 20.0503 7.05806 19.9331C7.17527 19.8158 7.33424 19.75 7.5 19.75C7.66576 19.75 7.82473 19.8158 7.94194 19.9331C8.05915 20.0503 8.125 20.2092 8.125 20.375C8.125 22.3563 13.125 26.1656 20 26.1656C26.875 26.1656 31.875 22.3563 31.875 20.375C31.875 20.2092 31.9408 20.0503 32.0581 19.9331C32.1753 19.8158 32.3342 19.75 32.5 19.75C32.6658 19.75 32.8247 19.8158 32.9419 19.9331C33.0592 20.0503 33.125 20.2092 33.125 20.375C33.125 23.5438 27.0688 27.4156 20 27.4156Z" fill="url(#paint3_linear_1256_26950)" />
            <path d="M29.375 27.5623C29.2092 27.5623 29.0502 27.4964 28.933 27.3792C28.8158 27.262 28.75 27.103 28.75 26.9373V15.1373L19.7219 10.6217C19.6458 10.5867 19.5775 10.5369 19.5211 10.4751C19.4647 10.4132 19.4212 10.3407 19.3934 10.2618C19.3655 10.1829 19.3538 10.0991 19.3589 10.0156C19.364 9.93206 19.3858 9.8504 19.4231 9.77545C19.4603 9.70051 19.5123 9.63381 19.5758 9.57932C19.6393 9.52483 19.7132 9.48365 19.7929 9.45823C19.8727 9.43281 19.9567 9.42367 20.04 9.43134C20.1234 9.43902 20.2043 9.46336 20.2781 9.50291L29.6531 14.1904C29.7572 14.2422 29.8449 14.3219 29.9061 14.4207C29.9674 14.5196 29.9999 14.6335 30 14.7498V26.9373C30 27.103 29.9341 27.262 29.8169 27.3792C29.6997 27.4964 29.5407 27.5623 29.375 27.5623Z" fill="url(#paint4_linear_1256_26950)" />
            <path d="M29.375 30.375C29.0042 30.375 28.6416 30.265 28.3333 30.059C28.025 29.853 27.7846 29.5601 27.6427 29.2175C27.5008 28.8749 27.4637 28.4979 27.536 28.1342C27.6084 27.7705 27.787 27.4364 28.0492 27.1742C28.3114 26.912 28.6455 26.7334 29.0092 26.661C29.3729 26.5887 29.7499 26.6258 30.0925 26.7677C30.4351 26.9096 30.728 27.15 30.934 27.4583C31.14 27.7666 31.25 28.1292 31.25 28.5C31.25 28.9973 31.0525 29.4742 30.7008 29.8258C30.3492 30.1775 29.8723 30.375 29.375 30.375ZM29.375 27.875C29.2514 27.875 29.1306 27.9117 29.0278 27.9803C28.925 28.049 28.8449 28.1466 28.7976 28.2608C28.7503 28.375 28.7379 28.5007 28.762 28.6219C28.7861 28.7432 28.8457 28.8545 28.9331 28.9419C29.0205 29.0294 29.1318 29.0889 29.2531 29.113C29.3743 29.1371 29.5 29.1247 29.6142 29.0774C29.7284 29.0301 29.826 28.95 29.8947 28.8472C29.9633 28.7445 30 28.6236 30 28.5C30 28.3342 29.9342 28.1753 29.8169 28.0581C29.6997 27.9408 29.5408 27.875 29.375 27.875Z" fill="url(#paint5_linear_1256_26950)" />
            <path d="M30.3125 34.1249H28.4375C28.3507 34.1252 28.2649 34.1074 28.1854 34.0727C28.1058 34.0381 28.0344 33.9872 27.9756 33.9234C27.9168 33.8596 27.872 33.7843 27.8439 33.7023C27.8158 33.6202 27.8051 33.5332 27.8125 33.4467L28.125 29.6967C28.1312 29.531 28.203 29.3745 28.3246 29.2617C28.4462 29.1488 28.6076 29.089 28.7734 29.0952C28.9392 29.1014 29.0957 29.1732 29.2085 29.2948C29.3213 29.4164 29.3812 29.5778 29.375 29.7436C29.3688 29.5778 29.4286 29.4164 29.5415 29.2948C29.6543 29.1732 29.8108 29.1014 29.9765 29.0952C30.1423 29.089 30.3037 29.1488 30.4253 29.2617C30.5469 29.3745 30.6188 29.531 30.625 29.6967L30.9375 33.4467C30.9448 33.5332 30.9341 33.6202 30.9061 33.7023C30.878 33.7843 30.8331 33.8596 30.7743 33.9234C30.7155 33.9872 30.6441 34.0381 30.5646 34.0727C30.4851 34.1074 30.3992 34.1252 30.3125 34.1249ZM29.1156 32.8749H29.6343L29.375 29.803C29.3734 29.7874 29.3734 29.7717 29.375 29.7561C29.3765 29.7717 29.3765 29.7874 29.375 29.803L29.1156 32.8749Z" fill="url(#paint6_linear_1256_26950)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26950" x1="0.000244307" y1="7.79222" x2="35.0904" y2="24.2394" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26950" x1="6.875" y1="16.1026" x2="8.21071" y2="16.1514" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_26950" x1="31.875" y1="16.1026" x2="33.2107" y2="16.1514" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_26950" x1="6.875" y1="22.6103" x2="24.3068" y2="36.2392" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_26950" x1="19.3577" y1="16.195" x2="30.544" y2="17.6939" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_26950" x1="27.5" y1="28.0243" x2="31.3137" y2="28.895" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_26950" x1="27.8102" y1="30.9716" x2="31.0926" y2="31.4379" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>

    )
};

export default EducationIcon;