import { Button, Col, Row } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DotIcon } from "../../../../assets/icons";
import website from "../../../../assets/images/site-create.png";
import { SiteStatusEnum } from "../../../../enums/website.enum";
import { formatDate } from "../../../../utils/date.ultis";
import DotStatus from "../../dot-status";

interface WebsiteDatabaseProps {
  budget: string;
  version: string;
  createAt: string;
  userOwner: string;
  databseName: string;
  webSiteLink: string;
  templateName: string;
  siteStatus: SiteStatusEnum;
  userOwnerId: number;
}

const WebsiteDatabaseDetail: FunctionComponent<WebsiteDatabaseProps> = (
  props
) => {
  const { t } = useTranslation();

  const {
    databseName,
    budget,
    version,
    webSiteLink,
    userOwner,
    createAt,
    templateName,
    siteStatus,
    userOwnerId,
  } = props;

  return (
    <Row>
      <Col span={21}>
        <Row>
          <Col span={4}>
            <img src={website} alt="img" />
          </Col>
          <Col span={10}>
            <div>
              <div>
                <div className="detail-name">
                  <div>{<DotStatus status={siteStatus} />}</div>
                  <div>{databseName}</div>
                  {}
                </div>
              </div>
              <div>
                <p className="detail-info">
                  {t("website.siteBudget")}: <span>{budget} VNĐ</span>
                </p>
              </div>
              <div>
                <p className="detail-info">
                  {t("website.version")}: <span>{version}</span>
                </p>
              </div>
              <div>
                <p className="detail-info">
                  {t("website.template")}: <span>{templateName}</span>
                </p>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div>
              <div style={{ visibility: "hidden" }}>
                <p className="detail-info">.</p>
              </div>
              <div>
                <p className="detail-info">
                  {t("website.websiteLink")}:{" "}
                  <span className="gx-link">
                    <a rel="noreferrer" target="_blank" href={webSiteLink}>
                      {webSiteLink}
                    </a>
                  </span>
                </p>
              </div>
              <div>
                <p className="detail-info">
                  {t("website.userOwner")}:
                  <Link to={`/users-detail/${userOwnerId}`}>
                    <span className="gx-link"> {userOwner}</span>
                  </Link>
                </p>
              </div>
              <div>
                <p className="detail-info">
                  {t("website.createdAt")}: <span>{formatDate(createAt)}</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="detail-btn" span={3}>
        <Button type="primary">{t("website.detail")}</Button>
      </Col>
    </Row>
  );
};

export default WebsiteDatabaseDetail;
