import React, { ReactNode } from "react";
import Widget from "../widget";

import './index.scss';

interface Props {
  icon?: ReactNode;
  subTitle?: string;
  iconSize?: number;
  cardColor?: string;
  iconColor?: string;
  numberWebsite: any;
  numberColor?: string;
  subTitleColor?: string;
}

const IconWithTextCard = (props: Props) => {
  const { cardColor, icon, numberWebsite, subTitle, iconColor, subTitleColor, numberColor, iconSize } = props;
  return (
    <Widget styleName={`gx-card-full gx-p-3 gx-bg-${cardColor} gx-text-white`}>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-3 gx-mr-xxl-3 widget-icon" style={{ fontSize: iconSize }}>
          {icon}
        </div>
        <div className="gx-media-body">
          <h1 className={`gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-${numberColor} text-card__amount`}>
            {numberWebsite}
          </h1>
          <p className={`gx-mb-0 gx-text-${subTitleColor}`}>{subTitle}</p>
        </div>
      </div>
    </Widget>
  );
};

IconWithTextCard.defaultProps = {
  iconSize: 35,
  numberColor: "white",
  subTitleColor: "white",
}

export default IconWithTextCard;
