import { deleteAsync, getAsync, postAsync, postFormDataAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/user`;

export const UserApi = {
  getUserOverview: () => {
    return getAsync(`${baseUrl}/overview`, {}, true, false);
  },

  getUserOverviewTable: (params?: any) => {
    return getAsync(`${baseUrl}/users-overview`, params, true, false);
  },

  remindUserOverviewTable: (userId: number | string) => {
    return postAsync(`${baseUrl}/remind?id=${userId}`);
  },

  getLatestUser: (params?: any) => {
    return getAsync(`${baseUrl}/users-lastest`, params, true, false);
  },

  getUserDetail: (userId: any) => {
    return getAsync(`${baseUrl}/detail?userId=${userId}`);
  },

  getUserList: (params?: any) => {
    return getAsync(`${baseUrl}`, params, true, false);
  },

  deleteUser: (data: Array<any>) => {
    return deleteAsync(`${baseUrl}`, data);
  },

  deactiveUser: (ids: Array<any>) => {
    return putAsync(`${baseUrl}/deactivate`, ids);
  },

  activeUser: (ids: Array<any>) => {
    return putAsync(`${baseUrl}/activate`, ids);
  },

  changeAvatar: (payload: any) => {
    return postFormDataAsync(`${baseUrl}/change-avatar`, payload);
  },

  deleteAccount: () => {
    return deleteAsync(`${baseUrl}/delete-account`);
  },

  updateUserInfo: (payload: any) => {
    return putAsync(baseUrl, payload);
  },

  changePassword: (payload: any) => {
    return putAsync(`${baseUrl}/change-password`, payload);
  },

  getUserByName: (payload: any) => {
    return getAsync(`${baseUrl}/user-items`, payload);
  },

  getUserActivity: (payload: any) => {
    return getAsync(`${baseUrl}/activities`, payload)
  },

  searchAuthor: (payload: any) => {
    return getAsync(`${baseUrl}/user-items`, payload);
  },

  getUserBudgetActivities: (payload: any ) => {
    return getAsync(`${process.env.REACT_APP_API_ENPOINT}/auth/userbudget/activities`, payload);
  },
};
