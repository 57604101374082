import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { PAGE_SIZE } from '../../../../constants/app-constants';
import { UserBudgetApi } from '../../../../apis/user-budget.api';

import BudgetTable from '../../budget/budget-table';
import { useUserDetailContext } from '../../../../pages/users/user-detail';

interface BudgetWigetProps {
    data?: Value;
};

type Value = {
    records: any;
    totalRecords: number;
}

const BudgetWiget: FunctionComponent<BudgetWigetProps> = ({ data }) => {

    // state data
    const [budgetData, setBudgetData] = useState<any>(data);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    // context
    const { userInfoId } = useUserDetailContext();
    
    // action
    const loadBudgetByUserId = async () => {
        try {
            setLoading(true);

            const payload = {
                userId: userInfoId,
                pageSize: PAGE_SIZE,
                pageIndex: currentPage,
            };

            const result = await UserBudgetApi.getUserBudgetList(payload);

            if (result?.data) {
                setBudgetData(result.data);
            }

        } finally {
            setLoading(false);
        }
    };
    
    const handleTablePaginationChange = (page: number) => {
        setCurrentPage(page);
    };

    // computed
    const paginationSetting = useMemo(() => ({
        size: PAGE_SIZE,
        page: currentPage,
        total: budgetData?.totalRecords || 0,
        onChange: handleTablePaginationChange,
    }), [currentPage, budgetData?.totalRecords]);

    useEffect(() => {
        if (userInfoId) {
            loadBudgetByUserId();
        }
    }, [userInfoId, currentPage]);

    return ( 
        <div style={{marginTop: '8px'}}> 
            <BudgetTable data={budgetData.records} pagination={paginationSetting} loading={loading}/>
        </div>
    );
};

BudgetWiget.defaultProps = {
    data: {
        records: [],
        totalRecords: 0
    },
};


export default BudgetWiget;
