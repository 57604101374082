import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

interface Props {
  data: any;
}

const SiteVisit = (props: Props) => {
  const chartModel = props?.data;

  const demo = chartModel?.demo?.map((item: any) => {
    return {
      name: item.date,
      demo: item.value,
    };
  });

  const production = chartModel?.production?.map((item: any) => {
    return {
      name: item.date,
      production: item.value,
    };
  });

  const stopped = chartModel?.stopped?.map((item: any) => {
    return {
      name: item.date,
      stopped: item.value,
    };
  });

  const merged = demo?.concat(production, stopped);

  const chartData = merged?.reduce((acc: any, val: any, ind: any) => {
    const index = acc.findIndex((el: any) => el.name === val.name);
    if (index !== -1) {
      const key = Object.keys(val)[1];
      acc[index][key] = val[key];
    } else {
      acc.push(val);
    }
    return acc;
  }, []);

  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <ResponsiveContainer width="100%" height={140}>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
        >
          <Tooltip labelFormatter={(index) => chartData[index].name} />
          <CartesianGrid horizontal={false} strokeDasharray="3 3" />
          <Area
            type="monotone"
            dataKey="production"
            fillOpacity={1}
            stroke="#038FDE"
            fill="#038FDE"
          />
          <Area
            type="monotone"
            dataKey="demo"
            fillOpacity={1}
            stroke="#FE9E15"
            fill="#FE9E15"
          />
          <Area
            type="monotone"
            dataKey="stopped"
            fillOpacity={1}
            stroke="#f5222d"
            fill="#f5222d"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SiteVisit;
