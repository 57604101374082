import { Popconfirm } from "antd";
import { FunctionComponent, ReactElement } from "react";

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";

import './index.scss';

interface PopConfirmActionProps {
    okText?: string,
    cancelText?: string,
    children: ReactElement | string;

    [key: string] : any;
}

const PopConfirmAction: FunctionComponent<PopConfirmActionProps> = ({ children, okText, cancelText, ...props }) => {
    const { t } = useTranslation();

    return (
        <Popconfirm
            {...props}
            className="popConfirm"
            okText={okText}
            cancelText={cancelText}
            title={t('common.confirmQuestion')}
            icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
        >
            {children}
        </Popconfirm>
    )
};

PopConfirmAction.defaultProps = {
    okText: i18n.t('common.yes'),
    cancelText: i18n.t('common.no'),
};

export default PopConfirmAction;
