import { atom, selector } from "recoil";
import { LOCAL_COOKIE_KEY } from "../constants/app-constants";
import { UserProfileModel } from "../models/user.model";

import LocalUtils from "../utils/local.utils";
interface AuthState {
  isLogined: boolean;
  user: UserProfileModel | undefined | null;
}

const authState = atom<AuthState>({
  key: "authState",
  default: {
    isLogined: LocalUtils.getCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN) == "true",
    user: LocalUtils.getUserInfo(),
  },
});

const getUserInfoSelector = selector({
  key: 'getUserInfoSelector',

  get: ({ get }) => {
    const { user } = get(authState);

    return user;
  },

  set: ({get, set }, data : any ) => {
    const state = get(authState);

    LocalUtils.setUserInfo(data);

    set(authState, {
      ...state,
      user: data,
    });
  },
})

export { authState, getUserInfoSelector };
