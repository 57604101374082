import { Col, Row } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useCurrencyConfig from "../../../hooks/useCurrencyConfig";

import './index.scss';

interface ThemeDetailActionProps {
    price: number;
    themeFee: any;
    siteId?: number | string;
}

const WebsitePriceDetail: FunctionComponent<ThemeDetailActionProps> = (props) => {
    const { t } = useTranslation();

    const { currencyConfig, convertNumericToFormattedAmount } = useCurrencyConfig();

    const { themeFee } = props;

    return (
        <div className="gx-text-center theme-detail__wrapper">
            <div className="theme-detail__info">
                <span className="theme-detail__info--price">{convertNumericToFormattedAmount(themeFee?.total)} {currencyConfig.currency} </span>
                <span className="theme-detail__info--unit">/ {t('website.website')}</span>
            </div>

            <div className="theme-detail__fee">
                <p className="theme-detail__fee-title">
                    {t('website.breakdown')}
                </p>

                <Row gutter={[4, 12]}>
                    <Col span={24}>
                        <Row gutter={[2, 0]}>
                            <Col span={12} className="fee__title">
                                {t('website.themeLifeTime')}
                            </Col>

                            <Col span={12} className="fee__value">
                                {convertNumericToFormattedAmount(themeFee?.theme)} {currencyConfig.currency}
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Row gutter={[2, 0]}>
                            <Col span={12} className="fee__title">
                                {t('website.pluginLifeTime')}
                            </Col>

                            <Col span={12} className="fee__value">
                                {convertNumericToFormattedAmount(themeFee?.plugin)} {currencyConfig.currency}
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Row gutter={[2, 0]}>
                            <Col span={12} className="fee__title">
                                {t('website.planPerDay')}
                            </Col>

                            <Col span={12} className="fee__value">
                                {convertNumericToFormattedAmount(themeFee?.plan)} {currencyConfig.currency}
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Row gutter={[2, 0]}>
                            <Col span={12} className="fee__title">
                                {t('website.tax')}
                            </Col>

                            <Col span={12} className="fee__value">
                                {convertNumericToFormattedAmount(themeFee?.tax)} {currencyConfig.currency}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

WebsitePriceDetail.defaultProps = {
    siteId: '',
    price: 0,
};

export default WebsitePriceDetail;
