import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";

type NotificationType = "success" | "info" | "warning" | "error";

const showNotification = (
  type: NotificationType,
  message: string,
  description = null
) => {

  const config: ArgsProps = {
    maxCount: 2,
    message: message,
    placement: "top",
    description: description,
  };

  notification[type](config);
};

export default showNotification;
