import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { commonCurrencySelector } from "../states/common";

const useCurrencyConfig = () => {
    const currencyConfig = useRecoilValue(commonCurrencySelector);

    const formatAmount = useCallback((value: string | number | undefined) => {
        if (!currencyConfig?.thousandSeparator) {
            return `${value}`;
        }

        if (!value) {
            return '0';
        }

        const thousandSeparator = currencyConfig?.thousandSeparator || ',';

        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    }, [currencyConfig]);

    const parserInput = useCallback((value: string | number | undefined) => {
        return parseFloat(value?.toString()!.replaceAll(currencyConfig.thousandSeparator, '') || '0');
    }, [currencyConfig]);

    const convertNumericToFormattedAmount = useCallback((value?: string | number | undefined ): string => {
        if (!currencyConfig) {
            return '';
        }

        return (
            convertNumericToFormattedString(value)
        );
    }, [currencyConfig]);

    const padding = "000000";

    const convertNumericToFormattedString = (
        value?: string | number | undefined,
    ): string => {
        if (!value) {
            value = 0;
        }
        
        const { thousandSeparator, decimalSeparator, allowedDecimals } = currencyConfig;
        
        const numericAmount = Number(value).toFixed(allowedDecimals).toString();
        let [integer, fraction = ""] = numericAmount.split(".");

        fraction =
            allowedDecimals > 0
                ? decimalSeparator + (fraction + padding).substring(0, allowedDecimals)
                : "";

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
        return integer + fraction;
    };

    return {
        formatAmount,
        parserInput,
        currencyConfig,
        convertNumericToFormattedAmount,
    }
};

export default useCurrencyConfig;