import classNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Row, Slider } from "antd";

import i18n from "../../../../utils/i18n";
import { useTranslation } from "react-i18next";

import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './index.scss';
import { NUMBER_UTIL } from "../../../../utils/number.utils";

const validateSchema = {
    cost: [{ required: true, message: i18n.t('admin.priceRequired') }],
    name: [{ required: true, message: i18n.t('admin.nameRequired') }],
};

const FormItemFormat = {
    labelCol: {
        xs: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24 },
    },

    className: "form__item--list",
};

interface ItemCardProps {
    isView?: boolean;
    onUpdateData?: (value: any) => void;

    [key: string]: any;
}

const ItemCard: FunctionComponent<ItemCardProps> = (props) => {
    const { t } = useTranslation();
    const { isView, styleName, headerStyle, itemStyle, footerStyle, data, onUpdateData } = props;

    const [form] = Form.useForm();

    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [price, setPrice] = useState(data?.cost || 0);

    const onAfterChange = (value: number) => {
        setPrice(value);
    };

    const handleInputPriceChange = (value: number | null) => {
        if (value) {
            setPrice(value);
            form.setFieldValue('price', value)
        }
    };

    const handleSaveButtonClick = () => {
        form.submit();
    };

    const handleFormSubmit = (value: any) => {
        if (onUpdateData) {
            onUpdateData(value);
            setEnableEdit(false);
        }
    };

    const handleEditButtonClick = () => {
        setEnableEdit(true);
    };

    const handleCancelButtonClick = () => {
        setEnableEdit(false);
    };

    const List = () => {
        if (!enableEdit) {
            return (
                <ul className="item-card__list--main list">
                    {data?.descriptions?.map((item: any, index: number) => {
                        return (
                            <li className="list__item" key={index}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            );
        }

        return (
            <Form.List name="descriptions">
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...formItemLayoutWithOutLabel}
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('admin.listFeldInvalid'),
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input className="form__item--input" />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <CloseCircleOutlined
                                        className="dynamic-delete-button form__item--remove"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}

                            </Form.Item>
                        ))}

                        <Form.Item {...formItemLayoutWithOutLabel} className="form__item--add">
                            <Button
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                {t('admin.addField')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    };

    const ButtonFooter = () => {
        if (!enableEdit) {
            return <Button onClick={handleEditButtonClick} type="primary" className={classNames(['item-card__button-detail', footerStyle])}>{t('common.detail')}</Button>
        };

        return (
            <>
                <Button onClick={handleCancelButtonClick} type="ghost" danger className='item-card__button-detail'>{t('common.cancel')}</Button>
                <Button onClick={handleSaveButtonClick} type="primary" className={classNames(['item-card__button-detail', footerStyle])}>{t('common.save')}</Button>
            </>
        )
    };

    useEffect(() => {
        if (enableEdit) {
            form.setFieldsValue(data);
        };
    }, [enableEdit]);

    return (
        <div className={classNames(['item-card', styleName])}>
            <Form onFinish={handleFormSubmit} form={form} className="item-card__form" initialValues={data}>
                <div className={classNames(['item-card__header', headerStyle])}>
                    {!enableEdit && (
                        <>
                            <h2 className="item-card__header--price">
                                {NUMBER_UTIL.convertNumericToFormattedString(data?.cost || 0)}
                                <span> VND / {t('common.day')}</span>
                            </h2>

                            <p className="item-card__header--level">{data?.name || ''}</p>
                        </>
                    )}

                    {enableEdit && (
                        <>
                            <Row justify="space-between" align="middle">
                                <Col xs={14} sm={14} md={12} lg={16} xl={14}>
                                    <Form.Item
                                        {...FormItemFormat}

                                        name="cost"
                                        labelAlign="left"
                                        label="Price (VND)"
                                        className="form__item"
                                        rules={validateSchema.cost}
                                    >
                                        <Slider max={1000000} step={500} onAfterChange={onAfterChange} />
                                    </Form.Item>
                                </Col>

                                <Col xs={10} sm={10} md={12} lg={8} xl={10}>
                                    <Form.Item
                                        {...FormItemFormat}

                                        label=" "
                                        className="form__item form__item--label-hiden"
                                    >
                                        <InputNumber value={price} onChange={handleInputPriceChange} max={1000000} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                {...FormItemFormat}

                                name="name"
                                labelAlign="left"
                                label="Plan name"
                                className="form__item"
                                rules={validateSchema.name}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    )}

                </div>

                <div className={classNames(['item-card__list', itemStyle])}>
                    <List />

                    <div className="gx-package-footer item-card__button">
                        {isView && (
                            <Button type="primary">{t('website.learnMore')}</Button>
                        )}

                        {!isView && (
                            <ButtonFooter />
                        )}
                    </div>
                </div>
            </Form>

        </div>
    );
};

ItemCard.defaultProps = {
    isView: false,
}

export default ItemCard;
