import {
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Col, Modal, Row, Switch } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentAction from "./payment-action";
import EditButton from "../../edit-button";
import { useUserDetailContext } from "../../../../../pages/users/user-detail";
import { FORMAT_DATE_TIME, IMAGE_DEFAULT_TYPE } from "../../../../../constants/app-constants";
import moment from "moment";
import ImageLoad from "../../../../../components/common/image-load";
import { generateImage } from "../../../../../utils/app.utils";
import ProtectedAction from "../../../../../components/action-protected";
import { RoleEnum } from "../../../../../enums/app.enum";

const UserDetailOverviewPayment = () => {
  const { t } = useTranslation();

  const { paymentAccounts, deleteAccount, changeDefaultAccountStatus, updateBankAccount, addNewPaymentAccount, cancelUpdatePaymentAccount } = useUserDetailContext();

  const [showPaymentAction, setShowPaymentAction] = useState<boolean>(false);

  //state data
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  // context
  const { isAccount } = useUserDetailContext();

  const handleEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleSaveButtonClick = async () => {
    await updateBankAccount();

    setIsEditMode(false);
  };

  const handleCancelButtonClick = () => {
    cancelUpdatePaymentAccount();
    setIsEditMode(false);
  };

  const addNewPayment = async (formData: any) => {
    await addNewPaymentAccount(formData);

    setShowPaymentAction(false);
  };

  const confirmDeleteAccount = (bankAccount: any) => {
    if (bankAccount?.isDefault) {
      Modal.warning({
        title: t('user.account-default-not-delete')
      });

      return;
    }

    Modal.confirm({
      title: t('user.confirm-delete-bank-account'),
      okText: t('common.delete'),
      okButtonProps: {
        type: 'default',
      },
      onOk: () => deleteAccount(bankAccount)
    })
  };

  return (
    <div className="payment-section">
      <div className="head">
        <div className="title">{t("user.paymentMethod")}</div>
        <ProtectedAction roles={!!isAccount ? [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User, RoleEnum.Accountant] : []}>
          <EditButton
            isEdit={isEditMode}
            loading={loading}

            onEdit={handleEditButtonClick}
            onSave={handleSaveButtonClick}
            onCancel={handleCancelButtonClick}
          />
        </ProtectedAction>
      </div>
      <div className="methods">
        <Row gutter={[36, 24]}>

          {
            paymentAccounts?.map((account: any) => {
              if (account?.isDelete) return "";

              return (
              <Col key={account.id} lg={12} md={12} sm={24} xs={24}>
                <div className="method-item">
                  <div className="main-info">
                    <div className="img">
                      <ImageLoad type={IMAGE_DEFAULT_TYPE.bank}
                        src={generateImage(account?.image)}
                        alt="payment"
                      />
                    </div>
                    <div className="card">
                      <p>{account?.bankAccountName}</p>
                      <p>{`•••• •••• •••• ${account?.lastFourDigits}`}</p>
                      <p>{t("user.releaseDate")} {moment(account?.releaseDate).format(FORMAT_DATE_TIME.myBank)}</p>
                    </div>
                  </div>
                  <div className="right-action">
                    {account?.isDefault && <div className="default">{t("common.default")}</div>}

                    {isEditMode && (
                      <div className="switch">
                        <Switch 
                          checked={account?.isDefault} 
                          onChange={(checked: boolean) => changeDefaultAccountStatus({defaultStatus: checked, bankAccount: account})}
                        />
                      </div>
                    )}
                    {isEditMode && (
                      <div className="delete-icon" onClick={() => confirmDeleteAccount(account)}>
                        <DeleteOutlined />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )})
          }
        </Row>

        <ProtectedAction roles={!!isAccount ? [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User, RoleEnum.Accountant] : []}>
          <div className="add-new" onClick={() => setShowPaymentAction(true)}>
            <div>
              <PlusOutlined />
            </div>
            <div className="text">{t("user.addNewCard")}</div>
          </div>
        </ProtectedAction>
      </div>

      <PaymentAction
        open={showPaymentAction}

        onSubmit={addNewPayment}
        onCancel={() => setShowPaymentAction(false)}
      />
    </div>
  );
};

export default UserDetailOverviewPayment;
