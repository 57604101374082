import classNames from "classnames";
import { Button, Modal } from "antd";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { RoleEnum } from "../../../../enums/app.enum";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { getUserInfoSelector } from '../../../../states/auth';
import { ROUTE_PATHS } from "../../../../constants/router.constants";

import RejectModal from "../../reject-modal";
import ProtectedAction from "../../../../components/action-protected";

import './index.scss';
import confirmReasonDeactivated from "../../deactivated-modal";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";

const confirm = Modal.confirm;
interface ThemeDetailActionProps {
    price: number;
    loading?: boolean;
    isSuccess?: boolean;
    isFree?: boolean;
    isDeactivated?: boolean;
    siteId?: number | string;
    authorId?: number | string;
    categoryId?: number | string;

    onCreate?: () => void;
    onDelete?: () => void;
    onActive?: () => void;
    onApprove?: () => void;
    onReject?: (value: any) => void;
    onDeactive?: (reason: string) => void;
}

const PluginDetailAction: FunctionComponent<ThemeDetailActionProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { price, siteId, categoryId, authorId, loading, isFree, onApprove, onActive, onDelete, onDeactive, isDeactivated } = props;
    const { currencyConfig, convertNumericToFormattedAmount} = useCurrencyConfig();

    // recoil
    const userInfo = useRecoilValue(getUserInfoSelector);

    const isOwnCreateSite = useMemo(() => {
        return authorId === userInfo?.userId;
    }, [authorId]);

    const handleEditButtonClick = () => {
        if (siteId) {
            navigate(ROUTE_PATHS.editThemePlugin.replace(':siteId', (siteId.toString())));
        };
    };

    const handleDeactiveButtonClick = () => {

        const handleGetReason = (reason: any) => {
            onDeactive?.(reason.replaceAll('\n', '%0A'));
        }

        confirm({
            ...confirmReasonDeactivated(handleGetReason),
            title: t('theme.confirmDeactivateTheme'),
            okButtonProps: { loading: loading },
            okType: 'primary'
        });
    };

    const handleActiveButtonClick = () => {
        onActive?.();
    };

    const handleDeleteButtonClick = () => {
        Modal.confirm({
            title: t('common.confirmQuestion'),

            onOk: onDelete,
        });
    };

    const handleCreateButtonClick = () => {
        navigate(ROUTE_PATHS.websiteCreateSites, {
            state: {
                themeSiteId: siteId,
                categoryId: categoryId,
            }
        });
    };

    const handleApproveButtonClick = () => {
        onApprove?.();
    };

    return (
        <div className="gx-text-center theme-detail__wrapper">
            <div className="theme-detail__info">
                <span className="theme-detail__info--price">{!isFree ? convertNumericToFormattedAmount(price) : 0} {currencyConfig.currency} </span>
                <span className="theme-detail__info--unit">/ {t('theme.plugin')}</span>
            </div>

            <ProtectedAction roles={[RoleEnum.User]}>
                <div className="theme-detail__group">
                    <div className="gx-module-list-content theme-detail__button-group">
                        <Button
                            disabled={!!isDeactivated}
                            className="theme-detail__button ant-btn ant-btn-primary gx-mt-3 gx-mb-0"
                        >
                            {t('theme.installPlugin')}
                        </Button>
                    </div>

                    {isOwnCreateSite && (
                        <div className="theme-detail__secondary-button-group">
                            <Button
                                onClick={handleEditButtonClick}
                                className="theme-detail__button"
                            >
                                {t("common.edit")}
                            </Button>

                            {!isDeactivated && <Button
                                danger
                                loading={loading}
                                onClick={handleDeactiveButtonClick}
                                className="theme-detail__button"
                            >
                                {t("theme.deactivate")}
                            </Button>}

                            {!!isDeactivated && <Button
                                danger
                                loading={loading}
                                onClick={handleActiveButtonClick}
                                className="theme-detail__button"
                            >
                                {t("common.activate")}
                            </Button>}
                        </div>
                    )}
                </div>
            </ProtectedAction>

            <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.Admin]}>
                <div className="theme-detail__group">
                    <div className="gx-module-list-content theme-detail__button-group">
                        <Button
                            disabled={!!isDeactivated}
                            className="theme-detail__button ant-btn ant-btn-primary gx-mt-3 gx-mb-0"
                        >
                            {t('theme.installPlugin')}
                        </Button>
                    </div>

                    {isOwnCreateSite && (
                        <div className="theme-detail__secondary-button-group">
                            <Button
                                onClick={handleEditButtonClick}
                                className="theme-detail__button"
                            >
                                {t("common.edit")}
                            </Button>

                            {!isDeactivated && <Button
                                danger
                                loading={loading}
                                onClick={handleDeactiveButtonClick}
                                className="theme-detail__button"
                            >
                                {t("theme.deactivate")}
                            </Button>}

                            {!!isDeactivated && <Button
                                danger
                                loading={loading}
                                onClick={handleActiveButtonClick}
                                className="theme-detail__button"
                            >
                                {t("common.activate")}
                            </Button>}
                        </div>
                    )}
                </div>
            </ProtectedAction>
        </div>
    );
};

PluginDetailAction.defaultProps = {
    siteId: '',
    price: 0,

    onCreate: () => null,
    onDelete: () => null,
    onActive: () => null,
};

export default PluginDetailAction;
