import { Form, Input } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ChangePasswordProps {
    [key: string]: any;
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

const ChangePasswordForm: FunctionComponent<ChangePasswordProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Form
            {...props}
            {...formItemLayout}
            scrollToFirstError
        >
            <Form.Item
                name="currentPassword"
                label={t("user.currentPassword")}
                labelAlign="left"
                rules={[
                    {
                        required: true,
                        message: t("user.currentPassword"),
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="newPassword"
                label={t("user.newPassword")}
                labelAlign="left"
                rules={[
                    {
                        required: true,
                        message: t("user.validate.newPassword"),
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                label={t("user.confirmNewPassword")}
                labelAlign="left"
                rules={[
                    {
                        required: true,
                        message: t("user.validate.confirmPassword"),
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue("newPassword") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t("user.validate.passwordNotMatch"));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
        </Form>
    );
};

export default ChangePasswordForm;
