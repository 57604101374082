import React from "react";

const BudgetIcon = () => {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_233_10104)">
        <path
          d="M27.4145 29.4708C27.3146 29.4492 27.2121 29.4453 27.1109 29.4605C26.0966 29.3424 25.3316 28.4843 25.3298 27.4636C25.3298 26.9745 24.9332 26.5779 24.4441 26.5779C23.9549 26.5779 23.5583 26.9745 23.5583 27.4636C23.5609 29.1681 24.701 30.6611 26.3445 31.1122V31.9979C26.3445 32.4871 26.7411 32.8837 27.2302 32.8837C27.7194 32.8837 28.116 32.4871 28.116 31.9979V31.1658C30.0129 30.7688 31.3022 29.002 31.1028 27.0744C30.903 25.1467 29.279 23.6819 27.3409 23.6814C26.2303 23.6814 25.3298 22.781 25.3298 21.6703C25.3298 20.5597 26.2303 19.6592 27.3409 19.6592C28.4516 19.6592 29.3521 20.5597 29.3521 21.6703C29.3521 22.1595 29.7487 22.5561 30.2378 22.5561C30.727 22.5561 31.1236 22.1595 31.1236 21.6703C31.121 19.8806 29.8672 18.337 28.116 17.9677V17.1615C28.116 16.6724 27.7194 16.2758 27.2302 16.2758C26.7411 16.2758 26.3445 16.6724 26.3445 17.1615V18.0218C24.5167 18.5209 23.3403 20.2954 23.5921 22.1733C23.8438 24.0512 25.4462 25.4529 27.3409 25.4525C28.4369 25.4525 29.3309 26.33 29.3508 27.4256C29.3711 28.5211 28.5096 29.4311 27.4145 29.4708Z"
          fill="#8A2BE2"
        />
        <path
          d="M14.4966 24.6351C14.4966 31.7289 20.2471 37.4794 27.3409 37.4794C34.4347 37.4794 40.1852 31.7289 40.1852 24.6351C40.1852 17.5412 34.4347 11.7908 27.3409 11.7908C20.2505 11.7985 14.5048 17.5447 14.4966 24.6351ZM27.3409 13.5623C33.4564 13.5623 38.4137 18.5196 38.4137 24.6351C38.4137 30.7506 33.4564 35.7079 27.3409 35.7079C21.2254 35.7079 16.2681 30.7506 16.2681 24.6351C16.275 18.5226 21.2284 13.5692 27.3409 13.5623Z"
          fill="#8A2BE2"
        />
        <path
          d="M44.5517 39.6631L37.6132 42.9414C36.6552 41.1595 34.8175 40.0255 32.7951 39.9684L26.3898 39.7924C25.3756 39.7634 24.3804 39.5065 23.4787 39.0407L22.8269 38.703C19.4898 36.9634 15.5121 36.9678 12.1784 38.7142L12.219 37.2376C12.2324 36.7485 11.8471 36.3411 11.3579 36.3277L4.33416 36.1343C3.84501 36.1209 3.43803 36.5063 3.42462 36.9954L3.00034 52.4088C2.98693 52.898 3.37229 53.3054 3.86144 53.3188L10.8852 53.5121H10.9094C11.3895 53.5121 11.7822 53.1302 11.7952 52.6506L11.8155 51.9132L13.6402 50.9362C14.3564 50.5508 15.1938 50.4591 15.9761 50.6806L26.8725 53.7387C26.8915 53.7444 26.9106 53.7487 26.93 53.753C27.7176 53.9178 28.5203 54.0004 29.3248 54C31.0288 54.0008 32.7129 53.6336 34.2617 52.9226C34.2998 52.9053 34.3365 52.885 34.3716 52.8621L50.1703 42.643C50.5639 42.3883 50.691 41.8714 50.4605 41.4632C49.2876 39.382 46.6861 38.5892 44.5517 39.6631ZM4.79564 51.5724L5.17105 37.9296L10.4237 38.0741L10.0483 51.7172L4.79564 51.5724ZM33.4638 51.3405C31.5396 52.209 29.3905 52.4486 27.3223 52.0261L16.4554 48.9756C15.2322 48.6301 13.9231 48.7732 12.8042 49.3748L11.8717 49.8744L12.1213 40.8036C15.0761 38.8383 18.8657 38.6363 22.0125 40.2759L22.6643 40.6137C23.8035 41.2019 25.0599 41.5263 26.3414 41.5635L32.7471 41.7395C34.5463 41.7914 36.0743 43.0725 36.4394 44.8353L26.898 44.5719C26.4089 44.5585 26.0019 44.9443 25.988 45.4331C25.9746 45.9222 26.3604 46.3296 26.8491 46.343L37.3787 46.6328H37.4034C37.883 46.6324 38.2753 46.2505 38.2883 45.7713C38.2991 45.3863 38.2705 45.001 38.2026 44.6221L45.3194 41.2594C45.3263 41.2564 45.3332 41.253 45.3402 41.2495C46.3496 40.7387 47.5701 40.9096 48.4005 41.6781L33.4638 51.3405Z"
          fill="#8A2BE2"
        />
        <path
          d="M28.2266 9.30044V0.885756C28.2266 0.396601 27.83 0 27.3408 0C26.8517 0 26.4551 0.396601 26.4551 0.885756V9.30044C26.4551 9.78959 26.8517 10.1862 27.3408 10.1862C27.83 10.1862 28.2266 9.78959 28.2266 9.30044Z"
          fill="#8A2BE2"
        />
        <path
          d="M34.8698 9.30044V5.31454C34.8698 4.82538 34.4732 4.42878 33.984 4.42878C33.4949 4.42878 33.0983 4.82538 33.0983 5.31454V9.30044C33.0983 9.78959 33.4949 10.1862 33.984 10.1862C34.4732 10.1862 34.8698 9.78959 34.8698 9.30044Z"
          fill="#8A2BE2"
        />
        <path
          d="M21.5835 9.30044V5.31454C21.5835 4.82538 21.1869 4.42878 20.6978 4.42878C20.2086 4.42878 19.812 4.82538 19.812 5.31454V9.30044C19.812 9.78959 20.2086 10.1862 20.6978 10.1862C21.1869 10.1862 21.5835 9.78959 21.5835 9.30044Z"
          fill="#8A2BE2"
        />
      </g>
      <defs>
        <clipPath id="clip0_233_10104">
          <rect width={54} height={54} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BudgetIcon;
