import moment from "moment";
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect } from "react";
import { Col, DatePicker, Form, Input, Row } from "antd";

import { useUserDetailContext } from "../../../../../pages/users/user-detail";

import EmailIcon from "../icons/email-icon";
import CalendarIcon from "../icons/calendar-icon";
import TelephoneIcon from "../icons/telephone-icon";

import "./index.scss";

const FormItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },

  className: "form__item",
};

interface AboutFormProps {
  isEdit?: boolean;
  [key: string]: any;
}

const AboutForm: FunctionComponent<AboutFormProps> = ({ isEdit, ...formProps }) => {
  const { t } = useTranslation();

  // context
  const { userData } = useUserDetailContext();

  const onLoadForm = () => {
    if (userData) {
      const { fullName, avatar, id, budget, ...initialValues } = userData;

      formProps.form.setFieldsValue({
        ...initialValues,
        birthday: initialValues?.birthday ? moment(initialValues?.birthday) : "",
      });
    };
  };

  useEffect(() => {
    if (isEdit) {
      onLoadForm();
    };
  }, [isEdit]);

  return (
    <Form {...formProps} className="form">
      <Row>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <div className="gx-media gx-flex-nowrap form__group">
            <div className="gx-mr-3">
              <EmailIcon />
            </div>
            <div className="gx-media-body">
              <Form.Item
                {...FormItemLayout}
                rules={[
                  {
                    type: "email",
                    message: t("validate.emailNotMatch"),
                  },
                  {
                    required: true,
                    message: t("validate.email"),
                  },
                ]}
                name="email"
                label={
                  <span className="label">{t("user.emailAddress")}: </span>
                }
              >
                <Input/>
              </Form.Item>
            </div>
          </div>
        </Col>

        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <div className="gx-media gx-flex-nowrap form__group">
            <div className="gx-mr-3">
              <TelephoneIcon />
            </div>
            <div className="gx-media-body">
              <Form.Item
                {...FormItemLayout}
                name="phoneNumber"
                label={<span className="label">{t("user.phoneNumber")}: </span>}
                rules={[
                  {
                    required: true,
                    message: t("validate.phoneNumber"),
                  },
                ]}
              >
                <Input maxLength={10}/>
              </Form.Item>
            </div>
          </div>
        </Col>

        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <div className="gx-media gx-flex-nowrap form__group">
            <div className="gx-mr-3">
              <CalendarIcon />
            </div>
            <div className="gx-media-body">
              <Form.Item
                {...FormItemLayout}
                name="birthday"
                label={<span className="label">{t("common.birthday")}: </span>}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default AboutForm;
