import React, { useMemo } from "react";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import {
  CopyOutlined,
  UserOutlined,
  MailOutlined,
  LayoutOutlined,
  WalletOutlined,
  MobileOutlined,
  SafetyOutlined,
  DesktopOutlined,
  ControlOutlined,
  ClusterOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  ExceptionOutlined,
  AppstoreAddOutlined,
  CloudServerOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CustomScrollbars from "../custom-scrollbar";
import AppsNavigation from "./apps-navigation";
import UserProfile from "./user-profile";
import SidebarLogo from "./sidebar-logo";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { RoleEnum } from "../../../enums/app.enum";
import { useRecoilValue } from "recoil";
import { authState } from "../../../states/auth";
import { NavLink } from "react-router-dom";

import './sidebar-content.scss'

const SidebarContent = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { user } = useRecoilValue(authState);

  const getNoHeaderClass = () => {
    return "gx-no-header-notifications";
  };

  const menuItems = [
    {
      key: "dashboard",
      roles: [
        RoleEnum.Mod,
        RoleEnum.User,
        RoleEnum.Admin,
        RoleEnum.Accountant,
      ],
      icon: <DashboardOutlined size={24}/>,
      href: ROUTE_PATHS.Home,
      label: <NavLink to={ROUTE_PATHS.Home}>{t("siderBar.dashboard")}</NavLink>,
    },

    {
      key: "sites",
      roles: [
        RoleEnum.Mod,
        RoleEnum.User,
        RoleEnum.Admin,
        RoleEnum.Accountant,
      ],
      icon: <DesktopOutlined />,
      href: ROUTE_PATHS.websiteSites,
      label: <NavLink to={ROUTE_PATHS.websiteSites}>{t("siderBar.sites")}</NavLink>,
    },

    {
      key: "template",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Mod,
        RoleEnum.Accountant,
        RoleEnum.User,
      ],
      icon: <LayoutOutlined />,
      href: ROUTE_PATHS.themesOverView,
      label: <NavLink to={ROUTE_PATHS.themesOverView}>{t("siderBar.template")}</NavLink>,
    },

    {
      key: "plugin",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Mod,
        RoleEnum.Accountant,
        RoleEnum.User,
      ],
      icon: <AppstoreAddOutlined />,
      href: ROUTE_PATHS.themesPlugin,
      label: <NavLink to={ROUTE_PATHS.themesPlugin}>{t("siderBar.plugin")}</NavLink>,
    },

    {
      key: "sampleData",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Mod,
        RoleEnum.Accountant,
        RoleEnum.User,
      ],
      icon: <CopyOutlined />,
      href: ROUTE_PATHS.themesSampleData,
      label: <NavLink to={ROUTE_PATHS.themesSampleData}>{t("siderBar.sampleData")}</NavLink>,
    },

    {
      key: "user",
      roles: [
        RoleEnum.Mod,
        RoleEnum.Admin,
        RoleEnum.Accountant,
      ],
      icon: <UserOutlined />,
      href: ROUTE_PATHS.usersList,
      label: <NavLink to={ROUTE_PATHS.usersList}>{t("siderBar.users")}</NavLink>,
    },

    {
      key: "revenue",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Accountant,
        RoleEnum.User,
      ],
      icon: <DollarCircleOutlined />,
      href: ROUTE_PATHS.Home,
      label: <NavLink to={ROUTE_PATHS.Home}>{t("siderBar.revenue")}</NavLink>,
    },

    {
      key: "budget",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Accountant,
        RoleEnum.User,
      ],
      icon: <WalletOutlined />,
      href: ROUTE_PATHS.myBudget,
      label: <NavLink to={ROUTE_PATHS.myBudget}>{t("siderBar.budget")}</NavLink>,
    },

    {
      key: "general",
      roles: [
        RoleEnum.Admin,
      ],
      icon: <ControlOutlined />,
      href: ROUTE_PATHS.adminGeneral,
      label: <NavLink to={ROUTE_PATHS.adminGeneral}>{t("siderBar.general")}</NavLink>,
    },

    {
      key: "websitePlan",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Mod,
      ],
      icon: <ScheduleOutlined />,
      href: ROUTE_PATHS.adminWebsitePlan,
      label: <NavLink to={ROUTE_PATHS.adminWebsitePlan}>{t("siderBar.websitePlan")}</NavLink>,
    },

    {
      key: "cloudflare",
      roles: [
        RoleEnum.Admin,
      ],
      icon: <CloudServerOutlined />,
      href: ROUTE_PATHS.adminCloudflare,
      label: <NavLink to={ROUTE_PATHS.adminCloudflare}>{t("siderBar.cloudflare")}</NavLink>,
    },

    {
      key: "announcement",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Mod,
        RoleEnum.Accountant,
        RoleEnum.User,
      ],
      icon: <ExceptionOutlined />,
      href: ROUTE_PATHS.Home,
      label: <NavLink to={ROUTE_PATHS.Home}>{t("siderBar.announcement")}</NavLink>,
    },

    {
      key: "mailer",
      roles: [
        RoleEnum.Admin,
        RoleEnum.Mod,
      ],
      icon: <MailOutlined />,
      href: ROUTE_PATHS.adminMailer,
      label: <NavLink to={ROUTE_PATHS.adminMailer}>{t("siderBar.mailer")}</NavLink>,
    },

    {
      key: "kubernet",
      roles: [
        RoleEnum.Admin,
      ],
      icon: <ClusterOutlined />,
      href: ROUTE_PATHS.adminKubebernet,
      label: <NavLink to={ROUTE_PATHS.adminKubebernet}>{t("siderBar.kubernet")}</NavLink>,
    },

    {
      key: "messages",
      roles: [
        RoleEnum.Admin,
      ],
      icon: <MobileOutlined />,
      href: ROUTE_PATHS.adminMessages,
      label: <NavLink to={ROUTE_PATHS.adminMessages}>{t("siderBar.messages")}</NavLink>,
    },

    {
      key: "databases",
      roles: [
        RoleEnum.Admin,
      ],
      icon: <DatabaseOutlined />,
      href: ROUTE_PATHS.adminDatabases,
      label: <NavLink to={ROUTE_PATHS.adminDatabases}>{t("siderBar.databases")}</NavLink>,
    },

    {
      key: "healthCheck",
      roles: [
        RoleEnum.Admin,
      ],
      icon: <SafetyOutlined />,
      href: ROUTE_PATHS.adminHealthCheck,
      label: <NavLink to={ROUTE_PATHS.adminHealthCheck}>{t("siderBar.healthCheck")}</NavLink>,
    },
  ];

  const selectedKeys = useMemo(() => {
    const items = menuItems.find(item => item.href.includes(pathname));

    if (items) {
      return items.key
    }

    return '';
  }, [pathname]);

  const defaultOpenKeys = 'dashboard';

  const permissionMenuItems = menuItems.filter((items: any) => {
    return !items.roles || (user?.role && items.roles.includes(user?.role))
  });

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass()}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            theme="dark"
            mode="inline"
            className="navbar"
            items={permissionMenuItems}
            selectedKeys={[selectedKeys]}
            defaultOpenKeys={[defaultOpenKeys]}
          />
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
