import { Button, Form, Input, Tooltip } from "antd";

import { LockOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import {
  PASSWORD_REGEX,
  VALIDATION_MESSAGE_CONFIG,
} from "../../constants/app-constants";
import { commonPageTitleSelector } from "../../states/common";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import showNotification from "../../components/common/notification";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { useUserActions } from "../../hooks/useUserActions";
import "./index.scss";

const FormItem = Form.Item;


const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userActions = useUserActions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const state = location.state;

  const setPageTitle = useSetRecoilState(commonPageTitleSelector);

  useEffect(() => {
    setPageTitle("");
  }, []);

  async function formSubmit(value: any) {
    setLoading(true);
    try {
      const password = value.password;
      const phoneNumber = state?.phoneNumber;
      const email = searchParams.get('email') 
      const code = state?.code || searchParams.get('code')

      // if (email) {
      await userActions.resetPassword({
        code,
        email,
        phoneNumber,
        newPassword: password,
        navigate,
      })

    } catch(error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="reset-password">
      <h2>{t("resetPassword.title")}</h2>
      <div className="tooltip" >
        <div className="desc">{t("resetPassword.des")}</div>
        <Tooltip  placement="right" title={<>
         <div>{t("resetPassword.rule1")}</div>
         <div>{t("resetPassword.rule2")}</div>
         <div>{t("resetPassword.rule3")}</div>
        </>} className="reset-password__tooltip">
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      </div>

      <Form
        initialValues={{ remember: true }}
        name="signInForm"
        className="gx-signin-form gx-form-row0 form-hidden-label sign-in-page"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={formSubmit}
      >
        <FormItem
          name="password"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                var passwordReg = new RegExp(PASSWORD_REGEX);
                if (!value || passwordReg.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(t("signUp.invalidPassword"));
              },
            }),
          ]}
          label={t("resetPassword.newPassword")}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder={t("signUp.password")}
          />
        </FormItem>
        <FormItem
          name="confirmPassword"
          label={t("resetPassword.confirmNewPassword")}
          dependencies={["password"]}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("signUp.passwordsNotMatch"));
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder={t("resetPassword.confirmNewPassword")}
          />
        </FormItem>

        <Form.Item>
          <Button
            type="primary"
            className="gx-mb-0"
            htmlType="submit"
            loading={loading}
          >
            {t("resetPassword.btnSubmit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordPage;
