import { Button, Card } from "antd";
import { useTranslation } from "react-i18next";
import Table, { ColumnsType } from "antd/lib/table";

import RounedeTag from "../../../../components/common/rounded-tag";
import ProtectedAction from "../../../../components/action-protected";

import { RoleEnum } from "../../../../enums/app.enum";

import './index.scss';
import { useEffect, useState } from "react";
import { UserApi } from "../../../../apis/user.api";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";
import TableNoData from "../../../../components/common/table/table-no-data";
interface ReviewListProps {
    onAction?: (id: string | number) => void;

    data?: Array<any>;
}

const ReviewListTable: React.FunctionComponent<ReviewListProps> = ({ data, onAction }) => {
    const { t } = useTranslation();
    const { currencyConfig,
        convertNumericToFormattedAmount } = useCurrencyConfig();

    const [reviewList, setReviewList] = useState([]);

    const handleActionButtonClick = (actionId: number | string) => {
        if (onAction) {
            onAction(actionId);
        };
    };

    const columns: ColumnsType<any> = [
        {
            title: t("user.activity"),
            dataIndex: "activity",
            key: "activity",
            className: 'cell__activity',
        },
        {
            title: t("user.amount") + `(${currencyConfig.currency})`,
            dataIndex: "amount",
            key: "amount",
            align: "center",
            className: 'cell__amount',
            render: (text) => {
                return convertNumericToFormattedAmount(text);
            }
        },
        {
            title: t("user.status"),
            dataIndex: "status",
            key: "status",
            align: "center",
            className: 'cell__status',
            render: (text) => {
                return <RounedeTag color="orange" />
            }
        },
        {
            title: t("user.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: '100px',
        },
        {
            width: '80',
            key: "action",
            fixed: 'right',
            align: "right",
            className: 'cell__button',
            render: (record) => {
                return (
                    <>
                        <ProtectedAction roles={[RoleEnum.Accountant]}>
                            <Button onClick={() => handleActionButtonClick(record.id)} className="review-list-table__button" type="primary">{t('common.process')}</Button>
                        </ProtectedAction>

                        <ProtectedAction roles={[]}>
                            <Button onClick={() => handleActionButtonClick(record.id)} className="review-list-table__button" type="primary">{t('common.detail')}</Button>
                        </ProtectedAction>
                    </>
                );
            },
        },
    ];

    const loadUserActivities = async () => {
        const response = await UserApi.getUserBudgetActivities({
            pageIndex: 1,
            pageSize: 10,
        });

        setReviewList(response.data?.records);
    };

    useEffect(() => {
        loadUserActivities();
    }, []);

    if (!reviewList.length) {
        return <></>
    };

    return (
        <Card title={t('dashboard.review')} bordered className="gx-card-widget">
            {reviewList.length > 0 ? <Table
                className="gx-table-responsive review-list-table"
                size="small"
                bordered={false}
                columns={columns}
                pagination={false}
                scroll={{ x: 600 }}
                dataSource={reviewList}
            /> : <TableNoData />}
        </Card>
    );
};

export default ReviewListTable;
