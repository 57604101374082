export const languageList = [
    {
        lng: 'vi',
        flag: 'vn'
    },

    {
        lng: 'en',
        flag: 'gb'
    }
]