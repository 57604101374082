import React, { useMemo, useState } from "react";
import {
  BellOutlined,
  SearchOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";

import './app-nav.scss';
import classNames from "classnames";
import i18n from "../../../utils/i18n";
import LocalUtils from "../../../utils/local.utils";
import { languageList } from "../../../languages/language-config";
import { LOCAL_STORAGE_KEY } from "../../../constants/app-constants";

const AppsNavigation = () => {
  const [openPopover, setOpenPopover] = useState(false);

  const detectLanguage = useMemo(() => {
    return i18n.language;
  }, [i18n.language]);

  const flagCss = useMemo(() => {
    const selectedLanguage = languageList.find((language: any) => language.lng === detectLanguage);

    if (selectedLanguage) {
      return selectedLanguage.flag
    }

    return '';
  }, [detectLanguage]);

  const handleChangeLanague = (lng: string) => {
    setOpenPopover(false);
    i18n.changeLanguage(lng);
    LocalUtils.set(LOCAL_STORAGE_KEY.LANGUAGE, lng);
  };

  const handleOpenChange = (status: boolean) => {
    setOpenPopover(status);
  };

  return (
    <ul className="gx-app-nav app-nav">
      <li>
        <BellOutlined />
      </li>
      <li>
        <SearchOutlined />
      </li>
      <li>
        <MessageOutlined />
      </li>

      <li>
        <Popover
          className="language"
          content={
            languageList.map((language: any) => {
              if (detectLanguage === language.lng) {
                return '';
              }

              return (
                <div
                  key={language.lng}
                  onClick={() => handleChangeLanague(language.lng)}
                  className={classNames([`language flag flag-24 flag-${language.flag}`])}
                />
              )
            })
          }
          trigger="click"
          placement="bottom"
          open={openPopover}
          onOpenChange={handleOpenChange}
        >
          <div className={classNames([`language flag flag-24 flag-${flagCss}`])} />
        </Popover>
      </li>
    </ul>
  )


};

export default AppsNavigation;
