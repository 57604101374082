import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Switch, Upload, UploadFile } from "antd";

// icon
import { UploadOutlined } from "@ant-design/icons";

// component
import UploadImage from "../../../../views/theme/upload-image";
import ProtectedAction from "../../../../components/action-protected";
import MutipleSelectBox from "../../../../views/theme/multiple-select-box";
import ThemeUploadWrapper from "../../../../views/theme/theme-upload-wrapper";

// ultil
import { useRecoilValue } from "recoil";
import { RoleEnum } from "../../../../enums/app.enum";
import { ThemeApis } from "../../../../apis/theme.api";
import { generateDownloadData, generateImage } from "../../../../utils/app.utils";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { themeCategoriesSelector, themeExtraInformationSelector, themeSiteByCategory, wordpressVersionsSelector } from "../../../../states/theme";

// style
import './index.scss';
import { getUserInfoSelector } from "../../../../states/auth";
import confirmReasonDeactivated from "../../../../views/theme/deactivated-modal";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },

    className: "form__item--horizone"
};

const colFormItemLayout = {
    labelCol: {
        md: { span: 24 },
        sm: { span: 6 },
        xs: { span: 24 },
    },
    wrapperCol: {
        md: { span: 24 },
        sm: { span: 18 },
        xs: { span: 24 },
    },

    className: "form__item--vertical"
}

const switchLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },

    className: "form__item--switch-horizone"
};

const switchThemeFreeLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 24 },
        xl: { span: 12 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        md: { span: 24 },
        xl: { span: 12 },
    },
}

const tailLayout = {
    wrapperCol: {
        span: 24,
    },
};

const validateSchema = (label: string) => {
    return [
        {
            required: true,
            message: t('theme.requiredMessageValidate', { label: label })
        }
    ]
};

interface UploadFormProps {
}

const UploadThemeOverview: FunctionComponent<UploadFormProps> = (props) => {
    // ultil
    const params = useParams();
    const location = useLocation();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { formatAmount, parserInput } = useCurrencyConfig();

    // state data
    const [loading, setLoading] = useState<boolean>(false);
    const [themeDetail, setThemeDetail] = useState<any>(null);
    const [dataThumbnail, setDataThumbnail] = useState<UploadFile[]>([]);
    const [deleteThumbnailIds, setDeleteThumbnailIds] = useState<Array<any>>([]);

    // recoil
    const userInfo = useRecoilValue(getUserInfoSelector);
    const themeSites = useRecoilValue(themeSiteByCategory);
    const wordpressVersions = useRecoilValue(wordpressVersionsSelector);
    const themeCategories = useRecoilValue(themeCategoriesSelector);
    const themeExtraInformation = useRecoilValue(themeExtraInformationSelector);

    const isEditMode = useMemo(() => {
        return params.siteId && location.pathname.includes('edit');
    }, [params.siteId, location]);

    const isReview = useMemo(() => {
        return location.pathname.includes('review');
    }, [location]);

    const isUserRole = useMemo(() => {
        return userInfo.role === 'User';
    }, [userInfo]);

    const onUploadThumbnail = (files: any) => {
        setDataThumbnail(files);
    };

    const handleUploadButtonClick = () => {
        form.submit();
    };

    const handleAprroveButtonClick = async () => {
        try {
            setLoading(true);
            await ThemeApis.approveSiteThemeOverview(params.siteId);
            navigate(ROUTE_PATHS.themesOverView)
        } finally {
            setLoading(false);
        }
    };

    const handleRejectButtonClick = async () => {
        const handleGetReason = (reason: string) => rejectSite(reason.replaceAll('\n', '%0A'));
        Modal.confirm({
            ...confirmReasonDeactivated(handleGetReason),
            title: t('theme.confirmRejectTheme'),
            okButtonProps: { loading: loading },
            okText: t('common.reject'),
            okType: 'primary'
        });
    };

    const rejectSite = async (reason: string) => {
        try {
            setLoading(true);
            await ThemeApis.rejectSiteThemeOverview({ id: params.siteId, reason });

            navigate(ROUTE_PATHS.themesOverView)
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = (value: any) => {
        if (isEditMode) {
            editSite(value);
            return;
        }

        addNewsite(value);
    };

    const downloadTheme = async (file: any) => {
        const response = await ThemeApis.downloadTheme({ id: params.siteId, fileId: file.id });
        generateDownloadData(response.data, file.name);
    };

    const addNewsite = async (value: any) => {
        try {
            setLoading(true);
            const thumbnailFiles = dataThumbnail.map(thumbnail => thumbnail.originFileObj);

            const payload = {
                ...value,
                thumbnailFiles: thumbnailFiles,
                templateFile: value['templateFile']?.[0]?.originFileObj,
                designAssetFile: value['designAssetFile']?.[0]?.originFileObj,
                sampleDataFile: value['sampleDataFiles']?.[0]?.originFileObj,
            };

            const result = await ThemeApis.postSiteByThemeCategory(payload);

            if (result?.data) {
                if (isUserRole) {
                    Modal.success({
                        content: t('theme.uploadSuccessMessage'),
                        onOk: () => navigate(ROUTE_PATHS.themesOverView)
                    })

                    return
                }

                navigate(ROUTE_PATHS.themeDetail.replace(':themeId', result?.data?.id?.toString()));
            }

        } finally {
            setLoading(false);
        }
    };

    const editSite = async (values: any) => {
        try {
            setLoading(true);

            const IsDeleteDesignAssetFile = values['designAssetFile']?.length ? false : true;
            const dataThumbnailsUpload = dataThumbnail.filter((thumbnail: any) => !!thumbnail.originFileObj);
            const payload = {
                ...values,
                IsDeleteDesignAssetFile,
                id: Number(params.siteId),
                ThumbnailsToDelete: deleteThumbnailIds,
                templateFile: values['templateFile']?.[0]?.originFileObj,
                sampleDataFile: values['sampleDataFiles']?.[0]?.originFileObj,
                designAssetFile: values['designAssetFile']?.[0]?.originFileObj,
                thumbnailFiles: dataThumbnailsUpload.map((dataUpload: any) => dataUpload.originFileObj),
            };

            delete payload.sampleDataFiles

            const result = await ThemeApis.updateSiteThemeOverview(payload);

            if (result?.data) {
                if (isUserRole) {
                    Modal.success({
                        content: t('theme.uploadSuccessMessage'),
                        onOk: () => navigate(ROUTE_PATHS.themesOverView)
                    })

                    return
                }

                navigate(ROUTE_PATHS.themeDetail.replace(':themeId', params.siteId?.toString() || ''));
            };

        } finally {
            setLoading(false);
        };
    };

    const getThumbnailRemove = (id: number | string) => {
        const removeIds = [...deleteThumbnailIds];
        removeIds.push(id);
        setDeleteThumbnailIds(removeIds);
    };

    const loadSiteDetailBySiteId = async () => {
        const result = await ThemeApis.getThemeSiteDetail({ id: params.siteId });

        if (!result?.data) {
            return;
        };

        setThemeDetail(result.data)

        const { templateFile, compatibleBrowsers, compatibleWith, designAssetsFile, sampleDataFile, themeFilesIncluded, tags, thumbnails, ...formData } = result?.data;

        const tabIds = tags?.map((tabs: any) => tabs.id);
        const compatibleWithIds = compatibleWith?.map((width: any) => width.id);
        const compatibleBrowsersIds = compatibleBrowsers?.map((browser: any) => browser.id);
        const themeFilesIncludeIds = themeFilesIncluded?.map((themeFile: any) => themeFile.id);

        form.setFieldValue('tagIds', tabIds);
        form.setFieldValue('compatibleWithIds', compatibleWithIds);
        form.setFieldValue('themeFilesIncludedIds', themeFilesIncludeIds);
        form.setFieldValue('compatibleBrowserIds', compatibleBrowsersIds);

        const thumbnailValues = thumbnails.map((thubnail: any, index: number) => ({
            uid: thubnail.id,
            name: t('theme.thumbnail') + (index + 1),
            status: 'done',
            url: generateImage(thubnail.name),
        }));

        setDataThumbnail(thumbnailValues);

        const templateFileValue = {
            uid: templateFile?.id,
            name: templateFile?.name,
            status: 'done',
            url: '',
        };

        const sampleDataFileValue = {
            uid: sampleDataFile?.id,
            name: sampleDataFile?.name,
            status: 'done',
            url: '',
        };

        if (designAssetsFile) {
            const designAssetsFileValue = {
                uid: designAssetsFile?.id,
                name: designAssetsFile?.name,
                status: 'done',
                url: '',
            };
            form.setFieldValue('designAssetFile', [designAssetsFileValue]);
        };

        form.setFieldValue('templateFile', [templateFileValue]);
        form.setFieldValue('sampleDataFiles', [sampleDataFileValue]);

        form.setFieldsValue(formData);
    };

    useEffect(() => {
        if (params.siteId) {
            loadSiteDetailBySiteId();
        }
    }, [params.siteId])

    return (
        <ThemeUploadWrapper>
            <ProtectedAction
                roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User]}
            >
                <Form className="upload__form form" form={form} onFinish={handleFormSubmit} disabled={loading}>
                    <Row gutter={[18, 18]}>

                        <Col md={16} xs={24}>
                            <Card>
                                <div className="gx-mb-3">{t("theme.generalInformation")}</div>

                                <Form.Item required {...formItemLayout} label={t('theme.themeName')} name="name" rules={validateSchema(t('theme.themeName'))}>
                                    <Input
                                        placeholder={t("theme.enterName")}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item required {...formItemLayout} label={t('theme.themeThumbnail')} name="thumbnailFiles" rules={!dataThumbnail.length ? validateSchema(t('theme.themeThumbnail')) : []}>
                                    <UploadImage onUpdate={onUploadThumbnail} multiple thumbnailFiles={dataThumbnail} onRemove={getThumbnailRemove} disabled={isReview} />
                                </Form.Item>

                                <Form.Item required {...formItemLayout} label={t('theme.description')} name="description" rules={validateSchema(t('theme.description'))}>
                                    <Input.TextArea rows={4} placeholder={t('theme.enterDescription')} disabled={isReview} />
                                </Form.Item>

                                <Form.Item required {...formItemLayout} label={t('theme.demoURL')} name="demoURL" rules={validateSchema(t('theme.demoURL'))}>
                                    <Input
                                        placeholder={t('theme.enterURL')}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Divider />

                                <div className="gx-mb-3">{t('theme.themeResource')}</div>

                                <Form.Item
                                    required
                                    {...formItemLayout}
                                    label={t('theme.template')}
                                    name="templateFile"
                                    valuePropName="fileList"
                                    getValueFromEvent={event => {
                                        if (Array.isArray(event)) {
                                            return event;
                                        }

                                        return event && event.fileList;
                                    }}
                                    rules={validateSchema(t('theme.template'))}
                                >
                                    <Upload
                                        className="upload-file"
                                        maxCount={1}
                                        beforeUpload={() => false}
                                        disabled={isReview}
                                        showUploadList={{
                                            showDownloadIcon: isReview,
                                        }}
                                        onDownload={() => downloadTheme(themeDetail.templateFile)}
                                    >
                                        <Button>
                                            <UploadOutlined /> {t('common.clickToUpload')}
                                        </Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item
                                    required
                                    {...formItemLayout}
                                    label={t('theme.sampleData')}
                                    name="sampleDataFiles"
                                    valuePropName="fileList"
                                    getValueFromEvent={event => {
                                        if (Array.isArray(event)) {
                                            return event;
                                        }

                                        return event && event.fileList;
                                    }}
                                    rules={validateSchema(t('theme.dataName'))}
                                >
                                    <Upload
                                        className="upload-file"
                                        maxCount={1}
                                        beforeUpload={() => false}
                                        disabled={isReview}
                                        showUploadList={{
                                            showDownloadIcon: isReview,
                                        }}
                                        onDownload={() => downloadTheme(themeDetail.sampleDataFile)}
                                    >
                                        <Button>
                                            <UploadOutlined /> {t('common.clickToUpload')}
                                        </Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    label={t('theme.designAssets')}
                                    name="designAssetFile"
                                    valuePropName="fileList"
                                    getValueFromEvent={event => {
                                        if (Array.isArray(event)) {
                                            return event;
                                        }

                                        return event && event.fileList;
                                    }}
                                >
                                    <Upload
                                        className="upload-file"
                                        maxCount={1}
                                        beforeUpload={() => false}
                                        disabled={isReview}
                                        showUploadList={{
                                            showDownloadIcon: isReview,
                                        }}
                                        onDownload={() => downloadTheme(themeDetail.designAssetsFile)}
                                    >
                                        <Button>
                                            <UploadOutlined /> {t('common.clickToUpload')}
                                        </Button>
                                    </Upload>
                                </Form.Item>

                                <Divider />

                                <div className="gx-mb-3">{t('theme.extraInformation')}</div>

                                <Row justify="center" align="middle">
                                    <Col span={12}>
                                        <Form.Item required {...switchLayout} label={t('theme.hightResolution')} name="isHighResolution" initialValue={true} valuePropName="checked">
                                            <Switch disabled={isReview} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item required {...switchLayout} label={t('theme.layoutResponsive')} name="isLayoutResponsive" initialValue={true} valuePropName="checked">
                                            <Switch disabled={isReview} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item {...formItemLayout} label={t('theme.compatibleBrowsers')} name="compatibleBrowserIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        disabled={isReview}
                                        placeholder={t('theme.compatibleBrowsers')}
                                        optionData={themeExtraInformation.compatibleBrowsers}
                                    />
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('theme.compatibleWith')} name="compatibleWithIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        disabled={isReview}
                                        placeholder={t('theme.compatibleWith')}
                                        optionData={themeExtraInformation.compatibleWith}
                                    />
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('theme.themeFilesInclude')} name="themeFilesIncludedIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        disabled={isReview}
                                        placeholder={t('theme.themeFilesInclude')}
                                        optionData={themeExtraInformation.themeFilesIncluded}
                                    />
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('theme.tags')} name="tagIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        disabled={isReview}
                                        placeholder={t('theme.tags')}
                                        optionData={themeExtraInformation.tags}
                                    />
                                </Form.Item>
                            </Card>
                        </Col>

                        <Col md={8} xs={24}>
                            <Card>
                                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.themeCategory')} name="categoryId" initialValue={themeSites.category?.id} rules={validateSchema(t('theme.themeCategory'))}>
                                    <MutipleSelectBox
                                        disabled={isReview}
                                        placeholder={t('theme.dataCategory')}
                                        optionData={themeCategories}
                                    />
                                </Form.Item>

                                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.wordpressVersion')} name="versionId" rules={validateSchema(t('theme.wordpressVersion'))}>
                                    <MutipleSelectBox
                                        disabled={isReview}
                                        placeholder={t('theme.wordpressVersion')}
                                        optionData={wordpressVersions.records}
                                    />
                                </Form.Item>

                                <Form.Item {...switchThemeFreeLayout} labelAlign="left" label={t('theme.themeFree')} name="isThemeFree" initialValue={false} valuePropName="checked">
                                    <Switch disabled={isReview} />
                                </Form.Item>

                                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.themePrice')} name="price" rules={validateSchema(t('theme.themePrice'))}>
                                    <InputNumber
                                        disabled={isReview}
                                        placeholder="0 VND"
                                        parser={value => parserInput(value)}
                                        formatter={value => formatAmount(value)}
                                    />
                                </Form.Item>

                                <Form.Item {...tailLayout} className="gx-text-center gx-mb-0">
                                    {!isReview && <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Button disabled={loading} className="gx-mb-0 gx-w-100" htmlType="button" >
                                                {t('common.preview')}
                                            </Button>
                                        </Col>

                                        <Col span={12}>
                                            <Button loading={loading} disabled={loading} className="gx-mb-0 gx-w-100" type="primary" htmlType="submit">
                                                {!isEditMode ? t('common.upload') : t('common.update')}
                                            </Button>
                                        </Col>
                                    </Row>}

                                    {!!isReview && <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Button onClick={handleRejectButtonClick} disabled={loading} className="gx-mb-0 gx-w-100">
                                                {t('common.reject')}
                                            </Button>
                                        </Col>

                                        <Col span={12}>
                                            <Button onClick={handleAprroveButtonClick} loading={loading} disabled={loading} className="gx-mb-0 gx-w-100" type="primary">
                                                {t('common.approve')}
                                            </Button>
                                        </Col>
                                    </Row>}

                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </ProtectedAction>
        </ThemeUploadWrapper>
    );
};

export default UploadThemeOverview;
