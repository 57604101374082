export const generateImage = (url: any) => {
    if (url) {
        return process.env.REACT_APP_API_ENPOINT + url;
    };

    return '';
};

export const generateDownloadData = (dataBlob: any, fileName: string) => {
    const blob = new Blob([dataBlob])
    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(url);
};