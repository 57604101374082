import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { RangePickerProps } from "antd/lib/date-picker";
import { Col, DatePicker, Input, Row, Switch } from "antd";

import { SiteStates, SiteTypes } from "../../../../constants/app-constants";

import "./index.scss";

interface SiteFilterBarProps {
  onSearch?: (value: string) => void;
  onSiteFilter?: (value: any) => void;
  onStateFilter?: (value: any) => void;
  onDateRangeFilter?: (date: any) => void;
}

const SiteFilter: React.FunctionComponent<SiteFilterBarProps> = (props) => {
  const { t } = useTranslation();
  const { onSiteFilter, onStateFilter, onDateRangeFilter, onSearch } = props;

  // state data
  const [filterSites, setFilterSites] = useState<number>(7);
  const [filterState, setFilterState] = useState<number>(31);
  const [showSwitch, setShowSwitch] = useState<boolean>(false);

  const handleSearchClick = () => {
    setShowSwitch(!showSwitch);
  };

  const stateSwitchGroup = (value: number, status: boolean) => {
    const filterNumber = (() => {
      if (status) {
        return filterState + value;
      }

      return filterState - value;
    })();

    setFilterState(filterNumber);
    if (onStateFilter) onStateFilter(filterNumber);
  };

  const siteSwitchGroup = (value: number, status: boolean) => {
    const filterNumber = (() => {
      if (status) {
        return filterSites + value;
      }

      return filterSites - value;
    })();

    setFilterSites(filterNumber);
    if (onSiteFilter) onSiteFilter(filterNumber);
  };

  const handleChangeDatePicker = (
    value: RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    if (onDateRangeFilter) {
      onDateRangeFilter({
        startDate: dateString[0],
        endDate: dateString[1],
      });
    }
  };

  const handeInputSearchChange = (event: any) => {
    const value = event.target.value;
    if (!value) {
      handleSearch('');
    }
  };

  const handleSearch = (name: string) => {
    if (onSearch) onSearch(name);
  };

  return (
    <div className="sites-filter">
      <Row
        justify="space-between"
        align="middle"
        className="website-site-filter filter"
      >
        <Col
          span={24}
          className={classNames([
            "filter__search",
            { ["not-show"]: !showSwitch },
          ])}
        >
          <div className="filter__search-wrapper" onClick={handleSearchClick}>
            <span className="filter__search--text">
              {t("dashboard.searchAdvance")}
            </span>
            <i
              className={`icon icon-menu-${
                showSwitch ? "up" : "down"
              } gx-fs-lg filter__search--icon`}
            />
          </div>
        </Col>

        <Col
          span={24}
          className={classNames([
            "filter__switch switch",
            { ["show"]: showSwitch },
          ])}
        >
          <Row>
            <Col span={24}>
              <Row justify="space-between" align="middle" flex-wrap="nowrap">
                <Col span={24} xl={10}>
                  <Row justify="start" align="middle">
                    {SiteTypes.map((type) => (
                      <Col className="switch-item" key={type.id}>
                        <label className="switch-item__label">
                          {type.name}
                        </label>
                        <Switch
                          defaultChecked
                          className={type.color}
                          onChange={(checked) =>
                            siteSwitchGroup(type.id, checked)
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col span={24} xl={14}>
                  <Row
                    className="switch-group"
                    justify="end"
                    align="middle"
                    flex-wrap="nowrap"
                  >
                    {SiteStates.map((state) => (
                      <Col className="switch-item-right" key={state.id}>
                        <label className="switch-item__label">
                          {state.name}
                        </label>
                        <Switch
                          defaultChecked
                          className={state.color}
                          onChange={(checked) =>
                            stateSwitchGroup(state.id, checked)
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col className="more-filters" span={24}>
              <Row className="filter__input">
                <Col xs={24} md={12}>
                  <Row align="middle" className="date-wrapper">
                    <label className="date__label">
                      {t("website.createAt")}:
                    </label>
                    <DatePicker.RangePicker
                      className="date__input"
                      onChange={handleChangeDatePicker}
                    />
                  </Row>
                </Col>

                <Col xs={24} md={12}>
                  <Row
                    justify="end"
                    align="middle"
                    flex-wrap="nowrap"
                    className="search-wrapper"
                  >
                    <label className="search__label">
                      {t("common.search")}:
                    </label>
                    <Input.Search
                      className="search__input"
                      onSearch={handleSearch}
                      allowClear
                      onChange={handeInputSearchChange}
                      placeholder={t("website.searchByName")}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SiteFilter;
