import { FunctionComponent } from "react";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

import "./index.scss";
interface AddDataBaseFormProps {
  form: any;
  onCreate?: (value: any) => void;
}

const AddUserDatabaseForm: FunctionComponent<AddDataBaseFormProps> = ({ form, onCreate }) => {
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    if (onCreate) {
      onCreate(values);
    }
  };

  return (
    <div className="add-user-database-form">
      <Form
        form={form}
        onFinish={onFinish}
        className="gx-signin-form gx-form-row0 form-hidden-label "
      >
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <label>
              <span className="redStar">*</span> {t("website.username")}
            </label>
            <Form.Item
              rules={[
                { required: true, message: t("website.validate.username") },
              ]}
              name="userName"
              label={t("website.username")}
            >
              <Input placeholder={t("website.username")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label>
              <span className="redStar">*</span> {t("website.databaseName")}
            </label>
            <Form.Item
              rules={[
                { required: true, message: t("website.validate.databaseName") },
              ]}
              name="name"
              label={t("website.databaseName")}
            >
              <Input placeholder={t("website.databaseName")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddUserDatabaseForm;
