import { RoleEnum } from "../enums/app.enum";
import { WordpressFilterType } from "../enums/theme.enum";
import { ActionOption, WithdrawMethod } from "../enums/user.enum";
import {
  SiteStatusEnum,
  SiteTypeEnum,
  UserTypeEnum,
  StatusType,
  SalesLevel,
  RatingSale,
} from "../enums/website.enum";
import i18n from "../utils/i18n";

export const DEFAULT_SETTINGS = {};

export const LOCAL_COOKIE_KEY = {
  USER_INFO: "USER_INFO",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  IS_LOGGED_IN: "IS_LOGGED_IN",
  TOKEN_EXPIRE: "TOKEN_EXPIRE",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  IS_REMEMBER_ME: "IS_REMEMBER_ME",
};

export const LOCAL_STORAGE_KEY = {
  LANGUAGE: "lang",
  USER_INFO: "USER_INFO",
  IS_REMEMBER_ME: "IS_REMEMBER_ME",
};

export const VALIDATION_MESSAGE_CONFIG = {
  required: i18n.t("common.requireValidation"),
  types: {
    email: i18n.t("common.emailValidation"),
  },
};

export const TAB_SIZE = 992;

export const PAGE_SIZE = 10;
export const SITE_PAGE_SIZE = 16;
export const CATEGORY_SHOW_LIMIT = 12;

export const ANT_LOCALE = {
  emptyText: i18n.t("common.noData"),
};

export const FORMAT_DATE_TIME = {
  y: 'YYYY',
  Hm: 'HH:mm',
  my: "MM-YYYY",
  Hms: 'HH:mm:ss',
  ymd: "YYYY-MM-DD",
  myBank: "MM/YYYY",
  dmyVN: "DD/MM/YYYY",
  ymdhs: "YYYY-MM-DD hh:mm",
}

export const IMAGE_DEFAULT_TYPE = {
  category: 1,
  themePreview: 2,
  website: 3,
  bank: 4,
}

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9_@./#&+-]{8,}$/;

export const PASSWORD_REGEX_ONE_LOWER_CASE = `(?=.*[a-z])`; // should contain at least one lower case
export const PASSWORD_REGEX_ONE_UPPER_CASE = `(?=.*[A-Z])`; // should contain at least one upper case
export const PASSWORD_REGEX_ONE_NUMBER = `(?=.*[0-9])`; // should contain at least one upper case
export const PASSWORD_REGEX_MINIMUM_8_CHARACTERS = `(?=.{8,})`; // should contain at least 8 characters
export const PASSWROD_REGEX_SPECIAL_CHARACTER = `(?=.*[!@#$%^&*])`; // should contain a special character

export const PASSWORD_POLYCIES = [
  {
    key: 1,
    valid: false,
    regex: PASSWORD_REGEX_ONE_LOWER_CASE,
  },
  {
    key: 2,
    regex: PASSWORD_REGEX_ONE_UPPER_CASE,
    valid: false,
  },
  {
    key: 3,
    regex: PASSWORD_REGEX_ONE_NUMBER,
    valid: false,
  },
  {
    key: 4,
    regex: PASSWORD_REGEX_MINIMUM_8_CHARACTERS,
    valid: false,
  },
  {
    key: 5,
    regex: PASSWROD_REGEX_SPECIAL_CHARACTER,
    valid: false,
    disabled: true,
  },
];

export const DATE_TIME_FORMAT = {};

export const SiteTypes = [
  {
    id: SiteTypeEnum.Production,
    name: i18n.t("website.production"),
    color: "success",
  },
  {
    id: SiteTypeEnum.Demo,
    name: i18n.t("website.demo"),
    color: "info",
  },
  {
    id: SiteTypeEnum.Expired,
    name: i18n.t("website.expired"),
    color: "danger",
  },
];

export const SiteStates = [
  {
    id: SiteStatusEnum.Running,
    name: i18n.t("website.running"),
    color: "success",
  },
  {
    id: SiteStatusEnum.Error,
    name: i18n.t("website.error"),
    color: "danger",
  },
  {
    id: SiteStatusEnum.Stop,
    name: i18n.t("website.stop"),
    color: "default",
  },
  {
    id: SiteStatusEnum.Locked,
    name: i18n.t("website.locked"),
    color: "primary",
  },
  {
    id: SiteStatusEnum.Warning,
    name: i18n.t("website.warning"),
    color: "warning",
  },
  // {
  //   id: SiteStatusEnum.Renew,
  //   name: i18n.t("website.renew"),
  // },
];

export const UserTypes = [
  {
    id: RoleEnum.Admin,
    name: i18n.t("user.admin"),
    color: "red",
  },
  {
    id: RoleEnum.Mod,
    name: i18n.t("user.moderator"),
    color: "gold",
  },
  {
    id: RoleEnum.Accountant,
    name: i18n.t("user.accountant"),
    color: "green",
  },
  {
    id: RoleEnum.User,
    name: i18n.t("user.user"),
    color: "blue",
  },
];

export const UserRole =[
  {
    id: RoleEnum.Admin,
    name: i18n.t("user.admin"),
    color: "red",
  },
  {
    id: RoleEnum.Mod,
    name: i18n.t("user.mod"),
    color: "gold",
  },
  {
    id: RoleEnum.Accountant,
    name: i18n.t("user.accountant"),
    color: "green",
  },
  {
    id: RoleEnum.User,
    name: i18n.t("user.user"),
    color: "blue",
  },
]

export const StatusTypes = [
  {
    id: StatusType.Processed,
    name: i18n.t("user.processed"),
    color: "success",
  },
  {
    id: StatusType.Processing,
    name: i18n.t("user.processing"),
    color: "processing",
  },
  {
    id: StatusType.Waiting,
    name: i18n.t("user.waiting"),
    color: "warning",
  },
  {
    id: StatusType.Deleted,
    name: i18n.t("user.delete"),
    color: "error",
  },
];

export const VIEW_ITEM = {
  grid: 0,
  list: 1,
};

export const FILTER_THEME_TYPE = {
  bestSeller: 1,
  newest: 2,
  bestRate: 4,
  trending: 8,
  price: 16,
};

export const FILTER_SLIDER_PRICE = {
  min: 0,
  max: 1000000,
}

export const USER_BUDGET_ACTION_OPTIONS = [
  {
    id: ActionOption.Increase,
    value: ActionOption.Increase,
    name: i18n.t('user.increaseBudget'),
  },

  {
    id: ActionOption.Coupon,
    value: ActionOption.Coupon,
    name: i18n.t('user.addCoupon'),
  },

  {
    id: ActionOption.Transfer,
    value: ActionOption.Transfer,
    name: i18n.t('user.transferCredit'),
  },

  {
    id: ActionOption.Withdraw,
    value: ActionOption.Withdraw,
    name: i18n.t('user.withdraw'),
  }
];

export const WITHDRAW_METHOD_TYPE = [
  {
    id: WithdrawMethod.BankAccount,
    value: WithdrawMethod.BankAccount,
    name: i18n.t('payment.bank-account'),
  },

  {
    id: WithdrawMethod.UserAccount,
    value: WithdrawMethod.UserAccount,
    name: i18n.t('payment.user-account'),
  },
]

export const WORDPRESS_FILTER_TYPE = [
  {
    id: WordpressFilterType.newest,
    value: WordpressFilterType.newest,
    name: i18n.t('theme.newest'),
  },

  {
    id: WordpressFilterType.oldest,
    value: WordpressFilterType.oldest,
    name: i18n.t('theme.oldest'),
  },

  {
    id: WordpressFilterType.name,
    value: WordpressFilterType.name,
    name: i18n.t('theme.name'),
  }
]

export const hardcoreTypes = {
  compatibleBrowsers: "CompatibleBrowsers",
  compatibleWith: "CompatibleWith",
  tags: "Tags",
  ThemeFilesIncluded: "ThemeFilesIncluded",
}

export const roleTitle = {
  [RoleEnum.User]: 'User',
  [RoleEnum.Mod]: 'Moderator',
  [RoleEnum.Admin]: 'Administrator',
  [RoleEnum.Accountant]: 'Accountant',
}

export const SALES_LEVELS = [
  {
    id: SalesLevel.noSales,
    value: SalesLevel.noSales,
    name: i18n.t('theme.noSales'),
  },

  {
    id: SalesLevel.low,
    value: SalesLevel.low,
    name: i18n.t('common.low'),
  },

  {
    id: SalesLevel.medium,
    value: SalesLevel.medium,
    name: i18n.t('common.medium'),
  },

  {
    id: SalesLevel.high,
    value: SalesLevel.high,
    name: i18n.t('theme.high'),
  },

  {
    id: SalesLevel.topSeller,
    value: SalesLevel.topSeller,
    name: i18n.t('theme.topSellers'),
  },
]

export const SALES_RATINGS = [
  {
    id: RatingSale.all,
    value: RatingSale.all,
    name: i18n.t('theme.showAll'),
    field: 'ShowAll',
  },

  {
    id: RatingSale.oneStar,
    value: RatingSale.oneStar,
    name: i18n.t('theme.starRatingFilter', { number: 1 }),
    field: 'OneStarAndHigher',
  },

  {
    id: RatingSale.twoStar,
    value: RatingSale.twoStar,
    name: i18n.t('theme.starRatingFilter', { number: 2 }),
    field: 'TwoStarAndHigher',
  },

  {
    id: RatingSale.threeStar,
    value: RatingSale.threeStar,
    name: i18n.t('theme.starRatingFilter', { number: 3 }),
    field: 'ThreeStarAndHigher',
  },

  {
    id: RatingSale.fourStar,
    value: RatingSale.fourStar,
    name: i18n.t('theme.starRatingFilter', { number: 4 }),
    field: 'FourtarAndHigher',
  },
]
