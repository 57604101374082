import { Link } from "react-router-dom";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Pagination, Row } from "antd";

import ThemeCardGrid from "../../theme-card/grid";

import { PAGE_SIZE } from "../../../../constants/app-constants";

import "./index.scss";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { useRecoilValue } from "recoil";
import { getUserInfoSelector } from "../../../../states/auth";
import TableNoData from "../../../../components/common/table/table-no-data";
interface ThemePluginListProps {
  data?: any;
  pagination?: any;
  loading?: boolean;
}

const ThemePluginList: FunctionComponent<ThemePluginListProps> = (props) => {
  const { t } = useTranslation();

  const { data, pagination } = props;

  const userInfo = useRecoilValue(getUserInfoSelector);

  const isUserRole = useMemo(() => {
    return userInfo?.role === 'User';
  }, [userInfo]);

  const redirectLink = (themeId: string | number, isApproved: boolean) => {
    const userRedirectLink = !isUserRole ? ROUTE_PATHS.reviewThemePlugin.replace(':siteId', themeId.toString()) : ''

    if (isApproved) {
      return ROUTE_PATHS.themesPluginDetail.replace(':siteId', themeId.toString())
    }

    return userRedirectLink;
  }

  return (
    <div className="theme-plugin-list">
      {!!data?.length && <Row gutter={[12, 12]}>
        {data.map((item: any) => (
          <Col key={item.id} lg={6} md={8} sm={12} xs={24}>
            <ThemeCardGrid data={item} link={redirectLink(item.id, item.isApproved)} isFree={item?.isFree}>
              <Button className="gx-btn-outline-primary">{t("theme.livePreview")}</Button>
            </ThemeCardGrid>
          </Col>
        ))}
      </Row>}

      {!data?.length && (
        <TableNoData>
          <>
            <p>
              {t("theme.no-theme-description")}
            </p>

            <Link to={ROUTE_PATHS.uploadThemePlugin}>
              <Button type="primary">
                {t("theme.upload-theme")}
              </Button>
            </Link>
          </>
        </TableNoData>
      )}

      {!!data.length && <div className="pagination">
        <Pagination
          {...pagination}
        />
      </div>}
    </div>
  );
};

ThemePluginList.defaultProps = {
  data: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: PAGE_SIZE,
  }
}

export default ThemePluginList;
