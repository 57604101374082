import { Button, Col, Divider, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const AdminCloudflarePage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <div className="admin-cloudflare">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        autoComplete="off"
        // onFinish={handleEdit}
      >
        <Row gutter={[18, 18]}>
          <Col lg={16} md={20} sm={24} xs={24}>
            <div className="col-left">
              <h4 className="title">{t("admin.domains")}</h4>
              <div>
                <Form.Item
                  required
                  label={t("admin.cloudflareToken")}
                  name="editValue"
                >
                  <Input placeholder={t("admin.enterCloudflare")} />
                </Form.Item>
              </div>

              <Divider />

              <div className="name-space">
                <h4 className="title">{t("admin.nameSpace")}</h4>
                <div className="name-space-desc">
                  {t("admin.nameSpaceDesc")}
                </div>

                <div className="name-space-table">
                  <div className="head">
                    <Row gutter={[18, 18]}>
                      <Col span={3}>
                        <div className="type">
                          <div>{t("admin.type")}</div>
                        </div>
                      </Col>
                      <Col span={21}>
                        <div className="value">{t("admin.value")}</div>
                      </Col>
                    </Row>
                  </div>

                  <div className="row">
                    <Row gutter={[18, 18]}>
                      <Col span={3}>
                        <div className="col-text">NS</div>
                      </Col>
                      <Col span={21}>
                        <div className="col-text">
                          <Input allowClear />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="row">
                    <Row gutter={[18, 18]}>
                      <Col span={3}>
                        <div className="col-text">NS</div>
                      </Col>
                      <Col span={21}>
                        <div className="col-text">
                          <Input allowClear />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} md={4} sm={24} xs={24}>
            <div className="col-right">
              <div className="update">
                <Button type="primary">{t("common.update")}</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AdminCloudflarePage;
