import { atom, DefaultValue, selector } from "recoil";

const INITIAL_SAMPE_DATA = {
    records: [],
    totalRecords: 0
};

const INITIAL_PLUGIN_DATA = {
    records: [],
    totalRecords: 0
};

const THEME_EXTRA_INFORMATION_DATA = {
    tags: [],
    compatibleWith: [],
    compatibleBrowsers: [],
    themeFilesIncluded: [],
}

interface ThemeState {
    sampleData: any;
    sampleCategories: any;
    sampleCategoriesTotal: number;

    pluginData: any;

    themeSites: any;
    themeCategories: any;
    themeCategoriesTotal: number;
    
    pluginCategories: any;
    pluginCategoriesTotal: number;
    wordpressVersions: any;
    themeExtraInformation: any;
}

const themeState = atom<ThemeState>({
    key: "themeState",
    default: {
        themeCategories: [],
        themeCategoriesTotal: 0,

        themeSites: {
            category: null,
            sites: [],
        },

        pluginCategories: [],
        pluginCategoriesTotal: 0,
        pluginData: INITIAL_PLUGIN_DATA,

        sampleCategories: [],
        sampleCategoriesTotal: 0,
        sampleData: INITIAL_SAMPE_DATA,
        
        wordpressVersions: [],
        themeExtraInformation: THEME_EXTRA_INFORMATION_DATA,
    },
});

const wordpressVersionsSelector = selector<any>({
    key: 'wordpressVersionsSelector',

    get: ({ get }) => {
        const { wordpressVersions } = get(themeState);
        return wordpressVersions;
    },

    set: ({ set, get }, data : any ) => {
        const state = get(themeState);

        set( themeState,  {
            ...state,
            wordpressVersions: data,
        });
    },
});

const deleteWordpressVersionSelector = selector<any>({
    key: 'deleteWordpressVersionSelector',

    get: ({ get }) => {
        const { wordpressVersions } = get(themeState);
        return wordpressVersions;
    },

    set: ({ get, set }, id) => {
        const state = get(themeState);
        const wordpressVersions = [...state.wordpressVersions.records];

        const wordpressVersionPosition = state.wordpressVersions.records.findIndex((version : any) => version.id === id);

        if (wordpressVersionPosition !== -1) {
            wordpressVersions.splice(wordpressVersionPosition, 1);
        }

        set( themeState, {
            ...state,
            wordpressVersions: {totalRecords: state.wordpressVersions.totalRecords - 1, records: wordpressVersions},
        });
    },
})

const themeExtraInformationSelector = selector<any>({
    key: 'themeExtraInformationSelector',

    get: ({ get }) => {
        const { themeExtraInformation } = get(themeState);
        return themeExtraInformation;
    },

    set: ({ set, get }, data : any ) => {
        const state = get(themeState);

        set( themeState,  {
            ...state,
            themeExtraInformation: data,
        });
    },
});

// theme overview
const themeCategoriesSelector = selector<[any]>({
    key: 'themeCategoriesSelector',

    get: ({ get }) => {
        const { themeCategories } = get(themeState);
        return themeCategories;
    },

    set: ({ get, set }, newValue: any ) => {
        const state = get(themeState);

        const categories = (() => {
            if (newValue && !(newValue instanceof DefaultValue)) {
                return newValue.records;
            };

            return state.themeCategories;
        })();

        set(themeState, {
            ...state,
            themeCategories: categories,
            themeCategoriesTotal: newValue?.totalRecords || state.themeCategoriesTotal
        });
    },
});

const getCategoriesTotal = selector<number>({
    key: 'getCategoriesTotal',

    get: ({ get }) => {
        const { themeCategoriesTotal } = get(themeState);

        return themeCategoriesTotal;
    },
})

const addThemeCategorySelector = selector<[any]>({
    key: 'addThemeCategorySelector',

    get: ({ get }) => {
        const { themeCategories } = get(themeState);

        return themeCategories;
    },

    set: ({ get, set }, data) => {
        const state = get(themeState);

        const themeCategories = [...state.themeCategories];

        themeCategories.push(data);
        const totalThemeCategories = state.themeCategoriesTotal;

        set(themeState, {
            ...state,
            themeCategories: themeCategories,
            themeCategoriesTotal: totalThemeCategories + 1
        })
    }
});

const editThemeCategorybyIdSelector = selector<any>({
    key: 'editThemeCategorybyIdSelector',

    get: ({ get }) => {
        const { themeCategories } = get(themeState);

        return themeCategories;
    },

    set: ({ get, set }, { index, data }) => {
        const state = get(themeState);
        const themeCategoryList = [...state.themeCategories];
        themeCategoryList.splice(index, 1, data);
        set(themeState, {
            ...state,
            themeCategories: themeCategoryList,
        })
    }
});

const deleteThemeCategorybyIdSelector = selector<any>({
    key: 'deleteThemeCategorybyIdSelector',

    get: ({ get }) => {
        const { themeCategories } = get(themeState);

        return themeCategories;
    },

    set: ({ get, set }, { index }) => {
        const state = get(themeState);
        const themeCategories = [...state.themeCategories];

        themeCategories.splice(index, 1);
        const totalThemeCategories = state.themeCategoriesTotal;

        set(themeState, {
            ...state,
            themeCategories: themeCategories,
            themeCategoriesTotal: totalThemeCategories - 1
        })
    }
}); 

const themeSiteByCategory = selector<any>({
    key: 'themeSiteByCategory',

    get: ({ get }) => {
        const { themeSites } = get(themeState);

        return themeSites;
    },

    set: ({ get, set }, { categoryId, site } : any) => {
        const state = get(themeState);

        const themeSites = {...state.themeSites};
        
        if (categoryId) {
            const category = state.themeCategories.find((category: any) => category.id == categoryId);

            if (category) {
                themeSites.category = category;
            };
        };

        if (site) {
            themeSites.sites = site;
        };

        set(themeState, {
            ...state,
            themeSites
        });
    },
});

// theme plugin
const themePluginCategoriesSelector = selector<[any]>({
    key: 'themePluginCategoriesSelector',

    get: ({ get }) => {
        const { pluginCategories } = get(themeState);
        return pluginCategories;
    },

    set: ({ get, set }, newValue:any ) => {
        const state = get(themeState);

        const categories = (() => {
            if (newValue && !(newValue instanceof DefaultValue)) {
                return newValue?.records || [];
            };

            return state.pluginCategories;
        })();

        set(themeState, {
            ...state,
            pluginCategories: categories,
            pluginCategoriesTotal: newValue?.totalRecords || state.pluginCategoriesTotal
        });
    },
});

const addThemePluginCategory = selector({
    key: 'addThemePluginCategory',

    get: ({ get }) => {

    },

    set: ({ get, set }, data) => {
        const state = get(themeState);
        const tempPluginCategories = [...state.pluginCategories];

        tempPluginCategories.push(data);
        const currentPluginCategoryTotal = state.pluginCategoriesTotal

        set(themeState, {
            ...state,
            pluginCategories: tempPluginCategories,
            pluginCategoriesTotal: currentPluginCategoryTotal + 1,
        });
    },
});

const editThemePluginCategory = selector({
    key: 'ediThemePluginCategory',

    get: ({ get }) => {

    },

    set: ({ get, set }, data: any) => {
        const state = get(themeState);
        const tempPluginCategories = [...state.pluginCategories];

        const categoryPosition = tempPluginCategories.findIndex(category => category.id === data.id);
        if (categoryPosition !== -1) {
            tempPluginCategories.splice(categoryPosition, 1, data);
        };

        set(themeState, {
            ...state,
            pluginCategories: tempPluginCategories
        });
    },
});

const deleteThemePluginCategory = selector({
    key: 'deleteThemePluginCategory',

    get: ({ get }) => {

    },

    set: ({ get, set }, id) => {
        const state = get(themeState);
        const tempPluginCategories = [...state.pluginCategories];

        const categoryPosition = tempPluginCategories.findIndex(category => category.id == id);
        if (categoryPosition !== -1) {
            tempPluginCategories.splice(categoryPosition, 1);
        };

        const currentPluginCategoryTotal = state.pluginCategoriesTotal

        set(themeState, {
            ...state,
            pluginCategories: tempPluginCategories,
            pluginCategoriesTotal: currentPluginCategoryTotal - 1
        });
    },
});

const getPluginCategoryTotal = selector({
    key: 'getPluginCategoryTotal',

    get: ({ get }) => {
        const { pluginCategoriesTotal } = get(themeState);

        return pluginCategoriesTotal;
    },
})

const pluginDataSelector = selector<any>({
    key: 'pluginDataSelector',

    get: ({ get }) => {
        const { pluginData } = get(themeState);
        return pluginData;
    },

    set: ({ get, set }, data) => {
        const state = get(themeState);

        set(themeState, {
            ...state,
            pluginData: data
        });
    },
});

//sample Data
const sampleDataSelector = selector<any>({
    key: 'sampleDataSelector',

    get: ({ get }) => {
        const { sampleData } = get(themeState);
        return sampleData;
    },

    set: ({ get, set }, data) => {
        const state = get(themeState);

        set(themeState, {
            ...state,
            sampleData: data
        });
    },
});

const getSampleCategoriesTotal = selector<number>({
    key: 'getSampleCategoriesTotal',

    get: ({ get }) => {
        const { sampleCategoriesTotal } = get(themeState);

        return sampleCategoriesTotal;
    },
})

const addSampleDataCategory = selector<any>({
    key: 'addSampleDataCategory',

    get: ({ get }) => {

    },

    set: ({ get, set }, data) => {
        const state = get(themeState);
        const sampleCategories = [...state.sampleCategories];

        sampleCategories.push(data);
        const currentPluginCategoryTotal = state.sampleCategoriesTotal

        set(themeState, {
            ...state,
            sampleCategories: sampleCategories,
            sampleCategoriesTotal: currentPluginCategoryTotal + 1,
        });
    },
});

const editSampleDataCategoryStore = selector({
    key: 'ediSampleDataCategory',

    get: ({ get }) => {

    },

    set: ({ get, set }, data: any) => {
        const state = get(themeState);
        const tempSampleDataCategories = [...state.sampleCategories];

        
        const categoryPosition = tempSampleDataCategories.findIndex(category => category.id === data.id);
        if (categoryPosition !== -1) {
            tempSampleDataCategories.splice(categoryPosition, 1, data);
        };

        set(themeState, {
            ...state,
            sampleCategories: tempSampleDataCategories
        });
    },
});

const deleteSampleDataCategoryStore = selector({
    key: 'deleteSampleDataCategoryStore',

    get: ({ get }) => {

    },

    set: ({ get, set }, id) => {
        const state = get(themeState);
        const tempSampleDataCategories = [...state.sampleCategories];

        const categoryPosition = tempSampleDataCategories.findIndex(category => category.id == id);
        if (categoryPosition !== -1) {
            tempSampleDataCategories.splice(categoryPosition, 1);
        };

        const currentSampleDataCategoryTotal = state.sampleCategoriesTotal

        set(themeState, {
            ...state,
            sampleCategories: tempSampleDataCategories,
            sampleCategoriesTotal: currentSampleDataCategoryTotal - 1
        });
    },
});

const sampleDataCategoriesSelector = selector({
    key: 'sampleDataCategoriesSelector',

    get: ({ get }) => {
        const { sampleCategories } = get(themeState);
        return sampleCategories;
    },

    set: ({ get, set }, data: any) => {
        const state = get(themeState);

        const categories = (() => {
            if (data && !(data instanceof DefaultValue)) {
                return data?.records || [];
            };

            return state.pluginCategories;
        })();

        set(themeState, {
            ...state,
            sampleCategories: categories,
            sampleCategoriesTotal: data?.totalRecords || state.pluginCategoriesTotal
        });
    },
});

export {
    themeState,
    sampleDataSelector,
    getCategoriesTotal,
    pluginDataSelector,
    themeSiteByCategory,
    addThemePluginCategory,
    getSampleCategoriesTotal,
    sampleDataCategoriesSelector,
    deleteSampleDataCategoryStore,
    getPluginCategoryTotal,
    editThemePluginCategory,
    themeCategoriesSelector,
    addThemeCategorySelector,
    editSampleDataCategoryStore,
    deleteThemePluginCategory,
    wordpressVersionsSelector,
    editThemeCategorybyIdSelector,
    themeExtraInformationSelector,
    themePluginCategoriesSelector,
    deleteWordpressVersionSelector,
    deleteThemeCategorybyIdSelector,
    addSampleDataCategory,
};
