import { Avatar, AvatarProps } from "antd";
import classNames from "classnames";
import { FunctionComponent } from "react";

// comment because do not found this file.
// import AvatarDefault from "../../../assets/images/avatar-default.svg";

import "./index.scss";

const AppAvatar = (props: AvatarProps) => {
  return <Avatar {...props} src={props.src}></Avatar>;
};

export default AppAvatar;

interface UserAvatarProps {
  src?: string;
  isEdit?: boolean;
  className?: string;
  [key: string]: any;
}

export const UserAvatar: FunctionComponent<UserAvatarProps> = ({
  src,
  className,
  avatarClass,
  ...props
}) => {
  return (
    <div className={classNames(["avatar", className])}>
      <AppAvatar
        className={classNames(["gx-size-90", avatarClass])}
        alt={props.alt}
        src={src}
      />
    </div>
  );
};
