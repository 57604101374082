import { Divider } from "antd";

import GeneralInformation from "./general-info";
import UserDetailOverviewPassword from "./password";
import UserDetailOverviewPayment from "./payment-method";

import "./index.scss";
import { useUserDetailContext } from "../../../../pages/users/user-detail";
import ProtectedAction from "../../../../components/action-protected";
import { RoleEnum } from "../../../../enums/app.enum";

const UserDetailOverview = () => {

  // context
  const { isAccount } = useUserDetailContext();

  return (
    <div className="user-detail-overview">
      <GeneralInformation />
      <Divider />

      <ProtectedAction roles={!!isAccount ? [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User, RoleEnum.Accountant] : []}>
        <>
          <UserDetailOverviewPassword />
          <Divider />
        </>
      </ProtectedAction>


      <UserDetailOverviewPayment />
    </div>
  );
};

export default UserDetailOverview;
