import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import qrcodeExample from '../../../assets/images/user/qrcode_example.png';

import './index.scss';
import showNotification from "../../../components/common/notification";

interface QRCodeProps {
    [key: string]: any
}

const QRcode: FunctionComponent<QRCodeProps> = ({ onHide }) => {
    const { t } = useTranslation();

    const [time, setTime] = useState<number>(180);

    const minutes = useMemo(() => {
        return `0${Math.floor(time / 60)}`;
    }, [time]);

    const seconds = useMemo(() => {
        const second = Math.ceil(time % 60);

        return second < 10 ? `0${second}` : second;
    }, [time]);

    useEffect(() => {
        const countDown = () => {
            if (time > 0) {
                setTime(time - 1);
            } else {
                onHide(false);
                showNotification('error', t('payment.qrcode-expired-time-down'))
                clearInterval(timer);
            }
        };

        const timer = setInterval(() => {
            countDown();
        }, 100);

        return () => clearInterval(timer);
    }, [time]);
  return (
    <div className="budget-qrcode">
        <h3 className="qrcode__title">{t('payment.scan-qr-code')}</h3>

        <div className="qrcode__code">
            <div className="qrcode__content">
                <img src={qrcodeExample} alt="qrCode"/>
            </div>
        </div>

        <div className="qrcode__expire-time">
            <span>{t('common.expired-in')}</span>
            <span>{minutes} : {seconds}</span>
        </div>
    </div>
  );
};

export default QRcode;
