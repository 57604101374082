import { useRecoilState } from "recoil";
import { commonNavCollapsedSelector } from "../../../states/common";

const AppHeader = () => {
  const [navCollapsed, seNavCollapsed] = useRecoilState(
    commonNavCollapsedSelector
  );

  const onToggleCollapsedNav = () => {
    seNavCollapsed(!navCollapsed);
  };

  return (
    <div className="gx-no-header-horizontal">
      <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
        <i
          className="gx-icon-btn icon icon-menu"
          onClick={() => onToggleCollapsedNav()}
        />
      </div>
      <div className="gx-no-header-horizontal-top">
        <div className="gx-no-header-horizontal-top-center">
          <i className="icon icon-alert gx-mr-3" />
          <p className="gx-mb-0 gx-text-truncate">
            A new version will be released on December 25th. Stay tuned!
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
