import { Button, Col, Pagination, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import { SITE_PAGE_SIZE } from "../../../constants/app-constants";
import { useRecoilState } from "recoil";
import { themeSiteByCategory } from "../../../states/theme";
import { ThemeApis } from "../../../apis/theme.api";
import { useCreateWebsiteContext } from "../context";
import FilterBar from "../../theme/filter-bar";
import ThemeCardGrid from "../../theme/theme-card/grid";
import classNames from "classnames";

interface Props {
  themeId: number | string;
  selectedThemePage: number;
  categoryId: number | string;
  selectThemeId: (value: any, object: any, currentPage: number) => void;
}

const SiteTheme = (props: Props) => {
  const { t } = useTranslation();
  const { themeId, categoryId, selectedThemePage, selectThemeId } = props;

  // state data
  const [filter, setFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(selectedThemePage);

  // recoil
  const [themeSites, setThemeSites] = useRecoilState(themeSiteByCategory);

  const { setLoading } = useCreateWebsiteContext();

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const listPagination = useMemo(() => {
    return {
      current: currentPage,
      pageSize: SITE_PAGE_SIZE,
      total: themeSites.sites?.totalRecords || 0,
      onChange: handleChangePage
    }
  }, [currentPage, themeSites.sites?.totalRecords]);

  const payloadData = useMemo(() => {
    return {
      ...filter,
      categoryId: categoryId,
      isIncludeDeactivated: false,
      isIncludePendingsItem: false,
      pageSize: listPagination.pageSize,
      pageIndex: listPagination.current,
    }
  }, [listPagination, categoryId, filter]);

  const isShowPagination = useMemo(() => {
    if (themeSites.sites?.totalRecords) {
      return true;
    };

    return false;
  }, [themeSites]);


  const getCategory = async () => {
    try {
      setLoading(true);
      const result = await ThemeApis.getSiteByThemeCategory(payloadData);

      if (result?.data) {
        setThemeSites({ ...themeSites.category, site: result.data });
      }

    } finally {
      setLoading(false);
    }
  };

  const handleSearchThemeSite = (search: string) => {
    setFilter({ ...filter, name: search });
  };

  const handleSortType = (event: any) => {
    const { value } = event.target;

    if (value) {
      setFilter({ ...filter, themeFilterType: value });
    }
  };

  useEffect(() => {
    getCategory();
  }, [payloadData]);

  return (
    <Row gutter={[0, 24]} className="site-theme">
      <Col span={24}>
        <FilterBar
          isFilter={false}
          isView={false}
          onSelectType={handleSortType}
          onSearch={handleSearchThemeSite}
        />
      </Col>

      <Col span={24}>
        <Row gutter={[24, 24]}>
          {themeSites.sites?.records?.map((site: any) => {
            const isSelected = site.id === themeId;
            return (
            <Col key={site.id} span={4} xl={6} lg={12} md={8} sm={12} xs={24} className={classNames({'theme-selected': isSelected})}>
              <ThemeCardGrid data={site} isFree={site?.isThemeFree}>
                <Row gutter={[10, 0]} className='button-group'>
                  <Col span={12}>
                    <Button>
                      {t('common.preview')}
                    </Button>
                  </Col>

                  <Col span={12}>
                    <Button type="primary" onClick={() => selectThemeId(site.id, site, currentPage)} className="button-select">
                      {isSelected && <CheckCircleOutlined />}
                      {isSelected ? t("website.selected") : t("website.select")}
                    </Button>
                  </Col>
                </Row>
              </ThemeCardGrid>
            </Col>
          )})}

          <Col span={24} className="pagination">
            {isShowPagination && (
              <Pagination
                {...listPagination}
              />
            )}
          </Col>
        </Row>
      </Col>

    </Row>
  );
};

export default SiteTheme;
