import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { authState } from "../../states/auth";

const Error404 = () => {
  const { t } = useTranslation();
  const { user } = useRecoilValue(authState);

  return (
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <div className="gx-error-code gx-mb-4">404</div>
        <h2 className="gx-text-center">{t("404.message")}</h2>
        {/* <form className="gx-mb-4" role="search">
          <div className="gx-search-bar">
            <div className="gx-form-group">
              <input
                type="search"
                className="ant-input ant-input-lg"
                placeholder="Search..."
              />
              <button className="gx-search-icon">
                <i className="icon icon-search" />
              </button>
            </div>
          </div>
        </form> */}
        <p className="gx-text-center">
          <Link className="gx-btn gx-btn-primary" to={user ? ROUTE_PATHS.Home : ROUTE_PATHS.signIn}>
            {t("404.btnGoHome")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Error404;
