import { Tooltip } from "antd";
import { DotIcon } from "../../../assets/icons";
import { SiteStatusEnum } from "../../../enums/website.enum";

interface Props {
  status: SiteStatusEnum;
}

const DotStatus = (props: Props) => {
  const renderSiteStatus = (status: SiteStatusEnum) => {
    switch (status) {
      case SiteStatusEnum.Running:
        return {
          color: "#52C41A",
          title: "Running",
        };
      case SiteStatusEnum.Renew:
        return {
          color: "#FA8C16",
          title: "Renew",
        };
      case SiteStatusEnum.Stop:
        return {
          color: "#D9D9D9",
          title: "Stop",
        };
      case SiteStatusEnum.Locked:
        return {
          color: "#8A2BE2",
          title: "Locked",
        };
      case SiteStatusEnum.Error:
        return {
          color: "#F5222D",
          title: "Error",
        };
      case SiteStatusEnum.Warning:
        return {
          color: "#FA8C16",
          title: "Warning",
        };
      default:
        break;
    }
  };

  return (
    <Tooltip title={renderSiteStatus(props.status)?.title}>
      <span>
      <DotIcon
        width={12}
        height={12}
        color={renderSiteStatus(props.status)?.color}
      />
      </span>
    </Tooltip>
  );
};

export default DotStatus;
