import {
  BarsOutlined,
  SettingOutlined,
  MailOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Drawer, Dropdown, Input, Menu, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProtectedAction from "../../../components/action-protected";
import { RoleEnum } from "../../../enums/app.enum";
import EmailSidebar from "../../../views/admin/email-template/email-sidebar";
import "./index.scss";

const { Search } = Input;

const AdminEmailTemplate = () => {
  const [drawerState, setDrawerState] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { t } = useTranslation();

  const menu = (
    <Menu
      items={[
        {
          label: <span style={{ color: "red" }}>{t("common.delete")}</span>,
          key: "0",
        },
      ]}
    />
  );

  const fakeData = [
    {
      id: 1,
      templateName: "/Email name/",
      creator: "Loi Truong",
      createdAt: "12/02/2022",
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: t("admin.templateName"),
      dataIndex: "templateName",
      key: "templateName",
      render: (text) => (
        <div className="templateEmail">
          <div className="mail-icon">
            <MailOutlined />
          </div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: t("admin.creator"),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: t("admin.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <div className="createdAt">
          <div>{text}</div>
          <div>
            <EditOutlined />
          </div>
        </div>
      ),
    },
    {
      title: (
        <Dropdown placement="bottomRight" overlay={menu} trigger={["click"]}>
          <SettingOutlined className="setting-icon" />
        </Dropdown>
      ),
      key: "action",
      render: () => <span className="delete">{t("common.delete")}</span>,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <ProtectedAction roles={[]}>
      <div className="admin-email-template">
        <div className="gx-main-content">
          <div className="gx-app-module">
            <div className="gx-d-block gx-d-lg-none">
              <Drawer
                onClose={() => setDrawerState(false)}
                placement="left"
                closable={false}
                visible={drawerState}
              >
                <div className="gx-module-sidenav  gx-d-lg-flex">
                  <EmailSidebar />
                </div>
              </Drawer>
            </div>
            <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
              <EmailSidebar />
            </div>

            <div className="gx-module-box">
              <div className="gx-module-box-header">
                <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                  <BarsOutlined
                    onClick={() => setDrawerState(true)}
                    className="align-left-icon"
                  />
                </span>
                <Search
                  className="search-input"
                  placeholder={t("common.search")}
                />
              </div>
              <div className="gx-module-box-content">
                <div className="email-template-table">
                  <Table
                    rowKey="id"
                    className="gx-table-responsive"
                    columns={columns}
                    dataSource={fakeData}
                    rowSelection={rowSelection}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectedAction>
  );
};

export default AdminEmailTemplate;
