import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Pagination,
  Rate,
  Select,
} from "antd";

import ReviewItem from "./review-item";

// style
import "./index.scss";
import { ThemeApis } from "../../../apis/theme.api";
import { PAGE_SIZE } from "../../../constants/app-constants";
import { useRecoilValue } from "recoil";
import { getUserInfoSelector } from "../../../states/auth";
import { RoleEnum } from "../../../enums/app.enum";

interface ThemeReviewProps {
  id?: number | string;
  postAction: string;
  getAction: string;
  checkReview: string;
}

const ThemeReview: FunctionComponent<ThemeReviewProps> = ({
  id,
  postAction,
  getAction,
  checkReview,
}) => {
  // utils
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // recoil
  const userInfo = useRecoilValue(getUserInfoSelector);

  // state data
  const [reviews, setReview] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<number>(2);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isReviewShow, setIsReviewShow] = useState<boolean>(false);

  const isShowFormReview = useMemo(() => {
    return isReviewShow || userInfo.role === RoleEnum.Mod || userInfo.role === RoleEnum.Accountant;
  }, [isReviewShow, userInfo.role]);

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (value: number) => {
    setFilterType(value);
  };

  const handleSubmitReviewButtonClick = () => {
    form.submit();
  };

  const pagination = useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      current: currentPage,
      total: reviews?.totalRecords || 0,

      onChange: handleChangePagination,
    };
  }, [currentPage, reviews?.totalRecords]);

  const filterPayload = useMemo(() => {
    return {
      targetId: id,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
      filterType: filterType ? filterType : undefined,
    };
  }, [pagination, filterType, id]);

  const handleFormSubmit = async (values: any) => {
    try {
      if (!postAction) return;

      setLoading(true);

      const payload = {
        ...values,
        targetId: id,
      };

      const result = await ThemeApis[postAction](payload);

      if (result.status === 204) {
        form.resetFields();

        if (currentPage !== 1) {
          setCurrentPage(1);
          return;
        }
        loadReviews();
        checkReviewed();
      }
    } finally {
      setLoading(false);
    }
  };

  const loadReviews = async () => {
    if (!getAction) return;

    const result = await ThemeApis[getAction](filterPayload);

    if (result?.data) {
      setReview(result.data);
    }
  };

  const checkReviewed = async () => {
    if (!checkReview) {
      return;
    }

    if (userInfo.role === RoleEnum.Mod || userInfo.role === RoleEnum.Accountant) {
      return;
    }

    const result = await ThemeApis[checkReview](id);

    setIsReviewShow(result?.data);
  };

  useEffect(() => {
    if (id) {
      loadReviews();
      checkReviewed();
    }
  }, [id, filterPayload]);

  return (
    <>
      <div className="rating-head">
        <div className="review-and-sorter">
          <div className="review">
            {reviews?.totalRecords || 0} {t("common.reviewAmount")}
          </div>
          <div>
            <Rate
              disabled
              className="rating"
              value={reviews?.averageRate || 0}
            />
          </div>
        </div>
        <div className="sorter">
          <Select
            defaultValue={filterType}
            options={[
              {
                value: 2,
                label: t("theme.sortByNewest"),
              },
              {
                value: 1,
                label: t("theme.sortByOldest"),
              },
            ]}
            onChange={handleSortChange}
          />
        </div>
      </div>

      {reviews?.records?.map((review: any) => {
        return <ReviewItem key={review.id} review={review} />;
      })}

      {!!reviews?.totalRecords && (
        <div className="review__pagination">
          <Pagination {...pagination} />
        </div>
      )}

      <Divider />

      {!isShowFormReview && <Form
        form={form}
        onFinish={handleFormSubmit}
        className="comment__form form"
      >
        <Form.Item
          label={t("theme.yourRate")}
          name="rate"
          colon={false}
          labelCol={{ xs: 24, sm: 4, md: 5, xl: 4 }}
          wrapperCol={{ xs: 24, sm: 21, md: 19, xl: 20 }}
          className="comment__form--rating"
          rules={[
            { required: true, message: t("theme.rateReviewValidateMessage") },
          ]}
        >
          <Rate className="form__your-rate" />
        </Form.Item>

        <Form.Item
          name="comment"
          labelAlign="left"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t("theme.comment")}
          className="comment__form--comment"
          rules={[
            {
              required: true,
              message: t("theme.commentReviewValidateMessage"),
            },
          ]}
        >
          <Input.TextArea placeholder={t("theme.enterYourComment")} rows={4} />
        </Form.Item>

        <Form.Item className="form__button-group" wrapperCol={{ span: 24 }}>
          <Button
            type="primary"
            loading={loading}
            onClick={handleSubmitReviewButtonClick}
          >
            {t("theme.submitPreview")}
          </Button>
        </Form.Item>
      </Form>}
    </>
  );
};

export default ThemeReview;
