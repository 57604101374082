import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { Col, Radio, RadioChangeEvent, Row, Spin } from "antd";

import { ThemeApis } from "../../../apis/theme.api";
import { generateImage } from "../../../utils/app.utils";
import { IMAGE_DEFAULT_TYPE } from "../../../constants/app-constants";

import { useCreateWebsiteContext } from "../context";
import ImageLoad from "../../../components/common/image-load";
import { themeCategoriesSelector } from "../../../states/theme";

import "./index.scss";

interface Props {
  categoryId?: number | string;
  setDataCreate: (value: any) => void;
}

const SiteCategory = (props: Props) => {
  const { t } = useTranslation();

  const { setDataCreate, categoryId } = props;

  const { setLoading } = useCreateWebsiteContext();

  // recoil
  const [themeCategories, setThemeCategories] = useRecoilState(themeCategoriesSelector);

  const activeCategories = useMemo(() => {
    if (!themeCategories) {
      return [];
    };

    return themeCategories?.filter((category: any) => !category.isDeactivated && !!category.numOfThemes);

  }, [themeCategories]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setDataCreate(value);
  };

  const getCategory = async () => {
    try {
      setLoading(true);

      const result = await ThemeApis.getThemeCategories({
        pageIndex: 1,
        pageSize: 100,
      });

      if (result?.data) {
        setThemeCategories(result.data);
      }

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className="site-category">
      <Radio.Group
        onChange={onChange}
        value={categoryId || 0}
        optionType="button"
      >
        <Row>
          {activeCategories?.map((item, index) => {
            return (
              <Col key={index} xs={24} sm={12} md={8} lg={4} className="site-category__item">
                <Radio.Button value={item.id}>
                  <div className="categories-image">
                    <ImageLoad type={IMAGE_DEFAULT_TYPE.category} src={generateImage(item.image)} alt={item.name} />
                  </div>
                  <div>{item.name}</div>
                </Radio.Button>
              </Col>
            )
          })}
        </Row>
      </Radio.Group>
    </div>
  );
};

export default SiteCategory;
