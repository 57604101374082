import { Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DotIcon } from "../../../../assets/icons";
import { UserRole, UserTypes } from "../../../../constants/app-constants";
import UserExpanded from "../user-expanded";
import UserName from "../user-name";

import UserAvatar from "../user-avatar";
import "./index.scss";
import { RoleEnum } from "../../../../enums/app.enum";
import { authState } from "../../../../states/auth";
import { useRecoilValue } from "recoil";
import { generateImage } from "../../../../utils/app.utils";
import moment from "moment";
import { FORMAT_DATE_TIME } from "../../../../constants/app-constants";

interface UserTableProps {
  data?: any;
  columns?: any;
  pagination?: any;
  loading?: boolean;
  [key: string]: any;
}

const UserListTable: FunctionComponent<UserTableProps> = ({
  data,
  columns,
  ...props
}) => {
  const { t } = useTranslation();

  // recoil
  const { user } = useRecoilValue(authState);
  
  const  roles  = [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.Accountant]
  const isPermission = useMemo(() => {
    if (user) {
      return (roles.some((roles) => roles === user.role))
    }

    return false;
  }, [user])

  const renderTag = (role: string) => {
    const userType = UserRole.find((type) => type.id === role);

    return userType ?? { color: "default", name: "" };
  };

  const tableColumns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      align: "center",
      render: (text, record) => <UserAvatar avatar={generateImage(text)} />,
    },

    {
      title: t("user.name"),
      dataIndex: "fullName",
      key: "fullName",
      className: "column__item name",
      render: (text, record) => {
        return <UserName data={{ name: text, avatar: record.avatar, id: record.id }} />;
      },
    },

    {
      title: t("user.noOfSites"),
      dataIndex: "totalSites",
      key: "totalSites",
      align: "center",
      className: "column__item sites",
    },

    {
      title: t("user.role"),
      dataIndex: "userRole",
      key: "userRole",
      align: "center",
      className: "column__item role",
      render: (text) => (
        <Tag className="role__tag" color={renderTag(text).color}>
          {renderTag(text).name}
        </Tag>
      ),
    },

    {
      title: t("user.status"),
      dataIndex: "isDeactivated",
      key: "isDeactivated",
      className: "column__item status",
      align: "center",
      render: (text, record) => {
        if (record.isDeleted) {
          return (
            <Tooltip title={t("common.deleted")}>
          <div className="dot-icon">
            <DotIcon
              width={12}
              height={12}
              color={"#545454"}
            />
          </div>
        </Tooltip>
          )
        }

        return (
        <Tooltip title={text ? t("user.inActive") : t("user.active")}>
          <div className="dot-icon">
            <DotIcon
              width={12}
              height={12}
              color={text ? "#F5222D" : "#52C41A"}
            />
          </div>
        </Tooltip>
        )
        },
    },

    {
      title: t("user.createdAt"),
      dataIndex: "createdOn",
      key: "createdOn",
      className: "column__item created-at",
      render: (text) => {
        const date = moment(text).format(FORMAT_DATE_TIME.dmyVN);
        const time = moment(text).format(FORMAT_DATE_TIME.Hms);
        return (
          <div className="date-time-column">
            <p className="date-time-item date">{date}</p>
            <p className="date-time-item time">{time}</p>
          </div>
        );
      },
    },

    ...columns,
  ];

  return (
    <Table
      {...props}
      rowKey="id"
      className="gx-table-responsive user-list-table"
      columns={tableColumns}
      dataSource={data}
      scroll={{ x: 992 }}
      pagination={props.pagination}
      loading={props.loading}
      expandable={ isPermission ?{
        expandedRowRender: (record) => <UserExpanded user={record} />,
      } : {}}
    />
  );
};

export default UserListTable;
