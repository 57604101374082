const LandingPageIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} viewBox="0 0 38 38" fill="none">
            <path d="M35.875 0.875H2.125C1.6279 0.875579 1.15132 1.07331 0.799814 1.42481C0.448309 1.77632 0.250579 2.2529 0.25 2.75V35.25C0.250579 35.7471 0.448309 36.2237 0.799814 36.5752C1.15132 36.9267 1.6279 37.1244 2.125 37.125H35.875C36.3721 37.1244 36.8487 36.9267 37.2002 36.5752C37.5517 36.2237 37.7494 35.7471 37.75 35.25V2.75C37.7494 2.2529 37.5517 1.77632 37.2002 1.42481C36.8487 1.07331 36.3721 0.875579 35.875 0.875ZM2.125 2.125H35.875C36.0407 2.12519 36.1996 2.19109 36.3167 2.30826C36.4339 2.42543 36.4998 2.5843 36.5 2.75V5.875H1.5V2.75C1.50019 2.5843 1.56609 2.42543 1.68326 2.30826C1.80043 2.19109 1.9593 2.12519 2.125 2.125ZM35.875 35.875H2.125C1.9593 35.8748 1.80043 35.8089 1.68326 35.6917C1.56609 35.5746 1.50019 35.4157 1.5 35.25V7.125H36.5V35.25C36.4998 35.4157 36.4339 35.5746 36.3167 35.6917C36.1996 35.8089 36.0407 35.8748 35.875 35.875Z" fill="url(#paint0_linear_1256_27025)" />
            <path d="M8.375 4.625C8.72018 4.625 9 4.34518 9 4C9 3.65482 8.72018 3.375 8.375 3.375C8.02982 3.375 7.75 3.65482 7.75 4C7.75 4.34518 8.02982 4.625 8.375 4.625Z" fill="url(#paint1_linear_1256_27025)" />
            <path d="M5.875 4.625C6.22018 4.625 6.5 4.34518 6.5 4C6.5 3.65482 6.22018 3.375 5.875 3.375C5.52982 3.375 5.25 3.65482 5.25 4C5.25 4.34518 5.52982 4.625 5.875 4.625Z" fill="url(#paint2_linear_1256_27025)" />
            <path d="M3.375 4.625C3.72018 4.625 4 4.34518 4 4C4 3.65482 3.72018 3.375 3.375 3.375C3.02982 3.375 2.75 3.65482 2.75 4C2.75 4.34518 3.02982 4.625 3.375 4.625Z" fill="url(#paint3_linear_1256_27025)" />
            <path d="M33.375 9.625H4.625C4.45924 9.625 4.30027 9.69085 4.18306 9.80806C4.06585 9.92527 4 10.0842 4 10.25V20.25C4 20.4158 4.06585 20.5747 4.18306 20.6919C4.30027 20.8092 4.45924 20.875 4.625 20.875H33.375C33.5408 20.875 33.6997 20.8092 33.8169 20.6919C33.9342 20.5747 34 20.4158 34 20.25V10.25C34 10.0842 33.9342 9.92527 33.8169 9.80806C33.6997 9.69085 33.5408 9.625 33.375 9.625ZM23.1288 18.8801L24.625 17.3838L26.8661 19.625H23.6875L23.1288 18.8801ZM22.125 19.625H17.125L19.625 16.2916L22.125 19.625ZM15.5625 19.625H10.8254L14 15.0898L16.3963 18.5132L15.5625 19.625ZM32.75 19.625H28.6339L25.067 16.058C25.0089 16 24.94 15.954 24.8642 15.9226C24.7884 15.8911 24.7071 15.875 24.625 15.875C24.5429 15.875 24.4616 15.8911 24.3858 15.9226C24.31 15.954 24.2411 16 24.183 16.058L22.3712 17.8699L20.125 14.875C20.0668 14.7974 19.9913 14.7344 19.9045 14.691C19.8177 14.6476 19.722 14.625 19.625 14.625C19.528 14.625 19.4323 14.6476 19.3455 14.691C19.2587 14.7344 19.1832 14.7974 19.125 14.875L17.1855 17.4609L14.512 13.6416C14.4544 13.5592 14.3778 13.492 14.2886 13.4456C14.1995 13.3992 14.1005 13.375 14 13.375C13.8995 13.375 13.8005 13.3992 13.7114 13.4456C13.6222 13.492 13.5456 13.5592 13.488 13.6416L9.29961 19.625H5.25V10.875H32.75V19.625Z" fill="url(#paint4_linear_1256_27025)" />
            <path d="M22.75 14.625C23.0952 14.625 23.375 14.3452 23.375 14C23.375 13.6548 23.0952 13.375 22.75 13.375C22.4048 13.375 22.125 13.6548 22.125 14C22.125 14.3452 22.4048 14.625 22.75 14.625Z" fill="url(#paint5_linear_1256_27025)" />
            <path d="M20.25 29.625H17.75C17.2527 29.625 16.7758 29.8225 16.4242 30.1742C16.0725 30.5258 15.875 31.0027 15.875 31.5C15.875 31.9973 16.0725 32.4742 16.4242 32.8258C16.7758 33.1775 17.2527 33.375 17.75 33.375H20.25C20.7473 33.375 21.2242 33.1775 21.5758 32.8258C21.9275 32.4742 22.125 31.9973 22.125 31.5C22.125 31.0027 21.9275 30.5258 21.5758 30.1742C21.2242 29.8225 20.7473 29.625 20.25 29.625ZM20.25 32.125H17.75C17.5842 32.125 17.4253 32.0592 17.3081 31.9419C17.1908 31.8247 17.125 31.6658 17.125 31.5C17.125 31.3342 17.1908 31.1753 17.3081 31.0581C17.4253 30.9408 17.5842 30.875 17.75 30.875H20.25C20.4158 30.875 20.5747 30.9408 20.6919 31.0581C20.8092 31.1753 20.875 31.3342 20.875 31.5C20.875 31.6658 20.8092 31.8247 20.6919 31.9419C20.5747 32.0592 20.4158 32.125 20.25 32.125Z" fill="url(#paint6_linear_1256_27025)" />
            <path d="M22.75 24.625C22.9158 24.625 23.0747 24.5592 23.1919 24.4419C23.3092 24.3247 23.375 24.1658 23.375 24C23.375 23.8342 23.3092 23.6753 23.1919 23.5581C23.0747 23.4408 22.9158 23.375 22.75 23.375H15.25C15.0842 23.375 14.9253 23.4408 14.8081 23.5581C14.6908 23.6753 14.625 23.8342 14.625 24C14.625 24.1658 14.6908 24.3247 14.8081 24.4419C14.9253 24.5592 15.0842 24.625 15.25 24.625H22.75Z" fill="url(#paint7_linear_1256_27025)" />
            <path d="M25.875 25.875H12.125C11.9592 25.875 11.8003 25.9408 11.6831 26.0581C11.5658 26.1753 11.5 26.3342 11.5 26.5C11.5 26.6658 11.5658 26.8247 11.6831 26.9419C11.8003 27.0592 11.9592 27.125 12.125 27.125H25.875C26.0408 27.125 26.1997 27.0592 26.3169 26.9419C26.4342 26.8247 26.5 26.6658 26.5 26.5C26.5 26.3342 26.4342 26.1753 26.3169 26.0581C26.1997 25.9408 26.0408 25.875 25.875 25.875Z" fill="url(#paint8_linear_1256_27025)" />
            <defs>
                <linearGradient id="paint0_linear_1256_27025" x1="0.25" y1="14.4011" x2="38.2547" y2="23.3774" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_27025" x1="7.75" y1="3.84142" x2="9.02123" y2="4.13166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_27025" x1="5.25" y1="3.84142" x2="6.52123" y2="4.13166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_27025" x1="2.75" y1="3.84142" x2="4.02123" y2="4.13166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_27025" x1={4} y1="13.8228" x2="27.4188" y2="28.0811" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_27025" x1="22.125" y1="13.8414" x2="23.3962" y2="14.1317" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_27025" x1="15.875" y1="31.0243" x2="21.7166" y2="33.2471" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint7_linear_1256_27025" x1="14.625" y1="23.8414" x2="17.2591" y2="28.0513" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint8_linear_1256_27025" x1="11.5" y1="26.3414" x2="13.3868" y2="31.5108" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>
    )
};

export default LandingPageIcon;