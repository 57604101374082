import { t } from "i18next";
import { FunctionComponent, useMemo } from "react";

import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

interface EditButtonProps {
  isEdit?: boolean;
  loading?: boolean;

  onSave?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
}

const EditButton: FunctionComponent<EditButtonProps> = (props) => {
  const { t } = useTranslation();

  const { isEdit, loading, onSave, onEdit, onCancel } = props;

  const buttonAction = useMemo(() => {
    if (isEdit) {
      return {
        icon: <SaveOutlined />,
        text: t("common.save"),
        action: onSave,
      };
    };

    return {
      icon: <EditOutlined />,
      text: t("common.edit"),
      action: onEdit,
    }
  }, [isEdit, loading, t]);

  return (
    <div className="user-edit-button-group">
      { isEdit && (
        <Button danger onClick={onCancel}>
          {t("common.cancel")}
        </Button>
      )}

      <Button loading={loading} onClick={buttonAction.action} icon={buttonAction.icon}>
        {buttonAction.text}
      </Button>
    </div>
  );
};

export default EditButton;
