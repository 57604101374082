import { Spin } from "antd";
import { createContext, FunctionComponent, useContext, useState } from "react";

import './index.scss';

const CreateWebsiteContext = createContext<any>({});

const CreateWebsiteBody: FunctionComponent<any> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <CreateWebsiteContext.Provider
            value={{ setLoading }}
        >
            <div className="site-body">
                <div className="site-body__wrapper">
                    <div className="site-body__content">
                        {children}
                    </div>

                    {loading && (
                        <div className="site-body__loading">
                            <div className="loading">
                                <Spin />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </CreateWebsiteContext.Provider>
    );
};

export const useCreateWebsiteContext = (): any => useContext(CreateWebsiteContext);

export default CreateWebsiteBody;
