import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tooltip,
} from "antd";

import { RoleEnum } from "../../../enums/app.enum";

import UserListTable from "../../../views/users/user-list/table";
import ProtectedAction from "../../../components/action-protected";
import UserListFilter from "../../../views/users/user-list/filter";


import {
  PlusOutlined,
  WalletOutlined,
  CloseCircleOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

import "./index.scss";
import TableNoData from "../../../components/common/table/table-no-data";
import { UserApi } from "../../../apis/user.api";
import { PAGE_SIZE } from "../../../constants/app-constants";
import { useRecoilValue } from "recoil";
import { authState } from "../../../states/auth";

const UserListPage: FunctionComponent<any> = () => {
  // ultils
  const { t } = useTranslation();

  // state
  const [total, setTotal] = useState(10);
  const [filter, setFilter] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // recoil
  const { user } = useRecoilValue(authState)

  // table columns
  const optionsView = (status: boolean, userId: number | string) => {
    if (!status) {
      return (
        <Tooltip title={t('user.remind')}>
          <div onClick={() => handleRemind(userId)}>
            <NotificationOutlined />
          </div>
        </Tooltip>
      );
    }

    if (status) {
      return (
        <Tooltip title={t('common.reminded')}>
          <div onClick={() => handleUnRemind(userId)}>
            <CloseCircleOutlined />
          </div>
        </Tooltip>
      );
    }
  };

  // actions
  const handleFilter = (value: number) => {
    setFilter(value);
  };

  const handleUnRemind = async (userId: any) => {
    try {
      setLoading(true);

    } finally {
      setLoading(false);
    }
  };

  const remindUser = async (userId: number | string) => {
    try {
      setLoading(true);

      const response = await UserApi.remindUserOverviewTable(userId);

      if (response?.data) {
        getUserList();
      };

    } finally {
      setLoading(false);
    }
  };

  const handleRemind = (userIds: any) => {
    Modal.confirm({
      title: t('user.remind-confirm'),
      okText: t('common.remind'),
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => remindUser(userIds)
    })
  };

  const getUserList = async () => {
    try {
      setLoading(true);
      const result = await UserApi.getUserList(payloadFilter);

      if (result?.data) {
        if (user?.role !== RoleEnum.Admin) {
          setUserList((prevState: any) => {
            return result.data.records.filter((data: any) => !data.isDeleted);
          });
        } else {
          setUserList(result.data.records);
        };

        setTotal(result.data.totalRecords);
      };

    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const pagination = useMemo(() => {
    return {
      total,
      pageSize: PAGE_SIZE,
      currentPage: currentPage,
      onChange: handlePaginationChange,
    };
  }, [currentPage, total]);

  const payloadFilter = useMemo(() => {
    if (filter.length) {
      return {
        roles: filter,
        pageSize: PAGE_SIZE,
        pageIndex: currentPage,
      };
    };

    return {
      pageSize: PAGE_SIZE,
      pageIndex: currentPage,
    };

  }, [filter, currentPage])

  const actionColumns: ColumnsType<any> = [
    {
      key: "options",
      className: "column__item options",
      render: (text, record) => {
        if (record.isDeleted) {
          return '';
        }

        return (
          <div className="option-wrapper">
            <div className="options__item">
              {record.isDeleted ? (
                <CloseCircleOutlined />
              ) : (
                optionsView(record.isReminded, record.id)
              )}
            </div>

            <ProtectedAction roles={[RoleEnum.Accountant, RoleEnum.Admin, RoleEnum.Mod]}>
              <div className="options__item">
                <Tooltip title={t("user.budget")}>
                  <Link to={`/users/budget/${record.id}`}>
                    <WalletOutlined />
                  </Link>
                </Tooltip>
              </div>
            </ProtectedAction>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getUserList();
  }, [payloadFilter]);

  return (
    <ProtectedAction roles={[RoleEnum.Accountant, RoleEnum.Mod, RoleEnum.Admin]}>
      <>
        <Card
          title={t("user.users")}
          extra={
            <Link to="">
              <Button icon={<PlusOutlined />} type="primary">
                {t("user.addUser")}
              </Button>
            </Link>
          }
          className="user-list"
        >
          <Row>
            <ProtectedAction roles={[RoleEnum.Accountant, RoleEnum.Admin, RoleEnum.Mod]}>
              <Col span={24} className="user-list__filter">
                <UserListFilter onFilter={handleFilter} />
              </Col>
            </ProtectedAction>


            <Col span={24} className="user-list__table">
              {(!loading || !!userList.length) ? (
                <UserListTable
                  data={userList}
                  loading={loading}
                  columns={actionColumns}
                  pagination={pagination}
                />
              ) : (
                <TableNoData>
                  <div className="user-list__no-data">
                    <p>{t("user.user-dont-have-data-desciption")}</p>

                    <Link to={''}>
                      <Button type="primary">{t("user.addNewUser")}</Button>
                    </Link>
                  </div>
                </TableNoData>
              )}
            </Col>
          </Row>
        </Card>
      </>
    </ProtectedAction>
  );
};

export default UserListPage;
