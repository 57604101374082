import { Drawer, Layout } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { TAB_SIZE } from "../../../constants/app-constants";
import {
  commonNavCollapsedSelector,
  commonPageTitleSelector,
  commonState,
  commonWidthSelector,
} from "../../../states/common";
import SidebarContent from "./sidebar-content";

const { Sider } = Layout;

const Sidebar = () => {
  const width = useRecoilValue(commonWidthSelector);
  const [navCollapsed, seNavCollapsed] = useRecoilState(
    commonNavCollapsedSelector
  );

  const onToggleCollapsedNav = () => {
    seNavCollapsed(!navCollapsed);
  };

  return (
    <Sider
      className={`gx-app-sidebar gx-layout-sider-dark gx-custom-sidebar`}
      trigger={null}
      theme="dark"
      collapsible
    >
      {width < TAB_SIZE ? (
        <Drawer
          className={`gx-drawer-sidebar gx-drawer-sidebar-dark`}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          open={navCollapsed}
        >
          <SidebarContent />
        </Drawer>
      ) : (
        <SidebarContent />
      )}
    </Sider>
  );
};
export default Sidebar;
