import { Col, Row } from "antd";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import GrowthCard from "../../../../components/common/growth-card";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { FORMAT_DATE_TIME } from "../../../../constants/app-constants";
import moment from "moment";

const SiteGrowth: FunctionComponent<any> = ({ lastWeek, lastMonth, lastYear }) => {
    const { t } = useTranslation();

    const lastWeekData = useMemo(() => {
        return lastWeek?.chartModels?.map((week: any) => {
            return {
                ...week,
                date: moment(week.date).format(FORMAT_DATE_TIME.ymd),
            }
        })
    }, [lastWeek]);

    const lastMonthData = useMemo(() => {
        return lastMonth?.chartModels?.map((month: any) => {
            return {
                ...month,
                date: moment(month.date).format(FORMAT_DATE_TIME.my),
            }
        })
    }, [lastMonth]);

    const lastYearData = useMemo(() => {
        return lastYear?.chartModels?.map((year: any) => {
            return {
                ...year,
                date: moment(year.date).format(FORMAT_DATE_TIME.y),
            }
        })
    }, [lastYear]);

    return (
        <Row>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                <GrowthCard
                    id='1'
                    toColor="#9087E1"
                    fromColor="#82B7E2"
                    title={t('dashboard.lastWeek')}
                    trafficData={lastWeekData}
                    cardTitle={t('dashboard.siteGrowth')}
                    status={lastWeek?.percent > 0 ? "up" : "down"}
                    color={lastWeek?.percent > 0 ? "#52C41A" : "#F5222D"}
                    percent={NUMBER_UTIL.converNumberToPercentage(Math.abs(lastWeek?.percent)) || '0%'}
                />
            </Col>

            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                <GrowthCard
                    id='2'
                    toColor="#65BF98"
                    fromColor="#81CFE4"
                    title={t('dashboard.lastMonth')}
                    trafficData={lastMonthData}
                    cardTitle={t('dashboard.siteGrowth')}
                    status={lastMonth?.percent > 0 ? "up" : "down"}
                    color={lastMonth?.percent > 0 ? "#52C41A" : "#F5222D"}
                    percent={NUMBER_UTIL.converNumberToPercentage(Math.abs(lastMonth?.percent)) || '0%'}
                />
            </Col>

            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                <GrowthCard
                    id='3'
                    toColor="#FA8C16"
                    fromColor="#AC52FF"
                    title={t('dashboard.lastYear')}
                    trafficData={lastYearData}
                    cardTitle={t('dashboard.siteGrowth')}
                    status={lastYear?.percent > 0 ? "up" : "down"}
                    color={lastYear?.percent > 0 ? "#52C41A" : "#F5222D"}
                    percent={NUMBER_UTIL.converNumberToPercentage(Math.abs(lastYear?.percent))|| '0%'}
                />
            </Col>
        </Row>
    );
};
export default SiteGrowth;
