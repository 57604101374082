import { Form, Input } from "antd";
import i18n from "../../../utils/i18n";
import showNotification from "../../../components/common/notification";

const confirmReasonDeactivated = (callback) => {
    let reason = ''

    const handleFieldChange = (data) => {
        reason = data?.reason;
    };

    return {
        title: i18n.t('theme.confirmDeactivateCategory'),
        content: (
            <Form id="form" layout="vertical" onValuesChange={handleFieldChange}>
                <Form.Item name="reason" label={i18n.t('theme.enterReason')} style={{ marginBottom: 0 }}>
                    <Input.TextArea row={4} rules={[{ require: true, message: i18n.t('theme.reasonRequired') }]} />
                </Form.Item>
            </Form>
        ),

        okButtonProps: { style: { opacity: reason.length + 0.5 } },
        okText: 'Deactivate',
        async onOk() {
            try {
                return new Promise((resolve, reject) => {
                    const form = document.querySelector('#form');
                    const inputForm = form?.querySelector('#reason');
                    const reasonValue = inputForm?.value;
                    if (reasonValue) {
                        callback(reasonValue);
                        resolve()
                    }

                    inputForm.classList.add('ant-input-status-error');
                    reject();
                });
            } catch (error) {
                showNotification('error', error.message)
            }
        },
    }
}

export default confirmReasonDeactivated;
