import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Avatar, Modal, Popover } from "antd";
import { useTranslation } from "react-i18next";

import { getUserInfoSelector } from "../../../states/auth";
import { useUserActions } from "../../../hooks/useUserActions";

import { generateImage } from "../../../utils/app.utils";
import { roleTitle } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";

import { UserOutlined } from "@ant-design/icons";

import './user-profile.scss';
import { useState } from "react";

const UserProfile = () => {
  const { t } = useTranslation();
  const userAction = useUserActions();

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const userInfo = useRecoilValue(getUserInfoSelector);

  const userMenuOptions = (
    <ul className="gx-user-popover" onClick={() => setOpenDropdown(false)}>
      <li>
        <Link to={ROUTE_PATHS.myAccount}>
          My Account
        </Link>
      </li>
      <li>Connections</li>
      <li
        onClick={logout}
      >
        {t("common.logout")}
      </li>
    </ul>
  );

  const logoutConfirm = async () => {
    await userAction.logout();
  }

  const handleOpenChange = (opened: boolean) => {
    setOpenDropdown(opened)
  } 

  function logout() {
    setOpenDropdown(false);

    Modal.confirm({
      onOk: logoutConfirm,
      okText: t("common.logout"),
      cancelText: t("common.cancel"),
      title: t("common.logoutMessage"),
    });
  }

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        trigger="click"
        open={openDropdown}
        placement="bottomRight"
        className="user-profile"
        content={userMenuOptions}

        onOpenChange={handleOpenChange}
      >
        {userInfo?.avatar && <Avatar
          src={generateImage(userInfo?.avatar)}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />}

        {!userInfo?.avatar && <Avatar
          src={generateImage(userInfo?.avatar)}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
          icon={<UserOutlined />}
        />}
        
        <span className="gx-avatar-name">
          <div className="userInfo">
            <div className="userInfo__fullName">
              {userInfo?.fullName}
            </div>

            <div className="userInfo__role">
              {roleTitle[userInfo?.role as keyof typeof roleTitle] ?? ''}
            </div>
          </div>
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
