import { ThemeApis } from "../../../apis/theme.api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { hardcoreTypes } from "../../../constants/app-constants";
import { FunctionComponent, ReactElement, useEffect } from "react";
import { sampleDataCategoriesSelector, themeCategoriesSelector, themeExtraInformationSelector, themePluginCategoriesSelector, wordpressVersionsSelector } from "../../../states/theme";

interface ThemeUploadWrapperProps {
    type?: string;
    children: ReactElement;
};

const ThemeUploadWrapper: FunctionComponent<ThemeUploadWrapperProps> = (props) => {
    const { children, type } = props;

    const setThemeCategories = useSetRecoilState(themeCategoriesSelector);
    const setSampleDataCategories = useSetRecoilState(sampleDataCategoriesSelector);
    const setThemePluginCategories = useSetRecoilState(themePluginCategoriesSelector);
    const [ wordpressVersion, setWordpressVersions ] = useRecoilState(wordpressVersionsSelector);
    const [themeExtraInformation, setThemeExtraInformation] = useRecoilState(themeExtraInformationSelector);

    const getHardCodeData = async () => {
        const [ compatibleBrowsers, compatibleWith, tags, themeFilesInclude ] = await Promise.all(
            Object.values(hardcoreTypes).map( async (hardcoreId) => {
                const result = await ThemeApis.getHardCoreData({ type: hardcoreId});
                return result;
            })
        );

        const themeExtraInfos = {
            tags: tags?.data || [],
            compatibleWith: compatibleWith?.data || [],
            themeFilesIncluded: themeFilesInclude?.data || [],
            compatibleBrowsers: compatibleBrowsers?.data || [],
        };

        setThemeExtraInformation(themeExtraInfos);
    };

    const getThemeCategories = async () => {
        const result = await ThemeApis.getThemeCategories();

        if ( result?.data) {
            setThemeCategories(result.data);
        };
    };

    const getPluginCategories = async () => {
        const result = await ThemeApis.getThemePluginCategories();

        if (result?.data) {
            setThemePluginCategories(result.data);
        };
    };

    const getSampleDataCategories = async () => {
        const result = await ThemeApis.getSampleDataCategory();

        if ( result?.data) {
            setSampleDataCategories(result.data);
        };
    };

    const getWordpressVersion = async () => {
        const result = await ThemeApis.getWordpressVersion();

        if (result?.data) {
            setWordpressVersions(result.data);
        };
    };

    useEffect(() => {
        const isEmpty = Object.values(themeExtraInformation).some((category: any) => !category.length);

        if (isEmpty) {
            getHardCodeData();
        };

        if (type === 'overview') {
            getThemeCategories();
        } else if (type === 'plugin') {
            getPluginCategories();
        } else if (type === 'sample-data') {
            getSampleDataCategories();
        }

        if (!wordpressVersion?.records?.length) {
            getWordpressVersion();
        };

    }, []);

    return children;
};

ThemeUploadWrapper.defaultProps = {
    type: 'overview',
};

export default ThemeUploadWrapper;
