import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { RoleEnum } from "../../../../enums/app.enum";

import BudgetWiget from "../budget-wiget";
import UserDetailOverview from "../overview";
import RecentActivity from "../recent-activities";
import Widget from "../../../../components/common/widget";
import ProtectedAction from "../../../../components/action-protected";
import { useUserDetailContext } from "../../../../pages/users/user-detail";

import {
  SettingOutlined,
  WalletOutlined,
  BarsOutlined,
} from "@ant-design/icons";

import "./index.scss";
import UserBudget from "../../../../pages/users/budget";
import { useLocation } from "react-router-dom";
interface UserAboutProps {
}

const UserAbout: FunctionComponent<UserAboutProps> = () => {
  // ultils
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  
  // state data
  const [currentTab, setCurrentTab] = useState<any>('1');

  // context
  const { isAccount } = useUserDetailContext();

  const budgetTabPermission: RoleEnum[] = useMemo(() => {
    if (isAccount) {
      return [RoleEnum.User];
    }

    return [...Object.values(RoleEnum).map(role => role)]
  }, [isAccount]);

  const activitylogTabPermission: RoleEnum[] = useMemo(() => {
    if (isAccount) {
      return [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User];
    };

    return [...Object.values(RoleEnum).map(role => role)];
  }, [isAccount])

  const tabs = [
    {
      key: '1',
      label: t("user.overview"),
      children: <UserDetailOverview />,
      icon: <SettingOutlined />,
      role: [...Object.values(RoleEnum).map(role => role)]
    },
    {
      key: '2',
      label: t("user.budgetHistory"),
      children: isAccount ? <UserBudget/> : <BudgetWiget />,
      icon: <WalletOutlined />,
      role: budgetTabPermission
    },
    {
      key: '3',
      label: t("user.activityLog"),
      children: <RecentActivity />,
      icon: <BarsOutlined />,
      role: activitylogTabPermission
    },
  ];

  const renderTabPanelChildren = (id: any) => {

    const activeSelectTab = tabs.find((tab: any) => tab.key === id);

    if (activeSelectTab) {
      return activeSelectTab.children;
    };

    return '';
  };

  const onChange = (key: string) => {
    setCurrentTab(key);
  };

  useEffect(() => {
    onChange("1")
  }, [pathname]);

  return (
    <Widget styleName="gx-card-profile">
      <div className="user-detail-about">
        <div className="user-detail-about__tabs">
          <Tabs
            onChange={onChange}
            activeKey={currentTab}

            items={tabs.map((tab) => ({
              label: (
                <ProtectedAction key={tab.key} roles={tab.role}>
                  <>
                    <span>{tab.icon}</span>
                    <span>{tab.label}</span>
                  </>
                </ProtectedAction>
              ),
              key: tab.key,
              children: renderTabPanelChildren(currentTab),
            }))}
          />
        </div>
      </div>
    </Widget>
  );
};

export default UserAbout;
