import { RegisterTokenModel } from "../models/notification.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/notification`;

function registerToken(model: RegisterTokenModel) {
  const url = `${baseUrl}/register-token`;
  return postAsync(url, model);
}

export const NotificationApi = {
  registerToken,
};
