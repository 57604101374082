import { Button, Rate } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FunctionComponent, ReactElement } from "react";

import { generateImage } from "../../../../utils/app.utils";

import "./index.scss";
import ImageLoad from "../../../../components/common/image-load";
import { IMAGE_DEFAULT_TYPE } from "../../../../constants/app-constants";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";
interface ThemeCardProps {
    data?: any;
    state?: any;
    link?: string;
    isFree?: boolean;
    children?: ReactElement;
}

const ThemeCardList: FunctionComponent<ThemeCardProps> = (props) => {
    const { t } = useTranslation();
    const { currencyConfig, convertNumericToFormattedAmount } = useCurrencyConfig();

    const { data, link, children, isFree = false } = props;

    const handlePreviewClick = (event: any) => {
        event.preventDefault();
    };

    return (
        <div className="list-theme-item">
            <Link to={link || ''} state={props.state}>
                <div className="gx-product-item gx-product-horizontal theme list-theme-item__wrapper">
                    <div className="gx-product-image theme__image">
                        <ImageLoad
                            type={IMAGE_DEFAULT_TYPE.themePreview} src={generateImage(data?.image)} alt={data?.name}
                        />

                        {!data?.isApproved && <div className="theme__status">
                            {t('common.pending')}
                        </div>}
                    </div>
                    <div className="theme__content content">

                        <div className="content__info">
                            <div className="gx-product-name content__info--name">
                                <h3 className="content__info--title">{data.name}</h3>
                            </div>

                            <div className="content__info--description">
                                <div className="content__info--author">{t('common.by')} {data.author}</div>
                                <div className="rate">
                                    <div className="rate__sales">{data.totalSales} {t('theme.sales')}</div>
                                    <div className="rate__star"><Rate allowHalf value={data.rating} disabled /></div>
                                </div>
                            </div>

                            <div className="gx-product-price content__info--price">{!isFree ? convertNumericToFormattedAmount(data.price) : 0} {currencyConfig.currency}</div>
                        </div>


                        <div className="theme__action">
                            {children ?? <Button onClick={handlePreviewClick} className="gx-btn-outline-primary">{t('common.livePreview')}</Button>}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

ThemeCardList.defaultProps = {
    link: '',
    data: {},
};

export default ThemeCardList;
