import { Col, Row } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";

import { AdminApis } from '../../../apis/admin.api';
import { useCreateWebsiteContext } from "../context";

import ItemCard from "../../admin/website-plan/item-card";

import "./index.scss";

interface Props {
  planId?: any;
  selectPlan: (value: any) => void;
}

const SitePlan = (props: Props) => {

  const { planId, selectPlan } = props;

  const [plans, setPlans] = useState<any[]>([]);

  const { setLoading } = useCreateWebsiteContext();

  const getPlan = async () => {
    try {
      setLoading(true);
      const result = await AdminApis.getWebsitePlan();

      if (result?.data) {
        setPlans(result.data);
      };

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  return (
    <div className="site-plan">
      <Row>
        {plans.map((plan, index) => {
          return (
            <Col key={index} xl={8} lg={24} md={8} xs={24}>
              <div className="plan-item__wrapper" onClick={() => selectPlan(plan)}>
                <ItemCard
                  isView={true}
                  footerStyle=""
                  styleName={classNames(["gx-package", { [' gx-bg-primary-light gx-highlight gx-border-0']: planId === plan.id }])}
                  itemStyle={classNames(["gx-package-body", { 'gx-text-white': planId === plan.id }])}
                  headerStyle="gx-package-header gx-bg-primary gx-text-white"
                  data={plan}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SitePlan;
