import moment from "moment";

import { FORMAT_DATE_TIME } from './../constants/app-constants';

const daysRemaining = (date: any) => {
  const expiryDate = moment(date);
  const now = moment();
  return expiryDate.diff(now, "days");
};

export const DATE_UTILS = {
  daysRemaining,
};

export const formatHours = (dateTime: any) => {
  return moment(dateTime).hours();
}

export const formatDate = (dateTime: any, formatType: string = FORMAT_DATE_TIME.dmyVN) => {
  return moment(dateTime).format(formatType)
}
