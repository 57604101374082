import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Rate, Row } from "antd";

import { generateImage } from "../../../../utils/app.utils";
import { getUserInfoSelector } from "../../../../states/auth";
import { UserExpandedModel } from "../../../../models/user.model";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

import "./index.scss";
interface Props {
  user: UserExpandedModel;
}

const UserExpanded: React.FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslation();
  const { currencyConfig, convertNumericToFormattedAmount } = useCurrencyConfig();

  const {
    id,
    name,
    sites,
    email,
    budget,
    earned,
    avatar,
    rateting,
    isDeleted,
    isCompany,
    totalThemes,
    phoneNumber,
  } = user;

  // recoil
  const userInfo = useRecoilValue(getUserInfoSelector);

  const redirectToDetail = useMemo(() => {
    if (userInfo.userId === id) {
      return ROUTE_PATHS.myAccount;
    }

    return ROUTE_PATHS.userDetail.replace(':userId', id);
  }, [userInfo.userId, id]);

  return (
    <Row className="user-expanded" justify="space-between" align="middle">
      <Col span={21} className="user__item">
        <Row className="user__info info">
          <Col span={4} className="info__item avatar">
            {avatar ? (
              <Avatar className="avatar__item" src={generateImage(avatar)} alt="img" />
            ) : (
              <Avatar className="avatar__item" icon={<UserOutlined />} />
            )}
          </Col>

          <Col className="info__item" span={20}>
            <Row className="info__desciption">
              <Col span={24} className="description__name">
                <div className="gx-description">
                  <h3>{name}</h3>
                </div>
              </Col>

              <Col span={24} className="description__more">
                <Row className="more__wrapper">
                  <Col span={7} className="more__wrapper--item">
                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.budget")}:
                      </div>
                      <div className="item__content--description">
                        {budget ? convertNumericToFormattedAmount(budget) : 0} {currencyConfig.currency}
                      </div>
                    </div>

                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.earned")}:
                      </div>
                      <div className="item__content--description">
                        {earned ? convertNumericToFormattedAmount(earned) : 0} {currencyConfig.currency}
                      </div>
                    </div>

                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.themes")}:
                      </div>
                      <div className="item__content--description">
                        {totalThemes}
                      </div>
                    </div>
                  </Col>

                  <Col span={7} className="more__wrapper--item">
                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.sites")}:
                      </div>
                      <div className="item__content--description">{sites}</div>
                    </div>

                    <div className="item__content item__rating">
                      <div className="item__content--label">
                        {t("user.rating")}:
                      </div>
                      <div className="item__content--description">
                        <Rate disabled className="rating" value={rateting} />
                      </div>
                    </div>

                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.email")}:
                      </div>
                      <div className="item__content--description">{email}</div>
                    </div>
                  </Col>

                  <Col span={10} className="more__wrapper--item">

                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.phoneNumber")}:
                      </div>
                      <div className="item__content--description">
                        {phoneNumber}
                      </div>
                    </div>

                    <div className="item__content">
                      <div className="item__content--label">
                        {t("user.personal")} / {t("user.company")}:
                      </div>
                      <div className="item__content--description">
                        {isCompany ? t("user.company") : t("user.personal")}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {!isDeleted && (
        <Col className="user__item--button">
          <Link to={redirectToDetail}>
            <Button type="primary" className="item__content--label">
              {t("user.detail")}
            </Button>
          </Link>
        </Col>
      )}
    </Row>
  );
};

export default UserExpanded;
