import { t } from "i18next";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Col, Form, Input, Row, Select, Switch, UploadFile } from "antd";
import { FunctionComponent, useImperativeHandle, useState } from "react";

import "./index.scss";
import UploadImage from "../../theme/upload-image";
import ThemeCardList from "../../theme/theme-card/list";
import { CommonUtils } from "../../../utils/common";

const { Option } = Select;

const BEFORE_WEBLINK = 'https://';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },

  className: "website-info__form--item"
};

const DomainAfters = [
  '.vn',
  '.com',
  '.org',
  '.net',
];

const validateSchema = (label: string) => {
  return [
    {
      required: true,
      message: t('theme.requiredMessageValidate', { label: label })
    }
  ]
};

interface Props {
  data?: any;
  [key: string]: any;
  onSubmit?: (values: any) => void;
};

const WebsiteInfoForm: FunctionComponent<any> = (props: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { data, onSubmit, formRef } = props;

  // state data
  const [afterWeblink, setAfterWeblink] = useState<string>('.com');
  const [dataThumbnail, setDataThumbnail] = useState<UploadFile[]>([]);
  const [isHaveDomain, setIsHaveDomain] = useState<boolean>(data?.isHaveDomain ?? true);
  const [isProductionSite, setIsProductionSite] = useState<boolean>(data?.isProductionSite ?? true);

  useImperativeHandle(formRef, () => ({
    handleSubmit,
    setInitialValue
  }));

  // recoil

  const onUploadThumbnail = (files: any) => {
    setDataThumbnail(files);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleProductionSwitchChange = (checked: boolean) => {
    setIsProductionSite(checked);
  };

  const handleHaveDomainSwitchChange = (checked: boolean) => {
    form.setFieldValue('webSiteLink', '');
    setIsHaveDomain(checked);
  };

  const onFormSubmit = (values: any) => {
    if (onSubmit) {
      const websiteDomain = values.isHaveDomain ? `${BEFORE_WEBLINK}${values.webSiteLink}${afterWeblink}` : '';
      const websiteInfo = { ...values, domain: websiteDomain, siteThumbnail: dataThumbnail };

      delete websiteInfo.webSiteLink;

      onSubmit(websiteInfo);
    }
  };

  const handleSelectDomain = (value: string) => {
    setAfterWeblink(value);
  };

  const setInitialValue = () => {
    if (data) {
      const { domain, siteThumbnail, ...formValues } = data || {};
      form.setFieldsValue(formValues);

      if (siteThumbnail) {
        const files = siteThumbnail?.reduce((thumbnail: any, current: any) => {
          const url = URL.createObjectURL(current.originFileObj);

          return [...thumbnail, { ...current, url }];
        }, []);

        setDataThumbnail(files);
      }
      generateWebsiteLink(domain || '');
    }
  };

  const generateWebsiteLink = (domain: string) => {
    const after = DomainAfters.find((text: string) => {
      return domain.includes(text);
    });

    if (after) {
      setAfterWeblink(after);
      const mainText = domain.replace(after, '').replace(BEFORE_WEBLINK, '');

      form.setFieldValue('webSiteLink', mainText);
    };
  };

  const selectAfter = (
    <Select value={afterWeblink} onChange={handleSelectDomain}>
      {DomainAfters.map((text, index) => {
        return <Option key={index} value={text}>{text}</Option>
      }
      )}
    </Select>
  );

  return (
    <div className="website-info">
      <Form form={form} onFinish={onFormSubmit} className="website-info__form">
        <Form.Item
          {...formItemLayout}
          name="siteThumbnail"
          label={t("website.siteThumbNail")}

          rules={!dataThumbnail.length ? validateSchema(t('website.siteThumbNail')) : []}
        >
          <UploadImage onUpdate={onUploadThumbnail} multiple thumbnailFiles={dataThumbnail} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="name"
          label={t("website.yourWebsiteName")}

          rules={validateSchema(t('website.yourWebsiteName'))}
        >
          <Input placeholder={t("website.enterWebsiteName")} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={t('website.haveDomain')}
          name="isHaveDomain"
          valuePropName="checked"

          rules={validateSchema(t('website.haveDomain'))}
        >
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"

            onChange={handleHaveDomainSwitchChange}
          />
        </Form.Item>

        {isHaveDomain && (
          <Form.Item
            {...formItemLayout}
            name="webSiteLink"
            label={t("website.domain")}

            rules={[
              ...validateSchema(t("website.domain")),

              () => ({
                validator(_, value) {
                  const url = `${BEFORE_WEBLINK}${value}${afterWeblink}`
                  if (!value || !!CommonUtils.isValidUrl(url)) {
                    return Promise.resolve();
                  }
  
                  return Promise.reject(new Error(t('website.validate-invalid-domain')));
                },
              }),
            ]}
          >
            <Input
              addonAfter={selectAfter}
              addonBefore={BEFORE_WEBLINK}
              placeholder={t("website.enterDomain")}
            />
          </Form.Item>
        )}

        <Form.Item
          {...formItemLayout}
          label={t('website.productionSite')}
          name="isProductionSite"
          valuePropName="checked"

          rules={validateSchema(t("website.domain"))}
        >
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={handleProductionSwitchChange}
          />
        </Form.Item>

        <Row className={classNames("website-info__production", { 'show': isProductionSite })}>
          <Col className="col-item" xs={24} sm={5} />
          <Col xs={24} sm={19}>

            <div className="website-info__production--content content">
              <div className="content__head">
                <h5 className="content__head--title">
                  {t("website.serverName")}
                </h5>

                <p className="content__head--description">
                  {t("website.serverDescription")}
                </p>
              </div>

              <div className="content__table">
                <Row>
                  <Col xs={24} className="content__table--header">
                    <Row>
                      <Col xs={4}>
                        {t("website.type")}
                      </Col>

                      <Col xs={20}>
                        {t("website.value")}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} className="content__table--body">
                    <Row>
                      <Col xs={4}>
                        NS
                      </Col>

                      <Col xs={20}>
                        carter.ns.cloudflare.com
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} className="content__table--body">
                    <Row>
                      <Col xs={4}>
                        NS
                      </Col>

                      <Col xs={20}>
                        carter.ns.cloudflare.com
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <Form.Item
          {...formItemLayout}
          label={t('website.themeInformation')}
          colon={false}
        >

          <ThemeCardList data={data?.site}>
            <></>
          </ThemeCardList>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WebsiteInfoForm;
