import { Button, Checkbox, Form, Input, InputNumber, Modal, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { PhoneNumberUtil, } from 'google-libphonenumber';

import {
  PASSWORD_REGEX,
  VALIDATION_MESSAGE_CONFIG
} from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { useUserActions } from "../../hooks/useUserActions";
import { commonPageTitleSelector } from "../../states/common";

import {
  LockOutlined,
  // FacebookOutlined,
  // GithubOutlined,
  // TwitterOutlined,
  // GoogleOutlined,
  PhoneOutlined, UserOutlined
} from "@ant-design/icons";

import TermCondition from "../term-condition";
import "./index.scss";
import { NUMBER_UTIL } from "../../utils/number.utils";

const FormItem = Form.Item;

const SignUp = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userActions = useUserActions();

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isConnectPhone, setIsConnectPhone] = useState(false);
  const [isOpenTerm, setIsOpenTerm] = useState(false);

  const setPageTitle = useSetRecoilState(commonPageTitleSelector);


  const FormEmailorPhone = useCallback(() => {
    if (isConnectPhone) {
      return (
        <Form.Item
          className="phoneNumber"
          rules={[
            { required: true },
            {
              pattern: /^(?:\d*)$/,
              message: t('signUp.validate-phone-number-is-number'),
            },

            () => ({
              validator(_, value) {
                if (!value || isNaN(parseInt(value))) {
                  return Promise.resolve();
                }
                const stringNumber = value.toString();
                if (stringNumber.length < 2) {
                  return Promise.reject(new Error(t('signUp.validate-invalid-phone-number')));
                }

                const phoneUtil = PhoneNumberUtil.getInstance();
                if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(stringNumber, 'VN'), 'VN') && value.length === 10) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('signUp.validate-invalid-phone-number')));
              },
            }),
          ]}
          name="phoneNumber"
          label={t("common.phone")}
        >
          <Input
            maxLength={10}
            prefix={<PhoneOutlined className="phone-icon" />}
            placeholder={t("common.phone")}
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        rules={[{ required: true }, { type: "email" }]}
        name="email"
        label={t("signIn.email")}
      >
        <Input prefix={<UserOutlined />} placeholder={t("common.email")} />
      </Form.Item>
    );
  }, [isConnectPhone]);

  const connectWithText = useMemo(() => {
    if (!isConnectPhone) {
      return t("common.signUpWithPhoneNumber");
    }

    return t("common.signUpWithEmail");
  }, [isConnectPhone]);

  const handleChangeEmailtoPhone = () => {
    setIsConnectPhone(!isConnectPhone);
  };

  const signUp = async (values: any) => {
    try {
      setLoading(true);
      const { confirmPassword, ...payload} = values

      await userActions.signUp(payload);

      navigate(ROUTE_PATHS.verifyCode, {
        state: {
          ...payload,
          callback: "verifySignUp",
          successMessage: t("signUp.signUpSuccess"),
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const checkboxChange = (checked: boolean) => {
    setButtonDisabled(!checked);
  };

  useEffect(() => {
    setPageTitle(t("signUp.title"));
  }, []);

  return (
    <Form
      form={form}
      name="basic"
      validateMessages={VALIDATION_MESSAGE_CONFIG}
      className="gx-signin-form gx-form-row0 form-hidden-label sign-in-page"
      onFinish={signUp}
    >
      {/* <FormItem
        name="email"
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
        label={t("signUp.email")}
      >
        <Input prefix={<UserOutlined />} placeholder={t("signUp.email")} />
      </FormItem> */}

      <FormEmailorPhone />

      <FormItem
        name="password"
        rules={[
          { required: true },
          () => ({
            validator(_, value) {
              var passwordReg = new RegExp(PASSWORD_REGEX);
              if (!value || passwordReg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(t("signUp.invalidPassword"));
            },
          }),
        ]}
        label={t("signUp.password")}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder={t("signUp.password")}
        />
      </FormItem>

      <FormItem
        name="confirmPassword"
        label={t("signUp.confirmPassword")}
        dependencies={["password"]}
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(t("signUp.passwordsNotMatch"));
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder={t("signUp.confirmPassword")}
        />
      </FormItem>

      <p className="gx-text-light">
        The password must contain at least 8 character categories among the
        following: Uppercase, lowercase and number.
      </p>

      <FormItem valuePropName="checked">
        <Checkbox onChange={(e) => checkboxChange(e.target.checked)}>
          {t("signUp.accept")}
        </Checkbox>
        <span onClick={() => setIsOpenTerm(true)} className="gx-link" >{t("signUp.termCondition")}</span>
      </FormItem>

      <FormItem>
        <div className="signInOrSignUp">
            <Tooltip title={buttonDisabled && t('signUp.noticeMustHaveAcceptTerm')}>
              <Button
                type="primary"
                className="gx-mb-0 gx-mr-0"
                htmlType="submit"
                loading={loading}
                disabled={buttonDisabled}
              >
                {t("signUp.btnSubmit")}
              </Button>
            </Tooltip>
          <div>
            <span>{t("common.or")}</span>{" "}
          </div>
          <div>
            <Link to={ROUTE_PATHS.signIn}> {t("signUp.btnSignIn")}</Link>
          </div>
        </div>
      </FormItem>

      <div className="gx-flex-row gx-justify-content-start sign-up-method">
        <span>{t('common.sign-up-with')}</span>
        <span className="withPhoneOrEmail" onClick={handleChangeEmailtoPhone} >
          {connectWithText}
        </span>
        {/* <ul className="gx-social-link">
          <li>
            <GoogleOutlined />
          </li>
          <li>
            <FacebookOutlined />
          </li>
          <li>
            <GithubOutlined />
          </li>
          <li>
            <TwitterOutlined />
          </li>
        </ul> */}
      </div>

      <Modal
        closable
        okText={t("common.save")}
        title=""
        open={isOpenTerm}
        footer={null}
        wrapClassName="term-modal"
        onCancel={() => setIsOpenTerm(false)}
      >
        <TermCondition onCloseModal={() => setIsOpenTerm(false)} />
      </Modal>
    </Form>
  );
};

export default SignUp;
