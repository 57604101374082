import { t } from "i18next";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Switch, UploadFile } from "antd";

// component
import UploadImage from "../../../../views/theme/upload-image";
import UploadFiles from "../../../../views/theme/upload-files";
import ProtectedAction from "../../../../components/action-protected";
import MutipleSelectBox from "../../../../views/theme/multiple-select-box";
import ThemeUploadWrapper from "../../../../views/theme/theme-upload-wrapper";

// ultil
import { RoleEnum } from "../../../../enums/app.enum";
import { ThemeApis } from "../../../../apis/theme.api";
import { generateDownloadData, generateImage } from "../../../../utils/app.utils";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { themeExtraInformationSelector, themePluginCategoriesSelector, wordpressVersionsSelector } from "../../../../states/theme";

// style
import './index.scss';
import { getUserInfoSelector } from "../../../../states/auth";
import confirmReasonDeactivated from "../../../../views/theme/deactivated-modal";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },

    className: "form__item--horizone"
};

const colFormItemLayout = {
    labelCol: {
        md: { span: 24 },
        sm: { span: 6 },
        xs: { span: 24 },
    },
    wrapperCol: {
        md: { span: 24 },
        sm: { span: 18 },
        xs: { span: 24 },
    },

    className: "form__item--vertical"
}

const switchLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },

    className: "form__item--switch-horizone"
};

const switchThemeFreeLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 24 },
        xl: { span: 12 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        md: { span: 24 },
        xl: { span: 12 },
    },
}

const tailLayout = {
    wrapperCol: {
        span: 24,
    },
};

const validateSchema = (label: string) => {
    return [
        {
            required: true,
            message: t('theme.requiredMessageValidate', { label: label })
        }
    ]
};

interface UploadFormProps {
}

const ThemePluginUpload: FunctionComponent<UploadFormProps> = (props) => {
    // ultil
    const params = useParams();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [themeDetail, setThemeDetail] = useState<any>(null);

    const { formatAmount, parserInput } = useCurrencyConfig();

    const isReview = useMemo(() => {
        return location.pathname.includes('review');
    }, [location]);

    // state data
    const [loading, setLoading] = useState<boolean>(false);
    const [pluginDetail, setPluginDetail] = useState<any>(null);
    const [dataThumbnail, setDataThumbnail] = useState<UploadFile[]>([]);
    const [deleteThumbnailIds, setDeleteThumbnailIds] = useState<Array<any>>([]);

    // recoil
    const userInfo = useRecoilValue(getUserInfoSelector);
    const wordpressVersions = useRecoilValue(wordpressVersionsSelector);
    const themeCategories = useRecoilValue(themePluginCategoriesSelector);
    const themeExtraInformation = useRecoilValue(themeExtraInformationSelector);

    const isUserRole = useMemo(() => {
        return userInfo.role === 'User';
    }, [userInfo]);

    const isEditMode = useMemo(() => {
        if (params.siteId) {
            return true;
        };

        return false;
    }, [params.siteId]);

    const onUploadThumbnail = (files: any) => {
        setDataThumbnail(files);
    };

    const getThumbnailRemove = (id: number | string) => {
        const removeIds = [...deleteThumbnailIds];
        removeIds.push(id);
        setDeleteThumbnailIds(removeIds);
    };

    const resetStateData = () => {
        setDataThumbnail([]);
        setDeleteThumbnailIds([]);
    };

    const handleUploadButtonClick = () => {
        form.submit();
    };

    const handleFormSubmit = async (value: any) => {
        if (!isEditMode) {
            uploadNewSite(value);
            return;
        };

        editSite(value);
    };

    const handleAprroveButtonClick = async () => {
        try {
            setLoading(true);
            await ThemeApis.approvePluginSite(params.siteId);
            navigate(ROUTE_PATHS.themesPlugin)
        } finally {
            setLoading(false);
        }
    };

    const handleRejectButtonClick = async () => {
        const handleGetReason = (reason: string) => rejectSite(reason.replaceAll('\n', '%0A'));
        Modal.confirm({
            ...confirmReasonDeactivated(handleGetReason),
            title: t('theme.confirmRejectTheme'),
            okButtonProps: { loading: loading },
            okText: t('common.reject'),
            okType: 'primary'
        });
    };

    const rejectSite = async (reason: string) => {
        try {
            setLoading(true);
            await ThemeApis.rejectPluginSite({ id: params.siteId, reason });

            navigate(ROUTE_PATHS.themesPlugin)
        } finally {
            setLoading(false);
        }
    };

    const downloadTheme = async (file: any) => {
        const response = await ThemeApis.downloadTheme({ id: params.siteId, fileId: file.id });
        generateDownloadData(response.data, file.name);
    };

    const uploadNewSite = async (value: any) => {
        try {
            setLoading(true);

            const thumbnailFiles = dataThumbnail.map(thumbnail => thumbnail.originFileObj);

            const payload = {
                ...value,
                thumbnailFiles: thumbnailFiles,
                templateFile: value['templateFile']?.[0].originFileObj,
                designAssetFile: value['designAssetFile']?.[0].originFileObj,
                sampleDataFiles: value['sampleDataFiles']?.[0].originFileObj,
            };

            const result = await ThemeApis.postThemePluginSite(payload);

            if (result?.data) {
                if (isUserRole) {
                    Modal.success({
                        content: t('theme.uploadSuccessMessage'),
                        onOk: () => navigate(ROUTE_PATHS.themesPlugin)
                    })

                    return
                }

                navigate(ROUTE_PATHS.themesPluginDetail.replace(':siteId', result?.data?.id?.toString() || ''));
            }

        } finally {
            setLoading(false);
        }
    };

    const editSite = async (values: any) => {
        try {
            setLoading(true);

            const IsDeleteDesignAssetFile = values['designAssetFile']?.length ? false : true;
            const dataThumbnailsUpload = dataThumbnail.filter((thumbnail: any) => !!thumbnail.originFileObj);

            const payload = {
                ...values,
                IsDeleteDesignAssetFile,
                id: Number(params.siteId),
                ThumbnailsToDelete: deleteThumbnailIds,
                templateFile: values['templateFile']?.[0]?.originFileObj,
                designAssetFile: values['designAssetFile']?.[0]?.originFileObj,
                sampleDataFile: values['sampleDataFiles']?.[0]?.originFileObj,

                thumbnailFiles: dataThumbnailsUpload.map(dataUpload => dataUpload.originFileObj),
            };

            delete payload.sampleDataFiles

            const result = await ThemeApis.updateThemePluginSite(payload);

            if (result?.data) {
                if (isUserRole) {
                    Modal.success({
                        content: t('theme.uploadSuccessMessage'),
                        onOk: () => navigate(ROUTE_PATHS.themesPlugin)
                    })

                    return
                }

                navigate(ROUTE_PATHS.themesPluginDetail.replace(':siteId', params.siteId?.toString() || ''))
            };

        } finally {
            setLoading(false);
        }
    };

    const loadThemeSiteDetail = async () => {
        resetStateData();

        const result = await ThemeApis.getThemePluginDetail(params.siteId);

        setThemeDetail(result.data)

        if (!result?.data) {
            return;
        };

        setPluginDetail(result.data);

        const { templateFile, compatibleBrowsers, compatibleWith, designAssetsFile, sampleDataFile, tags, thumbnails, isFree, ...formData } = result?.data;

        const tabIds = tags?.map((tabs: any) => tabs.id);
        const compatibleWithIds = compatibleWith?.map((width: any) => width.id);
        const compatibleBrowsersIds = compatibleBrowsers?.map((browser: any) => browser.id);
        form.setFieldValue('tagIds', tabIds);
        form.setFieldValue('compatibleWithIds', compatibleWithIds);
        form.setFieldValue('compatibleBrowserIds', compatibleBrowsersIds);

        const thumbnailValues = thumbnails.map((thubnail: any, index: number) => ({
            uid: thubnail.id,
            name: t('theme.thumbnail') + (index + 1),
            status: 'done',
            url: generateImage(thubnail.name),
        }));

        setDataThumbnail(thumbnailValues);

        form.setFieldsValue(formData);

        const templateFileValue = {
            uid: templateFile?.id,
            name: templateFile?.name,
            status: 'done',
            url: '',
        };

        const sampleDataFileValue = {
            uid: sampleDataFile?.id,
            name: sampleDataFile?.name,
            status: 'done',
            url: '',
        };

        if (designAssetsFile) {
            const designAssetsFileValue = {
                uid: designAssetsFile?.id,
                name: designAssetsFile?.name,
                status: 'done',
                url: '',
            };
            form.setFieldValue('designAssetFile', [designAssetsFileValue]);
        };

        form.setFieldValue('isFree', isFree);
        form.setFieldValue('templateFile', [templateFileValue]);
        form.setFieldValue('sampleDataFiles', [sampleDataFileValue]);

    };

    useEffect(() => {
        if (params.siteId) {
            loadThemeSiteDetail();
        }
    }, [params.siteId]);



    return (
        <ThemeUploadWrapper type="plugin">
            <ProtectedAction
                roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User]}
            >
                <Form className="upload__form form" form={form} onFinish={handleFormSubmit} disabled={loading}>
                    <Row gutter={[18, 18]}>
                        <Col md={16} xs={24}>
                            <Card>
                                <div className="gx-mb-3">{t("theme.generalInformation")}</div>

                                <Form.Item required {...formItemLayout} label={t('theme.pluginName')} name="name" rules={validateSchema(t('theme.pluginName'))}>
                                    <Input
                                        placeholder={t("theme.enterName")}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item required {...formItemLayout} label={t('theme.pluginThumbnail')} name="thumbnailFiles" rules={!dataThumbnail.length ? validateSchema(t('theme.pluginThumbnail')) : []}>
                                    <UploadImage onUpdate={onUploadThumbnail} multiple thumbnailFiles={dataThumbnail} onRemove={getThumbnailRemove} disabled={isReview} />
                                </Form.Item>

                                <Form.Item required {...formItemLayout} label={t('theme.description')} name="description" rules={validateSchema(t('theme.description'))}>
                                    <Input.TextArea rows={4} placeholder={t('theme.enterDescription')} disabled={isReview} />
                                </Form.Item>

                                <Form.Item required {...formItemLayout} label={t('theme.demoURL')} name="demoURL" rules={validateSchema(t('theme.demoURL'))}>
                                    <Input
                                        placeholder={t('theme.enterURL')}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Divider />

                                <div className="gx-mb-3">{t('theme.pluginResource')}</div>

                                <Form.Item
                                    required
                                    {...formItemLayout}
                                    name="templateFile"
                                    valuePropName="fileList"
                                    label={t('theme.plugin')}
                                    getValueFromEvent={event => {
                                        if (Array.isArray(event)) {
                                            return event;
                                        }

                                        return event && event.fileList;
                                    }}
                                    rules={validateSchema(t('theme.plugin'))}
                                >
                                    <UploadFiles 
                                        disabled={isReview}
                                        showUploadList={{ showDownloadIcon: isReview, }}
                                        onDownload={() => downloadTheme(pluginDetail.templateFile)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    required
                                    {...formItemLayout}
                                    name="sampleDataFiles"
                                    valuePropName="fileList"
                                    label={t('theme.sampleData')}
                                    getValueFromEvent={event => {
                                        if (Array.isArray(event)) {
                                            return event;
                                        }

                                        return event && event.fileList;
                                    }}
                                    rules={validateSchema(t('theme.sampleData'))}
                                >
                                    <UploadFiles 
                                        disabled={isReview}
                                        showUploadList={{ showDownloadIcon: isReview, }}
                                        onDownload={() => downloadTheme(pluginDetail.sampleDataFile)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    name="designAssetFile"
                                    valuePropName="fileList"
                                    label={t('theme.designAssets')}
                                    getValueFromEvent={event => {
                                        if (Array.isArray(event)) {
                                            return event;
                                        }

                                        return event && event.fileList;
                                    }}
                                >
                                    <UploadFiles 
                                        disabled={isReview}
                                        showUploadList={{ showDownloadIcon: isReview, }}
                                        onDownload={() => downloadTheme(pluginDetail.designAssetsFile)}
                                    />
                                </Form.Item>

                                <Divider />

                                <div className="gx-mb-3">{t('theme.extraInformation')}</div>

                                <Form.Item {...formItemLayout} label={t('theme.compatibleBrowsers')} name="compatibleBrowserIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        placeholder={t('theme.compatibleBrowsers')}
                                        optionData={themeExtraInformation.compatibleBrowsers}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('theme.compatibleWith')} name="compatibleWithIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        placeholder={t('theme.compatibleWith')}
                                        optionData={themeExtraInformation.compatibleWith}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('theme.tags')} name="tagIds">
                                    <MutipleSelectBox
                                        mode="tags"
                                        placeholder={t('theme.tags')}
                                        optionData={themeExtraInformation.tags}
                                        disabled={isReview}
                                    />
                                </Form.Item>
                            </Card>
                        </Col>

                        <Col md={8} xs={24}>
                            <Card>
                                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.pluginCategory')} name="categoryId" rules={validateSchema(t('theme.pluginCategory'))}>
                                    <MutipleSelectBox
                                        placeholder={t('theme.pluginCategory')}
                                        optionData={themeCategories}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.wordpressVersion')} name="versionId" rules={validateSchema(t('theme.wordpressVersion'))}>
                                    <MutipleSelectBox
                                        placeholder={t('theme.wordpressVersion')}
                                        optionData={wordpressVersions.records}
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item required {...switchThemeFreeLayout} labelAlign="left" label={t('theme.thisPluginIsFree')} name="isFree" initialValue={false} valuePropName="checked">
                                    <Switch disabled={isReview} />
                                </Form.Item>

                                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.pluginPrice')} name="price" rules={validateSchema(t('theme.pluginPrice'))}>
                                    <InputNumber
                                        parser={value => parserInput(value)}
                                        formatter={value => formatAmount(value)}
                                        placeholder="0 VND"
                                        disabled={isReview}
                                    />
                                </Form.Item>

                                <Form.Item {...tailLayout} className="gx-text-center gx-mb-0">
                                   {!isReview && <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Button onClick={handleUploadButtonClick} disabled={loading} className="gx-mb-0 gx-w-100" htmlType="button" >
                                                {t('common.preview')}
                                            </Button>
                                        </Col>

                                        <Col span={12}>
                                            <Button loading={loading} disabled={loading} className="gx-mb-0 gx-w-100" type="primary" htmlType="submit">
                                                {!isEditMode ? t('common.upload') : t('common.update')}
                                            </Button>
                                        </Col>
                                    </Row>} 

                                    {!!isReview && <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Button onClick={handleRejectButtonClick} disabled={loading} className="gx-mb-0 gx-w-100">
                                                {t('common.reject')}
                                            </Button>
                                        </Col>

                                        <Col span={12}>
                                            <Button onClick={handleAprroveButtonClick} loading={loading} disabled={loading} className="gx-mb-0 gx-w-100" type="primary">
                                                {t('common.approve')}
                                            </Button>
                                        </Col>
                                    </Row>}

                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </ProtectedAction>
        </ThemeUploadWrapper>
    );
};

export default ThemePluginUpload;
