import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row } from "antd";
import Metrics from "../metrics";
import "./index.scss";

interface Props {
  trafficData: any[];
  title: string;
  fromColor?: string;
  toColor?: string;
  id: string;
  color?: string;
  traffic: any;
}

const TrafficCard = (props: Props) => {
  const { fromColor, toColor, id, color, traffic, title } = props;

  return (
    <div className="traffic-card">
      <Metrics styleName={`gx-card-full`} title={<span style={{fontWeight: 500}}>{title}</span>}>
        <Row>
          <Col lg={9} md={24} sm={9} xs={9}>
            <div className="gx-pb-4 gx-pl-4 gx-pt-4">
              <h2
                style={{ color: color || "#000", fontWeight: 700 }}
                className="gx-fs-xxxl gx-mb-1 gx-chart-up gx-text-nowrap"
              >
                {traffic || 0}
                <i className="icon icon-menu-up gx-fs-xxl" />
              </h2>
            </div>
          </Col>
          <Col lg={15} md={24} sm={15} xs={15}>
            <ResponsiveContainer width="100%">
              <AreaChart
                data={props.trafficData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip
                  labelFormatter={(index) => props.trafficData[index].date}
                />
                <defs>
                  <linearGradient id={id} x1="0" y1="0" x2="1" y2="0">
                    <stop offset="5%" stopColor={fromColor} stopOpacity={0.9} />
                    <stop offset="95%" stopColor={toColor} stopOpacity={0.9} />
                  </linearGradient>
                </defs>
                <Area
                  dataKey="value"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#867AE5"
                  fill={`url(#${id})`}
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Metrics>
    </div>
  );
};

export default TrafficCard;
