import { useTranslation } from "react-i18next";
import { Button, Card, Col, Input, Modal, Row } from "antd";
import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import CategoryItem from "../category-item";
import ProtectedAction from "../../../components/action-protected";

import i18n from "../../../utils/i18n";
import { RoleEnum } from "../../../enums/app.enum";
import { ThemeApis } from "../../../apis/theme.api";
import CategoryAction from "../overview/category-action";
import {
  addThemeCategorySelector,
  editThemeCategorybyIdSelector,
  themeCategoriesSelector,
} from "../../../states/theme";

import { PlusOutlined } from "@ant-design/icons";

import "./index.scss";
import confirmReasonDeactivated from "../deactivated-modal";

const { confirm } = Modal;

const config = {
  title: i18n.t("theme.confirmDeactivateCategoryTheme"),
};

const ExtendAction: FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const [ search, setSearch ] = useState('');

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleShowAllCLick = () => {
    setSearch('');
    props.onSearch('')
  };

  return (
    <Row justify="end" gutter={[16, 14]}>
      <Col>
        <Input.Search value={search} placeholder={t('theme.searchByName')} onSearch={props.onSearch} onChange={handleSearchChange}/>
      </Col>

      <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
        <Col>
          <AddButton onClick={props.onClick} />
        </Col>
      </ProtectedAction>

      <Col>
        <Button onClick={handleShowAllCLick} type="ghost" className="categories__button">
          {t("common.showAll")}
        </Button>
      </Col>
    </Row>
  )
};

const AddButton: FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  return (
    <Button {...props} type="primary" className="categories__button">
      <PlusOutlined />

      {t("common.add")}
    </Button>
  );
};

interface CategoryProps {
  categories?: Array<any>;
}

const CategoryList: React.FunctionComponent<CategoryProps> = () => {
  const { t } = useTranslation();

  // state data
  const [loading, setLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [selectCategory, setSelectCategory] = useState<any>(null);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);

  //recoil
  const themeCategories = useRecoilValue(themeCategoriesSelector);
  const addThemeCategory = useSetRecoilState(addThemeCategorySelector);
  const editThemeCategory = useSetRecoilState(editThemeCategorybyIdSelector);

  const filterByName = (keyword: string) => {
    setKeyword(keyword);
  };

  const filterThemeCategories = useMemo(() => {
    if (keyword) {
      return themeCategories.filter(category => category.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
    }

    return themeCategories;
  }, [keyword, themeCategories]);

  const handleAddButtonClick = () => {
    setOpenFormModal(true);
  };

  const showDeactiveCategoryByRole = (isDeactivated: boolean) => {
    if (isDeactivated) {
      return [RoleEnum.Admin, RoleEnum.Mod];
    }
    return Object.values(RoleEnum);
  };

  const handleEditButtonClick = (categoryId: number) => {
    setEditMode(true);

    const category = themeCategories?.find(
      (category) => category.id === categoryId
    );
    if (category) {
      setSelectCategory(category);
    }

    setOpenFormModal(true);
  };

  const onDeactivateCategory = (categoryId: number, categoryPosition: number) => {
    const handleGetReason = (reason: any) => {
      deActiveCategory(categoryId, categoryPosition, reason.replaceAll('\n', '%0A'));
    }

    confirm({
      ...confirmReasonDeactivated(handleGetReason),
      okButtonProps: { loading: loading },
      okType: 'primary'
    });

  };

  const addCategory = async (values: any) => {
    try {
      setLoading(true);
      const { isDeactivated, image, ...fields } = values
      const payload = {
        ...fields,
        isDeactivated: !isDeactivated,
        image: image?.[0]?.originFileObj,
      };

      const result = await ThemeApis.addThemeOverviewCategory(payload);

      if (result?.data) {
        addThemeCategory({ ...result.data, numOfThemes: 0 });
        closeCategoryAction();
      }
    } finally {
      setLoading(false);
    }
  };

  const editCategorybyId = async (values: any) => {
    try {
      setLoading(true);
      const { isDeactivated, ...fields } = values

      const payload = {
        ...fields,
        id: selectCategory.id,
        isDeactivated: !isDeactivated,
        image: values?.image?.[0]?.originFileObj,
      };

      const result = await ThemeApis.editThemeOverviewCategory(payload);

      if (result?.data) {
        const categoryIndex = themeCategories?.findIndex(
          (category) => category.id === result.data.id
        );

        if (categoryIndex !== -1) {
          editThemeCategory({
            index: categoryIndex,
            data: { ...result.data, numOfThemes: themeCategories[categoryIndex].numOfThemes }
          });
          closeCategoryAction();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const changeStatusCategory = (categoryId: number) => {

    const categoryIndex = themeCategories?.findIndex((category) => category.id === categoryId);

    if (categoryIndex === -1) return;

    const isDeactivate = themeCategories[categoryIndex]?.isDeactivated;

    if (isDeactivate) {
      activeCategory(categoryId, categoryIndex);
      return;
    }

    onDeactivateCategory(categoryId, categoryIndex)
  };

  const activeCategory = async (categoryId: number, categoryPosition: number) => {
    const result = await ThemeApis.activeThemeCategory(categoryId);
    if (result?.data) {
      editThemeCategory({
        index: categoryPosition,
        data: { ...themeCategories[categoryPosition], isDeactivated: false }
      });
    };
  };

  const deActiveCategory = async (categoryId: number, categoryPosition: number, reason: string) => {
    try {
      setLoading(true);

      const result = await ThemeApis.deactiveCategory({id: categoryId, reason: reason});
      if (result?.data) {
        editThemeCategory({
          index: categoryPosition,
          data: { ...themeCategories[categoryPosition], isDeactivated: true }
        });
      };

    } finally {
      setLoading(false);
    }
  };

  const handleFormConfirm = (value: any) => {

    if (isEditMode) {
      editCategorybyId(value);
      return;
    }

    addCategory(value);
  };

  const closeCategoryAction = () => {
    setEditMode(false);
    setOpenFormModal(false);
  };

  return (
    <div className="category-list">
      <Card
        bordered
        title={t("theme.themeCategories")}
        extra={<ExtendAction onClick={handleAddButtonClick} onSearch={filterByName} />}
      >
        <div className="category-list__wrapper">
          <Row>
            {filterThemeCategories?.map((category) => {
              return (
                <ProtectedAction key={category.id} roles={showDeactiveCategoryByRole(category.isDeactivated)}>
                  <Col xs={24} sm={12} md={8} xl={6}>
                    <div className="category-item">
                      <CategoryItem
                        id={category.id}
                        image={category.image}
                        title={category.name}
                        amount={category.numOfThemes}
                        isHardCoded={category.isDefault}
                        isDeactivated={category.isDeactivated}

                        onEdit={() => handleEditButtonClick(category.id)}
                        onChangeStatus={() => changeStatusCategory(category.id)}
                      />
                    </div>
                  </Col>
                </ProtectedAction>
              );
            })}
          </Row>
        </div>
      </Card>

      <CategoryAction
        isEdit={isEditMode}
        open={openFormModal}
        value={selectCategory}
        confirmLoading={loading}
        onSubmit={handleFormConfirm}
        onCancel={closeCategoryAction}
      />

      
    </div>
  );
};

CategoryList.defaultProps = {
  categories: [],
};

export default CategoryList;
