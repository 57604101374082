import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import './index.scss';

interface FilterHeaderProps {
  onSubmit?: () => void;
  onReset?: () => void;
}

const FilterHeader: React.FunctionComponent<FilterHeaderProps> = ({ onSubmit, onReset }) => {
  const { t } = useTranslation();

  return (
    <Row justify="space-between" align="middle" className="filter-header">
        <Col className="filter-header__title">
            { t('common.filter') }
        </Col>

        <Col className="filter-header__button-group button-group">
            <Button className="button-group__reset" onClick={onReset}>{ t('common.reset') }</Button>
            <Button className="ant-btn-primary button-group__submit" onClick={onSubmit}>{ t('common.apply') }</Button>
        </Col>
    </Row>
  )
};

export default FilterHeader;
