import { useState } from "react";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";

import AboutForm from "../../about/about-form";
import AboutItem from "../../about/about-item";

import EditButton from "../../edit-button";
import EmailIcon from "../../about/icons/email-icon";
import FamilyIcon from "../../about/icons/family-icon";
import CalendarIcon from "../../about/icons/calendar-icon";
import TelephoneIcon from "../../about/icons/telephone-icon";
import WorkIcon from "../../about/icons/work-icon/work-icon";
import LivingIcon from "../../about/icons/living-icon/living-icon";
import UniversityIcon from "../../about/icons/university-icon/university-icon";

import i18n from "../../../../../utils/i18n";
import { UserApi } from "../../../../../apis/user.api";
import { formatDate } from '../../../../../utils/date.ultis';
import { FORMAT_DATE_TIME } from "../../../../../constants/app-constants";
import { useUserDetailContext } from "../../../../../pages/users/user-detail";
import ProtectedAction from "../../../../../components/action-protected";
import { RoleEnum } from "../../../../../enums/app.enum";


const GeneralInformation = () => {
  // utils
  const [form] = Form.useForm();
  const { t } = useTranslation();

  //state data
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  // recoil
  const { isAccount, userData, setUserDetail } = useUserDetailContext();

  const aboutData = [
    {
      id: 1,
      icon: <EmailIcon />,
      title: "Email Adress",
      desc: userData?.email,
    },

    {
      id: 2,
      icon: <TelephoneIcon />,
      title: "Phone Number",
      desc: userData?.phoneNumber,
    },

    {
      id: 3,
      icon: <CalendarIcon />,
      title: i18n.t("common.birthday"),
      desc: formatDate(userData?.birthday, 'MMMM Do, YYYY'),
    },

  ];

  const handleSaveButtonClick = () => {
    form.submit();
  };

  const handleEditButtonClick = () => {
    setIsEditMode(true);
  };

  const handleCancelButtonClick = () => {
    setIsEditMode(false);
  };

  const handleFormSubmit = async (values: any) => {
    try {
      setLoading(true);

      const { birthday, email, ...payloadData } = values;

      const payload = {
        ...payloadData,
        birthday: formatDate(birthday, FORMAT_DATE_TIME.ymd),
      };

      const result = await UserApi.updateUserInfo(payload);

      if (result?.data) {
        setUserDetail({...result.data, avatar: userData?.avatar});
        setIsEditMode(false);
      }

    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="head">
        <div className="head-title">{t("theme.generalInformation")}</div>

        <ProtectedAction roles={!!isAccount ? [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User, RoleEnum.Accountant] : []}>
          <EditButton
            isEdit={isEditMode}
            loading={loading}

            onEdit={handleEditButtonClick}
            onSave={handleSaveButtonClick}
            onCancel={handleCancelButtonClick}
          />
        </ProtectedAction>
      </div>

      {isEditMode && (
        <AboutForm isEdit={isEditMode} form={form} onFinish={handleFormSubmit} />
      )}

      {!isEditMode && (
        <Row>
          {aboutData?.map((about) => (
            <Col key={about.id} xl={8} lg={12} md={12} sm={12} xs={24}>
              <AboutItem data={about} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default GeneralInformation;
