import jwt_decode from "jwt-decode";
import qs from "qs";
import showNotification from "../components/common/notification";
import axios, { AxiosInstance, AxiosResponse, ResponseType } from "axios";
import {
  LOCAL_COOKIE_KEY,
  LOCAL_STORAGE_KEY,
} from "../constants/app-constants";
import { UserProfileModel } from "../models/user.model";
import LocalUtils from "../utils/local.utils";
import { AuthApi } from "./auth.api";
import { ROUTE_PATHS } from "../constants/router.constants";
import { Navigate, Route } from "react-router-dom";
import i18n from "../utils/i18n";

const toggleLoading = (value: boolean) => {};

let IS_REFRESHING_TOKEN = false;

const getHeaders = (contentType: string) => {
  return {
    "Content-Type": contentType,
    Authorization: `Bearer ${LocalUtils.getCookie(
      LOCAL_COOKIE_KEY.ACCESS_TOKEN
    )}`,
  };
};

const axiosInstance = (
  contentType: string = "application/json",
  responseType: ResponseType = "json",
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): AxiosInstance => {
  if (isShowLoading) toggleLoading(true);

  const instance = axios.create({
    responseType: responseType,
  });

  instance.interceptors.request.use(async (config: any) => {
    if (allowAnonymous) {
      return config;
    }

    //can check ingore in here
    await checkRefreshTokenFinished(config);

    let accessToken = LocalUtils.getAccessToken();
    const refreshToken = LocalUtils.getRefeshToken();

    if (!accessToken) {
      if (refreshToken && !IS_REFRESHING_TOKEN) {
        try {
          IS_REFRESHING_TOKEN = true;
          const refreshTokenRefresh = {
            refreshToken: refreshToken,
          };
          const { data } = await AuthApi.refreshToken(refreshTokenRefresh);

          const isRememberMe = LocalUtils.get(LOCAL_STORAGE_KEY.IS_REMEMBER_ME) === "true";
          const user: UserProfileModel = jwt_decode(data.token);
        
          const userInfo = { ...user, userId: data.userId }
          LocalUtils.setAuthenticatedData(data, isRememberMe, userInfo);
          
        } catch (error) {
          console.log(error);
        }

        IS_REFRESHING_TOKEN = false;
      }
    }

    config.headers = getHeaders(contentType);
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (isShowLoading) toggleLoading(false);

      return response;
    },
    (error) => {
      if (isShowLoading) toggleLoading(false);

      if (error.response.status === 401) {
        handleUnAuthorize();
      }
      
      else {
        const data = error.response.data;
        if (isShowErrorMessage) {
          let message = "An error has occurred please contact the system administrator";
          
          
          if (data && data.message) {
            message = data.message;
          } else if (typeof data == "string" && data !== "") {
            message = data;
          }
          
          if (error.response.status === 403) {
            message= i18n.t('common.forbiden-alert');
          }
          
          showNotification("error", message);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const getAsync = (
  url: string,
  params?: { [key: string]: any },
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getFileAsync = (
  url: string,
  params?: { [key: string]: any },
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "blob",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const postAsync = (
  url: string,
  json?: object,
  isShowLoading = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).post(url, json);
};

export const putAsync = (
  url: string,
  json?: object,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, json);
};

export const deleteAsync = (
  url: string,
  json?: object,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    (allowAnonymous = false)
  ).delete(url, { data: json });
};

export const postFormDataAsync = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).postForm(url, parseFormdata(json));
};

export const putFormDataAsync = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, parseFormdata(json));
};

export const downloadAsync = (
  url: string,
  params?: object
): Promise<AxiosResponse> => {
  return axiosInstance("application/json", "blob", true).get(url, { params });
};

const parseFormdata = (model: any) => {
  const formdata = new FormData();
  Object.keys(model || {}).forEach((p) => {
    if (model[p]) {
      if (Array.isArray(model[p])) {
        (model[p] as Array<any>).forEach((q) => {
          formdata.append(p, q);
        });
      } else {
        formdata.append(p, model[p]);
      }
    }
  });

  return formdata;
};

function handleUnAuthorize() {
  LocalUtils.clear();
  window.location.replace(ROUTE_PATHS.signIn);
}

const reDirectNotFoundPage = () => {
  window.location.replace(ROUTE_PATHS.notFound);
};

function checkRefreshTokenFinished(config: any): Promise<boolean> {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (!IS_REFRESHING_TOKEN) {
        clearInterval(timer);
        resolve(true);
      }
    }, 100);
  });
}
