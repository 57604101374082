
import { Button, Card, Col, Form, Input, Modal, Row } from "antd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ThemeApis } from "../../../apis/theme.api";
import { RoleEnum } from "../../../enums/app.enum";
import { ROUTE_PATHS } from "../../../constants/router.constants";

import CategoryDetailFilterBar from "../../../views/theme/filter-bar";
import CategoriesBarItem from "../../../views/theme/category-bar-item";
import SiteFilterDrawer from "../../../views/theme/sites-filter-drawer";
import SampleDataCardExtra from "../../../views/theme/plugin/plugin-card-extra";
import ThemeSampleDataList from "../../../views/theme/sample-data/theme-sample-data-list";

import { ExclamationCircleOutlined, CaretDownOutlined, PlusOutlined } from "@ant-design/icons";

import { CATEGORY_SHOW_LIMIT, SITE_PAGE_SIZE } from "../../../constants/app-constants";
import { addSampleDataCategory, deleteSampleDataCategoryStore, deleteThemeCategorybyIdSelector, editSampleDataCategoryStore, getSampleCategoriesTotal, sampleDataCategoriesSelector, sampleDataSelector } from "../../../states/theme";

import "./index.scss";
import ProtectedAction from "../../../components/action-protected";
import confirmReasonDeactivated from "../../../views/theme/deactivated-modal";
import ModalComponent from "../../../components/common/modal";

const { confirm } = Modal;

const ThemeSampleDataPage = () => {
  // ultils
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // state data
  const [filter, setFilter] = useState<any>({});
  const [keyword, setKeyword] = useState<any>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editSelectCategory, setEditSelectCategory] = useState<any>({});
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [ratingFilterData, setRatingFilterData] = useState<any>(null);
  const [selectCategory, setSelectCategory] = useState<number | string>(0);
  const [categoryLimit, setCategoryLimit] = useState<number>(CATEGORY_SHOW_LIMIT);

  // recoil
  const themeCategoriesTotal = useRecoilValue(getSampleCategoriesTotal);
  const [sampleData, setSampleData] = useRecoilState(sampleDataSelector);
  const addNewCategory = useSetRecoilState(addSampleDataCategory);
  const editCategoryStore = useSetRecoilState(editSampleDataCategoryStore);
  const deleteCategoryRecoil = useSetRecoilState(deleteSampleDataCategoryStore);
  const [categories, setCategories] = useRecoilState(sampleDataCategoriesSelector);

  const handlePaginationListChange = (page: number) => {
    setCurrentPage(page);
  };

  // computed
  const titleModal = useMemo(() => {
    return isEdit ? t("theme.editSampleCategory") : t("theme.addSampleCategory");
  }, [isEdit, t]);

  const sampleDataCategories = useMemo(() => {
    if (!keyword) {
      return categories
    }

    return categories.filter((category: any) => category.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
  }, [categories, keyword]);

  const confirmButtonModalText = useMemo(() => {
    return isEdit ? t("common.save") : t("common.add");
  }, [isEdit, t]);

  const confirmCallback = useCallback((values: any) => {
    return isEdit ? editCategory(values) : handleAddNewCategory(values);
  }, [isEdit, editSelectCategory]);

  const categoryPayload = useMemo(() => {
    return {
      PageIndex: 1,
      PageSize: categoryLimit,
    }
  }, [categoryLimit]);

  const isShowMoreCategories = useMemo(() => {
    return !keyword && themeCategoriesTotal > 12 && categories.length <= 12
  }, [themeCategoriesTotal, categories, keyword]);


  const pagination: any = useMemo(() => {
    return {
      pageSize: SITE_PAGE_SIZE,
      current: currentPage,
      total: sampleData.totalRecords,
      onChange: handlePaginationListChange,
    }
  }, [currentPage, sampleData.totalRecords]);

  const sampleDataFilter = useMemo(() => {
    return {
      ...filter,
      categoryId: selectCategory,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
    }
  }, [pagination, selectCategory, filter]);

  // action
  const showDeactiveCategoryByRole = (isDeactivated: boolean) => {
    if (isDeactivated) {
      return [RoleEnum.Admin, RoleEnum.Mod];
    }
    return Object.values(RoleEnum);
  };

  const handleShowMoreClick = () => {
    setCategoryLimit(100);
  };

  const onLoadCategories = async () => {
    const result = await ThemeApis.getSampleDataCategory(categoryPayload);
    if (result?.data) {
      setCategories(result?.data);

      for (let index = 0; index < result?.data?.records?.length; index++) {
        if (!result?.data?.records?.[index].isDeactivated) {
          setSelectCategory(result?.data.records?.[index].id);
          break;
        }
      }
    };
  };

  const handleChangeStatus = (selectedCategory: any) => {
    showActiveConfirm(!selectedCategory.isDeactivated, selectedCategory);
  };

  const activeCategory = async (category: any) => {
    try {
      setLoading(true);

      const categoryId = category?.id
      const result = await ThemeApis.activeSampleDataCategory(categoryId);

      if (result?.status === 204) {
        editCategoryStore({ ...category, isDeactivated: false });
      };

    } finally {
      setLoading(false);
    }
  };

  const deActiveCategory = async (category: any, reason: string) => {
    try {
      setLoading(true);

      const categoryId = category?.id
      const result = await ThemeApis.deActiveSampleDataCategory({ id: categoryId, reason: reason });

      if (result?.status === 204) {
        editCategoryStore({ ...category, isDeactivated: true });
      };

    } finally {
      setLoading(false);
    }
  };

  const showActiveConfirm = (active: boolean, category: any) => {
    if (active) {
      const handleGetReason = (reason: any) => {
        deActiveCategory(category, reason.replaceAll('\n', '%0A'));
      }

      confirm({
        ...confirmReasonDeactivated(handleGetReason),
        okButtonProps: { loading: loading },
        okType: 'primary'
      });

      return;
    }
    activeCategory(category)
  };

  const handleDeleteCategory = (categoryId: string | number) => {
    confirm({
      title: t("common.confirmQuestion"),
      icon: <ExclamationCircleOutlined />,
      okButtonProps: { loading: loading },
      onOk() {
        deleteCategory(categoryId)
      },
    });
  };

  const deleteCategory = async (categoryId: any) => {
    try {
      setLoading(true);

      const result = await ThemeApis.deleteSampleDataCategory(categoryId);

      if (result?.status === 204) {
        deleteCategoryRecoil(categoryId);
      }

    } finally {
      setLoading(false);
    }
  };

  // add category methods
  const handleAddNewCategory = async (values: any) => {
    try {
      setLoading(true);

      const payload = {
        name: values.name,
      };

      const result = await ThemeApis.addNewSampleDataCategory(payload);

      if (result?.data) {
        addNewCategory(result.data);
        setSelectCategory(result.data.id);
        handleCloseEditCategories();
      };
    } finally {
      setLoading(false)
    }
  };

  const handleEditCategory = async (category: any) => {
    setIsEdit(true);
    setEditSelectCategory(category);

    await form.setFieldsValue(category);
    setOpenFormModal(true);
  };

  const searchCategoryByName = (keyword: string) => {
    if (!keyword) {
      setKeyword('');
      return;
    }
    setKeyword(keyword);
    setCategoryLimit(100);
  };

  const editCategory = async (values: any) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        isDeactivated: editSelectCategory.isDeactivated,
        id: editSelectCategory.id,
      };

      const result = await ThemeApis.editSampleDataCategory(payload);

      if (result?.data) {
        editCategoryStore(result.data);
        handleCloseEditCategories();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseEditCategories = () => {
    setIsEdit(false);
    form.resetFields();
    setEditSelectCategory({});
    setOpenFormModal(false);
  };

  const onLoadSampleData = async () => {
    try {
      setLoading(true);
      const params = {
        ...sampleDataFilter,
      }
      const result = await ThemeApis.getSampleData(params);

      if (result?.data) {
        setSampleData(result.data);

        if (openFilterForm) {
          setOpenFilterForm(false);
        };
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSelectCategory = (categoryId: number | string) => {
    setSelectCategory(categoryId);
  };

  const handleSearchSampleData = (search: string) => {
    setFilter({ ...filter, name: search });
  };

  const handleFilterButtonClick = async () => {
    const response = await ThemeApis.getSampleDataFilterData();

    if (response?.data) {
      setRatingFilterData(response.data);
      setOpenFilterForm(true);
    }
  };

  const handleFilterFormClose = () => {
    setOpenFilterForm(false);
  };

  const handleFilterFormSubmit = (value: any) => {
    setFilter({ ...filter, ...value });
  };

  const hanldeFilterFormReset = () => {
    const { isIncludePendingsItem, publishedEndDate, publishedStartDate, uploadByUserId, ...dataFilter } = filter;

    setFilter(dataFilter);
  };

  const handleSortType = (event: any) => {
    const { value } = event.target;

    if (value) {
      setFilter({ ...filter, themeFilterType: value });
    };
  };

  // mounted
  useEffect(() => {
    if (selectCategory) {
      onLoadSampleData();
    }
  }, [selectCategory, sampleDataFilter]);

  useEffect(() => {
    onLoadCategories();
  }, [categoryPayload]);

  return (
    <Card
      className="theme-sample-data"
      title={t("theme.sampleData")}
      extra={<SampleDataCardExtra uploadRedirect={ROUTE_PATHS.uploadThemeSampleData} onSearch={searchCategoryByName} />}
    >
      <div className="content">
        <div className="header">
          <div className="title">{t("theme.sampleDataCategories")}</div>

          <div className="action">
            <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
              <Button
                disabled={isEdit}
                icon={<PlusOutlined />}
                onClick={() => setOpenFormModal(true)}
              >
                {t("common.add")}
              </Button>
            </ProtectedAction>
          </div>
        </div>

        <div className="categories">
          <Row gutter={[16, 16]}>
            {sampleDataCategories?.map((category: any) => (
              <ProtectedAction key={category?.id} roles={showDeactiveCategoryByRole(category?.isDeactivated)}>
                <Col lg={4} md={6} sm={8} xs={24} >
                  <CategoriesBarItem
                    category={category}
                    isHardCoded={category?.isDefault}
                    active={selectCategory === category.id}
                    onEdit={() => handleEditCategory(category)}
                    onClick={() => handleSelectCategory(category.id)}
                    onDelete={() => handleDeleteCategory(category.id)}
                    onChangeStatus={() => handleChangeStatus(category)}
                  />
                </Col>
              </ProtectedAction>
            ))}
          </Row>

          {isShowMoreCategories && <div className="show-more">
            <div className="show-more-title" onClick={handleShowMoreClick}>
              <div>{t("theme.showAll")}</div>
              <div>
                <CaretDownOutlined />
              </div>
            </div>
          </div>}

        </div>
      </div>

      <div className="sample-data__filter">
        <CategoryDetailFilterBar
          isView={false}
          onSelectType={handleSortType}
          onSearch={handleSearchSampleData}
          onFilter={handleFilterButtonClick}
        />
      </div>

      <ThemeSampleDataList pagination={pagination} loading={loading} />

      <SiteFilterDrawer
        open={openFilterForm}
        onClose={handleFilterFormClose}
        onReset={hanldeFilterFormReset}
        onFilter={handleFilterFormSubmit}
        ratingFilterData={ratingFilterData}
      />

      <ModalComponent
        loading={loading}
        title={titleModal}
        isOpen={openFormModal}
        okTextColor="#8a2be2"
        setIsOpen={handleCloseEditCategories}
        onYes={() => form.submit()}
        okText={confirmButtonModalText}
      >
        <>
          <p>{t("theme.enterSampleDataCategoryName")}</p>
          <Form
            className="gx-signin-form gx-form-row0 form-hidden-label "
            form={form}
            onFinish={(values: any) => confirmCallback(values)}
          >
            <Form.Item
              rules={[
                { required: true, message: t("theme.validate.categoryName") },
              ]}
              name="name"
              label={t("forgotPassword.email")}
            >
              <Input style={{ width: "50%" }} />
            </Form.Item>
          </Form>
        </>
      </ModalComponent>

    </Card>
  );
};

export default ThemeSampleDataPage;
