export enum ActionOption {
    Increase = 1,
    Coupon = 2,
    Transfer = 4,
    Withdraw = 8,
}

export enum WithdrawMethod {
    BankAccount = 1,
    UserAccount = 2,
}