import { useRecoilValue } from "recoil";
import { RoleEnum } from "../../enums/app.enum";
import { authState } from "../../states/auth";

interface ProtectedRouteProps {
  roles: RoleEnum[];
  children: JSX.Element;
}

const ProtectedAction = (props: ProtectedRouteProps) => {
  const { roles, children } = props;
  const { user } = useRecoilValue(authState);

  if (roles && user && roles.some((x) => x === user.role)) {
    return children;
  }

  return null;
};

export default ProtectedAction;
