import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/userbudget`;

export const UserBudgetApi = {
    deleteUserBudget: (params: any) => {
        return deleteAsync(baseUrl, params, false, true);
    },

    addNewUserBudget: (payload: any) => {
        return postAsync(baseUrl, payload, false, true);
    },

    getUserBudgetList: (params?: any) => {
        return getAsync(baseUrl, params, false, true);
    },

    approveUserBudget: (payload: any) => {
        return putAsync(`${baseUrl}/approve`, payload);
    }
};