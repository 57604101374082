import { deleteAsync, getAsync, postAsync, postFormDataAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/website`;

function getCategories() {
  const url = `${baseUrl}/categories`;
  return getAsync(url);
}

function getTheme(params?: any) {
  const url = `${baseUrl}/theme`;
  return getAsync(url, params);
}

function getPlan() {
  const url = `${baseUrl}/plan`;
  return getAsync(url);
}

function getSites(params?: any) {
  const url = `${baseUrl}/sites`;
  return getAsync(url, params, true, false);
}

function getSitesOverview() {
  const url = `${baseUrl}/sites/overview`;
  return getAsync(url, undefined, true, false);
}

function startSite(data: Array<Number>) {
  const url = `${baseUrl}/sites/start`;
  return putAsync(url, data);
}

function stopSite(data: Array<Number>) {
  const url = `${baseUrl}/sites/stop`;
  return putAsync(url, data);
}

function deleteSite(data: Array<number | string>) {
  const url = `${baseUrl}/sites`;
  return deleteAsync(url, data);
}

function createSite(data: object) {
  const url = `${baseUrl}/sites/create`;
  return postFormDataAsync(url, data, false, true);
}

function getDatabase(params?: any) {
  const url = `${baseUrl}/userdatabase`;
  return getAsync(url, params, true, false);
}

function deleteDatabase(data: Array<number | string>) {
  const url = `${baseUrl}/userdatabase`;
  return deleteAsync(url, data);
}

function createDatabase(data: object) {
  const url = `${baseUrl}/userdatabase`;
  return postAsync(url, data);
}

export const WebsiteApi = {
  getCategories,
  getTheme,
  getPlan,
  getSites,
  getSitesOverview,
  startSite,
  stopSite,
  deleteSite,
  createSite,
  getDatabase,
  deleteDatabase,
  createDatabase,

  activeSite: (id: any) => {
    return putAsync(`${baseUrl}/sites/activate?siteIds=${id}`, {}, false, true);
  },

  deActivateSite: (payload: any) => {
    return putAsync(`${baseUrl}/sites/deactivate?siteIds=${payload.id}&reason=${payload.reason}`, {}, false, true);
  },

  dashboardOverview: () => {
    return getAsync(`${baseUrl}/dashboard/overview`);
  },

  getCalculationSite: (payload: any) => {
    return postAsync(`${baseUrl}/sites/calculation`, payload);
  },
};
