import { ROUTE_PATHS } from "./constants/router.constants";
import ForgotPasswordPage from "./pages/forgot-password";
import ResetPasswordPage from "./pages/reset-password";
import SignInPage from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import VerifyCodePage from "./pages/veviry-code";
import CompletePage from "./pages/sign-up/complete";
import WebsiteOverviewPage from "./pages/website/overview";
import WebsiteSitesPage from "./pages/website/sites";
import WebsiteDatabasePage from "./pages/website/database";
import WebsiteCreatePage from "./pages/website/sites/create";
import UserList from "./pages/users/list";
import UserOverviewPage from "./pages/users/overview";
import ThemeOverviewPage from "./pages/theme/overview";
import UserBudget from "./pages/users/budget";
import ThemePluginPage from "./pages/theme/plugin";
import UploadThemePlugin from "./pages/theme/plugin/upload";
import CategoryDetailPage from "./pages/theme/overview/category-detail";
import ThemeDetailPage from "./pages/theme/overview/theme-detail";
import ThemePluginDetail from "./pages/theme/plugin/plugin-detail";
import ThemeSampleDataPage from "./pages/theme/sample-data";
import UploadThemeSampleData from "./pages/theme/sample-data/upload";
import ThemeSampleDataDetail from "./pages/theme/sample-data/sample-data-detail";
import UploadThemeOverview from "./pages/theme/overview/upload";
import ThemeWordpressPage from "./pages/theme/wordpress";
import AdminEmailTemplate from "./pages/admin/email-template";
import AddNewEmailTemplate from "./pages/admin/email-template/add-new";
import AdminCloudflarePage from "./pages/admin/cloudflare";
import UserDetailPage from "./pages/users/user-detail";
import WebsitePlanPage from "./pages/admin/website-plan";
import AdminMailer from "./pages/admin/mailer";
import DashboardOverviewPage from "./pages/dashboard/overview";
import DashboardUserPage from "./pages/dashboard/user";
import TermCondition from "./pages/term-condition";
import BudgetWiget from "./views/users/user-detail/budget-wiget";

export const authRouters = [
  {
    href: ROUTE_PATHS.signIn,
    component: SignInPage,
  },
  {
    href: ROUTE_PATHS.signUp,
    component: SignUp,
  },
  {
    href: ROUTE_PATHS.forgotPassword,
    component: ForgotPasswordPage,
  },
  {
    href: ROUTE_PATHS.resetPassword,
    component: ResetPasswordPage,
  },

  {
    href: ROUTE_PATHS.verifyCode,
    component: VerifyCodePage,
  },

  {
    href: ROUTE_PATHS.termCondition,
    component: TermCondition,
  },
  {
    href: ROUTE_PATHS.complete,
    component: CompletePage,
  },
];

export const mainRouters = [
  {
    href: ROUTE_PATHS.Home,
    component: DashboardOverviewPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.dashboardOverview,
    component: DashboardOverviewPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.dashboardUser,
    component: DashboardUserPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.websiteOverview,
    component: WebsiteOverviewPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.websiteSites,
    component: WebsiteSitesPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.websiteDatabase,
    component: WebsiteDatabasePage,
  },
  {
    href: ROUTE_PATHS.websiteCreateSites,
    component: WebsiteCreatePage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.usersList,
    component: UserList,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.myAccount,
    component: UserDetailPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.usersBudget,
    component: UserBudget,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.myBudget,
    component: UserBudget,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.usersOverview,
    component: UserOverviewPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.userDetail,
    component: UserDetailPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesOverView,
    component: ThemeOverviewPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.reviewSiteOverviewUpload,
    component: UploadThemeOverview,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesPlugin,
    component: ThemePluginPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.editThemePlugin,
    component: UploadThemePlugin,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.reviewThemePlugin,
    component: UploadThemePlugin,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.uploadThemePlugin,
    component: UploadThemePlugin,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesPluginDetail,
    component: ThemePluginDetail,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesSampleData,
    component: ThemeSampleDataPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.uploadThemeSampleData,
    component: UploadThemeSampleData,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.editThemeSampleData,
    component: UploadThemeSampleData,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.reviewThemeSampleData,
    component: UploadThemeSampleData,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesSampleDataDetail,
    component: ThemeSampleDataDetail,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesCategoryDetail,
    component: CategoryDetailPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.themeDetail,
    component: ThemeDetailPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themeUpload,
    component: UploadThemeOverview,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.editSiteOverviewUpload,
    component: UploadThemeOverview,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.themesWordpress,
    component: ThemeWordpressPage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.adminEmailTemplate,
    component: AdminEmailTemplate,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.addNewEmailTemplate,
    component: AddNewEmailTemplate,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.adminCloudflare,
    component: AdminCloudflarePage,
    permissions: [],
  },

  {
    href: ROUTE_PATHS.adminWebsitePlan,
    component: WebsitePlanPage,
    permissions: [],
  },
  
  {
    href: ROUTE_PATHS.adminMailer,
    component: AdminMailer,
    permissions: [],
  }
];
