import { FunctionComponent, ReactElement } from "react";
import "./index.scss";
interface AboutItemProps {
  data?: Value;
}

type Value = {
  desc?: string;
  title?: string;
  icon?: ReactElement;
};

const AboutItem: FunctionComponent<AboutItemProps> = ({ data }) => {
  return (
    <div className="about-item-overview">
      <div className="icon">{data?.icon}</div>
      <div className="gx-media-body">
        <h6 className="title">{data?.title}</h6>
        {!data?.desc ? "--" : <p className="desc">{data?.desc}</p>}
      </div>
    </div>
  );
};

export default AboutItem;
