import moment from "moment";
import { useMemo, useState } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  Radio,
  Row,
  Slider,
  Switch,
} from "antd";

import FilterHeader from "../filter-header";

import "./index.scss";
import {
  FILTER_SLIDER_PRICE,
  FORMAT_DATE_TIME,
  SALES_LEVELS,
  SALES_RATINGS,
} from "../../../constants/app-constants";
import { useTranslation } from "react-i18next";
import { NUMBER_UTIL } from "../../../utils/number.utils";
import UploadBySelect from "../upload-by";


const initialValues = {
  price: [0, 1000000],
  uploadByUserId: undefined,
  isIncludePendingsItem: false,
  // publishDate: [moment(), moment().add(1, 'day')],
};

interface FilterFormProps {
  values?: any;
  open: boolean;
  ratingFilterData?: any;
  onClose: () => void;
  onReset?: () => void;
  onFilter?: (value: any) => void;
}

const formItemLayoutVertical = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },

  className: "form__item--vertical"
};

const formItemLayoutHorizone = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },

  className: "form__item--horizone"
};

const SiteFilterDrawer: React.FunctionComponent<FilterFormProps> = ({
  values,
  open,
  ratingFilterData,
  onReset,
  onClose,
  onFilter,
}) => {
  const { t } = useTranslation();

  const salesRatings = useMemo(() => {
    return SALES_RATINGS.map((rating: any) => {
      return {...rating, number: ratingFilterData?.ratingFilters?.[rating.field] || 0}
    })
  }, [ratingFilterData])


  // state data
  const [price, setPrice] = useState<[number, number] | number[]>(
    values?.price
  );

  const [form] = Form.useForm();

  const handleSubmitForm = (values: any) => {
    const { publishDate, price: priceForm, ...filterValues } = values;
    const [fromDate, endDate] = publishDate ? publishDate : [];
    const [ThemePriceFrom, ThemePriceTo] = priceForm;

    if (onFilter) {
      onFilter({
        ...filterValues,
        ThemePriceFrom,
        ThemePriceTo,
        publishedStartDate: fromDate && moment(fromDate).format(FORMAT_DATE_TIME.ymd),
        publishedEndDate: endDate && moment(endDate).format(FORMAT_DATE_TIME.ymd),
      });
    }
  };

  const handleSubmitButtonClick = () => {
    form.submit();
  };

  const handleResetButtonClick = () => {
    setPrice(initialValues.price);

    form.resetFields();

    if (onReset) {
      onReset();
    }
  };

  const handleSliderChange = (value: [number, number]) => {
    setPrice(value);
  };

  return (
    <>
      <Drawer
        closable
        open={open}
        placement="right"
        onClose={onClose}
        className="drawer-wrapper"

        title={
          <FilterHeader
            onSubmit={handleSubmitButtonClick}
            onReset={handleResetButtonClick}
          />
        }
      >
        <Form
          form={form}
          onFinish={handleSubmitForm}
          className="form"
          initialValues={values}
          colon={false}
        >
          <Form.Item
            {...formItemLayoutHorizone}
            label={t("theme.pendingItem")}
            name="isIncludePendingsItem"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            {...formItemLayoutHorizone}
            label={t("theme.deactivated")}
            name="isIncludeDeactivated"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item {...formItemLayoutVertical} labelAlign='left' name="uploadByUserId" label={t("theme.uploadBy")} >
            <UploadBySelect placeholder={t("theme.searchToSelect")} />
          </Form.Item>

          <Form.Item {...formItemLayoutVertical} labelAlign='left' name="publishDate" label={t("theme.publishDate")}>
            <DatePicker.RangePicker className="form__date-picker" />
          </Form.Item>

          <Form.Item {...formItemLayoutVertical} labelAlign='left' name="price" label={t("theme.themePrice")} className="form__slider">
            <Slider
              range
              step={500}
              max={FILTER_SLIDER_PRICE.max}
              min={FILTER_SLIDER_PRICE.min}
              onChange={handleSliderChange}
            />
          </Form.Item>
          <Form.Item {...formItemLayoutVertical} labelAlign='left'>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col span={12}>
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => NUMBER_UTIL.formatInput(value)}
                  value={price?.[0]}
                />
              </Col>

              <Col span={12}>
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => NUMBER_UTIL.formatInput(value)}
                  value={price?.[1]}
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...formItemLayoutVertical} name="isOnSales" labelAlign='left' label={t('theme.onSales')} valuePropName="checked">
            <Row justify="space-between">
              <Col span={12}>
                <Checkbox>{t('common.yes')}</Checkbox>
              </Col>
              <Col className="values" span={12}>
                <div>
                  <span>0</span>
                </div>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...formItemLayoutVertical} name="salesLevel" labelAlign='left' label={t('theme.sales')}>
            <Checkbox.Group>
              <Row gutter={[0, 12]}>
                {SALES_LEVELS.map(sale => (
                  <Col className="cols" key={sale.id} span={24}>
                    <div className="sales">
                      <Col span={12}>
                        <Checkbox value={sale.value}>
                          {sale.name}
                        </Checkbox>
                      </Col>
                      <Col className="values" span={12}>
                        <div>
                          <span>0</span>
                        </div>
                      </Col>
                    </div>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item {...formItemLayoutVertical} name="ratingFilter" labelAlign='left' label={t('theme.rating')} initialValue={SALES_RATINGS[0].value}>
            <Radio.Group>
              <Row gutter={[0, 12]}>
                {salesRatings.map(sale => (
                  <Col className="cols" key={sale.id} span={24}>
                      <div className="sales">
                        <Col span={12}>
                          <Radio value={sale.value}>{sale.name}</Radio>
                        </Col>
                        <Col className="values" span={12}>
                          <div>
                            <span>{sale.number}</span>
                          </div>
                        </Col>
                      </div>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

SiteFilterDrawer.defaultProps = {
  open: false,
  values: initialValues,

  onClose: () => null,
  onFilter: () => null,
};

export default SiteFilterDrawer;
