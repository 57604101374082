import { Modal } from "antd";
import { ReactNode } from "react";
import "./index.scss";

interface Props {
  children?: ReactNode;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onYes: () => void;
  title: string;
  okText?: string;
  disabledOkButton?: boolean;
  okTextColor?: string;
  loading?: boolean;
}

const ModalComponent = ({
  title,
  children,
  isOpen,
  setIsOpen,
  onYes,
  okText = "Ok",
  okTextColor,
  loading,
  disabledOkButton = false,
}: Props) => {
  const handleOk = () => {
    onYes();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      className={okTextColor ? "" : "modal-component"}
      title={title}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={okText}
      okButtonProps={{
        loading: loading,
        disabled: disabledOkButton,
        style: { backgroundColor: okTextColor, border: 0 },
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
