import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { Button, Card, Table, Typography } from "antd";

import { CaretRightOutlined, LaptopOutlined } from "@ant-design/icons";

import DotStatus from "../dot-status";
import { formatDate } from "../../../utils/date.ultis";
import { WebsiteApi } from "../../../apis/website.api";
import { ROUTE_PATHS } from "../../../constants/router.constants";

import "./index.scss";

const { Text } = Typography;

type optionProps = {
  pageSize: number;
  pageIndex: number;
};

const RenewWebsite = () => {
  const [options, setOptions] = useState<optionProps>({
    pageIndex: 1,
    pageSize: 10,
  });

  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<any[]>([]);
  const { t } = useTranslation();

  const getSites = async (options: object) => {
    setLoading(true);
    const res = await WebsiteApi.getSites(options);
    setSites(res.data.records);
    setLoading(false);
  };

  useEffect(() => {
    getSites(options);
  }, [options]);

  const columns: ColumnsType<any> = [

    {
      title: "",
      dataIndex: "name",
      key: "icon-name",
      width: "5%",
      className: "website-name-column",
      render: (text) => (
        <div className="website-name">
            <LaptopOutlined />
        </div>
      ),
    },

    // {
    //   title: t("website.websiteName"),
    //   dataIndex: "name",
    //   key: "name",
    //   width: "20%",
    //   className: "website-name-column",
    //   render: (text) => (
    //     <div className="website-name">
    //       <div>
    //         <LaptopOutlined />
    //       </div>
    //       <div>{text}</div>
    //     </div>
    //   ),
    // },
    {
      title: t("website.status"),
      dataIndex: "siteStatus",
      key: "siteStatus",
      align: "center",
      width: "30%",
      render: (text) => <DotStatus status={text} />,
    },
    {
      title: t("website.plan"),
      dataIndex: "planName",
      key: "planName",
      width: "30%",
    },
    {
      title: "",
      key: "createAt",
      width: "15%",
      render: (text) => {
        return (
          <div className="expiryDate">
            <div>{text && formatDate(text)}</div>
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "5%",
      render: () => <Button className="website-button" type="primary">{t("common.detail")}</Button>,
    },
  ];

  return (
    <Card
      title={
        <div className="header">
          <div>{t("website.renewWebsite")}</div>
          <div className="viewMore">
            <Link to={ROUTE_PATHS.websiteSites}>
              <Text>
                {t("common.showMore")}
                <span className="arrow-icon">
                  <CaretRightOutlined />
                </span>
              </Text>
            </Link>
          </div>
        </div>
      }
      bordered
      className="renew-websites gx-card-widget"
    >
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={sites}
        pagination={{ hideOnSinglePage: true }}
        bordered={false}
        loading={loading}
        rowKey="id"
      />
    </Card>
  );
};

export default RenewWebsite;
