import { Form, Input, Select } from "antd";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const FormItemFormat = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 12 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
        lg: { span: 16 },
    },

    className: "form__item"
};

const validateSchema = {
    smtpHost: [
        { required: true, message: 'SMTP host is empty' },
    ],

    smtpPost: [
        { required: true, message: 'SMTP Post is empty' },
    ],

    emailFrom: [
        { type: 'email', message: 'Invalid email format' },
        { required: true, message: 'Email from is empty' },
    ],

    smtpLogin: [
        { required: true, message: 'SMTP login is empty' },
    ],

    smtpPassword: [
        { required: true, message: 'SMTP passowrd is empty' },
    ],

    scureConnection: [
        { required: true, message: 'Secure connection is empty' },
    ],
};

interface MailerFormProps {
    className?: string;
    [key: string]: any;
};

const MailerForm: FunctionComponent<MailerFormProps> = ({ className, ...props }) => {
    const { t } = useTranslation();
    return (
        <Form {...props} className={classNames(['mailer-form', className])}>
            <Form.Item {...FormItemFormat} rules={validateSchema.smtpHost} label={t('admin.smtpHostLabel')} name="smtpHost">
                <Input />
            </Form.Item>

            <Form.Item {...FormItemFormat} rules={validateSchema.smtpPost} label={t('admin.smtpPostLabel')} name="smtpPost">
                <Input />
            </Form.Item>

            <Form.Item {...FormItemFormat}
                rules={[
                    { type: 'email', message: 'Invalid email format' },
                    { required: true, message: 'Email from is empty' },
                ]}
                label={t('admin.emailFromLabel')}
                name="emailFrom"
            >
                <Input />
            </Form.Item>

            <Form.Item {...FormItemFormat} rules={validateSchema.smtpLogin} label={t('admin.smtpLoginLabel')} name="smtpLogin">
                <Input />
            </Form.Item>

            <Form.Item {...FormItemFormat} rules={validateSchema.smtpPassword} label={t('admin.smtpPasswordLabel')} name="smtpPassword">
                <Input.Password />
            </Form.Item>

            <Form.Item {...FormItemFormat} rules={validateSchema.scureConnection} label={t('admin.scureConnectionLabel')} name="scureConnection" initialValue="none">
                <Select>
                    <Select.Option value="none">
                        None
                    </Select.Option>

                    <Select.Option value="starttls">
                        StartTLS
                    </Select.Option>

                    <Select.Option value="ssl">
                        SSL
                    </Select.Option>
                </Select>
            </Form.Item>
        </Form>
    );
};

export default MailerForm;
