import { Table, TableColumnsType, Tag } from "antd";
import moment from "moment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { StatusTypes } from "../../../../constants/app-constants";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import './index.scss';
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";
interface BudgetTableProps {
    data?: any;
    actionColumns?: any;
    pagination?: Pagination;

    [key: string]: any;
};

type Pagination = {
    page: number;
    total: number;
    onChange: (value: any) => void;
};

const DEFAULT_TAG = { color: '', name: '' };

const BudgetTable: FunctionComponent<BudgetTableProps> = ({ data, pagination, actionColumns, ...props }) => {

    const { t } = useTranslation();

    const { convertNumericToFormattedAmount } = useCurrencyConfig();

    const renderTag = (status: number) => {
        const statusType = StatusTypes.find(type => type.id === status);

        if (!statusType) {
            return DEFAULT_TAG;
        };

        return statusType;
    };

    const convertAmountToNumber = (amount: string) => {
        let temp: string = "";
        for (let index = 0; index < amount.length; index++) {
            if (amount[index] != '.') {
                temp += amount[index];
            }
        }
        return Number(temp);
    };

    const comparisonTwoString = (a: string, b: string) => {
        return a.localeCompare(b);
    }
    const columns: TableColumnsType<any> = [
        {
            title: t('user.activity'),
            dataIndex: "activity",
            key: "activity",
            className: "column__item activity",
            sorter: (a, b) => comparisonTwoString(a.activity, b.activity),
            render: (text) => <span className="content-table">{text}</span>
        },
        {
            title: t("user.amount"),
            dataIndex: "amount",
            key: "amount",
            className: "column__item amount",
            sorter: (a, b) => convertAmountToNumber(a.amount) - convertAmountToNumber(b.amount),
            render: (text) => <span className="content-table">{ convertNumericToFormattedAmount(text)}</span>
        },
        {
            title: t("user.status"),
            dataIndex: "status",
            key: "status",
            className: "column__item status",
            sorter: (a, b) => a.status - b.status,
            render: (text) => <Tag color={renderTag(text).color} style={{ marginBottom: 0 }}>{renderTag(text).name}</Tag>,
        },
        {
            title: t("user.createdAt"),
            dataIndex: "createAt",
            key: "createAt",
            className: "column__item created",
            render: (daytime) => <div>
                <div className="content-table">{moment(daytime).format("DD/MM/YYYY")}</div>
                <div className="time-table">{moment(daytime).format("HH:mm:ss")}</div>
            </div>,
            sorter: (a, b) => moment(a.createAt).unix() - moment(b.createAt).unix()
        },

        ...actionColumns,
    ];

    return (
       <div className="detail-budget-table">
         <Table
            {...props}
            rowKey="id"
            dataSource={data}
            columns={columns}
            pagination={pagination}
            className="gx-table-responsive budget-list"
        />
       </div>
    );
};

BudgetTable.defaultProps = {
    data: [],
    actionColumns: [],
};

export default BudgetTable;
