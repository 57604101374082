import { Avatar, Timeline } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserApi } from "../../../../apis/user.api";
import moment from "moment";
import { generateImage } from "../../../../utils/app.utils";
import { useUserDetailContext } from "../../../../pages/users/user-detail";

const recentActivity = [
    {
        id: 1,
        day: 'Today',
        tasks: [
            {
                id: 1,
                name: 'Mila Alba',
                title: [<span className="gx-link" key={1}>Mila Alba</span>, ' left a 5 star review on ',
                <span className="gx-link" key={2}>Albama’s House</span>],
                avatar: "https://via.placeholder.com/150",
                imageList: [],
            },
            {
                id: 2,
                name: 'Bob Builder',
                title: ['Callback request from ', <span key={3} className="gx-link">Bob Builder</span>, ' for the property ',
                    <span className="gx-link" key={4}>Dimitri House</span>],
                avatar: "https://via.placeholder.com/150",
                imageList: [],
            },
            {
                id: 3,
                name: 'Tom Moody',
                title: ['Congratulations to ', <span key={5} className="gx-link">Tom Moody</span>,
                    ' for joining 10+ club '],
                avatar: "https://via.placeholder.com/150",
                imageList: [],
            },
            {
                id: 4,
                name: 'Norman Dolphi',
                title: ['Norman Dolphi is looking for a house in New Jersy, USA'],
                avatar: '',
                imageList: [],
            }
        ]
    },
    {
        id: 2,
        day: 'Yesterday',
        tasks: [
            {
                id: 5,
                name: 'Kily Johns',
                title: ['Agent ',
                    <span key={6} className="gx-link">Kily Johns</span>, ' has added 7 new photos to the property ',
                    <span key={7} className="gx-link">Albama’s House</span>],
                avatar: '',
                imageList: ["https://via.placeholder.com/150", "https://via.placeholder.com/150", "https://via.placeholder.com/150"],
            },
            {
                id: 6,
                name: 'Tom Moody',
                title: ['Welcome to a new agent ', <span className="gx-link" key={8}>Tom Moody in the Company</span>],
                avatar: "https://via.placeholder.com/150",
                imageList: [],
            },
            {
                id: 7,
                name: 'Oliver Shorter',
                title: [<span key={9} className="gx-link">Oliver Shorter</span>, ' is looking for an office space in ',
                <span key={10} className="gx-link">Colorado, USA</span>],
                avatar: "https://via.placeholder.com/150",
                imageList: [],
            }
        ]
    }];

interface RecentActivityProps {
}

function getName(task: any) {
    if (!task.userAvatar) {
        let nameSplit = task?.userName?.split(" ");
        const initials = nameSplit?.[0]?.charAt(0)?.toUpperCase() || 'A';
        return <Avatar className="gx-size-40 gx-bg-primary">{initials}</Avatar>
    } else {
        return <Avatar className="gx-size-40" src={generateImage(task.userAvatar)} />;
    }
}

const RecentActivity: React.FunctionComponent<RecentActivityProps> = (props) => {
    const { t } = useTranslation();

    const { userInfoId } = useUserDetailContext();

    const [activities, setActivities] = useState([]);

    const loadUserActivity = async () => {
        const result = await UserApi.getUserActivity({ userId: userInfoId, pageIndex: 1, pageSize: 20 });
        setActivities(result.data?.records);
    };

    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    const todayActivities = useMemo(() => {
        return activities?.filter((activity: any) => {
            return moment(activity.createdOn).isSame(today, 'day');
        })
    }, [activities, today]);

    const yesterdayActivities = useMemo(() => {
        return activities?.filter((activity: any) => {
            return moment(activity.createdOn).isSame(yesterday, 'day');
        })
    }, [activities, yesterday]);

    const otherActivities = useMemo(() => {
        return activities?.filter((activity: any) => {
            return !moment(activity.createdOn).isSame(today, 'day') && !moment(activity.createdOn).isSame(yesterday, 'day');
        })
    }, [activities, yesterday, today]);

    useEffect(() => {
        loadUserActivity()
    }, []);
    return (
        <div style={{ marginTop: '8px' }}>

            {!!todayActivities?.length && (<div className="gx-timeline-info">
                <h4 className="gx-timeline-info-day">{t('common.today')}</h4>

                <Timeline>
                    {todayActivities.map((activity: any, index: number) => {

                        return <Timeline.Item key={"timeline" + index} dot={
                            getName(activity)
                        }>
                            <p className="gx-mb-0" style={{ marginTop: '12px', fontWeight: '500' }}>
                                { activity?.description }
                            </p>
                        </Timeline.Item>
                    })}
                </Timeline>
            </div>)}

            {!!yesterdayActivities?.length && (<div className="gx-timeline-info">
                <h4 className="gx-timeline-info-day">{t('common.yesterday')}</h4>

                <Timeline>
                    {yesterdayActivities.map((activity: any, index: number) => {

                        return <Timeline.Item key={"timeline" + index} dot={
                            getName(activity)
                        }>
                            <p className="gx-mb-0" style={{ marginTop: '12px', fontWeight: '500' }}>
                                { activity?.description }
                            </p>
                        </Timeline.Item>
                    })}
                </Timeline>
            </div>)}

            {!!otherActivities?.length && (<div className="gx-timeline-info">
                <h4 className="gx-timeline-info-day">{t('common.many-date-before')}</h4>

                <Timeline>
                    {otherActivities.map((activity: any, index: number) => {

                        return <Timeline.Item key={"timeline" + index} dot={
                            getName(activity)
                        }>
                            <p className="gx-mb-0" style={{ marginTop: '12px', fontWeight: '500' }}>
                                { activity?.description }
                            </p>
                        </Timeline.Item>
                    })}
                </Timeline>
            </div>)}
        </div>
    );
};

export default RecentActivity;
