import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Form, Input, Modal, Upload, UploadFile, UploadProps } from "antd";

import { EditOutlined } from "@ant-design/icons";

import "./index.scss";
import { generateImage } from '../../../../../utils/app.utils';
import { RcFile } from 'antd/lib/upload';

interface Props {
  initialData?: any;
  onSave?: (values: any) => void;

  [key: string]: any;
}

const EditInfoForm = (props: Props) => {
  // ultils
  const [form] = Form.useForm();
  const { t } = useTranslation();
  
  // props
  const { initialData, onSave, ...modalProps } = props;

  // state data
  const [fileList, setFileList] = useState<any>([]);

  const handleFormSubmit = () => {
    form.submit();
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onSubmit = (values: any) => {
    if (onSave) {
      delete values.avatar;

      const file = fileList?.[0]?.originFileObj;

      onSave({
        ...values,
        file: file,
      })
    }
  };

  const onModalClose = () => {
    form.resetFields();
    setFileList([]);
  }

  const beforeUpload = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList((prev: any) => [{ ...prev[0], url: reader.result }]);
    };

    return false;
  };

  const loadForm = () => {
    form.setFieldValue('fullName', initialData.fullName);
    setFileList([{
        url: generateImage(initialData.avatar)
    }])
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
    if (modalProps.open && initialData) {
      loadForm();
    };
  }, [modalProps.open, initialData]);

  return (
    <Modal
      {...modalProps}
      closable
      okText={t("common.save")}
      title={t("user.editInfo")}

      afterClose={onModalClose}
      onOk={handleFormSubmit}
    >
      <div className="edit-info-form">
        <Form
          form={form}
          onFinish={onSubmit}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            name="avatar"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            className="form__upload-avatar"
          >
              <Upload
                maxCount={1}
                showUploadList={false}
                accept='image/png, image/jpeg'
                listType="picture-card"
                beforeUpload={beforeUpload}
                onChange={onChange}
                onPreview={onPreview}
              >
                <div className="avatar">
                  <Avatar className="img" size="large" src={fileList?.[0]?.url} alt="img" />
                  <div className="edit-icon">
                    <EditOutlined />
                  </div>
                </div>
              </Upload>
          </Form.Item>

          <Form.Item
            name="fullName"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={t("user.yourName")}
            className="form__input"
            rules={[{ required: true, message: t("user.validate.name") }]}
          >
            <Input placeholder={t("user.yourName")} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditInfoForm;
