const FashionIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={34} height={36} viewBox="0 0 34 36" fill="none">
            <path d="M16.8029 3.59963C16.4716 3.59963 16.2029 3.86829 16.2029 4.19964V6.16753L13.0499 7.21813L7.08269 8.4117C7.01033 8.42605 6.94089 8.45359 6.8782 8.49315L0.278121 12.6932C-0.0016671 12.871 -0.0839922 13.2422 0.0938418 13.522L4.29389 20.1221C4.3894 20.2712 4.54555 20.3705 4.72104 20.3937C4.89683 20.4168 5.07349 20.3614 5.20415 20.2419L6.6028 18.9742V35.4C6.6028 35.7313 6.87146 36 7.20281 36H26.403C26.7344 36 27.003 35.7313 27.003 35.4V19.1025L28.4234 20.2642C28.552 20.3711 28.7198 20.4183 28.8854 20.3937C29.0506 20.3714 29.1992 20.2806 29.2944 20.1435L33.4944 14.1434C33.6822 13.8756 33.6204 13.5068 33.3558 13.3143L26.7558 8.51424C26.6857 8.46326 26.6055 8.4284 26.5208 8.4117L20.5559 7.21813L17.4029 6.16753V4.71439C18.6076 4.40033 19.3711 3.21642 19.1599 1.98946C18.9486 0.762784 17.8333 -0.097968 16.5931 0.00896689C15.3527 0.115902 14.4011 1.15478 14.4029 2.39962C14.4029 2.73097 14.6715 2.99963 15.0029 2.99963C15.3342 2.99963 15.6029 2.73097 15.6029 2.39962C15.6029 1.73692 16.1402 1.19961 16.8029 1.19961C17.4656 1.19961 18.0029 1.73692 18.0029 2.39962C18.0029 3.06232 17.4656 3.59963 16.8029 3.59963ZM16.8029 7.2319L19.7611 8.21951C19.5586 9.69814 18.2953 10.8003 16.8029 10.8003C15.3105 10.8003 14.0475 9.69814 13.8448 8.21951L16.8029 7.2319ZM32.174 13.9383L28.6829 18.9262L26.7836 17.3717C26.604 17.2243 26.3556 17.1938 26.1458 17.2934C25.9363 17.3928 25.8027 17.6043 25.803 17.8366V34.8H7.80282V17.6175C7.80282 17.3799 7.66278 17.1648 7.44568 17.0687C7.2283 16.9726 6.97488 17.0137 6.79909 17.1736L4.92642 18.8764L1.43067 13.384L7.43074 9.56601L12.6629 8.51951C13.0122 10.5322 14.7586 12.0018 16.8012 12.0018C18.8438 12.0018 20.5902 10.5322 20.9394 8.51951L26.1546 9.56308L32.174 13.9383Z" fill="url(#paint0_linear_1256_26591)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26591" x1="1.39564e-07" y1="13.4328" x2="34.393" y2="20.7624" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FashionIcon;
