const CosmeticIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={35} height={38} viewBox="0 0 35 38" fill="none">
            <path d="M32.6562 38H25.5312C25.3738 38 25.2228 37.9374 25.1115 37.826L23.924 36.6385C23.8126 36.5272 23.75 36.3762 23.75 36.2188V23.1562C23.75 22.9988 23.8126 22.8478 23.9239 22.7364C24.0353 22.6251 24.1863 22.5625 24.3438 22.5625H33.8438C34.0012 22.5625 34.1522 22.6251 34.2636 22.7364C34.3749 22.8478 34.4375 22.9988 34.4375 23.1562V36.2188C34.4375 36.3762 34.3749 36.5272 34.2635 36.6385L33.076 37.826C32.9647 37.9374 32.8137 38 32.6562 38ZM25.7771 36.8125H32.4104L33.25 35.9729V23.75H24.9375V35.9729L25.7771 36.8125Z" fill="url(#paint0_linear_1256_26864)" />
            <path d="M33.25 23.1562H32.0625V17.8125H26.125V23.1562H24.9375V17.2188C24.9375 17.0613 25.0001 16.9103 25.1114 16.7989C25.2228 16.6876 25.3738 16.625 25.5312 16.625H32.6562C32.8137 16.625 32.9647 16.6876 33.0761 16.7989C33.1874 16.9103 33.25 17.0613 33.25 17.2188V23.1562Z" fill="url(#paint1_linear_1256_26864)" />
            <path d="M32.0625 17.2189H30.875V13.3085L28.326 10.7595C28.243 10.6765 28.1372 10.62 28.022 10.5971C27.9069 10.5742 27.7875 10.5859 27.679 10.6309C27.5706 10.6758 27.4778 10.7519 27.4126 10.8495C27.3474 10.9471 27.3125 11.0619 27.3125 11.1793V17.2189H26.125V11.1793C26.1251 10.8271 26.2296 10.4828 26.4253 10.1899C26.621 9.89704 26.8992 9.66879 27.2246 9.53401C27.5501 9.39922 27.9082 9.36395 28.2536 9.43264C28.5991 9.50134 28.9165 9.67093 29.1656 9.91996L31.8885 12.6429C31.9999 12.7542 32.0625 12.9052 32.0625 13.0627V17.2189Z" fill="url(#paint2_linear_1256_26864)" />
            <path d="M24.3438 24.9375H33.8438V26.125H24.3438V24.9375Z" fill="url(#paint3_linear_1256_26864)" />
            <path d="M5.34375 38H1.78125C1.62379 38 1.47279 37.9374 1.36147 37.826L0.173969 36.6385C0.0626113 36.5272 3.36285e-05 36.3762 0 36.2188V23.1562C0 22.9988 0.0625556 22.8478 0.173905 22.7364C0.285255 22.6251 0.436278 22.5625 0.59375 22.5625H6.53125C6.68872 22.5625 6.83975 22.6251 6.95109 22.7364C7.06244 22.8478 7.125 22.9988 7.125 23.1562V36.2188C7.12497 36.3762 7.06239 36.5272 6.95103 36.6385L5.76353 37.826C5.65221 37.9374 5.50121 38 5.34375 38ZM2.02706 36.8125H5.09794L5.9375 35.9729V23.75H1.1875V35.9729L2.02706 36.8125Z" fill="url(#paint4_linear_1256_26864)" />
            <path d="M0.59375 24.9375H6.53125V26.125H0.59375V24.9375Z" fill="url(#paint5_linear_1256_26864)" />
            <path d="M19.5938 17.8125H12.4688C12.3643 17.8127 12.2617 17.7854 12.1712 17.7332C12.0807 17.6811 12.0056 17.6061 11.9534 17.5156L7.20338 9.20312C7.15168 9.11275 7.12465 9.01037 7.125 8.90625C7.125 6.54417 8.06334 4.27883 9.73358 2.60858C11.4038 0.938334 13.6692 0 16.0313 0C18.3933 0 20.6587 0.938334 22.3289 2.60858C23.9992 4.27883 24.9375 6.54417 24.9375 8.90625C24.9379 9.01037 24.9108 9.11275 24.8591 9.20312L20.1091 17.5156C20.0569 17.6061 19.9818 17.6811 19.8913 17.7332C19.8008 17.7854 19.6982 17.8127 19.5938 17.8125ZM12.8131 16.625H19.2494L23.75 8.75128C23.7066 6.73332 22.8746 4.81263 21.4321 3.4008C19.9896 1.98898 18.0515 1.19835 16.033 1.19835C14.0146 1.19835 12.0765 1.98898 10.634 3.4008C9.19151 4.81263 8.35943 6.73332 8.31607 8.75128L12.8131 16.625Z" fill="url(#paint6_linear_1256_26864)" />
            <path d="M19.5938 21.375H12.4688C12.3113 21.375 12.1603 21.3124 12.0489 21.2011C11.9376 21.0897 11.875 20.9387 11.875 20.7812V17.2188C11.875 17.0613 11.9376 16.9103 12.0489 16.7989C12.1603 16.6876 12.3113 16.625 12.4688 16.625H19.5938C19.7512 16.625 19.9022 16.6876 20.0136 16.7989C20.1249 16.9103 20.1875 17.0613 20.1875 17.2188V20.7812C20.1875 20.9387 20.1249 21.0897 20.0136 21.2011C19.9022 21.3124 19.7512 21.375 19.5938 21.375ZM13.0625 20.1875H19V17.8125H13.0625V20.1875Z" fill="url(#paint7_linear_1256_26864)" />
            <path d="M18.4062 38H13.6562C13.4988 38 13.3478 37.9374 13.2365 37.826L12.049 36.6385C11.9376 36.5272 11.875 36.3762 11.875 36.2188V20.7812H13.0625V35.9729L13.9021 36.8125H18.1604L19 35.9729V20.7812H20.1875V36.2188C20.1875 36.3762 20.1249 36.5272 20.0135 36.6385L18.826 37.826C18.7147 37.9374 18.5637 38 18.4062 38Z" fill="url(#paint8_linear_1256_26864)" />
            <path d="M12.4688 34.4375H19.5938V35.625H12.4688V34.4375Z" fill="url(#paint9_linear_1256_26864)" />
            <path d="M15.4375 8.3125H16.625V17.2188H15.4375V8.3125Z" fill="url(#paint10_linear_1256_26864)" />
            <path d="M11.886 9.62744L13.0426 9.36025L14.825 17.0761L13.6684 17.3433L11.886 9.62744Z" fill="url(#paint11_linear_1256_26864)" />
            <path d="M17.2363 17.0991L19.0187 9.38327L20.1753 9.65046L18.3929 17.3663L17.2363 17.0991Z" fill="url(#paint12_linear_1256_26864)" />
            <path d="M5.34375 16.0312H1.78125C1.62378 16.0312 1.47276 15.9687 1.36141 15.8573C1.25006 15.746 1.1875 15.595 1.1875 15.4375V10.0938C1.1875 9.46386 1.43772 8.85977 1.88312 8.41437C2.32852 7.96897 2.93261 7.71875 3.5625 7.71875C4.19239 7.71875 4.79648 7.96897 5.24188 8.41437C5.68728 8.85977 5.9375 9.46386 5.9375 10.0938V15.4375C5.9375 15.595 5.87494 15.746 5.76359 15.8573C5.65225 15.9687 5.50122 16.0312 5.34375 16.0312ZM2.375 14.8438H4.75V10.0938C4.75 9.77881 4.62489 9.47676 4.40219 9.25406C4.17949 9.03136 3.87744 8.90625 3.5625 8.90625C3.24756 8.90625 2.94551 9.03136 2.72281 9.25406C2.50011 9.47676 2.375 9.77881 2.375 10.0938V14.8438Z" fill="url(#paint13_linear_1256_26864)" />
            <path d="M2.96875 15.4375H4.15625V23.1562H2.96875V15.4375Z" fill="url(#paint14_linear_1256_26864)" />
            <path d="M1.78125 12.4688H5.34375V13.6562H1.78125V12.4688Z" fill="url(#paint15_linear_1256_26864)" />
            <path d="M1.78125 10.0938H5.34375V11.2812H1.78125V10.0938Z" fill="url(#paint16_linear_1256_26864)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26864" x1="23.75" y1="28.3228" x2="34.9068" y2="30.0863" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26864" x1="24.9375" y1="19.062" x2="33.1393" y2="21.4453" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_26864" x1="26.125" y1="12.3165" x2="32.2928" y2="13.3857" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_26864" x1="24.3438" y1="25.3806" x2="26.688" y2="29.6624" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_26864" x1="2.95923e-08" y1="28.3228" x2="7.53998" y2="29.1173" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_26864" x1="0.59375" y1="25.3806" x2="3.35213" y2="28.5295" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_26864" x1="7.125" y1="6.64646" x2="25.24" y2="10.7824" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint7_linear_1256_26864" x1="11.875" y1="18.3974" x2="19.5449" y2="21.4619" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint8_linear_1256_26864" x1="11.875" y1="27.2062" x2="20.6626" y2="28.1747" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint9_linear_1256_26864" x1="12.4688" y1="34.8806" x2="15.119" y2="38.5111" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint10_linear_1256_26864" x1="15.4375" y1="11.6357" x2="16.7069" y2="11.6744" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint11_linear_1256_26864" x1="12.5511" y1="12.5065" x2="13.797" y2="12.2632" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint12_linear_1256_26864" x1="17.6679" y1="17.1988" x2="22.0301" y2="15.5869" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint13_linear_1256_26864" x1="1.1875" y1="10.8204" x2="6.18491" y2="11.4724" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint14_linear_1256_26864" x1="2.96875" y1="18.3176" x2="4.2378" y2="18.3622" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint15_linear_1256_26864" x1="1.78125" y1="12.9118" x2="4.37584" y2="14.689" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint16_linear_1256_26864" x1="1.78125" y1="10.5368" x2="4.37584" y2="12.314" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>

    )
};

export default CosmeticIcon;