import { Col, Row } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ThemeApis } from "../../../apis/theme.api";
import { getCategoriesTotal, themeCategoriesSelector } from "../../../states/theme";

import ThemeChart from "../../../views/theme/theme-chart";
import CategoryList from "../../../views/theme/category-list";
import WelcomeCard from "../../../components/common/welcome-card";

import { NUMBER_UTIL } from "../../../utils/number.utils";

const PAGE_SIZE = 100

function ThemeOverviewPage() {
  const { t } = useTranslation();
  // state data
  const [ page, setPage ] = useState(1);
  const [themeOverView, setThemeOverView] = useState<any>(null);

  // recoil
  const themeCategoriesTotal = useRecoilValue(getCategoriesTotal);
  const setThemeCategories = useSetRecoilState(themeCategoriesSelector);

  const payloadCategories = useMemo(() => {
    return {
      PageIndex: page,
      PageSize: PAGE_SIZE,
    }
  }, [page]);

  const onLoadPage = async () => {
    const [themeOverviews, categories] = await Promise.all([
      ThemeApis.getThemeOverview(),
      ThemeApis.getThemeCategories(payloadCategories),
    ]);

    setThemeOverView(themeOverviews.data);

    if (categories?.data) {
      setThemeCategories(categories.data);
    }
  };

  useEffect(() => {
    onLoadPage();
  }, []);

  return (
    <Row className="theme-overview">
      <Col xs={24}>
        <div className="gx-card">
          <div className="gx-card-body">
            <Row>
              <Col xl={6} lg={10} md={12} sm={12} xs={24}>
                <WelcomeCard unit={t("theme.themes")} number={NUMBER_UTIL.formatInput(themeOverView?.totalThemes || 0)} />
              </Col>

              <Col
                xl={18}
                lg={14}
                md={24}
                sm={24}
                xs={24}
                className="gx-visit-col"
              >
                <ThemeChart data={themeOverView?.themeCharts} />
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <CategoryList />
      </Col>
    </Row>
  );
}

export default ThemeOverviewPage;
