import { useEffect, useMemo, useState } from "react";
import { Button, PaginationProps } from "antd";
import { Card, Col, Pagination, Row } from "antd";

import FilterBar from "../../../../views/theme/filter-bar";
import CategoryDetailHeader from "../../../../views/theme/category-detail/header";
import CategoriesList from "../../../../views/theme/category-detail/categories/categories";

import { SITE_PAGE_SIZE, VIEW_ITEM } from "../../../../constants/app-constants";

import "./index.scss";
import CategoryFilterForm from "../../../../views/theme/category-filter";
import { useRecoilState } from "recoil";
import {
  themeCategoriesSelector,
  themeSiteByCategory,
} from "../../../../states/theme";
import { Link, useLocation, useParams } from "react-router-dom";
import { ThemeApis } from "../../../../apis/theme.api";
import SiteFilterDrawer from "../../../../views/theme/sites-filter-drawer";
import { t } from "i18next";
import TableNoData from "../../../../components/common/table/table-no-data";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

const CategoryDetailPage = () => {
  const { t } = useTranslation();
  // ultils
  const params = useParams();

  // state data
  const [filter, setFilter] = useState<any>({});
  const [view, setView] = useState<number>(VIEW_ITEM.grid);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [ratingFilterData, setRatingFilterData] = useState<any>(null);
  const router = useLocation()

  // recoil
  const [themeSites, setThemeSites] = useRecoilState(themeSiteByCategory);
  const [themeCategories, setThemeCategories] = useRecoilState(
    themeCategoriesSelector
  );

  const onChangeView = (view: number) => {
    setView(view);
  };

  const handlePaginationChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  // computed
  const pagination: any = useMemo(() => {
    return {
      pageSize: SITE_PAGE_SIZE,
      current: currentPage,
      total: themeSites.sites?.totalRecords || 0,
      onChange: handlePaginationChange,
    };
  }, [currentPage, themeSites.sites?.totalRecords]);

  const themeSiteFilter = useMemo(() => {
    return {
      ...filter,
      categoryId: router.pathname === '/themes/category/all' ? '' : params.category,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
    };
  }, [filter, router.pathname, params.category, pagination]);

  const handleFilterSubmit = (filters: any) => {
    setFilter({ ...filter, ...filters });
  };

  const handleFilterButtonClick = async () => {
    const response = await ThemeApis.themeFilterData();
    if (response?.data) {
      setRatingFilterData(response.data);
      setOpenFilter(true);
    }
  };

  const handleSortType = (event: any) => {
    const { value } = event.target;

    if (value) {
      setFilter({ ...filter, themeFilterType: value });
    }
  };

  const handleSearchThemeSite = (search: string) => {
    setFilter({ ...filter, name: search });
  };

  const hanldeFilterFormReset = () => {
    setFilter({});
    setOpenFilter(false);
  };

  const hanldeDrawerClose = () => {
    setOpenFilter(false);
  };

  const getSiteByCategory = async () => {
    const result = await ThemeApis.getSiteByThemeCategory(themeSiteFilter);

    if (result?.data) {
      setThemeSites({ ...themeSites.category, site: result.data });

      if (openFilter) {
        setOpenFilter(false);
      }
    }
  };

  const getThemeCategorise = async () => {
    const result = await ThemeApis.getThemeCategories();

    if (result?.data) {
      setThemeCategories(result.data);
    }
  };

  // mounted
  useEffect(() => {
    getSiteByCategory();
  }, [themeSiteFilter]);

  useEffect(() => {
    if (!themeCategories.length) {
      getThemeCategorise();
    }

    if (params.category && !!themeCategories.length) {
      setThemeSites({ categoryId: params.category, site: null });
    }
  }, [params.category, themeCategories]);

  return (
    <Card
      title={<CategoryDetailHeader title={themeSites.category?.name || t('common.all')} />}
      bordered
      className="website-sites-page"
    >
      <Row>
        <Col span={24}>
          <FilterBar
            view={view}
            onView={onChangeView}
            onSelectType={handleSortType}
            onSearch={handleSearchThemeSite}
            onFilter={handleFilterButtonClick}
          />
        </Col>

        <Col span={24}>
          <div className="category-detail__list">
            {!!themeSites?.sites?.records?.length && <CategoriesList view={view} />}
            {!themeSites?.sites?.records?.length && (
              <TableNoData>
                <>
                  <p>
                    {t("theme.no-theme-description")}
                  </p>

                  <Link to={ROUTE_PATHS.themeUpload}>
                    <Button type="primary">
                      {t("theme.upload-theme")}
                    </Button>
                  </Link>
                </>
              </TableNoData>
            )}
          </div>

          {!!themeSites?.sites?.records?.length && (
            <div className="category-detail__pagination">
              <Pagination {...pagination} />
            </div>
          )}
        </Col>
      </Row>

      <SiteFilterDrawer
        open={openFilter}
        ratingFilterData={ratingFilterData}
        onClose={hanldeDrawerClose}
        onFilter={handleFilterSubmit}
        onReset={hanldeFilterFormReset}
      />
    </Card>
  );
};

export default CategoryDetailPage;
