import { Button } from "antd";
import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import PopConfirmAction from "../../../../components/controls/popconfirm-action";
import { formatHours } from "../../../../utils/date.ultis";

import "./index.scss";

interface WordpressItemProps {
  data?: any;
  onClaim?: () => void;
  onDelete?: () => void;
}

const WordpressItem: FunctionComponent<WordpressItemProps> = ({
  data,
  onClaim,
  onDelete,
}) => {
  const { t } = useTranslation();

  const buttonRender = (date: string) => {
    if (!formatHours(date)) {
      return (
        <Button className="action" type="primary" onClick={onClaim}>
          {t("theme.claim")}
        </Button>
      );
    }

    return (
      <PopConfirmAction onConfirm={onDelete}>
        <Button className="action" danger>
          {t("common.delete")}
        </Button>
      </PopConfirmAction>
    );
  };

  return (
    <div className="theme-wordpress-item">
      <div className="info">
        <div>
          <div className="gx-link title">{data.name}</div>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: t("theme.wordpressCrawled", {
                  crawledDate: formatHours(data.crawledDate),
                }),
              }}
            />
            <span className="text-blue">{data.owner}</span>
          </div>
        </div>
        <div>{buttonRender(data.crawledDate)}</div>
      </div>
      <div className="more-info">
        <div className="digest bold">
          {t("theme.digest")}: <span className="text-blue">{data.digest}</span>
        </div>
        <div>
          <div className="bold">
            OR/ARCH: <span>{data.osarch}</span>
          </div>
        </div>
        <div>
          <div className="bold">
            {t("theme.compressedSize")}: <span>{data.fileSize}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordpressItem;
