import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useState } from "react";

import { RoleEnum } from "../../../enums/app.enum";

import ProtectedAction from "../../../components/action-protected";
import ItemCard from "../../../views/admin/website-plan/item-card";

import './index.scss';
import { AdminApis } from "../../../apis/admin.api";

const sampleStaterData = {
    price: 3000,
    level: 'STATER',
    list: [
        'Easy translate',
        'Awesome Google Fonts',
        'Hotel Booking System',
        'Email Compose Interface',
        'Location Finder App',
    ]
};

const sampleBusinessData = {
    price: 5000,
    level: 'BUSINESS',
    list: [
        'Easy translate',
        'Awesome Google Fonts',
        'Hotel Booking System',
        'Email Compose Interface',
        'Location Finder App',
    ]
};

const sampleProfesionalData = {
    price: 10000,
    level: 'PROFESIONAL',
    list: [
        'Easy translate',
        'Awesome Google Fonts',
        'Hotel Booking System',
        'Email Compose Interface',
        'Location Finder App',
    ]
};

const WebsitePlanPage: FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const [starter, setStaters] = useState<any>(sampleStaterData);
    const [business, setBusiness] = useState<any>(sampleBusinessData);
    const [professional, setProfessional] = useState<any>(sampleProfesionalData);

    const onUpdateStaterData = (value: any) => {
        setStaters((previousState: any) => {
            return {...previousState, ...value};
        });
    };

    const onUpdateBusinessData = (value: any) => {
        setBusiness((previousState: any) => {
            return {...previousState, ...value};
        });
    };

    const onUpdateProfessionalData = (value: any) => {
        setProfessional((previousState: any) => {
            return {...previousState, ...value};
        });
    };

    const loadWebstiePlanData = async () => {
        const result = await AdminApis.getWebsitePlan();

        if (result?.data) {
            const [starter, business, professional] = result.data;

            setStaters(starter);
            setBusiness(business);
            setProfessional(professional);
        };
    };

    useEffect(() => {
        loadWebstiePlanData();
    }, []);

    return (
        <ProtectedAction
            roles={[]}
        >
            <Card title={t('user.overview')} bordered className="website-sites-page website-plan">
                <div className="gx-price-tables gx-pt-default website-plan__content">
                    <Row>
                        <Col xl={8} lg={24} md={8} xs={24} className=" website-plan__item">
                            <ItemCard
                                footerStyle=""
                                styleName="gx-package"
                                itemStyle="gx-package-body"
                                headerStyle="gx-package-header gx-bg-primary gx-text-white"
                                data={starter}
                                onUpdateData={onUpdateStaterData}
                            />
                        </Col>

                        <Col xl={8} lg={24} md={8} xs={24} className=" website-plan__item">
                            <ItemCard
                                footerStyle="gx-btn-primary"
                                itemStyle="gx-package-body gx-text-white"
                                headerStyle="gx-package-header gx-bg-primary gx-text-white"
                                styleName="gx-package gx-bg-primary-light gx-highlight gx-border-0"
                                data={business}
                                onUpdateData={onUpdateBusinessData}
                            />
                        </Col>

                        <Col xl={8} lg={24} md={8} xs={24} className=" website-plan__item">
                            <ItemCard
                                footerStyle=""
                                styleName="gx-package"
                                itemStyle="gx-package-body"
                                headerStyle="gx-package-header gx-bg-primary gx-text-white"
                                data={professional}
                                onUpdateData={onUpdateProfessionalData}
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        </ProtectedAction>
    );
};

export default WebsitePlanPage;
