import { FunctionComponent } from "react";
import CreateLoading from "../create-loading";

import createSiteLoading from '../../../assets/gifs/create-site-loading.gif';

import './index.scss';

const CreateLoadingPopup: FunctionComponent<any> = () => {
    return (
        <div className="create-site-loading">
            <div className="loading">
                <img src={createSiteLoading} alt="Create site loading..." />
                <div className="loading-text">
                    Please wait for processing and don't reload page
                </div>
            </div>
        </div>
    );
};

export default CreateLoadingPopup;
