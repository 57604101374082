export const AdminPrefixRouter = "/admin";
export const ROUTE_PATHS = {
  Home: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  verifyCode: "/verify-code",
  complete: "/sign-up/complete",
  termCondition: '/term-condition',
  myAccount: '/my-account',
  dashboardOverview: "/dasboard/overview",
  dashboardRevenue: "/dasboard/revenue",
  dashboardBudget: "/dasboard/budget",
  dashboardUser: "/dasboard/user",
  websiteOverview: "/website/overview",
  websiteSites: "/website/sites",
  websiteDatabase: "/website/database",
  websiteCreateSites: "/website/sites/create",
  usersOverview: "/users/overview",
  usersList: "/users/list",
  usersBudget: "/users/budget/:userId",
  myBudget: "/users/budget",
  userDetail: "/users-detail/:userId",
  themesOverView: "/themes/overview",
  themesCategoryDetail: "/themes/category/:category",
  themeDetail: "/themes/category/overview-sites/:themeId",
  themeUpload: "/themes/categories/upload",
  editSiteOverviewUpload: "/themes/overview-sites/edit/:siteId",
  reviewSiteOverviewUpload: "/themes/overview-sites/review/:siteId",
  themesTemplate: "/themes/template",
  themesPlugin: "/themes/plugin",
  themesPluginDetail: "/themes/plugin/:siteId",
  editThemePlugin: "/themes/plugin/:siteId/edit",
  reviewThemePlugin: "/themes/plugin/:siteId/review",
  uploadThemePlugin: "/themes/plugin/upload",
  themesWordpress: "/themes/wordpress",
  themesSampleData: "/themes/sample-data",
  uploadThemeSampleData: "/themes/sample-data/upload",
  editThemeSampleData: "/themes/sample-data/:siteId/edit",
  reviewThemeSampleData: "/themes/sample-data/:siteId/review",
  themesSampleDataDetail: "/themes/sample-data-site/:siteId",
  themesMarketing: "/themes/marketing",
  adminGeneral: "/admin/general",
  adminKubebernet: "/admin/kubernet",
  adminDockers: "/admin/dockers",
  adminDatabases: "/admin/databases",
  adminPreference: "/admin/preference",
  adminMessages: "/admin/messages",
  adminHealthCheck: "/admin/health-check",
  adminUpdateChanged: "/admin/update-changed",
  adminMailer: "/admin/mailer",
  adminUpdateSystem: "/admin/update-system",
  adminEmailTemplate: "/admin/email-template",
  addNewEmailTemplate: "/admin/email-template/add-new",
  adminCloudflare: "/admin/cloudflare",
  adminWebsitePlan: "/admin/website-plan",
  notFound: "/*",
};
