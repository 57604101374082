import { Link } from "react-router-dom";
import { FunctionComponent, useState } from "react";
import { Button, Col, Divider, Row, Switch } from "antd";

import Widget from "../../../components/common/widget";
import MailerForm from "../../../views/admin/mailer/form";

import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../constants/router.constants";

import './index.scss';
import { useForm } from "antd/lib/form/Form";

const sampleFormData = {
    smtpHost: "smtp.sendgrid.net",
    smtpPost: "559",
    emailFrom: "namvo.ito@gmail.com",
    smtpLogin: "apiKey",
    smtpPassword: "123123123",
    scureConnection: "starttls",
};

const AdminMailer: FunctionComponent<any> = () => {
    const [form] = useForm();
    const { t } = useTranslation();

    const [ enabledNotifier, setEnabledNotifier ] = useState<boolean>(true);

    const handleEnabledSwitchChange = (checked: boolean) => {
        setEnabledNotifier(checked);
    };

    const handleSaveButtonClick = () => {
        form.submit();
    };

    const handleMailerFormSubmit = (value: any) => {
        console.log('handleMailerFormSubmit', value);
    };

    return (
        <Row className="admin-mailer mailer">
            <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                <Widget title={t('admin.emailNotifierTitle')}>
                    <MailerForm 
                        form={form} 
                        initialValues={sampleFormData}
                        className="mailer__form"
                        onFinish={handleMailerFormSubmit}
                    />

                    <Divider />

                    <p className="mailer__description">{t('admin.adminMailerDescription')} <Link to={ROUTE_PATHS.adminEmailTemplate}><span className="">{t('common.here')}</span></Link></p>
                </Widget>
            </Col>

            <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                <Widget>
                    <Row className="mailer__toggle" justify="start" align="middle">
                        <Col className="mailer__toggle--label">
                            {t('admin.mailerEnabledLabel')}
                        </Col>

                        <Col className="mailer__toggle--switch">
                            <Switch checked={enabledNotifier} onChange={handleEnabledSwitchChange}/>
                        </Col>
                    </Row>

                    <Row className="mailer__button-group" justify="center" align="middle">
                        <Col xs={12} md={16} xl={12} className="mailer__button--item mailer__button--connection">
                            <Button type="default">{t('admin.testConnection')}</Button>
                        </Col>

                        <Col xs={12} md={8} xl={12} className="mailer__button--item mailer__button--save">
                            <Button onClick={handleSaveButtonClick} type="primary">{t('common.save')}</Button>
                        </Col>
                    </Row>
                </Widget>
            </Col>
        </Row >
    );
};

export default AdminMailer;