import moment from "moment";
import Cookies from 'js-cookie'
import {
  LOCAL_COOKIE_KEY,
  LOCAL_STORAGE_KEY,
} from "../constants/app-constants";
import { UserProfileModel } from "../models/user.model";

export const LocalUtils = {
  get(key: string) {
    return localStorage.getItem(key);
  },

  set(key: string, value: string | any) {
    localStorage.setItem(key, value);
  },

  remove(key: string) {
    return localStorage.removeItem(key);
  },

  setCookie(key: string, value: string, opt?: any) {
    Cookies.set(key, value, opt);
  },

  getCookie(key: string) {
    return Cookies.get(key);
  },

  removeCookie(key: string) {
    Cookies.remove(key);
  },

  clear() {
    Object.keys(LOCAL_COOKIE_KEY).forEach((item) => {
      LocalUtils.removeCookie(item);
    });
    localStorage.removeItem(LOCAL_STORAGE_KEY.IS_REMEMBER_ME);
    sessionStorage.clear();
  },

  setAuthenticatedData(data: any, isRemember: boolean = false, userInfo: any) {
    const loginExpire = moment().add(7, "days").toDate();
    const tokenExpire = moment().add(data.expiresIn - 60, "seconds").toDate();

    LocalUtils.set(LOCAL_STORAGE_KEY.IS_REMEMBER_ME, isRemember);

    if (isRemember) {
      LocalUtils.setCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN, "true", {
        expires: loginExpire,
      });

      LocalUtils.setCookie(LOCAL_COOKIE_KEY.REFRESH_TOKEN, data.refreshToken, {
        expires: loginExpire,
      });

      LocalUtils.setCookie(LOCAL_COOKIE_KEY.ACCESS_TOKEN, data.token, {
        expires: tokenExpire,
      });

      LocalUtils.setCookie(LOCAL_COOKIE_KEY.USER_INFO, JSON.stringify(userInfo), {
        expires: tokenExpire,
      });

      LocalUtils.setCookie(LOCAL_COOKIE_KEY.TOKEN_EXPIRE, JSON.stringify({tokenExpire}), {
        expires: tokenExpire,
      });

    } else {
      LocalUtils.setCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN, "true");
      LocalUtils.setCookie(LOCAL_COOKIE_KEY.ACCESS_TOKEN, data.token);
      LocalUtils.setCookie(LOCAL_COOKIE_KEY.REFRESH_TOKEN, data.refreshToken);
      LocalUtils.setCookie(LOCAL_COOKIE_KEY.USER_INFO, JSON.stringify(userInfo));
    }
  },

  setUserInfo(data: UserProfileModel) {
    try {
      const isRemember = LocalUtils.get(LOCAL_STORAGE_KEY.IS_REMEMBER_ME) === "false" ? false : true;
      if (isRemember) {
        const expire = JSON.parse(LocalUtils.getCookie(LOCAL_COOKIE_KEY.TOKEN_EXPIRE) || '');

        LocalUtils.setCookie(LOCAL_COOKIE_KEY.USER_INFO, JSON.stringify(data), {
          expires: moment(expire.tokenExpire).toDate(),
        });
      } else {
        LocalUtils.setCookie(LOCAL_COOKIE_KEY.USER_INFO, JSON.stringify(data))
      }

    } catch(error) {
      return
    }
  },

  getUserInfo(): UserProfileModel | undefined | null {
    try {
      const data = LocalUtils.getCookie(LOCAL_COOKIE_KEY.USER_INFO);
      return data ? JSON.parse(data) : undefined;
    } catch (error: any) {
      return null;
    }
  },

  getIsLogin() {
    try {
      return LocalUtils.getCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN);
    } catch(error) {
      return false;
    }
  },

  getRefeshToken() {
    try {
      return LocalUtils.getCookie(LOCAL_COOKIE_KEY.REFRESH_TOKEN) || '';
    } catch(error) {
      return '';
    }
  },

  getAccessToken() {
    try {
      return LocalUtils.getCookie(LOCAL_COOKIE_KEY.ACCESS_TOKEN) || '';
    } catch(error) {
      return '';
    }
  }
};

export default LocalUtils;
