import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AuthApi } from "../../apis/auth.api";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { commonPageTitleSelector } from "../../states/common";

import './index.scss';

interface Props {
    onCloseModal?: () => void
}

const TermCondition = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [termData, setTermData] = useState('');

    const setPageTitle = useSetRecoilState(commonPageTitleSelector);

    const getTermCondition = async () => {
        const { data } = await AuthApi.getTermandCondition();
        if (data) {
            setTermData(data);
        }
    };

    const handleButtonClick = () => {
        if(props.onCloseModal) {
            props.onCloseModal()
        }
    };

    useEffect(() => {
        setPageTitle("");
        getTermCondition();
    }, []);

    return (
        <div className="term-condition">
            {!termData &&
                <div className="term-condition__loading">
                    <Spin />
                </div>
            }
            {
                termData && <div className="term-condition__content" dangerouslySetInnerHTML={{ __html: termData }} />
            }

            <div className="term-condition__footer">
                {termData && <Button onClick={handleButtonClick} type="primary" className="term-condition__button">{t('common.ok')}</Button>}
            </div>
        </div>
    );
};

export default TermCondition;
