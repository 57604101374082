import { Button, Card } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";

// ulilty
import { RoleEnum } from "../../../enums/app.enum";
import { PAGE_SIZE, USER_BUDGET_ACTION_OPTIONS } from "../../../constants/app-constants";
import { UserBudgetApi } from "../../../apis/user-budget.api";

// component
import BudgetFilters from "../../../views/users/budget/filter";
import ProtectedAction from "../../../components/action-protected";
import BudgetTable from "../../../views/users/budget/budget-table";
import BudgetFormModal from "../../../views/users/budget/budget-form";
import BudgetTableAction from "../../../views/users/budget/budget-table-action";

import { PlusOutlined } from "@ant-design/icons";

import "./index.scss";
import { useRecoilState } from "recoil";
import { getUserInfoSelector } from "../../../states/auth";
import { useTranslation } from "react-i18next";
import { useUserDetailContext } from "../user-detail";

type Value = {
    pageSize: number,
    pageIndex: number,
    userId: number | string,
    status?: number | string,
}

const UserBudget = () => {
    const { t } = useTranslation();

    // state data
    const [totalItem, setTotalItem] = useState<number>(0);
    const [userBudgets, setUserBudgets] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [statusOption, setStatusOptions] = useState<number>(15);

    // recoil
    const [userInfo, setUserInfo] = useRecoilState(getUserInfoSelector);

    // recoil
    const { userInfoId, userData } = useUserDetailContext();
    
    const budgetHistoryActionColumn = useMemo(() => {
        if (userData) {
            return userData.userRole === RoleEnum.Accountant
        }
        
        if (userInfo) {
            return userInfo.userRole === RoleEnum.Accountant
        }
    }, [userData, userInfo]);

    // actions
    const handleTablePageChange = (value: number) => {
        setCurrentPage(value);
    };

    const openAddNewFormModal = () => {
        if (!showModal) {
            setShowModal(true);
        };
    };

    const closeAddNewFormModal = () => {
        setShowModal(false);
    };

    const onAddNewBudget = async (value: any) => {
        try {
            setLoading(true)

            const action = USER_BUDGET_ACTION_OPTIONS.find(option => option.id === value.action);

            const activity = action?.name || '';

            const payload = {
                ...value,
                activity,
                userId: userInfoId,
            };

            const result = await UserBudgetApi.addNewUserBudget(payload);

            if (result?.data) {
                if (currentPage === 1) {
                    getUserBudget();
                } else {
                    setCurrentPage(1);
                }

                setShowModal(false);
            };

        } finally {
            setLoading(false);
        }
    };

    const getUserBudget = async () => {
        try {
            setLoading(true);

            if (!userInfo.userId) {
                return;
            }

            const payload: Value = {
                userId: userInfo.userId,
                pageSize: PAGE_SIZE,
                pageIndex: currentPage,
            };

            if (statusOption) {
                payload.status = statusOption;
            }

            const result = await UserBudgetApi.getUserBudgetList(payload);

            if (result?.data) {
                setUserBudgets(result.data?.records || [])
                setTotalItem(result.data?.totalItem || 0)
            };

        } finally {
            setLoading(false);
        };
    };

    const handleDeleteActionClick = async (budgetId: string | number) => {
        if (!budgetId) {
            return;
        };

        const payload = { id: budgetId };

        const result = await UserBudgetApi.deleteUserBudget(payload);

        if (result?.data) {
            getUserBudget();
        };

    };

    const handleApproveActionClick = async (budgetId: string | number) => {
        const payload = { id: budgetId };

        const result = await UserBudgetApi.approveUserBudget(payload);

        if (result?.data) {
            getUserBudget();
        }
    };

    const onFilter = (value: number) => {
        setStatusOptions(value);
    };

    // computed
    const columns: ColumnsType<any> = [
        {
            width: '15%',
            key: "action",
            fixed: 'right',
            className: 'column__item action',
            render: (record) => {
                return (
                    <BudgetTableAction
                        onApprove={() => handleApproveActionClick(record.id)}
                        onDelete={() => handleDeleteActionClick(record.id)}
                    />
                )
            }
        },
    ];

    const paginationSetting = {
        total: totalItem,
        page: currentPage,
        onChange: handleTablePageChange,
    };

    useEffect(() => {
        getUserBudget();
    }, [currentPage, statusOption]);

    return (

        <ProtectedAction
            roles={[
                RoleEnum.User,
                RoleEnum.Mod,
                RoleEnum.Admin,
                RoleEnum.Accountant,
            ]}
        >
            <>
                <Card className="budget-page">
                    <div className="components-table-demo-control-bar">
                        <div className="search-card">
                            <BudgetFilters onFilter={onFilter} status={statusOption} />

                            <Button type="primary" onClick={openAddNewFormModal}>
                                <PlusOutlined />
                                {t('user.addNew')}
                            </Button>
                        </div>

                        <BudgetTable
                            rowKey="id"
                            className="gx-table-responsive"

                            scroll={{ x: 992 }}
                            data={userBudgets}
                            actionColumns={(budgetHistoryActionColumn && columns) || []}
                            pagination={paginationSetting}
                        />

                    </div>
                </Card>

                <BudgetFormModal open={showModal} onAdd={onAddNewBudget} onCancel={closeAddNewFormModal} loading={loading} />

            </>
        </ProtectedAction>
    );
};

export default UserBudget;
