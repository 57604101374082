import { SignUpModel } from "../models/sign-up";
import { getAsync, postAsync } from "./http-client";
import { SignInRequestModel } from "../models/sign-in.model";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth`;

function getUsers() {
  const url = `${baseUrl}/user/users`;
  return getAsync(url);
}

function signUp(model: SignUpModel) {
  const url = `${baseUrl}/authenticate/register`;
  return postAsync(url, model, false, undefined, true);
}

function signIn(model: SignInRequestModel) {
  const url = `${baseUrl}/authenticate/login`;
  return postAsync(url, model, false, undefined, true);
}

function refreshToken(request: any) {
  const url = `${baseUrl}/authenticate/refresh-token`;
  return postAsync(url, request, false, false, true);
}

function forgotPassword(email: string, phoneNumber: string) {
  const url = `${baseUrl}/authenticate/forgot-password`;
  return postAsync(url, { email, phoneNumber }, false, true, true);
}

function sendOTP(email: string,phoneNumber: string) {
  const url = `${baseUrl}/authenticate/send-otp`;
  return postAsync(url, { email, phoneNumber }, false, false, true);
}

function resetPassword(payload: any) {
  const url = `${baseUrl}/authenticate/reset-password`;
  return postAsync(url, payload, false, true, true);
}

function otpConfirmSignup( payload: any) {
  return postAsync(`${baseUrl}/authenticate/otp-comfirmation`, payload, false, true, true);
}

function getTermandCondition() {
  return getAsync(`https://stagingapi.webhub.vn/website/hardcoded-datas/term-conditions`, {}, false, true, false);
}

function getCurrencySystem() {
  return getAsync(`${baseUrl}/system`);
}

export const AuthApi = {
  signUp,
  signIn,
  sendOTP,
  getUsers,
  refreshToken,
  resetPassword,
  forgotPassword,
  otpConfirmSignup,
  getCurrencySystem,
  getTermandCondition,
};
