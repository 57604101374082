import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Button, Form, Input, InputNumber, Modal, Radio, Select } from "antd";

import i18n from "../../../../utils/i18n";
import { ActionOption, WithdrawMethod } from "../../../../enums/user.enum";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { USER_BUDGET_ACTION_OPTIONS, WITHDRAW_METHOD_TYPE } from "../../../../constants/app-constants";

import './index.scss';
import QRcode from "../../qr-code";
import paymentApis from "../../../../apis/payment.api";

const formatItemLayout = {
    labelCol: {
        span: 24,
    },

    wrapperCol: {
        span: 24,
    }
};

const initialValues: Value = {
    action: 1,
    amount: 0,
    transferTo: '',
};

const validateScheamas = {
    action: [{
        required: true,
        message: i18n.t('user.actionRequireValidateText'),
    }],

    amount: [
        {
            required: true,
            message: i18n.t('user.amountRequireValidateText'),
        },

        () => ({
            validator(_dir: any, value: any) {
                if (value === 0) {
                    return Promise.reject(new Error(i18n.t('user.amountRequireValidateText')))
                };

                if (!value) {
                    return Promise.resolve();
                };

                return Promise.resolve();
            }
        }),
    ],

    transferTo: [
        {
            required: true,
            message: i18n.t('user.transferToRequireValidateText'),
        },
    ],
};

type Value = {
    action: number,
    transferTo: string,
    amount: number | string,
};

interface BudgetFormProps {
    [key: string]: any;

    onAdd?: (value: any) => void;
};

const BudgetFormModal: FunctionComponent<BudgetFormProps> = ({ onAdd, ...props }) => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState<any>({});
    const [bankAccounts, setBankAccounts] = useState<any>([]);
    const [isQrcodeShow, setIsQrcodeShow] = useState<boolean>(false);
    const [actionNumber, setActionNumber] = useState<number>(ActionOption.Increase);
    const [withdrawToType, setWithdrawToType] = useState<number>(WithdrawMethod.BankAccount);

    const [form] = Form.useForm();

    const isTransferSelect = useMemo(() => {
        return actionNumber === ActionOption.Transfer;
    }, [actionNumber]);

    const isWithdrawSelect = useMemo(() => {
        return actionNumber === ActionOption.Withdraw;
    }, [actionNumber]);

    const isBankAccount = useMemo(() => {
        return withdrawToType === WithdrawMethod.BankAccount;
    }, [withdrawToType]);

    const handleConfirmButtonClick = () => {
        form.submit();
    };

    const handleFormSubmit = (value: any) => {
        // if (actionNumber === ActionOption.Increase || isTransferSelect) {
        //     setIsQrcodeShow(true);
        //     setFormData(value);
        // } else {
        const { withdrawTo, ...formData } = value;
        if (onAdd) {
            onAdd(formData);
        }
        // }
    };

    const scanQRcode = (status: boolean) => {
        if (status) {
            if (onAdd) {
                onAdd(formData);
            }
        }

        setFormData({});
        setIsQrcodeShow(false);
    };

    const handleFormChange = (values: any) => {
        const action = values?.action;
        const withdrawTo = values?.withdrawTo;

        if (action) {
            setActionNumber(action);
        }

        if (withdrawTo) {
            setWithdrawToType(withdrawTo);
        }
    };

    const loadBankAccount = async () => {
        const bankResponse = await paymentApis.getActivePaymentAccount();
        setBankAccounts(bankResponse?.data);
    };

    useEffect(() => {
        if (isWithdrawSelect && isBankAccount) {
            loadBankAccount();
        }
    }, [isWithdrawSelect, isBankAccount]);

    useEffect(() => {
        if (!props.open) {
            setActionNumber(0);
            form.setFieldsValue(initialValues);
            form.resetFields();
        };

    }, [props.open]);

    return (
        <Modal
            {...props}
            title={t('user.addNewBudgetModalTitle')}
            footer={
                !isQrcodeShow && (<div className="modal-footer">
                    <div className="modal-footer__button modal-footer__button--cancel">
                        <Button onClick={props.onCancel}>{t('common.cancel')}</Button>
                    </div>

                    <div className="modal-footer__button modal-footer__button--ok">
                        <Button loading={props.loading} type="primary" onClick={handleConfirmButtonClick}>{t('common.submit')}</Button>
                    </div>
                </div>)
            }
        >
            {!isQrcodeShow && (
                <Form
                    form={form}
                    className="budget-form form"
                    initialValues={initialValues}
                    onValuesChange={handleFormChange}
                    onFinish={handleFormSubmit}
                >
                    <Form.Item className="form__item" {...formatItemLayout} rules={validateScheamas['action']} name="action" label={t('user.budgetAction')}>
                        <Select>
                            {USER_BUDGET_ACTION_OPTIONS.map(option => {
                                return (
                                    <Select.Option key={option.id} value={option.value}>
                                        {option.name}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item className="form__item" {...formatItemLayout} rules={validateScheamas['amount']} name="amount" label={t('user.amount')}>
                        <InputNumber
                            parser={value => NUMBER_UTIL.parserInput(value)}
                            formatter={value => NUMBER_UTIL.formatInput(value)}
                        />
                    </Form.Item>

                    {isTransferSelect && (
                        <Form.Item className="form__item" {...formatItemLayout} rules={validateScheamas['transferTo']} name="transferTo" label={t('user.transferTo')}>
                            <Input />
                        </Form.Item>
                    )}

                    {isWithdrawSelect && (
                        <>
                            <Form.Item className="form__item" {...formatItemLayout} rules={validateScheamas['transferTo']} name="withdrawTo" label={t('user.withdrawTo')} initialValue={WithdrawMethod.BankAccount}>
                                <Radio.Group>
                                    {
                                        WITHDRAW_METHOD_TYPE.map((type: any) => (
                                            <Radio key={type.id} value={type.value}>{type.name}</Radio>
                                        ))
                                    }
                                </Radio.Group>
                            </Form.Item>

                            {isBankAccount && (
                                <Form.Item className="form__item" {...formatItemLayout} rules={validateScheamas['transferTo']} name="paymentMethodId" label={t('payment.bank-account')}>
                                    <Select>
                                        {bankAccounts?.map((account: any) => (
                                            <Select.Option key={account.id} value={account.id}>
                                                <div className="form__item-select-option">
                                                    {account.name}
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            {!isBankAccount && (
                                <Form.Item className="form__item" {...formatItemLayout} rules={validateScheamas['transferTo']} name="userAccount" label={t('payment.user-account')}>
                                    <Select />
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form>
            )}

            {isQrcodeShow && <QRcode onHide={scanQRcode} />}
        </Modal>
    );
};

export default BudgetFormModal;
