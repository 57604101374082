import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserApi } from "../../../../../apis/user.api";
import { DotIcon } from "../../../../../assets/icons";
import EditButton from "../../edit-button";
import ChangePasswordForm from "./change-password-form";
import ProtectedAction from "../../../../../components/action-protected";
import { useUserDetailContext } from "../../../../../pages/users/user-detail";
import { RoleEnum } from "../../../../../enums/app.enum";

const UserDetailOverviewPassword = () => {
  // ultils
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // state data
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { isAccount } = useUserDetailContext();

  const handleFormSubmit = async (values: any) => {
    try {
      setLoading(true);

      delete values.confirmPassword;

      const payload = {
        ...values
      };

      const result = await UserApi.changePassword(payload);

      if (result?.data) {
        setIsEdit(false);
        form.resetFields();
      }

    } finally {
      setLoading(false);
    }
  };

  const handleEditButtonClick = () => {
    setIsEdit(true);
  };

  const handleCancelButtonClick = () => {
    setIsEdit(false);
  };

  const handleSaveButtonClick = () => {
    form.submit();
  };

  return (
    <div className="password-section">
      <div className="password">
        <div className="right-head">
          <div className="title">{t("common.password")}</div>
          {!isEdit && (
            <div>
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
              <DotIcon color="#999999" />
            </div>
          )}
        </div>
        <ProtectedAction roles={!!isAccount ? [RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User, RoleEnum.Accountant] : []}>
          <EditButton
            isEdit={isEdit}
            loading={loading}

            onEdit={handleEditButtonClick}
            onSave={handleSaveButtonClick}
            onCancel={handleCancelButtonClick}
          />
        </ProtectedAction>

      </div>

      {isEdit && (
        <ChangePasswordForm
          form={form}
          onFinish={handleFormSubmit}
        />
      )}
    </div>
  );
};

export default UserDetailOverviewPassword;
