import { useTranslation } from "react-i18next";
import { Button, Card, Form, Input } from "antd";
import { useEffect, useMemo, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { RoleEnum } from "../../../enums/app.enum";
import { WebsiteApi } from "../../../apis/website.api";
import { PAGE_SIZE } from "../../../constants/app-constants";

import ModalComponent from "../../../components/common/modal";
import ProtectedAction from "../../../components/action-protected";
import WebsiteDatabaseTable from "../../../views/websites/database/database-table";
import AddUserDatabaseForm from "../../../views/websites/database/add-user-database";

import "./index.scss";

const selectDataBaseDelete = {
  id: 0,
  databaseName: "",
};

function WebsiteDatabasePage() {
  // ultils
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // state data
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [website, setWebsite] = useState<any>(selectDataBaseDelete);

  const paginationOptions = useMemo(() => {
    return {
      total,
      currentPage: page,
      pageSize: PAGE_SIZE,
      onChange: (page: any) => setPage(page),
    };
  }, [page, total]);

  const dataParams = useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      pageIndex: paginationOptions.currentPage,
    };
  }, [paginationOptions]);

  const getDatabase = async (dataParams: any) => {
    try {
      setIsLoading(true);

      const res = await WebsiteApi.getDatabase(dataParams);
      setTableData(res.data.records);
      setTotal(res.data.totalRecords);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleDeleteRow = async () => {
    await WebsiteApi.deleteDatabase([website.id]);
    await getDatabase({ ...dataParams });
    setIsOpenDelete(false);
    setInputValue("");
  };

  const createUserDatabase = async (payload: any) => {
    await WebsiteApi.createDatabase(payload);
    await getDatabase({ ...dataParams });
    form.resetFields();
    setIsOpenAddUser(false);
  };

  const handleCreateUser = async () => {
    form.submit();
  };

  useEffect(() => {
    getDatabase(dataParams);
  }, [dataParams]);

  return (
    <ProtectedAction roles={[RoleEnum.Admin]}>
      <div className="website-database-table">
        <Card
          extra={
            <Button
              onClick={() => setIsOpenAddUser(true)}
              icon={<PlusOutlined />}
              type="primary"
            >
              {t("common.addNew")}
            </Button>
          }
          title={t("website.title")}
        >
          <WebsiteDatabaseTable
            data={tableData}
            loading={isLoading}
            pagination={paginationOptions}
            setIsOpenDelete={setIsOpenDelete}
            setWebsite={setWebsite}
          />
        </Card>

        <ModalComponent
          title={t("website.deleteDatabase")}
          onYes={handleDeleteRow}
          isOpen={isOpenDelete}
          setIsOpen={setIsOpenDelete}
          okText={t("common.delete")}
          disabledOkButton={inputValue !== website.databaseName}
        >
          <div>
            <p>
              Please enter confirmation Database Name {website.databaseName} to
              delete
            </p>
            <Input
              value={inputValue}
              style={{ width: "50%" }}
              onChange={handleChange}
            />
          </div>
        </ModalComponent>

        <ModalComponent
          title={t("website.addNewUserDatabase")}
          onYes={handleCreateUser}
          isOpen={isOpenAddUser}
          setIsOpen={setIsOpenAddUser}
          okText={t("common.save")}
          okTextColor="#8a2be2"
        >
          <AddUserDatabaseForm form={form} onCreate={createUserDatabase} />
        </ModalComponent>
      </div>
    </ProtectedAction>
  );
}

export default WebsiteDatabasePage;
