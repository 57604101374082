import {
  LockOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SettingOutlined,
  PauseCircleOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

import {
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  Modal,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { RoleEnum } from "../../../enums/app.enum";
import { WebsiteApi } from "../../../apis/website.api";
import { PAGE_SIZE } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";

import SiteExtra from "../../../views/websites/sites/detail";
import SiteFilter from "../../../views/websites/sites/filter";
import ProtectedAction from "../../../components/action-protected";

import "./index.scss";
import DotStatus from "../../../views/websites/dot-status";
import { NUMBER_UTIL } from "../../../utils/number.utils";
import classNames from "classnames";
import { SiteStatusEnum } from "../../../enums/website.enum";
import confirmReasonDeactivated from "../../../views/theme/deactivated-modal";
import showNotification from "../../../components/common/notification";

const { Text } = Typography;

type optionProps = {
  name?: any;
  siteType?: any;
  endDate?: Date;
  siteStatus?: any;
  startDate?: Date;
  pageSize: number;
  pageIndex: number;
};

const DEFAULT_WEBSITE_SELECTED: any = {
  id: 0,
  link: "",
}

function WebsiteSitesPage() {
  const { t } = useTranslation();

  const [options, setOptions] = useState<optionProps>({
    pageIndex: 1,
    pageSize: PAGE_SIZE,
  });
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [website, setWebsite] = useState(DEFAULT_WEBSITE_SELECTED);

  const isSelectedItem = useMemo(() => {
    return !!selectedRowKeys.length;
  }, [selectedRowKeys]);

  const renderTypeColor = (type: string) => {
    switch (type) {
      case "Expired":
        return "red";
      case "Production":
        return "green";

      case "Demo":
        return "blue";

      default:
        return "default";
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDeleteRow = async () => {
    try {
      setLoading(true);
      const deleteIds = isSelectedItem ? selectedRowKeys : [website.id]
      await WebsiteApi.deleteSite(deleteIds);
      await getSites({ ...options });

      setIsOpenDelete(false);
      setInputValue("");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRows = async () => {
    setIsOpenDelete(true);
    const site = sites.find(site => site.id === selectedRowKeys[0]);
    const id = selectedRowKeys?.[0] || 0
    setWebsite({
      id: id,
      link: site.name,
    });
  };

  const menu = (
    <Menu
      items={[
        {
          label: <span>{t("website.archive")}</span>,
          key: "0",
        },
        {
          label: (
            <span
              onClick={handleDeleteRows}
              style={{ color: "#F5222D", fontWeight: 500 }}
            >
              {t("common.delete")}
            </span>
          ),
          key: "1",
        },
      ]}
    />
  );

  const handleStart = async (id: any) => {
    await WebsiteApi.startSite([id]);
    getSites({ ...options });
  };

  const handleStop = async (site: any) => {
    if (site.isDeactivated) {
      showNotification('warning', t('website.stop-active-site'));
      return;
    }

    await WebsiteApi.stopSite([site.id]);
    getSites({ ...options });
  };

  const handleActiveSite = async (site: any) => {
    const result = await WebsiteApi.activeSite(site.id);

    if (result?.data) {
      getSites(options);
    }
  };

  const handleDeactivateSite = async (site: any) => {
    const handleGetReason = async (reason: any) => {
      const result = await WebsiteApi.deActivateSite({ id: site.id, reason: reason.replaceAll('\n', '%0A') });

      if (result?.data) {
        getSites(options);
      }
    }

    if (site.siteStatus !== SiteStatusEnum.Stop) {
      showNotification('warning', t('website.stop-site-before'));
      return;
    }

    Modal.confirm({
      ...confirmReasonDeactivated(handleGetReason),
      title: t('theme.confirmDeactivateTheme'),
      okButtonProps: { loading: loading },
      okType: 'primary'
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: t("website.typeOfSite"),
      dataIndex: "siteType",
      key: "siteType",
      width: 100,
      className: "column-item type-site",
      render: (text) => <Tag color={renderTypeColor(text)}>{text}</Tag>,
      sorter: (a, b) => a.siteType.length - b.siteType.length,
    },
    {
      title: t("website.name"),
      dataIndex: "name",
      key: "name",
      className: "column-item",
      render: (text) => <span className="gx-link">{text}</span>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("website.age"),
      dataIndex: "age",
      key: "age",
      className: "column-item__age",
      sorter: (a, b) => a.age - b.age,
      render: (text) => (
        NUMBER_UTIL.formatInput(text)
      )
    },
    {
      title: t("website.status"),
      dataIndex: "siteStatus",
      key: "siteStatus",
      className: "column-item",
      align: "center",
      width: 80,
      render: (text) => <DotStatus status={text} />,
      sorter: (a, b) => a.siteStatus - b.siteStatus,
    },
    {
      title: t("website.plan"),
      dataIndex: "planName",
      key: "planName",
      className: "column-item plan__column",
      width: 80,
      sorter: (a, b) => a.planName.length - b.planName.length,
      render: (text, record) => {
        return (
          <div className="plan">
            <div>{text}</div>
          </div>
        );
      },
    },
    {
      title: (
        <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.User, RoleEnum.Admin]}>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            disabled={!isSelectedItem}
            className={classNames(["selected-action", { 'disabled': !isSelectedItem }])}
          >
            <SettingOutlined style={{ fontSize: 20 }} />
          </Dropdown>
        </ProtectedAction>
      ),
      key: "action",
      className: "column-item action__table",
      render: (text, record) => {
        const isStopped = record.siteStatus === SiteStatusEnum.Stop;
        const isDeactivated = record.isDeactivated
        return (
          <div className="action__site">
            <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.User, RoleEnum.Admin, RoleEnum.Accountant]}>
              <div className="action__icon">
                <Tooltip title={isStopped ? t('common.start') : t('common.stop')}>
                  {isStopped ? (
                    <PlayCircleOutlined
                      onClick={() => handleStart(record.id)}
                      className="icon"
                    />
                  ) : (
                    <PauseCircleOutlined
                      onClick={() => handleStop(record)}
                      className="icon"
                    />
                  )}
                </Tooltip>

                <Tooltip title={isDeactivated ? t('common.activate') : t('common.deactivate')}>
                  {isDeactivated ? (
                    <LockOutlined
                      onClick={() => handleActiveSite(record)}
                      className="icon"
                    />
                  ) : (
                    <UnlockOutlined
                      onClick={() => handleDeactivateSite(record)}
                      className="icon"
                    />
                  )}
                </Tooltip>

              </div>
            </ProtectedAction>
            <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.User, RoleEnum.Admin]}>
              <Text
                onClick={() => {
                  setIsOpenDelete(true);
                  setWebsite({
                    id: record.id,
                    link: record.name,
                  });
                }}
                className="deleteAction"
                type="danger"
              >
                {t("common.delete")}
              </Text>
            </ProtectedAction>

          </div>
        )
      },
    },
  ];

  const data = [];
  for (let i = 1; i <= 10; i++) {
    data.push({
      key: i,
      name: "John Brown",
      age: `${i}2`,
      address: `New York No. ${i} Lake Park`,
      description: `My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
    });
  }

  const getSites = async (options: any) => {
    try {
      setLoading(true);
      const res = await WebsiteApi.getSites(options);
      setSites(res.data.records);
      setTotal(res.data.totalRecords);

    } finally {
      setLoading(false);
    }
  };

  const paginationOptions = useMemo(() => {
    return {
      total,
      pageSize: PAGE_SIZE,
      currentPage: options.pageIndex,
      onChange: (page: any) => {
        setOptions({ ...options, pageIndex: page });
      },
    }
  }, [options, total]);

  const filterDateRange = (dateRange: any) => {
    setOptions({ ...options, ...dateRange });
  };

  const filterSearch = (textSearch: string) => {
    if (!textSearch) {
      setOptions((prev) => {
        const { name, ...prevOptions } = prev;
        return prevOptions;
      });

      return;
    }

    setOptions({ ...options, name: textSearch, pageIndex: 1 });
  };

  const filterSite = (site: number) => {
    if (!site) {
      setOptions((prev) => {
        const { siteType, ...prevOptions } = prev;
        return prevOptions;
      });

      return;
    }

    setOptions({ ...options, siteType: site, pageIndex: 1 });
  };

  const filterState = (state: number) => {
    if (!state) {
      setOptions((prev) => {
        const { siteStatus, ...prevOptions } = prev;
        return prevOptions;
      });

      return;
    }

    setOptions({ ...options, siteStatus: state, pageIndex: 1 });
  };

  useEffect(() => {
    getSites(options);
  }, [options]);

  return (
    <Card
      title={t("website.siteTitle")}
      className="website-sites-page"
      extra={
        <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.User, RoleEnum.Admin]}>
          <Link to={ROUTE_PATHS.websiteCreateSites}>
            <Button icon={<PlusOutlined />} type="primary">
              {t("website.addWebsite")}
            </Button>
          </Link>
        </ProtectedAction>
      }
    >
      <div className="filter__bar">
        <SiteFilter
          onSearch={filterSearch}
          onSiteFilter={filterSite}
          onStateFilter={filterState}
          onDateRangeFilter={filterDateRange}
        />
      </div>

      <Table
        rowKey="id"
        className="gx-table-responsive site-table"
        columns={columns}
        dataSource={sites}
        expandable={{
          expandedRowRender: (record) => <SiteExtra {...record} />,
        }}
        rowSelection={rowSelection}
        pagination={paginationOptions}
        loading={loading}
      />

      <Modal
        title={t("website.deleteWebsite")}
        onOk={handleDeleteRow}
        open={isOpenDelete}
        onCancel={() => {
          setIsOpenDelete(false);
          setInputValue('');
          setWebsite(DEFAULT_WEBSITE_SELECTED);
        }}
        okText={t("common.delete")}
        okButtonProps={{
          loading: loading,
          disabled: inputValue !== website.link,
          style: { backgroundColor: '#F5222D', border: 0, color: '#fff' },
        }}
        okType="danger"
      >
        <>
          <p className="confirm-description" dangerouslySetInnerHTML={{ __html: t('website.confirm-delete-site', { siteLink: website.link }) }} />
          <Input
            value={inputValue}
            style={{ width: "50%" }}
            onChange={handleChange}
          />
        </>
      </Modal>
    </Card>
  );
}

export default WebsiteSitesPage;
