import React from "react";
interface Props {
  title: string;
  width: string;
  value: string;
  color: string;
}

const LineIndicator = (props: Props) => {
  const { title, width, value, color } = props;
  return (
    <div style={{marginBottom: 12}}>
      <p style={{fontWeight: 500}}>{title}</p>
      <div className="gx-line-indi-info">
        <div
          className={`gx-line-indi gx-bg-${color}`}
          style={{
            width: Number.parseInt(width, 10) * 4,
            marginTop: 8
          }}
        />

        <span className="gx-line-indi-count gx-ml-2">{value}</span>
      </div>
    </div>
  );
};

export default LineIndicator;
