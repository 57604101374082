const PresentationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} viewBox="0 0 38 38" fill="none">
            <path d="M25.625 11.25H33.125C33.2908 11.25 33.4497 11.1842 33.5669 11.0669C33.6842 10.9497 33.75 10.7908 33.75 10.625C33.75 10.4592 33.6842 10.3003 33.5669 10.1831C33.4497 10.0658 33.2908 10 33.125 10H25.625C25.4592 10 25.3003 10.0658 25.1831 10.1831C25.0658 10.3003 25 10.4592 25 10.625C25 10.7908 25.0658 10.9497 25.1831 11.0669C25.3003 11.1842 25.4592 11.25 25.625 11.25Z" fill="url(#paint0_linear_1256_26966)" />
            <path d="M25.625 15H33.125C33.2908 15 33.4497 14.9342 33.5669 14.8169C33.6842 14.6997 33.75 14.5408 33.75 14.375C33.75 14.2092 33.6842 14.0503 33.5669 13.9331C33.4497 13.8158 33.2908 13.75 33.125 13.75H25.625C25.4592 13.75 25.3003 13.8158 25.1831 13.9331C25.0658 14.0503 25 14.2092 25 14.375C25 14.5408 25.0658 14.6997 25.1831 14.8169C25.3003 14.9342 25.4592 15 25.625 15Z" fill="url(#paint1_linear_1256_26966)" />
            <path d="M25.625 18.75H33.125C33.2908 18.75 33.4497 18.6842 33.5669 18.5669C33.6842 18.4497 33.75 18.2908 33.75 18.125C33.75 17.9592 33.6842 17.8003 33.5669 17.6831C33.4497 17.5658 33.2908 17.5 33.125 17.5H25.625C25.4592 17.5 25.3003 17.5658 25.1831 17.6831C25.0658 17.8003 25 17.9592 25 18.125C25 18.2908 25.0658 18.4497 25.1831 18.5669C25.3003 18.6842 25.4592 18.75 25.625 18.75Z" fill="url(#paint2_linear_1256_26966)" />
            <path d="M25.625 22.5H33.125C33.2908 22.5 33.4497 22.4342 33.5669 22.3169C33.6842 22.1997 33.75 22.0408 33.75 21.875C33.75 21.7092 33.6842 21.5503 33.5669 21.4331C33.4497 21.3158 33.2908 21.25 33.125 21.25H25.625C25.4592 21.25 25.3003 21.3158 25.1831 21.4331C25.0658 21.5503 25 21.7092 25 21.875C25 22.0408 25.0658 22.1997 25.1831 22.3169C25.3003 22.4342 25.4592 22.5 25.625 22.5Z" fill="url(#paint3_linear_1256_26966)" />
            <path d="M35.875 0.25H2.125C1.62818 0.251484 1.15212 0.449504 0.800814 0.800814C0.449504 1.15212 0.251484 1.62818 0.25 2.125V35.875C0.251484 36.3718 0.449504 36.8479 0.800814 37.1992C1.15212 37.5505 1.62818 37.7485 2.125 37.75H35.875C36.3718 37.7485 36.8479 37.5505 37.1992 37.1992C37.5505 36.8479 37.7485 36.3718 37.75 35.875V2.125C37.7485 1.62818 37.5505 1.15212 37.1992 0.800814C36.8479 0.449504 36.3718 0.251484 35.875 0.25ZM36.5 35.875C36.5 36.0408 36.4342 36.1997 36.3169 36.3169C36.1997 36.4342 36.0408 36.5 35.875 36.5H2.125C1.95924 36.5 1.80027 36.4342 1.68306 36.3169C1.56585 36.1997 1.5 36.0408 1.5 35.875V6.5H36.5V35.875ZM36.5 5.25H1.5V2.125C1.5 1.95924 1.56585 1.80027 1.68306 1.68306C1.80027 1.56585 1.95924 1.5 2.125 1.5H35.875C36.0408 1.5 36.1997 1.56585 36.3169 1.68306C36.4342 1.80027 36.5 1.95924 36.5 2.125V5.25Z" fill="url(#paint4_linear_1256_26966)" />
            <path d="M34.625 2.75H34C33.8342 2.75 33.6753 2.81585 33.5581 2.93306C33.4408 3.05027 33.375 3.20924 33.375 3.375C33.375 3.54076 33.4408 3.69973 33.5581 3.81694C33.6753 3.93415 33.8342 4 34 4H34.625C34.7908 4 34.9497 3.93415 35.0669 3.81694C35.1842 3.69973 35.25 3.54076 35.25 3.375C35.25 3.20924 35.1842 3.05027 35.0669 2.93306C34.9497 2.81585 34.7908 2.75 34.625 2.75Z" fill="url(#paint5_linear_1256_26966)" />
            <path d="M26.625 2.75H11.625C11.4592 2.75 11.3003 2.81585 11.1831 2.93306C11.0658 3.05027 11 3.20924 11 3.375C11 3.54076 11.0658 3.69973 11.1831 3.81694C11.3003 3.93415 11.4592 4 11.625 4H26.625C26.7908 4 26.9497 3.93415 27.0669 3.81694C27.1842 3.69973 27.25 3.54076 27.25 3.375C27.25 3.20924 27.1842 3.05027 27.0669 2.93306C26.9497 2.81585 26.7908 2.75 26.625 2.75Z" fill="url(#paint6_linear_1256_26966)" />
            <path d="M4.625 22.75H22.75C22.9158 22.75 23.0747 22.6842 23.1919 22.5669C23.3092 22.4497 23.375 22.2908 23.375 22.125V9.625C23.375 9.45924 23.3092 9.30027 23.1919 9.18306C23.0747 9.06585 22.9158 9 22.75 9H4.625C4.45924 9 4.30027 9.06585 4.18306 9.18306C4.06585 9.30027 4 9.45924 4 9.625V22.125C4 22.2908 4.06585 22.4497 4.18306 22.5669C4.30027 22.6842 4.45924 22.75 4.625 22.75ZM12.8863 19.0863L12.8937 19.0744L16.5 13.3044L21.625 21.5H11.375L12.8863 19.0863ZM9.90375 21.5H5.7925L9.625 15.75L11.625 18.75L9.90375 21.5ZM22.125 10.25V19.9462L17.03 11.7937C16.9701 11.7085 16.8905 11.6389 16.7981 11.5909C16.7056 11.5429 16.6029 11.5178 16.4987 11.5178C16.3946 11.5178 16.2919 11.5429 16.1994 11.5909C16.107 11.6389 16.0274 11.7085 15.9675 11.7937L12.3488 17.585L10.1425 14.2781C10.082 14.1973 10.0035 14.1317 9.91328 14.0865C9.82302 14.0412 9.72345 14.0177 9.6225 14.0177C9.52155 14.0177 9.42198 14.0412 9.33172 14.0865C9.24145 14.1317 9.16297 14.1973 9.1025 14.2781L5.25 20.0625V10.25H22.125Z" fill="url(#paint7_linear_1256_26966)" />
            <path d="M22.75 34H15.25C15.0842 34 14.9253 33.9342 14.8081 33.8169C14.6908 33.6997 14.625 33.5408 14.625 33.375V25.875C14.625 25.7092 14.6908 25.5503 14.8081 25.4331C14.9253 25.3158 15.0842 25.25 15.25 25.25H22.75C22.9158 25.25 23.0747 25.3158 23.1919 25.4331C23.3092 25.5503 23.375 25.7092 23.375 25.875V33.375C23.375 33.5408 23.3092 33.6997 23.1919 33.8169C23.0747 33.9342 22.9158 34 22.75 34ZM15.875 32.75H22.125V26.5H15.875V32.75Z" fill="url(#paint8_linear_1256_26966)" />
            <path d="M33.375 34H25.875C25.7092 34 25.5503 33.9342 25.4331 33.8169C25.3158 33.6997 25.25 33.5408 25.25 33.375V25.875C25.25 25.7092 25.3158 25.5503 25.4331 25.4331C25.5503 25.3158 25.7092 25.25 25.875 25.25H33.375C33.5408 25.25 33.6997 25.3158 33.8169 25.4331C33.9342 25.5503 34 25.7092 34 25.875V33.375C34 33.5408 33.9342 33.6997 33.8169 33.8169C33.6997 33.9342 33.5408 34 33.375 34ZM26.5 32.75H32.75V26.5H26.5V32.75Z" fill="url(#paint9_linear_1256_26966)" />
            <path d="M12.125 34H4.625C4.45924 34 4.30027 33.9342 4.18306 33.8169C4.06585 33.6997 4 33.5408 4 33.375V25.875C4 25.7092 4.06585 25.5503 4.18306 25.4331C4.30027 25.3158 4.45924 25.25 4.625 25.25H12.125C12.2908 25.25 12.4497 25.3158 12.5669 25.4331C12.6842 25.5503 12.75 25.7092 12.75 25.875V33.375C12.75 33.5408 12.6842 33.6997 12.5669 33.8169C12.4497 33.9342 12.2908 34 12.125 34ZM5.25 32.75H11.5V26.5H5.25V32.75Z" fill="url(#paint10_linear_1256_26966)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26966" x1={25} y1="10.4664" x2="27.6341" y2="14.6763" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26966" x1={25} y1="14.2164" x2="27.6341" y2="18.4263" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_26966" x1={25} y1="17.9664" x2="27.6341" y2="22.1763" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_26966" x1={25} y1="21.7164" x2="27.6341" y2="25.9263" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_26966" x1="0.25" y1="14.2425" x2="38.3868" y2="22.9498" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_26966" x1="33.375" y1="3.21642" x2="35.1706" y2="3.83137" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_26966" x1={11} y1="3.21642" x2="12.7725" y2="8.47733" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint7_linear_1256_26966" x1={4} y1="14.1306" x2="22.7867" y2="20.1746" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint8_linear_1256_26966" x1="14.625" y1="28.5149" x2="23.5236" y2="30.5466" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint9_linear_1256_26966" x1="25.25" y1="28.5149" x2="34.1486" y2="30.5466" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint10_linear_1256_26966" x1={4} y1="28.5149" x2="12.8986" y2="30.5466" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>
    )
};

export default PresentationIcon;