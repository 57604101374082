import classNames from "classnames";
import { FunctionComponent, ReactEventHandler, useMemo } from "react";

import { ArrowRightOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Typography } from "antd";
import { useTranslation } from "react-i18next";

import './index.scss';
import { RoleEnum } from "../../../enums/app.enum";
import ProtectedAction from "../../../components/action-protected";
interface MenuDropListProps {
    active?: boolean;

    onEdit?: () => void;
    onDelete?: () => void;
    onChangeStatus?: () => void;
};

const MenuDropList: FunctionComponent<MenuDropListProps> = ({ active, onEdit, onDelete, onChangeStatus }) => {
    const { t } = useTranslation();

    const activeText = useMemo(() => {
        if (active) {
            return t("common.deActive")
        }

        return t("common.active")
    }, [active]);

    const handleEditClick = (event: any) => {
        event.domEvent?.stopPropagation();

        onEdit?.();
    };

    const handleEditChangeStatus = (event: any) => {
        event.domEvent?.stopPropagation();

        onChangeStatus?.();
    };

    const handleDeleteMenuClick = (event: any) => {
        event.domEvent?.stopPropagation();

        onDelete?.();
    };
    return (
        <Menu style={{ width: 120 }}>
            <Menu.Item
                onClick={handleEditClick}
                key="0"
            >
                {t("common.edit")}
            </Menu.Item>

            <Menu.Item
                onClick={handleEditChangeStatus}
                key="1"
            >
                {activeText}
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item onClick={handleDeleteMenuClick} key="2">
                <Typography.Text type="danger">
                    {t("common.delete")}
                </Typography.Text>
            </Menu.Item>
        </Menu>
    )
};

interface CategoriesItemProps {
    category: Value;
    active?: boolean;
    isEdit?: boolean;
    isHardCoded?: boolean;
    
    onEdit?: () => void;
    onClick?: () => void;
    onDelete?: () => void;
    onChangeStatus?: () => void;
};

type Value = {
    name: string,
    isDeactivated: boolean,
};

const CategoriesBarItem: FunctionComponent<CategoriesItemProps> = ({ category, active, isEdit, isHardCoded, onEdit, onDelete, onChangeStatus, onClick, ...props }) => {
    const menuEvent = { onEdit, onDelete, onChangeStatus };

    const isShowActive = active

    const handleSelectCategory = () => {
        if (category.isDeactivated) {
            return
        }

        onClick?.();
    };

    return (
        <div
            onClick={handleSelectCategory}
            className={classNames(["cate-title", { "active": isShowActive, "deactivated": category.isDeactivated }])}
        >
            <div className="category__title-text">{category.name}</div>
            {!isHardCoded && <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
                <Dropdown
                    placement="bottomRight"
                    overlay={<MenuDropList {...menuEvent} active={!category.isDeactivated} />}
                    trigger={["click"]}
                >
                    <div className='setting__icon' onClick={event => event.stopPropagation()}>
                        <MoreOutlined />
                    </div>
                </Dropdown>
            </ProtectedAction>}
        </div>
    );
};

CategoriesBarItem.defaultProps = {
    isEdit: false,
    active: false,
};

export default CategoriesBarItem;
