import { Select } from "antd";

interface SelectBoxProps {
  optionData?: [any];

  [key: string]: any;
};

const MutipleSelectBox: React.FunctionComponent<SelectBoxProps> = ({ optionData, ...props }) => {
  return (
    <Select
      {...props}
      options={optionData?.map(data => ({ label: data?.name, value: data?.id}))}
    />
  );
};

export default MutipleSelectBox;
