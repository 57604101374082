import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";

import Metrics from "../metrics";

import './index.scss';
interface Props {
  trafficData: any[];
  title: string;
  fromColor?: string;
  toColor?: string;
  id: string;
  color?: string;
  percent: string;
}

const GrowthCardInUser = (props: Props) => {
  const { fromColor, id, toColor, color, title, percent, trafficData } = props;
  const { t } = useTranslation();

  return (
    <Metrics styleName={`gx-card-full`} title={<h4 className="card__title">{t("user.userGrowth")}</h4>}>
      <Row>
        <Col lg={9} md={24} sm={9} xs={9}>
          <div className="gx-pb-4 gx-pl-4 gx-pt-4">
            <h2
              style={{ color: color || "#000" }}
              className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-chart-up gx-text-nowrap"
            >
              {percent}
              <i className="icon icon-menu-up gx-fs-xxl" />
            </h2>
            <p className="gx-mb-0 gx-text-grey">{title}</p>
          </div>
        </Col>
        <Col lg={15} md={24} sm={15} xs={15}>
          <ResponsiveContainer width="100%" height={103}>
            <AreaChart
              data={trafficData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip />
              <defs>
                <linearGradient id={id} x1="0" y1="0" x2="1" y2="0">
                  <stop offset="5%" stopColor={fromColor} stopOpacity={0.9} />
                  <stop offset="95%" stopColor={toColor} stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                strokeWidth={0}
                stackId="2"
                stroke="#867AE5"
                fill={`url(#${id})`}
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Metrics>
  );
};

export default GrowthCardInUser;
