const DefaultCategoryIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40" fill="none">
            <rect width={40} height={40} rx={4} fill="#FAF0FF" />
            <path d="M24.1291 25.5044L27.5765 12.6958H26.4483L23.0005 25.5044H24.1291Z" fill="url(#paint0_linear_3263_72562)" />
            <path d="M34.2937 20.1297L28.8804 17.8202V16.5699L36 19.6149V20.6445L28.8804 23.6895V22.4392L34.2937 20.1297Z" fill="url(#paint1_linear_3263_72562)" />
            <path d="M13.511 17.1072H12.1724L15.5704 24.5063L17.7033 19.3578L19.8363 24.5063L23.2637 17.1072H21.9251L19.8657 21.785L17.7033 16.5776L15.5557 21.785L13.511 17.1072Z" fill="url(#paint2_linear_3263_72562)" />
            <path d="M4 19.6226L11.1196 16.5776V17.828L5.70636 20.1375L11.1196 22.4469V23.6973L4 20.6523V19.6226Z" fill="url(#paint3_linear_3263_72562)" />
            <defs>
                <linearGradient id="paint0_linear_3263_72562" x1="23.0005" y1="17.4751" x2="36.2011" y2="20.534" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_3263_72562" x1="23.0005" y1="17.4751" x2="36.2011" y2="20.534" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_3263_72562" x1="12.1724" y1="19.5361" x2="22.9415" y2="22.9756" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_3263_72562" x1={4} y1="19.2342" x2="11.2405" y2="20.8873" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>
    )
};

export default DefaultCategoryIcon;