import { Col, Row } from "antd";
import { useRecoilValue } from "recoil";
import { FunctionComponent, useCallback, useMemo } from "react";

import ThemeCardList from "../../theme-card/list";
import ThemeCardGrid from "../../theme-card/grid";
import { themeSiteByCategory } from "../../../../states/theme";

import { VIEW_ITEM } from "../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { getUserInfoSelector } from "../../../../states/auth";

interface CategoryPros {
    view?: number;
}

const CategoriesList: FunctionComponent<CategoryPros> = ({ view }) => {

    // recoil
    const userInfo = useRecoilValue(getUserInfoSelector);
    const themeSites = useRecoilValue(themeSiteByCategory);

    const isUserRole = useMemo(() => {
        return userInfo?.role === 'User';
    }, [userInfo]);

    const render = useCallback((site: any) => {
        const userRedirectLink = !isUserRole ? ROUTE_PATHS.reviewSiteOverviewUpload.replace(':siteId', site.id) : ''
        const redirectToLink = site.isApproved ? ROUTE_PATHS.themeDetail.replace(':themeId', site.id) : userRedirectLink

        if (view === VIEW_ITEM.grid) {
            return (
                <Col  key={site.id} xl={6} md={8} sm={12} xs={24}>
                    <ThemeCardGrid data={site} link={redirectToLink} state={{site}} isFree={site?.isThemeFree}/>
                </Col>
            )
        };

        return (
            <Col  key={site.id} span={24}>
                <ThemeCardList data={site} link={redirectToLink} state={{site}} isFree={site?.isThemeFree}/>
            </Col>
        )
    }, [view, isUserRole])

    return (
        <Row>
            {themeSites.sites?.records?.map((site: any) => (
                render(site)
            ))}
        </Row>
    );
};

export default CategoriesList;