import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";

import './index.scss';
import { useEffect, useState } from "react";
import paymentApis from "../../../../../../apis/payment.api";

interface PaymentActionProps {
    [propKeys: string]: any;
}

const PaymentAction: React.FunctionComponent<PaymentActionProps> = ({ onSubmit, onCancel, ...modalProps }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const bankId = Form.useWatch('bankId', form);

    const [banks, setBanks] = useState([]);
    const [branchBanks, setBranchBanks] = useState([]);

    const handleFormSubmit = async (formData: any) => {
        onSubmit?.(formData);
    };

    const loadBanks = async () => {
        const response = await paymentApis.getBanks();
        setBanks(response?.data || []);
    };

    const loadBranchBankByBankID = async () => {
        const response = await paymentApis.getBankBranchsByBankId({ bankId: bankId })

        setBranchBanks(response?.data || []);
    };

    useEffect(() => {
        if (modalProps?.open) {
            form.resetFields();
            loadBanks();
        }
    }, [modalProps?.open]);

    useEffect(() => {
        if (bankId) {
            form.setFieldValue('bankBranchId', '');
            loadBranchBankByBankID();
        }
    }, [bankId]);

    return (
        <Modal
            {...modalProps}
            footer={false}
            onCancel={onCancel}
            title={t('payment.add-new-payment-method')}
            className="payment-action-modal"
        >
            <Form
                form={form}
                layout="vertical"
                className="payment-action-form"

                onFinish={handleFormSubmit}
            >
                <Row className="form__content">
                    <Col span={24} className="form__item">
                        <Form.Item name="paymentMethod" label={t('payment.payment-method')} initialValue="bank">
                            <Select disabled>
                                <Select.Option value="bank">Bank Account</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="form__item">
                        <Form.Item name="bankId" label={t('payment.bank-name')}
                            rules={[
                                {
                                    required: true,
                                    message: t('user.payment-account-bank-alert')
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder={t('payment.bank-name')}

                                filterOption={(input, option) => {
                                    return (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                }
                            >
                                {
                                    banks?.map((bank: any) => (
                                        <Select.Option key={bank.id} value={bank.id}>{bank.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="form__item">
                        <Form.Item name="bankBranchId" label={t('payment.bank-branch')}
                            rules={[
                                {
                                    required: true,
                                    message: t('user.payment-account-bank-branch-alert')
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder={t('payment.bank-branch')}

                                filterOption={(input, option) => {
                                    return (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                }
                            >
                                {
                                    branchBanks?.map((bank: any) => (
                                        <Select.Option key={bank.id} value={bank.id}>{bank.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="form__item">
                        <Form.Item name="cardNumber" label={t('payment.bank-account-number')}
                            rules={[
                                {
                                    required: true,
                                    message: t('user.payment-account-card-number-alert')
                                }
                            ]}
                        >
                            <Input placeholder={t('payment.bank-account-number')} />
                        </Form.Item>
                    </Col>

                    <Col span={24} className="form__item">
                        <Form.Item name="bankAccountName" label={t('payment.bank-account-name')}
                            rules={[
                                {
                                    required: true,
                                    message: t('user.payment-account-name-alert')
                                }
                            ]}
                        >
                            <Input placeholder={t('payment.bank-account-name')} />
                        </Form.Item>
                    </Col>

                    <Col span={24} className="form__item form__button-group">
                        <Button htmlType="button" type="default" className="form__button-item" onClick={onCancel}>{t('common.cancel')}</Button>

                        <Button htmlType="submit" type="primary" className="form__button-item">{t('common.add')}</Button>
                    </Col>
                </Row>
            </Form>

        </Modal>
    );
};

export default PaymentAction;
