import { Avatar, Button } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { generateImage } from "../../../utils/app.utils";
import { UserOutlined } from "@ant-design/icons";

import "./index.scss";
import { Link } from "react-router-dom";

interface ThemeAuthorProps {
  id?: string;
  name?: string;
  avatar?: string;
}

const ThemeAuthor: FunctionComponent<ThemeAuthorProps> = ({
  id,
  name,
  avatar,
}) => {
  const { t } = useTranslation();
  return (
    <div className="author-wapper author">
      <div className="author__content">
        <div className="author__avatar">
          <Avatar
            icon={<UserOutlined />}
            size="large"
            src={generateImage(avatar)}
            alt={name}
          />
        </div>

        <div className="author__info">
          <div className="gx-module-contact-content author__info--upload">
            <span className="gx-mr-2 author__info--upload-by">
              {t("theme.uploadedBy")}
            </span>

            <p className="gx-mb-1 author__info--name">
              <Link to={`/users-detail/${id}`}>
                <span> {name} </span>
              </Link>
            </p>
          </div>
        </div>
      </div>

      <Button className=" author__contact">{t("common.contact")}</Button>
    </div>
  );
};

export default ThemeAuthor;
