import { Col, Row } from "antd";
import { BudgetIcon } from "../../../assets/icons";
import Metrics from "../metrics";
import "./index.scss";

interface Props {
  trafficData?: any[];
  title: string;
  budget: any;
}

const BudgetCard = (props: Props) => {
  return (
    <div className="budget-card">
      <Metrics styleName={`gx-card-full`} title="">
        <Row>
          <Col lg={6} md={24} sm={6} xs={6}>
            <div className="gx-pb-4 gx-pl-4 gx-pt-4">
              <BudgetIcon />
            </div>
          </Col>
          <Col className="col-item gx-pt-4" lg={18} md={24} sm={18} xs={18}>
            <p className="money">{props.budget}</p>
            <p className="unit">Budget (VND)</p>
          </Col>
        </Row>
      </Metrics>
    </div>
  );
};

export default BudgetCard;
