import { atom, DefaultValue, selector } from "recoil";
import { AmountFormat } from "../utils/number.utils";

interface CommonState {
  pageTitle: string;
  width: number;
  navCollapsed: boolean;
  currencyConfig: any;
}

const commonState = atom<CommonState>({
  key: "commonState",
  default: {
    pageTitle: "",
    width: window.innerWidth,
    navCollapsed: false,
    currencyConfig: AmountFormat,
  },

});

const commonPageTitleSelector = selector<string>({
  key: "commonPageTitleSelector",
  get: ({ get }) => {
    const { pageTitle } = get(commonState);
    return pageTitle;
  },
  set: ({ set, get }, newValue) => {
    const state = get(commonState);

    let title = "";
    if (newValue && !(newValue instanceof DefaultValue)) {
      title = newValue;
    }

    set(commonState, {
      ...state,
      pageTitle: title,
    });
  },
});

const commonWidthSelector = selector<number>({
  key: "commonWidthSelector",
  get: ({ get }) => {
    const { width } = get(commonState);
    return width;
  },
  set: ({ set, get }, newValue) => {
    const state = get(commonState);

    let width = state.width;
    if (newValue && !(newValue instanceof DefaultValue)) {
      width = newValue;
    }

    set(commonState, {
      ...state,
      width: width,
    });
  },
});

const commonNavCollapsedSelector = selector<boolean>({
  key: "commonNavCollapsedSelector",
  get: ({ get }) => {
    const { navCollapsed } = get(commonState);
    return navCollapsed;
  },
  set: ({ set, get }, newValue) => {
    const state = get(commonState);

    let navCollapsed = state.navCollapsed;
    if (!(newValue instanceof DefaultValue)) {
      navCollapsed = newValue;
    }

    set(commonState, {
      ...state,
      navCollapsed: navCollapsed,
    });
  },
});

const commonCurrencySelector = selector<any>({
  key: "commonCurrencySelector",
  get: ({ get }) => {
    const { currencyConfig } = get(commonState);
    return currencyConfig;
  },
  set: ({ set, get }, newValue: any) => {
    const state = get(commonState);

    let cloneCurrencyConfig = state.currencyConfig;
    if (!(newValue instanceof DefaultValue)) {
      cloneCurrencyConfig = {...newValue};
    }

    set(commonState, {
      ...state,
      currencyConfig: cloneCurrencyConfig,
    });
  },
});

export {
  commonState,
  commonWidthSelector,
  commonCurrencySelector,
  commonPageTitleSelector,
  commonNavCollapsedSelector,
};
