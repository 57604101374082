const httpPrefix = "http://";
const httpsPrefix = "https://";

function secondsToHms(d?: number) {
  if (!d) {
    return "00:00";
  }

  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h === 0 ? "" : h < 10 ? `0${h}:` : `${h}:`;
  const mDisplay = m < 10 ? `0${m}` : m.toString();
  const sDisplay = s < 10 ? `0${s}` : s.toString();
  return `${hDisplay}${mDisplay}:${sDisplay}`;
}

function removeProtocol(url: string | undefined) {
  if (!url) {
    return "";
  }

  if (url.startsWith(httpPrefix)) {
    url = url.replace(httpPrefix, "");
  } else if (url.startsWith(httpsPrefix)) {
    url = url.replace(httpsPrefix, "");
  }

  return url;
}

const isValidUrl = (string: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return pattern.test(string);
}

export const CommonUtils = { secondsToHms, removeProtocol, isValidUrl };
