import { Switch } from "antd";
import { FunctionComponent, useState } from "react";
import { StatusTypes } from "../../../../constants/app-constants";

import './index.scss';

interface BudgetFilterProps {
  status?: number; 
  onFilter?: (value: number) => void;
};

const BudgetFilters: FunctionComponent<BudgetFilterProps> = ({ onFilter, status }) => {
  const [ filterOptions, setFilterOptions ] = useState<number>(status || 15);

  const handleSwitchChange = (value: number, checked: boolean) => {
    const filterNumber = (() => {
      if (checked) {
        return (filterOptions + value)
      };

      return filterOptions - value;
    })();


    setFilterOptions(filterNumber);
    
    if (onFilter) {
      onFilter(filterNumber);
    }
  };

  return (
    <div className="budget-filters">
      <div className="search-row">
        <div className="search-col budget-type">

          {StatusTypes.map((status) => (
            <div className="search-swicth-item search-control-item" key={status.id}>
              <label>{status.name}</label>
              <Switch className={status.color} defaultChecked onChange={(checked) => handleSwitchChange(status.id, checked)}/>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

BudgetFilters.defaultProps = {
  status: 15,
  onFilter: () => null,
};

export default BudgetFilters;
