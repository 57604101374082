import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { FORMAT_DATE_TIME } from "../../../../constants/app-constants";

interface Props {
  data: any;
}

export const siteVisit = [
  { name: "1", production: 0, demo: 0, stopped: 1 },
  { name: "2", production: 0, demo: 1, stopped: 1 },
  { name: "3", production: 5, demo: 2, stopped: 1 },
  { name: "4", production: 10, demo: 0, stopped: 1 },
  { name: "5", production: 4, demo: 1, stopped: 1 },
  { name: "6", production: 16, demo: 3, stopped: 1 },
  { name: "7", production: 5, demo: 1, stopped: 1 },
  { name: "8", production: 11, demo: 5, stopped: 1 },
  { name: "9", production: 6, demo: 2, stopped: 1 },
  { name: "10", production: 11, demo: 3, stopped: 1 },
  { name: "11", production: 30, demo: 2, stopped: 1 },
  { name: "12", production: 10, demo: 1, stopped: 1 },
  { name: "13", production: 13, demo: 0, stopped: 1 },
  { name: "14", production: 4, demo: 2, stopped: 1 },
  { name: "15", production: 3, demo: 8, stopped: 1 },
  { name: "16", production: 1, demo: 0, stopped: 1 },
  { name: "17", production: 0, demo: 0, stopped: 1 },
];

const SiteVisit = (props: Props) => {
  const { t } = useTranslation();
  const chartModel = props?.data;

  const demo = chartModel?.demo?.map((item: any) => {
    return {
      name: moment(item.date).format(FORMAT_DATE_TIME.ymd),
      demo: item.value,
    };
  });

  const production = chartModel?.production?.map((item: any) => {
    return {
      name: moment(item.date).format(FORMAT_DATE_TIME.ymd),
      production: item.value,
    };
  });

  const stopped = chartModel?.stopped?.map((item: any) => {
    return {
      name: moment(item.date).format(FORMAT_DATE_TIME.ymd),
      stopped: item.value,
    };
  });

  const merged = demo?.concat(production, stopped);

  const chartData = merged?.reduce((acc: any, val: any, ind: any) => {
    const index = acc.findIndex((el: any) => el.name === val.name);
    if (index !== -1) {
      const key = Object.keys(val)[1];
      acc[index][key] = val[key];
    } else {
      acc.push(val);
    }
    return acc;
  }, []);

  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <ResponsiveContainer width="100%" height={140}>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
        >
          <Tooltip labelFormatter={(index) => chartData[index].name} />
          <CartesianGrid horizontal={false} strokeDasharray="3 3" />
          <Area
            type="monotone"
            dataKey="production"
            name={t('website.production')}
            fillOpacity={1}
            stroke="#038FDE"
            fill="#038FDE"
          />
          <Area
            type="monotone"
            dataKey="demo"
            name={t('website.demo')}
            fillOpacity={1}
            stroke="#FE9E15"
            fill="#FE9E15"
          />
          <Area
            type="monotone"
            dataKey="stopped"
            name={t('website.stopped')}
            fillOpacity={1}
            stroke="#f5222d"
            fill="#f5222d"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SiteVisit;
