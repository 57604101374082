import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "../../../../constants/app-constants";
import WebsiteDatabaseDetail from "../database-detail";
import "./index.scss";
import showNotification from "../../../../components/common/notification";

interface TableProps {
  data?: any;
  pagination?: any;
  loading?: boolean;
  setIsOpenDelete: (value: boolean) => void;
  setWebsite: (value: any) => void;
}

const WebsiteDatabaseTable: FunctionComponent<TableProps> = (props) => {
  const { t } = useTranslation();

  const { data, pagination, loading, setIsOpenDelete, setWebsite } = props;

  const [showList, setShowList] = useState<any>([]);

  const handleCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    showNotification("success", t("notification.copySuccess"));
  };

  const handleShow = (id: any) => {
    if (!showList.includes(id)) {
      setShowList([...showList, id]);
    } else {
      setShowList(showList.filter((x: any) => x !== id));
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t("website.userName"),
      dataIndex: "userName",
      sorter: (a, b) => a.userName.length - b.userName.length,
      render: (text) => (
        <span>
          {text}{" "}
          <CopyOutlined
            onClick={() => handleCopy(text)}
            className="copy-icon"
          />
        </span>
      ),
    },
    {
      title: t("website.databaseName"),
      dataIndex: "databseName",
      sorter: (a, b) => a.databseName.length - b.databseName.length,
    },
    {
      title: t("website.password"),
      dataIndex: "password",
      sorter: (a, b) => a.password.length - b.password.length,
      render: (text, record) => {
        return (
          <span onClick={() => handleShow(record.id)} className="gx-link">
            {showList.includes(record.id) ? text : t("website.show")}
          </span>
        );
      },
    },
    {
      title: t("website.site"),
      dataIndex: "webSiteLink",
      sorter: (a, b) => a.webSiteLink.length - b.webSiteLink.length,
      render: (text) => (
        <a className="site" rel="noreferrer" target="_blank" href={text}>
          {text}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="action">
          <div>
            <Tooltip placement="top" title={t("website.reset")}>
              <ReloadOutlined className="reload-icon" />
            </Tooltip>
          </div>
          <span
            onClick={() => {
              setIsOpenDelete(true);
              setWebsite({
                id: record.id,
                databaseName: record.databseName || "",
              });
            }}
            className="delete-btn"
          >
            {t("common.delete")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="website-database-table">
      <Table
        rowKey="id"
        className="gx-table-responsive"
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: (record) => <WebsiteDatabaseDetail {...record} />,
        }}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

WebsiteDatabaseTable.defaultProps = {
  data: [],
  loading: false,
  pagination: {
    currentPage: 1,
    pageSize: PAGE_SIZE,
  },
};

export default WebsiteDatabaseTable;
