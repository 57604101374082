import moment from "moment";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Area,
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { FORMAT_DATE_TIME } from "../../../constants/app-constants";

interface ThemeChartProps {
  data?: any;
};


const ThemeChart: FunctionComponent<ThemeChartProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const charts = useMemo(() => {
    return data?.map((item: any) => ({
      name: moment(item.date).format(FORMAT_DATE_TIME.ymd), themes: item.value
    }))
  }, [data]);

  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <ResponsiveContainer width="100%" height={140}>
        <AreaChart
          margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
          data={charts}
        >
          <Tooltip labelFormatter={(index) => charts[index].name}/>
          <CartesianGrid horizontal={false} strokeDasharray="3 3" />
          <Area
            type="monotone"
            dataKey="themes"
            name={t('theme.themes')}
            fillOpacity={1}
            stroke="#038FDE"
            fill="#038FDE"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

ThemeChart.defaultProps = {
  data: [],
};

export default ThemeChart;
