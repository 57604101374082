import { Link } from "react-router-dom";
import logo from "../../../assets/images/dark-logo.svg";

const SidebarLogo = () => {
  return (
    <div className="gx-layout-sider-header">
      <Link to="/" className="gx-site-logo">
        <img style={{width: 160, height: "auto"}} src={logo} />
      </Link>
    </div>
  );
};

export default SidebarLogo;
