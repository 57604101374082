import { FunctionComponent, ReactElement, useEffect, useState } from "react";


const useDebounce = (value: any, deplay: any) => {
    const [debounceSearch, setDebounceSearch] = useState<string>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceSearch(value || '');
        }, deplay);

        return () => {
            clearTimeout(handler);
        }
    }, [value, deplay]);

    return debounceSearch;
};

export default useDebounce;