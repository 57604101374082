import { Button, Form } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import showNotification from "../../components/common/notification";
import InputCode from "../../components/controls/input-code";

import { useUserActions } from "../../hooks/useUserActions";
import { commonPageTitleSelector } from "../../states/common";

import {
  ArrowLeftOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { VALIDATION_MESSAGE_CONFIG } from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import "./index.scss"

interface verifyCodeType {
  callback: any;
  redirect: string;
  successMessage?: string;

  [key: string]: any;
}

const CODE_LENGTH: number = 6;

const VerifyCodePage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userActions = useUserActions();

  const setPageTitle = useSetRecoilState(commonPageTitleSelector);

  const codeRef = useRef("");
  const state: verifyCodeType = location.state;

  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);

  const deviceName = useMemo(() => {
    if (state?.email) {
      return t('common.viaEmail');
    }

    return t('common.viaSms');
  }, [state?.email]);

  async function formSubmit(value: any) {
    try {
      setLoading(true);
      setSubmited(true);
      if (codeRef.current.length !== CODE_LENGTH) {
        return;
      }
      const code = codeRef.current;
      const {callback, redirect, successMessage, ...payload} = state;
      if (callback) {
        await userActions[callback as keyof typeof userActions]({...payload, navigate, code } as any);
      }

      if(successMessage) {
        successMessage && showNotification("success", successMessage);
      }

      if (redirect) navigate(redirect, {
        state: { ...payload, code }
      });

    } finally {
      setLoading(false);
    }
  }

  function inputCodeOnChange(value: string) {
    codeRef.current = value;
    form.validateFields();
  }

  useEffect(() => {
    setPageTitle("");
  }, []);

  return (
    <>
      <h2>{t("resetPassword.confirmOTPTitle")}</h2>
      <p style={{ marginBottom: 30 }}>{t("resetPassword.confirmOTPDes", { device: deviceName})}</p>
      <Form
        initialValues={{ remember: true }}
        name="signInForm"
        className="gx-signin-form gx-form-row0 form-hidden-label sign-in-page"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={formSubmit}
        form={form}
      >
        <Form.Item name="code" label={t("resetPassword.newPassword")}>
          <InputCode
            length={CODE_LENGTH}
            formSubmited={submited}
            onChange={inputCodeOnChange}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 50 }}>
          <Button
            type="primary"
            className="gx-mb-0"
            htmlType="submit"
            loading={loading}
          >
            {t("verifyEmail.btnSubmit")}
          </Button>
        </Form.Item>

        <Link to={ROUTE_PATHS.signIn} className="backToSignIn">
        <ArrowLeftOutlined className="right-arrow" />
        {t("forgotPassword.backToSignIn")}
      </Link>
      </Form>
    </>
  );
};

export default VerifyCodePage;
