import {
  HistoryOutlined, PlayCircleOutlined,
  StopOutlined
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WebsiteApi } from "../../../apis/website.api";
import TicketIcon from "../../../assets/icons/TicketIcon";
import ProtectedAction from "../../../components/action-protected";
import BudgetCard from "../../../components/common/budget-card";
import GrowthCard from "../../../components/common/growth-card";
import IconWithTextCard from "../../../components/common/icon-with-text-card";
import StorageCard from "../../../components/common/storage-card";
import TrafficCard from "../../../components/common/traffic-card";
import WelcomeCard from "../../../components/common/welcome-card";
import { RoleEnum } from "../../../enums/app.enum";
import { WebsiteOverviewModel } from "../../../models/website.model";
import RenewWebsite from "../../../views/websites/renew-websites";
import SiteTypes from "../../../views/websites/site-type";
import SiteVisit from "../../../views/websites/site-visit";
import UserTable from "../../../views/users/user-table";

const trafficData = [
  { name: "Page A", value: 0 },
  { name: "Page B", value: 2000 },
  { name: "Page C", value: 600 },
  { name: "Page D", value: 4400 },
  { name: "Page D", value: 900 },
  { name: "Page H", value: 4860 },
];

const defaultValues = {
  siteGrowthLastWeek: "",
  siteGrowthLastMonth: "",
  siteGrowthLastYear: "",
  userBudget: 0,
  trafficRaise: "",
  storage: "",
  totalDemoWebsites: 0,
  totalExpiredWebsites: 0,
  totalRenewWebsites: 0,
  totalRuningWebsites: 0,
  webSiteChartModel: {},
  totalWebSites: 0
};

function WebsiteOverviewPage() {
  const { t } = useTranslation();

  const [overview, setOverview] = useState<WebsiteOverviewModel>(defaultValues);

  useEffect(() => {
    getSitesOverview();
  }, []);

  const getSitesOverview = async () => {
    const res = await WebsiteApi.getSitesOverview();
    setOverview(res.data);
  };

  return (
    <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User]}>
      <Row>
        <Col span={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                  <WelcomeCard unit={t("website.websites")}  number={overview.totalWebSites} />
                </Col>

                <Col
                  xl={6}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  className="gx-audi-col"
                >
                  <SiteTypes />
                </Col>

                <Col
                  xl={12}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="gx-visit-col"
                >
                  <SiteVisit data={overview.webSiteChartModel} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        {/* Admin, Mod */}
        <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
          <>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <GrowthCard
                fromColor="#82B7E2"
                toColor="#9087E1"
                trafficData={overview.siteGrowthLastWeek?.chartModels}
                title={t("website.lastWeek")}
                id="1"
                color="#52C41A"
                percent={overview.siteGrowthLastWeek?.percent}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                fromColor="#81CFE4"
                toColor="#65BF98"
                trafficData={overview.siteGrowthLastMonth?.chartModels}
                title={t("website.lastMonth")}
                id="2"
                color="#52C41A"
                percent={overview.siteGrowthLastMonth?.percent}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                fromColor="#AC52FF"
                toColor="#FA8C16"
                trafficData={overview.siteGrowthLastYear?.chartModels}
                title={t("website.lastYear")}
                id="3"
                color="#F5222D"
                status="down"
                percent={overview.siteGrowthLastYear?.percent}
              />
            </Col>
          </>
        </ProtectedAction>

        {/* User */}
        <ProtectedAction roles={[RoleEnum.User]}>
          <>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <BudgetCard
                budget={overview.userBudget}
                trafficData={trafficData}
                title={t("website.lastWeek")}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <TrafficCard
                fromColor="#82B7E2"
                toColor="#9087E1"
                trafficData={overview.trafficRaise?.chartModels}
                title={t("website.trafficRaise")}
                id="2"
                color="#52C41A"
                traffic={overview.trafficRaise?.percent}
              />
            </Col>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <StorageCard
                trafficData={trafficData}
                title={t("website.lastWeek")}
                storage={overview.storage}
              />
            </Col>
          </>
        </ProtectedAction>

        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <IconWithTextCard
            cardColor="blue"
            icon={<TicketIcon />}
            subTitle={t("website.demo")}
            numberWebsite={overview.totalDemoWebsites}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <IconWithTextCard
            cardColor="teal"
            icon={<PlayCircleOutlined />}
            subTitle={t("website.running")}
            numberWebsite={overview.totalRuningWebsites}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <IconWithTextCard
            cardColor="orange"
            icon={<HistoryOutlined />}
            subTitle={t("website.renew")}
            numberWebsite={overview.totalRenewWebsites}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <IconWithTextCard
            cardColor="red"
            icon={<StopOutlined />}
            subTitle={t("website.expired")}
            numberWebsite={overview.totalExpiredWebsites}
          />
        </Col>

        <Col span={24}>
          <ProtectedAction roles={[RoleEnum.User]}>
            <RenewWebsite />
          </ProtectedAction>

          <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
            <UserTable />
          </ProtectedAction>
        </Col>
      </Row>
    </ProtectedAction>
  );
}

export default WebsiteOverviewPage;
