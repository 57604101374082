import {
    deleteAsync,
    getAsync,
    postAsync,
    postFormDataAsync,
    putAsync,
    putFormDataAsync,
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/website`;

interface ThemeApiType {
    [key: string]: any;
}

export const ThemeApis: ThemeApiType = {
    getHardCoreData: (params: any) => {
        return getAsync(`${baseUrl}/hardcoded-datas`, params, true);
    },

    // reviews
    getReviewThemeOverviewById: (params: any) => {
        return getAsync(`${baseUrl}/rating/themes-rating`, params);
    },

    checkReviewThemeOverview: (themeId: any) => {
        return postAsync(`${baseUrl}/rating/check-rated-theme?themeId=${themeId}`, {}, false, true);
    },

    postReviewThemeOverview: (payload: any) => {
        return postAsync(`${baseUrl}/rating/rate-theme`, payload);
    },

    getReviewThemePluginById: (params: any) => {
        return getAsync(`${baseUrl}/rating/plugins-rating`, params);
    },

    postReviewThemePlugin: (payload: any) => {
        return postAsync(`${baseUrl}/rating/rate-plugin`, payload);
    },

    checkReviewThemePlugin: (pluginId: any) => {
        return postAsync(`${baseUrl}/rating/check-rated-plugin?pluginId=${pluginId}`, {}, false, true);
    },

    getReviewSampleDataById: (params: any) => {
        return getAsync(`${baseUrl}/rating/sampledatas-rating`, params);
    },

    postReviewSampleData: (payload: any) => {
        return postAsync(`${baseUrl}/rating/rate-sample-data`, payload);
    },

    checkReviewThemeSampleData: (sampleDataId: any) => {
        return postAsync(`${baseUrl}/rating/check-rated-sampledata?sampleDataId=${sampleDataId}`, {}, false, true);
    },

    // wordpress
    getWordpressVersion: (params?: any) => {
        return getAsync(`${baseUrl}/wordpress-version`, params, true);
    },

    claimWordpressVersion: (payload: any) => {
        return postAsync(`${baseUrl}/wordpress-version`, payload);
    },

    deleteWordpressVersion: (id: number) => {
        return deleteAsync(`${baseUrl}/wordpress-version/${id}`);
    },

    // theme
    getThemeOverview: () => {
        return getAsync(`${baseUrl}/theme/overview`);
    },

    activeThemeCategory: (categoryId: any) => {
        return putAsync(`${baseUrl}/categories/activate?id=${categoryId}`);
    },

    deactiveCategory: (params: any) => {
        return putAsync(`${baseUrl}/categories/deactivate?id=${params.id}&reason=${params.reason}`);
    },

    deleteSiteById: (id: any) => {
        return deleteAsync(`${baseUrl}/theme/${id}`)
    },

    getThemeCategories: (payloadCategories?: any) => {
        return getAsync(`${baseUrl}/categories`,  payloadCategories, true);
    },

    getThemeSiteDetail: (params: any) => {
        return getAsync(`${baseUrl}/theme/detail`, params, true);
    },

    addThemeOverviewCategory: (payload: any) => {
        return postFormDataAsync(`${baseUrl}/categories`, payload, false, true);
    },

    editThemeOverviewCategory: (payload: any) => {
        return putFormDataAsync(`${baseUrl}/categories`, payload, false, true);
    },

    deleteThemeCategory: (id: any) => {
        return deleteAsync(`${baseUrl}/categories/${id}`, {}, false, true);
    },

    getSiteByThemeCategory: (params: any) => {
        return getAsync(`${baseUrl}/theme`, params, true);
    },

    postSiteByThemeCategory: (payload: any) => {
        return postFormDataAsync(`${baseUrl}/theme`, payload);
    },

    updateSiteThemeOverview: (payload: any) => {
        return putFormDataAsync(`${baseUrl}/theme`, payload);
    },  

    deactiveSiteThemeOverview: (params: any) => {
        return putAsync(`${baseUrl}/theme/deactivate?id=${params.id}&reason=${params.reason}`);
    },

    activeSiteThemeOverview: (params: any) => {
        return putAsync(`${baseUrl}/theme/activate?id=${params.id}`);
    },

    approveSiteThemeOverview: (id : any) => {
        return putAsync(`${baseUrl}/theme/approve?id=${id}`);
    },

    rejectSiteThemeOverview: (params : any) => {
        return putAsync(`${baseUrl}/theme/reject?id=${params.id}&reason=${params.reason}`);
    },

    downloadTheme: (payload: any) => {
        return getAsync(`${baseUrl}/theme/download`, payload);
    },
    
    themeFilterData: () => {
        return getAsync(`${baseUrl}/theme/filter-data`);
    },

    // plugin
    getThemePluginSiteByCategory: (params: any) => {
        return getAsync(`${baseUrl}/plugin`, params, true);
    },

    getThemePluginSiteFilterData: () => {
        return getAsync(`${baseUrl}/plugin/filter-data`);
    },

    getThemePluginDetail: (id: string | number) => {
        return getAsync(`${baseUrl}/plugin/detail?id=${id}`);
    },

    postThemePluginSite: (payload: any) => {
        return postFormDataAsync(`${baseUrl}/plugin`, payload);
    },

    updateThemePluginSite: (payload: any) => {
        return putFormDataAsync(`${baseUrl}/plugin`, payload);
    },

    getThemePluginCategories: (payload?: any) => {
        return getAsync(`${baseUrl}/plugin-categories`, payload, true);
    },

    addNewThemePluginCategory: (payload: any) => {
        return postAsync(`${baseUrl}/plugin-categories`, payload);
    },

    editThemePluginCategory: (payload: any) => {
        return putAsync(`${baseUrl}/plugin-categories`, payload);
    },

    activePluginCategory: (params: any) => {
        return putAsync(`${baseUrl}/plugin-categories/activated?id=${params.id}`);
    },

    deActivePluginCategory: (params: any) => {
        return putAsync(`${baseUrl}/plugin-categories/deactivated?id=${params.id}&reason=${params.reason}`);
    },

    deletePluginCategory: (params: any) => {
        return deleteAsync(`${baseUrl}/plugin-categories/${params.id}`);
    },

    rejectPluginSite: (payload: any) => {
        return putAsync(`${baseUrl}/plugin/reject?id=${payload.id}&reason=${payload.reason}`);
    },

    deactivePluginSite: (payload: any) => {
        return putAsync(`${baseUrl}/plugin/deactivate?id=${payload.id}&reason=${payload.reason}`);
    },

    approvePluginSite: (id : any) => {
        return putAsync(`${baseUrl}/plugin/approve?id=${id}`);
    },

    activePluginSite: (id : any) => {
        return putAsync(`${baseUrl}/plugin/activate?id=${id}`);
    },

    deletePluginSite: (id: any) => {
        return deleteAsync(`${baseUrl}/plugin/${id}`);
    },

    downloadPluginFile: (payload: any) => {
        return getAsync(`${baseUrl}/plugin/download`, payload); 
    },

    // sample data
    getSampleDataCategory: (params: any) => {
        return getAsync(`${baseUrl}/sampledata-categories`, params, true);
    },

    addNewSampleDataCategory: (payload: any) => {
        return postAsync(`${baseUrl}/sampledata-categories`, payload);
    },

    editSampleDataCategory: (payload: any) => {
        return putAsync(`${baseUrl}/sampledata-categories`, payload);
    },

    activeSampleDataCategory: (id: any) => {
        return putAsync(`${baseUrl}/sampledata-categories/activated?id=${id}`);
    },

    deActiveSampleDataCategory: (params: any) => {
        return putAsync(`${baseUrl}/sampledata-categories/deactivated?id=${params.id}&reason=${params.reason}`);
    },
    
    deleteSampleDataCategory: (id: any) => {
        return deleteAsync(`${baseUrl}/sampledata-categories/${id}`);
    },

    getSampleData: (params: any) => {
        return getAsync(`${baseUrl}/sampledata`, params);
    },

    getSampleDataFilterData: () => {
        return getAsync(`${baseUrl}/sampledata/filter-data`);
    },

    postSampleData: (payload: any) => {
        return postFormDataAsync(`${baseUrl}/sampledata`, payload);
    },

    updateSampleDataSiteData: (payload: any) => {
        return putFormDataAsync(`${baseUrl}/sampledata`, payload);
    },

    getSampleDataSiteDetail: (params: any) => {
      return getAsync(`${baseUrl}/sampledata/detail`, params, true);
    },

    rejectSampleDataSite: (payload: any) => {
        return putAsync(`${baseUrl}/sampledata/reject?id=${payload.id}&reason=${payload.reason}`);
    },

    deactivateSampleDataSite: (payload: any) => {
        return putAsync(`${baseUrl}/sampledata/deactivate?id=${payload.id}&reason=${payload.reason}`);
    },

    downloadSampleDataFile: (payload: any) => {
        return getAsync(`${baseUrl}/sampledata/download`, payload); 
    },

    activeSampleDataSite: (id : any) => {
        return putAsync(`${baseUrl}/sampledata/activate?id=${id}`);
    },

    approveSampleDataSite: (id : any) => {
        return putAsync(`${baseUrl}/sampledata/approve?id=${id}`);
    },

    deleteSampleDataSite: (id: any) => {
        return deleteAsync(`${baseUrl}/sampledata/${id}`);
    },

};
