import { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import noDataImage from "../../../../assets/images/user/no-data.png";

import './index.scss';

type TableNoDataProps = {
    children?: any;
}

const TableNoData: FunctionComponent<TableNoDataProps> = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div className="table-no-data">
            <div className="table-no-data__content">
                <div className="no-data-img">
                    <img src={noDataImage} alt="no-data" />
                </div>
                <h3 className="no-data__title">{t("user.tableNoDataDesc")}</h3>
                <div className="content__children">{ children }</div>
            </div>
        </div>
    );
};

export default TableNoData;