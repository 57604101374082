import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Switch, Upload } from "antd";
import { FunctionComponent, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";

import i18n from "../../../../utils/i18n";
import { generateImage } from "../../../../utils/app.utils";

import './index.scss';

const initialImages = {
    uid: 1,
    status: 'done',
    name: 'image.png',
};

interface CategoryActionProps {
    value?: any;
    isEdit?: boolean;
    onSubmit?: (value: any) => void;

    [key: string]: any;
}

const formItemLayout = {
    labelCol: { span: 24 },

    wrapperCol: { span: 12 },

    className: "category-action-form__item"
};

const formItemUploadLayout = {
    labelCol: { span: 24 },

    wrapperCol: { span: 24 },

    className: 'category-action-form__item category-action-form__upload'
}

const switchLayout = {
    // labelCol: {
    //     xs: { span: 24 },
    //     sm: { span: 9 },
    // },
    // wrapperCol: {
    //     xs: { span: 24 },
    //     sm: { span: 15 },
    // },

    className: "category-action-form__item--switch"
};

const validateSchema = {
    name: [
        {
            required: true,
            message: i18n.t('theme.formCategoryNameValidate', { label: i18n.t('theme.categoryName') })
        }
    ],
}

const CategoryAction: FunctionComponent<CategoryActionProps> = (props) => {
    // ultils
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { isEdit, onSubmit, value, ...modalProps } = props;

    const handleOkButtonClick = () => {
        form.submit();
    };

    const handleFormSubmit = (value: any) => {
        if (onSubmit) {
            onSubmit(value);
        };
    };

    const resetFormModal = () => {
        form.resetFields();
    }

    useEffect(() => {
        if (value) {
            const initialValue = { ...value };
            delete initialValue.image;
            delete initialValue.isDeactivated;

            form.setFieldsValue({...initialValue, isDeactivated: !value?.isDeactivated});
            if (value.image) {
                form.setFieldValue('image', [{ ...initialImages, url: generateImage(value.image) }]);
            }
        };
    }, [value]);

    useEffect(() => {
        if (!props.open) {
            resetFormModal();
        };
    }, [props.open])

    return (
        <Modal
            closable
            {...modalProps}
            afterClose={resetFormModal}
            className="category-action-modal"
            title={t(isEdit ? 'theme.editCategory' : 'theme.addCategory')}
            okText={t('common.save')}
            onOk={handleOkButtonClick}
        >
            <Form form={form} className="category-action-form" onFinish={handleFormSubmit}>
                <Form.Item {...formItemLayout} name='name' label={t('theme.categoryName')} rules={validateSchema.name}>
                    <Input />
                </Form.Item>

                <Form.Item 
                    {...formItemUploadLayout} 
                    name="image"
                    valuePropName="fileList"
                    label={t('theme.thumbnail')}
                    getValueFromEvent={event => {
                        if (Array.isArray(event)) {
                            return event;
                        }

                        return event && event.fileList;
                    }}
                >
                    <Upload
                        maxCount={1}
                        listType="picture-card"
                        beforeUpload={() => false}
                    >
                        <PlusOutlined />
                        <span className="category-action-form__upload--text">{t('common.upload')}</span>
                    </Upload>
                </Form.Item>

                <Form.Item {...switchLayout} name="isDeactivated" label={t('theme.catgoryIsActive')} initialValue={true} valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CategoryAction;
