import React from "react";
import LineIndicator from "./line-indicator";
import { useTranslation } from "react-i18next";

const SiteTypes = () => {
  const { t } = useTranslation();

  return (
    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
      <h6 style={{fontWeight: 500, color: "#000" }} className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">
        {t("website.siteType")}
      </h6>
      <ul className="gx-line-indicator">
        <li>
          <LineIndicator
            width="100%"
            title={t("website.production")}
            color="blue"
            value=""
          />
        </li>
        <li>
          <LineIndicator
            width="100%"
            title={t("website.demo")}
            color="orange"
            value=""
          />
        </li>
        <li>
          <LineIndicator
            width="100%"
            title={t("website.stopped")}
            color="red"
            value=""
          />
        </li>
      </ul>
    </div>
  );
};
export default SiteTypes;
