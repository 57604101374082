import {
  FacebookOutlined,
  GithubOutlined,
  GoogleOutlined,
  LockOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useSetRecoilState } from "recoil";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, InputNumber } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { commonPageTitleSelector } from "../../states/common";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { VALIDATION_MESSAGE_CONFIG } from "../../constants/app-constants";

import { useUserActions } from "../../hooks/useUserActions";

import "./index.scss";

const SignInPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userActions = useUserActions();

  const state: any = location.state;

  const [loading, setLoading] = useState(false);
  const [isConnectPhone, setIsConnectPhone] = useState(false);

  const setPageTitle = useSetRecoilState(commonPageTitleSelector);

  function getRedirectUrl() {
    if (state && state.redirectUrl) {
      return state.redirectUrl;
    }

    return ROUTE_PATHS.Home;
  }

  const FormEmailorPhone = useCallback(() => {
    if (isConnectPhone) {
      return (
        <Form.Item
          className="phoneNumber"
          rules={[
            { required: true },
            {
              pattern: /^(?:\d*)$/,
              message: "Value should contain just number",
            },
          ]}
          name="phoneNumber"
          label={t("common.phone")}
        >
          <Input
            prefix={<PhoneOutlined className="phone-icon" />}
            placeholder={t("common.phone")}
            maxLength={10}
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        rules={[{ required: true }, { type: "email" }]}
        name="email"
        label={t("signIn.email")}
      >
        <Input prefix={<UserOutlined />} placeholder={t("common.email")} />
      </Form.Item>
    );
  }, [isConnectPhone]);

  const connectWithText = useMemo(() => {
    if (!isConnectPhone) {
      return t("user.phoneNumber");
    }

    return t("common.email");
  }, [isConnectPhone]);

  const handleChangeEmailtoPhone = () => {
    setIsConnectPhone(!isConnectPhone);
  };

  async function signIn() {
    setLoading(true);
    try {
      const value = form.getFieldsValue();

      await userActions.signIn({
        ...value,
        is_remember: value?.is_remember ?? false,
      });
      const redirectUrl = getRedirectUrl();
      navigate(redirectUrl);
    } catch {}
    setLoading(false);
  }

  useEffect(() => {
    setPageTitle(t("signIn.title"));
  }, []);
  return (
    <Form
      initialValues={{ remember: true }}
      name="signInForm"
      className="gx-signin-form gx-form-row0 form-hidden-label sign-in-page"
      validateMessages={VALIDATION_MESSAGE_CONFIG}
      onFinish={signIn}
      form={form}
    >
      <FormEmailorPhone />

      <Form.Item
        rules={[{ required: true }]}
        name="password"
        label={t("signIn.password")}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder={t("signIn.password")}
        />
      </Form.Item>

      <div className="gx-login-form-forgot">
        <Link to={ROUTE_PATHS.forgotPassword}>
          {t("signIn.forgotPassword")}
        </Link>
      </div>

      <Form.Item name="is_remember" valuePropName="checked">
        <Checkbox>{t("signIn.rememberMe")}</Checkbox>
      </Form.Item>

      <Form.Item>
        <div className="signInOrSignUp">
          <div>
            <Button
              type="primary"
              htmlType="submit"
              className="gx-mb-0 gx-mr-0"
              loading={loading}
            >
              {t("signIn.btnSubmit")}
            </Button>
          </div>

          <div>
            <span>{t("common.or")}</span>
          </div>

          <div>
            <Link to={ROUTE_PATHS.signUp}>{t("signIn.signUp")}</Link>
          </div>
        </div>
      </Form.Item>
      <div className="gx-flex-row gx-justify-content-between">
        <span onClick={handleChangeEmailtoPhone} >
          {t("common.orConnectWith")} <span className="emailOrPhone" >{connectWithText.toLowerCase()}</span>
        </span>
        {/* <ul className="gx-social-link">
          <li>
            <GoogleOutlined />
          </li>
          <li>
            <FacebookOutlined />
          </li>
          <li>
            <GithubOutlined />
          </li>
          <li>
            <TwitterOutlined />
          </li>
        </ul> */}
      </div>
    </Form>
  );
};

export default SignInPage;
