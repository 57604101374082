import { Button, Col, Row } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";

import ProtectedAction from "../../../../components/action-protected";
import { RoleEnum } from "../../../../enums/app.enum";

import "./index.scss";

interface HeaderProps {
  title?: string;
}

const CategoryDetailHeader: FunctionComponent<HeaderProps> = ({ title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [titles, setTitles] = useState<boolean>(false);
  const params = useParams();

  const handleCategory = () => {
    navigate("/themes/category/all");
  };

  const isNotShowCloseIcon = useMemo(() => {
    return params?.category !== 'all';
  }, [params]);


  return (
    <Row justify="space-between" align="middle" className="category-header">
      <Col span={6}>
        <div className="category-header__title">
          {t("theme.category")}:
          <span className="category-header__title--category">{isNotShowCloseIcon ? title : t('common.all')}</span>
          {isNotShowCloseIcon && <span onClick={handleCategory} className="ant-icon"><CloseCircleOutlined /></span>}
        </div>
      </Col>

      <Col span={18} className="category-header__button">
        <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User]}>
          <Link to="/themes/categories/upload">
            <Button className="ant-btn-primary">
              <UploadOutlined />
              {t("common.upload")}
            </Button>
          </Link>
        </ProtectedAction>
      </Col>
    </Row>
  );
};

export default CategoryDetailHeader;
