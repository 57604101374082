import { Layout } from "antd";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import useNotification from "../../../hooks/useNotification";
import { commonCurrencySelector, commonWidthSelector } from "../../../states/common";
import AppHeader from "../../common/header";
import Sidebar from "../../common/sidebar";
import { AuthApi } from "../../../apis/auth.api";
const { Content, Footer } = Layout;

const MainLayout = () => {
  const setWidth = useSetRecoilState(commonWidthSelector);
  const setCurrencyConfig = useSetRecoilState(commonCurrencySelector);
  useNotification();

  const loadCurrencyConfig = async () => {
    const result = await AuthApi.getCurrencySystem();

    if (result?.status === 200) {
      setCurrencyConfig(result?.data);
    }
  };


  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    loadCurrencyConfig()

  }, []);

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <AppHeader />
        <Content className={`gx-layout-content`}>
          <div className="gx-main-content-wrapper">
            <Outlet />
          </div>
          <Footer>
            <div className="gx-layout-footer-content">
              Copyright @ 2022 {`<webhub/>`}. v1.0.2022.11.01
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
