import { Button, Rate } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FunctionComponent, ReactElement } from "react";

import { generateImage } from "../../../../utils/app.utils";

import "./index.scss";
import ImageLoad from "../../../../components/common/image-load";
import { IMAGE_DEFAULT_TYPE } from "../../../../constants/app-constants";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";

interface ThemeCardProps {
    data?: any;
    state?: any;
    link?: string;
    isFree?: boolean;
    children?: ReactElement;
}

const ThemeCardGrid: FunctionComponent<ThemeCardProps> = (props) => {
    const { t } = useTranslation();
    const { currencyConfig, convertNumericToFormattedAmount } = useCurrencyConfig();

    const { data, link, children, isFree = false } = props;

    const handlePreviewClick = (event: any) => {
        event.preventDefault();
    };

    return (
        <div className="grid-theme-item">
            <Link to={link || ''} state={props.state || ''}>
                <div className="gx-product-item gx-product-vertical grid-theme-item__wrapper">
                    <div className="gx-product-image theme-detail__image">
                        <ImageLoad type={IMAGE_DEFAULT_TYPE.themePreview} src={generateImage(data?.image)} alt={data?.name}
                            className="theme-detail-image"
                        />

                        {!data?.isApproved && <div className="theme-detail__status">
                            {t('common.pending')}
                        </div>}
                    </div>

                    <div className="theme__info">

                        <div className="gx-product-name theme__name">
                            <h3 className="theme__info--title">{data.name}</h3>
                            <small className="theme__info--author">{t('common.by')} {data.author}</small>
                        </div>
                        <div className="gx-product-price theme__info--price">{!isFree ? convertNumericToFormattedAmount(data.price) : 0} {currencyConfig.currency}</div>
                        <div className="theme__info--rate rate">
                            <div className="rate__sales">{data.totalSales} {t('theme.sales')}</div>
                            <div className="rate__star"><Rate allowHalf value={data.rating} disabled /></div>
                        </div>

                        <div className="theme__info--button">
                            {children ?? <Button onClick={handlePreviewClick} className="gx-btn-outline-primary">{t('common.livePreview')}</Button>}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

ThemeCardGrid.defaultProps = {
    link: '',
    data: {},
};

export default ThemeCardGrid;
