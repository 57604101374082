import { Col, Row } from "antd";

import Widget from "../widget";

import './index.scss';

interface Props {
  cardColor: string;
  icon: JSX.Element;
  number: string | number;
  subTitle: string;
}

const IconWithTextCardInUser = (props: Props) => {
  const { cardColor, icon, number, subTitle } = props;
  return (
    <Widget styleName={`gx-card-full gx-p-3 gx-border gx-border-${cardColor} gx-text-${cardColor}`} >
      <Row align="middle" gutter={[50, 10]} className="user-content">
        <Col lg={6} md={8} sm={6} xs={8} className="user-content__icon">
          <div className="user-content__icon--content">
            {icon}
          </div>
        </Col>
        <Col lg={18} md={16} sm={6} xs={8} className="user-content__info">
          <div
            className={`gx-font-weight-semi-bold gx-mb-1 gx-text-${cardColor} user-content__info--number`}
          >
            {number}
          </div>
          <p className="gx-mb-0 user-content__info--title">{subTitle}</p>
        </Col>
      </Row>
    </Widget>
  );
};

export default IconWithTextCardInUser;
