import { Select } from "antd";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";

import { UserApi } from "../../../apis/user.api";
import useDebounce from "../../../hooks/useDebounce";
import { PAGE_SIZE } from "../../../constants/app-constants";
import { initObserver } from "../../../hooks/useObserver";

interface UploadAuthProps {
    [key: string]: any;
}

const UploadBySelect: FunctionComponent<UploadAuthProps> = (props) => {

    const { onSelect, ...selectProps } = props;

    const selectBoxRef = useRef(null);

    // state data
    const [search, setSearch] = useState<string>('');
    const [authorList, setAuthorList] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalAuthor, setTotalAuthor] = useState<number>(0);

    const debounceSearchTerm = useDebounce(search, 400);

    const payloadRequestAuthor = useMemo(() => {
        return {
            SearchText: search,
            pageIndex: currentPage,
            pageSize: PAGE_SIZE,
        }
    }, [currentPage, search]);

    const handleSelectSearch = (value: string) => {
        resetOptionData();
        setSearch(value);
    };

    const handlePopupVisibleChange = (open: boolean) => {
        if (open) {
            resetOptionData();
        }
    };

    const handleSelectPopupScroll = (event: any) => {
        const target = event.target;

        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            scrollToEnd();
        }
    };

    const resetOptionData = () => {
        setSearch('');
        setAuthorList([]);
        setTotalAuthor(0);
        setCurrentPage(1);
    };

    const handleSelectAuthorFocus = () => {
        resetOptionData();
        getAuthorData();
    };

    const scrollToEnd = () => {
        setCurrentPage(currentPage + 1);
        getAuthorData();
    };

    const getAuthorData = async () => {
        try {
            setLoading(true);
            if (authorList.length >= totalAuthor && totalAuthor > 0) {
                return;
            }

            const result = await UserApi.searchAuthor(payloadRequestAuthor);
            
            if (result?.data) {
                
                if (totalAuthor && authorList.length < totalAuthor) {
                    const previousAuthorState = [...authorList];
                    
                    const addedAuthorList = previousAuthorState.concat(result.data.records);
                    
                    setAuthorList(addedAuthorList);
                }
                else {
                    setAuthorList(result.data.records);
                };
                
                setTotalAuthor(result.data.totalRecords);

            } else {
                setAuthorList([]);
                setTotalAuthor(0);
            };

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (debounceSearchTerm) {
            getAuthorData();
        }
    }, [debounceSearchTerm]);

    return (
        <Select
            {...selectProps}
            ref={selectBoxRef}
            showSearch
            allowClear
            loading={loading}
            filterOption={false}


            options={authorList?.map((author: any) => ({
                value: author.id,
                label: author.fullName
            }))}

            onFocus={handleSelectAuthorFocus}
            onPopupScroll={handleSelectPopupScroll}
            onSearch={handleSelectSearch}
            onDropdownVisibleChange={handlePopupVisibleChange}
        />
    );
};

export default UploadBySelect;
