import { Link } from "react-router-dom";
import { FunctionComponent } from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { Card, Col, Modal, Row, Tooltip, Typography } from "antd";

import i18n from "../../../utils/i18n";
import { RoleEnum } from "../../../enums/app.enum";

import DashboardTable from "../../../views/users/user-list/table";
import ProtectedAction from "../../../components/action-protected";
import DashbooardUserFilter from "../../../views/users/user-list/filter";

import {
    CloseCircleOutlined,
    WalletOutlined,
    NotificationOutlined,
} from "@ant-design/icons";

import './index.scss';

const SAMPLE_DATA = [
    {
        id: 1,
        name: "Darlene Robertson",
        avatar:
            "https://bloganchoi.com/wp-content/uploads/2018/09/james-jirayu-1-2.jpg",
        noOfSites: "2",
        role: 2,
        status: true,
        createdAt: "12/02/2022 08:02:22",
        budget: "3.490.000",
        earned: "21.430.202",
        themes: 3,
        sites: 2,
        users: 4,
        rating: 5,
        email: "arleneg@gmail.com",
        phone: "+84 898 434 302",
        company: "Company",
        active: true,
    },

    {
        id: 2,
        name: "Darlene Robertson",
        avatar:
            "https://bloganchoi.com/wp-content/uploads/2018/09/james-jirayu-1-2.jpg",
        noOfSites: "2",
        role: 4,
        status: false,
        createdAt: "12/02/2022 08:02:22",
        budget: "3.490.000",
        earned: "21.430.202",
        themes: 3,
        sites: 2,
        users: 4,
        rating: 5,
        email: "arleneg@gmail.com",
        phone: "+84 898 434 302",
        company: "Company",
        active: false,
    },

    {
        id: 3,
        name: "Darlene Robertson",
        avatar:
            "https://bloganchoi.com/wp-content/uploads/2018/09/james-jirayu-1-2.jpg",
        noOfSites: "2",
        role: 8,
        status: true,
        createdAt: "12/02/2022 08:02:22",
        budget: "3.490.000",
        earned: "21.430.202",
        themes: 3,
        sites: 2,
        users: 4,
        rating: 5,
        email: "arleneg@gmail.com",
        phone: "+84 898 434 302",
        company: "Company",
        active: null,
    },
];

const { confirm } = Modal;
const { Text } = Typography;

const config = {
    title: i18n.t('dashboard.confirmRemind'),
};


const DashboardUserPage: FunctionComponent<any> = () => {
    const { t } = useTranslation();

    const tableColumns: ColumnsType<any> = [
        {
            key: "options",
            className: "column__item options",
            render: (text, record) => {
                const isActive = !!record.active;

                return (
                    <div className="option-wrapper">
                        <div className="options__item">
                            <Tooltip
                                title={isActive ? t("common.remind") : t("common.remove")}
                            >
                                {isActive ? <NotificationOutlined onClick={() => handleRemindIconClick(record.id)} /> : <CloseCircleOutlined />}
                            </Tooltip>
                        </div>

                        <div className="options__item">
                            <Tooltip title={t("user.budget")}>
                                <Link to={`/users/budget/${record.id}`} >
                                    <WalletOutlined />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                )
            },
        },

        {
            key: "action",
            className: "column__item action",
            render: (text, record) => (
                <Text
                    className="action__review"
                    type="secondary"
                >
                    {t("common.review")}
                </Text>
            ),
        },
    ];

    const handleRemindIconClick = (userId: string | number) => {
        confirm({
            ...config,
            onOk() {
                console.log('OK', userId);
            },
        });
    };

    const handleFilter = (value: number) => {
        console.log('dashboardUser', value);
    };

    return (
        <ProtectedAction roles={[RoleEnum.Accountant]}>
            <Card title={t('dashboard.userCardTitle')} className="dashboard-user">
                <Row>
                    <Col span={24} className="dashboard-user__filter">
                        <DashbooardUserFilter onFilter={handleFilter} />
                    </Col>

                    <Col span={24} className="dashboard-user__table">
                        <DashboardTable data={SAMPLE_DATA} columns={tableColumns}/>
                    </Col>
                </Row>
            </Card>
        </ProtectedAction>
    );
};

export default DashboardUserPage; 