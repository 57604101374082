export interface RegisterTokenModel {
  token: string;
  notificationDeviceType: NotificationDeviceType;
}

export enum NotificationDeviceType {
  /// <summary>
  /// Website
  /// </summary>
  Web = 0,

  /// <summary>
  /// Android
  /// </summary>
  Android = 1,

  /// <summary>
  /// Ios
  /// </summary>
  IOS = 2,
}
