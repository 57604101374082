import { Card, Pagination } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";


import WordpressItem from "../../../views/theme/wordpress/wordpress-item";
import ThemeWordpressFilters from "../../../views/theme/wordpress/theme-wordpress-filters";

import { ThemeApis } from "../../../apis/theme.api";
import { PAGE_SIZE } from "../../../constants/app-constants";
import { deleteWordpressVersionSelector, wordpressVersionsSelector } from "../../../states/theme";

import './index.scss';

const ThemeWordpressPage = () => {
  // ultils
  const { t } = useTranslation();

  // state data
  const [filter, setFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);

  // recoil
  const [ wordpress, setWordPress ] = useRecoilState(wordpressVersionsSelector);
  const setWordpressVersion = useSetRecoilState(deleteWordpressVersionSelector);

  // pagination
  const handlePaginationListChange = (page: number) => {
    setCurrentPage(page);
  };

  // computed
  const pagination: any = useMemo(() => {
    return {
      pageSize: PAGE_SIZE,
      current: currentPage,
      total: wordpress.totalRecords,
      onChange: handlePaginationListChange,
    }
  }, [currentPage, wordpress.totalRecords ]);

  const filterPayload = useMemo(() => {
    return {
      ...filter,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
    }
  }, [pagination, filter]);

  const onFilterSearchByName = (searchText: string) => {
    setFilter({...filter, name: searchText });
  };

  const onFilterByType = (typeEnum: number ) => {
    setFilter({ ...filter, versionFilterType: typeEnum });
  };

  const loadWordpressVersion = async () => {
    const result = await ThemeApis.getWordpressVersion(filterPayload);

    if (result?.data) {
      setWordPress(result.data);
    }
  };

  const claimWordpressVersion = async (versionId: number) => {
    const result = await ThemeApis.claimWordpressVersion({id: versionId});

    if (result?.data) {
      // deleteWordpressVersion(versionId);
    };
  };

  const deleteWordpressVersion = async (versionId : any) => {
    const result = await ThemeApis.deleteWordpressVersion(versionId);
    if (result?.data || result?.data == '') {
      setWordpressVersion(versionId);
    };
  };

  useEffect(() => {
    loadWordpressVersion();
  }, [filterPayload])

  useEffect(() => {
    loadWordpressVersion();
  }, []);

  return (
    <div className="theme-wordpress-page">
      <Card title={t("theme.version")}>
        <ThemeWordpressFilters onSearch={onFilterSearchByName} onFilter={onFilterByType} />

        <div className="theme-wordpress-list">
          {wordpress?.records?.map((version : any) => {
            return <WordpressItem
                      data={version} 
                      key={version.id} 
                      onClaim={() => claimWordpressVersion(version.id)}
                      onDelete={() => deleteWordpressVersion(version.id)} 
                    />
          })}

          <div className="pagination">
            {!!wordpress.totalRecords && <Pagination {...pagination} />}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ThemeWordpressPage;
