import { PhoneNumberUtil } from "google-libphonenumber";
import { Button, Form, Input, InputNumber, Spin } from "antd";

import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AuthApi } from "../../apis/auth.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { commonPageTitleSelector } from "../../states/common";
import "./index.scss";

const ForgotPasswordPage = () => {
  const setPageTitle = useSetRecoilState(commonPageTitleSelector);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate()

  const [forgotWithPhone, setForgotWithPhone] = useState(false);

  const forgotText = !forgotWithPhone
    ? t("user.phoneNumber")
    : t("common.email");

  useEffect(() => {
    setPageTitle("");
  }, []);

  async function submitForm() {
    setLoading(true);
    const email = form.getFieldValue("email");
    const phoneNumber = form.getFieldValue("phoneNumber");
    try {
      await AuthApi.forgotPassword(email, phoneNumber);
      setEmail( email);
      setPhone(phoneNumber)

      if(forgotWithPhone) {
        navigate(ROUTE_PATHS.verifyCode,{
          state: {
            phoneNumber,
            callback: null,
            redirect: ROUTE_PATHS.resetPassword,
          }
        })
      }
      else {
        setSubmited(true);

      }

    } catch {}
    setLoading(false);
  }

  async function resendEmail() {
    setLoading(true);
    try {
      await AuthApi.forgotPassword(email, phone);
    } catch {}
    setLoading(false);
  }

  return (
    <div className="forgot-page">
      <h2>{t("forgotPassword.title")}</h2>
      {submited ? (
        <div className="sent">
          <div>
            <CheckCircleOutlined className="checkIcon" />
          </div>
          <div className="desc">
            We’ve sent a Password Reset Link to {email}. Please check your
            mailbox.
            <div style={{ marginTop: 20 }}>
              {t("forgotPassword.notReceiveEmail")}{" "}
              {!loading ? (
                <span
                  onClick={resendEmail}
                  style={{ textDecoration: "underline" }}
                  className="gx-link"
                >
                  {t("forgotPassword.resend")}
                </span>
              ) : (
                <Spin className="spin" size="small" />
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <p style={{ marginBottom: 10 }}>{t("forgotPassword.des")}</p>
          <div className="forgotWithPhone">
            {t("forgotPassword.orRecoverBy")}{" "}
            <span
              onClick={() => setForgotWithPhone(!forgotWithPhone)}
              className="orRecoverBy"
            >
              {forgotText.toLowerCase()}
            </span>
          </div>
          <Form
            initialValues={{ remember: true }}
            name="signInForm"
            className="gx-signin-form gx-form-row0 form-hidden-label sign-in-page"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
            form={form}
            onFinish={submitForm}
          >
            {!forgotWithPhone && (
              <Form.Item
                rules={[{ required: true }, { type: "email" }]}
                name="email"
                label={t("forgotPassword.email")}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder={t("forgotPassword.email")}
                />
              </Form.Item>
            )}

            {forgotWithPhone && (
              <Form.Item
                className="phoneNumber"
                rules={[
                  { required: true },
                  {
                    pattern: /^(?:\d*)$/,
                    message: t('signUp.validate-phone-number-is-number'),
                  },
      
                  () => ({
                    validator(_, value) {
                      if (!value || isNaN(parseInt(value))) {
                        return Promise.resolve();
                      }
                      const stringNumber = value.toString();
                      if (stringNumber.length < 2) {
                        return Promise.reject(new Error(t('signUp.validate-invalid-phone-number')));
                      }
      
                      const phoneUtil = PhoneNumberUtil.getInstance();
                      if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(stringNumber, 'VN'), 'VN')  && value.length === 10) {
                        return Promise.resolve();
                      }
      
                      return Promise.reject(new Error(t('signUp.validate-invalid-phone-number')));
                    },
                  }),
                ]}
                name="phoneNumber"
                label={t("user.phoneNumber")}
              >
                <Input
                  prefix={<PhoneOutlined className="phone-icon" />}
                  placeholder={t("user.phoneNumber")}
                  maxLength={10}
                />
              </Form.Item>
            )}

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                className="gx-mb-0"
                htmlType="submit"
                loading={loading}
              >
                {t("forgotPassword.btnSubmit")}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      <Link to={ROUTE_PATHS.signIn} className="backToSignIn">
        <ArrowLeftOutlined className="right-arrow" />
        {t("forgotPassword.backToSignIn")}
      </Link>
    </div>
  );
};

export default ForgotPasswordPage;
