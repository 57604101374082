import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../constants/router.constants';
import './index.scss';

interface UserNameProps {
    data?: Value;
};

type Value = {
    id: string,
    avatar?: string,
    name?: string,
}

const UserName: React.FunctionComponent<UserNameProps> = ({ data }) => {
    return (
        <div className="gx-flex-row gx-align-items-center user-name">
            <p className="gx-mb-0">{data?.name}</p>
        </div>
    );
};

export default UserName;
