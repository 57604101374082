import classNames from "classnames";
import { Col, Row, Switch } from "antd";
import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";
import { UserTypeEnum } from "../../../../enums/website.enum";
import { UserRole, UserTypes } from "../../../../constants/app-constants";

import "./index.scss";
import { RoleEnum } from "../../../../enums/app.enum";

interface FilterProps {
  onFilter?: (value: any) => void;
}

const UserListFilter: FunctionComponent<FilterProps> = ({ onFilter }) => {
  const { t } = useTranslation();
  const [showSwitch, setShowSwitch] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<any>([]);

  const handleSearchClick = () => {
    setShowSwitch(!showSwitch);
  };

  const handleSwitchChange = (value: string, status: boolean) => {
    const filterNumber = (() => {
      if (status) {
        return [...filterOptions, value];
      }

      return filterOptions.filter((option: any) => option !== value);
    })();

    setFilterOptions(filterNumber);

    if (onFilter) {
      onFilter(filterNumber);
    }
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      className="user-list-filter filter"
    >
      <Col
        span={24}
        className={classNames([
          "filter__search",
          { "not-show": !showSwitch },
        ])}
      >
        <div className="filter__search-wrapper" onClick={handleSearchClick}>
          <span className="filter__search--text">
            {t("dashboard.searchAdvance")}
          </span>
          <i
            className={`icon icon-menu-${
              showSwitch ? "up" : "down"
            } gx-fs-lg filter__search--icon`}
          />
        </div>
      </Col>

      <Col
        xs={24}
        sm={20}
        md={12}
        lg={16}
        className={classNames([
          "filter__switch switch",
          { "show": showSwitch },
        ])}
      >
        <Row justify="start" align="middle">
          {UserTypes.map((user) => {
            if (user.id === RoleEnum.Admin || user.id === RoleEnum.Accountant) return "";

            return (
              <Col className="switch-item" key={user.id}>
                <label className="switch-item__label">{user.name}</label>
                <Switch
                  className={classNames(["switch-item__label", user.color])}
                  onChange={(checked) => handleSwitchChange(user.id, checked)}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default UserListFilter;
