import { Col, Divider, Row } from "antd";
import { FunctionComponent } from "react";

import { CheckCircleOutlined } from "@ant-design/icons";

import { RoleEnum } from "../../../../enums/app.enum";
import ProtectedAction from "../../../../components/action-protected";
import PopConfirmAction from "../../../../components/controls/popconfirm-action";

import './index.scss';

interface BudgetTableProps {
    onDelete?: () => void;
    onApprove?: () => void;
}

const BudgetTableAction: FunctionComponent<BudgetTableProps> = ({ onDelete, onApprove }) => {
    return (
        <Row justify="end" align="middle" className="budget-action">
            <ProtectedAction
                roles={[
                    RoleEnum.Accountant,
                ]}
            >
                <>
                        <CheckCircleOutlined onClick={onApprove} />
                        <Divider type="vertical" />
                </>
            </ProtectedAction>

            <ProtectedAction
                roles={[
                    RoleEnum.Accountant,
                ]}
            >
                <div onClick={onDelete} className="budget-action__delete-button">Delete</div>
            </ProtectedAction>
        </Row>
    );
};

BudgetTableAction.defaultProps = {
    onDelete: () => null,
    onApprove: () => null,
};

export default BudgetTableAction;
