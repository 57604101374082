import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import backgroundImage from "../../../assets/images/auth-background.png";
import authBanner from "../../../assets/images/auth-banner.png";
import logo from "../../../assets/images/logo.svg";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { commonPageTitleSelector } from "../../../states/common";
import "./index.scss";

function AuthLayout() {
  const pageTitle = useRecoilValue(commonPageTitleSelector);

  return (
    <div
      className="auth-layout"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="auth-layout__formContent">
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                  <img src={authBanner} alt="Neature" />
                </div>
                <div className="gx-app-logo-wid">
                  <h1>{pageTitle}</h1>
                </div>
                <div className="gx-app-logo">
                  <Link to={ROUTE_PATHS.signIn}>
                    <img alt="example" src={logo} />
                  </Link>
                </div>
              </div>
              <div className="gx-app-login-content">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
