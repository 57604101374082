import { Link } from 'react-router-dom';
import { Col, Dropdown, Menu, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useMemo } from 'react';

import CategoryIcon from '../category-icon';
import ProtectedAction from '../../../components/action-protected';

import { RoleEnum } from '../../../enums/app.enum';
import { generateImage } from '../../../utils/app.utils';

import { MoreOutlined } from "@ant-design/icons";

import './index.scss';
import classNames from 'classnames';
import ImageLoad from '../../../components/common/image-load';
import { IMAGE_DEFAULT_TYPE } from '../../../constants/app-constants';

interface MenuDropListProps {
    isDeactivated?: boolean;

    onEdit?: () => void;
    onChangeStatus?: () => void;
};

const MenuDropList: FunctionComponent<MenuDropListProps> = ({ isDeactivated, onEdit, onChangeStatus }) => {
    const { t } = useTranslation();

    const activeText = useMemo(() => {
        if (isDeactivated) {
            return t("common.active")
        }

        return t("common.deActive")
    }, [isDeactivated])

    return (
        <Menu style={{ width: 120 }}>
            <Menu.Item
                key="0"
                onClick={onEdit}
            >
                {t("common.edit")}
            </Menu.Item>

            <Menu.Item
                key="1"
                onClick={onChangeStatus}
            >
                {activeText}
            </Menu.Item>
        </Menu>
    )
};
interface CategoryItemProps {
    image: string;
    title: string;
    amount: number;
    id: string | number;
    isDeactivated: boolean;

    [key: string]: any;
};

const CategoryItem: React.FunctionComponent<CategoryItemProps> = (props) => {
    const { t } = useTranslation();

    const { image, title, amount, id, isDeactivated, isHardCoded, ...eventProps } = props;

    const showSettingIcon = useMemo(() => {
        if (!isHardCoded) {
            return [RoleEnum.Admin, RoleEnum.Mod]
        }

        return [];
    }, [isHardCoded]);

    return (
        <Row className="category">
            <Col xs={24} className="category__wrapper">
                <div className="category__content content">
                    <Link to={`/themes/category/${id}`}>
                        <div onClick={(event) => {isDeactivated && event.preventDefault()}} className={classNames(["content__wrapper"], {'deactive': isDeactivated})}>
                            <div className="content__icon">
                                {image ? <ImageLoad type={IMAGE_DEFAULT_TYPE.category} src={generateImage(image)} alt={image} />: <CategoryIcon icon="default" />}
                            </div>

                            <div className="content__info info">
                                <div className="info__title">
                                    {title}
                                </div>

                                <div className="info__amount">
                                    <span className="info__amount--number">{amount} </span>
                                    {amount < 2 && <span>{t('theme.theme')}</span>}
                                    {amount > 1 && <span>{t('theme.themes')}</span>}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <ProtectedAction roles={showSettingIcon}>
                        <div className="content__setting">
                            <Dropdown
                                placement="bottomRight"
                                overlay={<MenuDropList {...eventProps} isDeactivated={isDeactivated}/>}
                                trigger={["click"]}
                            >
                                <MoreOutlined />
                            </Dropdown>
                        </div>
                    </ProtectedAction>

                </div>
            </Col>
        </Row>
    );
};

export default CategoryItem;
