import { SortAscendingOutlined } from "@ant-design/icons";
import { Col, Input, Radio, Row } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { WORDPRESS_FILTER_TYPE } from "../../../../constants/app-constants";
import { WordpressFilterType } from "../../../../enums/theme.enum";
import "./index.scss";

const { Search } = Input;

interface ThemeWordpressProps {
  onFilter?: (type: number) => void;
  onSearch?: (search: string) => void;
};

const ThemeWordpressFilters: FunctionComponent<ThemeWordpressProps> = ({ onSearch, onFilter }) => {
  const { t } = useTranslation();

  const handleViewButtonRadioChange = (event: any) => {
    const { value } = event.target;

    if (onFilter) {
      onFilter(value);
    };
  };

  return (
    <Col span={24}>
      <Row justify="space-between" align="middle" className="theme-wordpress-filters filters">
        <Col className="filters__search">
          <Search className="search-input" placeholder={t("common.search")} onSearch={onSearch}/>
        </Col>

        <Col className="filters__radio-button">
          <Radio.Group onChange={handleViewButtonRadioChange}>
            {WORDPRESS_FILTER_TYPE.map((type: any) => {
              return <Radio.Button key={type.id} value={type.value}>{type.name} {type.id === WordpressFilterType.name && <SortAscendingOutlined />}</Radio.Button>
            })}
          </Radio.Group>
        </Col>
      </Row>
    </Col>
  );
};

export default ThemeWordpressFilters;
