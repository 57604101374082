import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import { useRecoilValue } from 'recoil';
import { getUserInfoSelector } from '../../../states/auth';

interface WelcomeCardProps {
    unit?: string;
    number?: number | string;
};

const WelcomeCard: FunctionComponent<WelcomeCardProps> = ({ unit, number }) => {
    const { t } = useTranslation();

    // recoil
    const userInfo = useRecoilValue(getUserInfoSelector);

    return (
        <div className="welcome-card">
            <h1 className="welcome-card__title">{t("common.welcome")} {userInfo?.fullName},</h1>
            <p className="welcome-card__youHave">{t("common.youHave")}</p>
            <span className=" welcome-card__number">{number} </span>
            <span>{unit}</span>
        </div>
    );
};

WelcomeCard.defaultProps = {
    unit: '',
    number: 0,
};

export default WelcomeCard;
