import { getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth`;

const paymentApis = {
    getBanks: () => {
        return getAsync(baseUrl + '/payment/banks');
    },

    getBankBranchsByBankId: (payload: any) => {
        return getAsync(baseUrl + '/payment/bank-branches', payload);
    },

    getActivePaymentAccount: () => {
        return getAsync(baseUrl + '/payment/payment-accounts');
    },

    createNewUserPaymentAccount: (payload: any) => {
        return postAsync(baseUrl + '/payment', payload, false, true);
    },

    getUserPaymentAccount: (payload: any) => {
        return getAsync(baseUrl + '/payment/payment-methods', payload);
    },

    updateStatusUserPaymentAccount: (payload: any) => {
        return putAsync(baseUrl + '/payment', payload, false, true);
    },
};

export default paymentApis;