export enum SiteTypeEnum {
  Production = 1,
  Demo = 2,
  Expired = 4,
}

export enum SiteStatusEnum {
  Running = 1,
  Error = 2,
  Stop = 4,
  Locked = 8,
  Warning = 16,
  Renew = 32,
}

export enum SitePlanEnum {
  Business = 1,
  Starter = 2,
  Professional = 4,
}

export enum ActivityType {
  Add = 1,
  Update,
  Delete,
  Rate,
  Login,
  Logout,
}

export enum UserTypeEnum {
  Admins = 1,
  Mods = 2,
  Sellers = 4,
  Users = 8,
}
export enum StatusType {
  Processed = 1,
  Processing = 2,
  Waiting = 4,
  Deleted = 8,
}

export enum SalesLevel {
  noSales = 1,
  low = 2,
  medium = 4,
  high = 8,
  topSeller = 16
}

export enum RatingSale {
  all = 1,
  oneStar = 2,
  twoStar = 4,
  threeStar = 8,
  fourStar = 16,
}
