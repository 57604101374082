import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Segmented,
} from "antd";

import {
  FilterOutlined,
  AppstoreOutlined,
  BarsOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";

import "./index.scss";
import { FILTER_THEME_TYPE, VIEW_ITEM } from "../../../constants/app-constants";
import classNames from "classnames";

interface FilterProps {
  isView?: boolean;
  isFilter?: boolean;
  view?: number | string;
  type?: number | string;

  onFilter?: () => void;
  onSearch?: (search: string) => void;
  onView?: (view: number) => void;
  onSelectType?: (type: number | string) => void;
}

const FilterBar: FunctionComponent<FilterProps> = (props: any) => {
  const { view, type, isFilter = true, isView, onSearch, onView, onSelectType, onFilter } =
    props;
  const { t } = useTranslation();

  const handleViewButtonRadioChange = ({
    target: { value },
  }: RadioChangeEvent) => {
    onView(value);
  };

  const handleSearchButtonClick = (searchText: string) => {
    if (onSearch) onSearch(searchText);
  };

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    if (!value) {
      handleSearchButtonClick('');
    }
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      className="filter"
      gutter={[4, { xs: 16, sm: 16, md: 16, lg: 16, xl: 8 }]}
    >
      <Col xs={24} xl={isFilter ? 8 : 6}>
        <Row gutter={[8, 8]} justify="space-between">
          <Col lg={18} className={classNames({"filter__search-container" : !isFilter})}>
            <Input.Search
              className="filter__search"
              placeholder={t("theme.searchByName")}
              onSearch={handleSearchButtonClick}
              onChange={handleSearchChange}
            />
          </Col>

          {isFilter && <Col lg={6}>
            <Button onClick={onFilter} className=" filter__button">
              <FilterOutlined />
              {t("common.filter")}
            </Button>
          </Col>}
        </Row>
      </Col>

      <Col xs={24} xl={16}>
        <Row justify="end" align="middle" gutter={[8, 8]}>
          {isView && (
            <Col>
              <Radio.Group
                className="filter__view"
                value={view}
                onChange={handleViewButtonRadioChange}
              >
                <Radio.Button value={VIEW_ITEM.grid}>
                  <AppstoreOutlined />
                </Radio.Button>
                <Radio.Button value={VIEW_ITEM.list}>
                  <BarsOutlined />
                </Radio.Button>
              </Radio.Group>
            </Col>
          )}

          <Col className="filter__button-radio">
            <Radio.Group className="filter__select" onChange={onSelectType}>
              <Radio.Button value={FILTER_THEME_TYPE.bestSeller}>
                {t("theme.bestSellers")}
              </Radio.Button>
              <Radio.Button value={FILTER_THEME_TYPE.newest}>
                {t("theme.newest")}
              </Radio.Button>
              <Radio.Button value={FILTER_THEME_TYPE.bestRate}>
                {t("theme.bestRated")}
              </Radio.Button>
              <Radio.Button value={FILTER_THEME_TYPE.trending}>
                {t("theme.trending")}
              </Radio.Button>
              <Radio.Button value={FILTER_THEME_TYPE.price}>
                {t("theme.price")} <SortAscendingOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

FilterBar.defaultProps = {
  view: 0,
  type: 1,
  isView: true,
  onView: () => null,
  onSearch: () => null,
  onFilter: () => null,
  onSelectType: () => null,
};

export default FilterBar;
