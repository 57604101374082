import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Input, Modal, Row } from "antd";

import FilterBar from '../../../views/theme/filter-bar';
import ModalComponent from "../../../components/common/modal";
import ProtectedAction from "../../../components/action-protected";
import CategoriesBarItem from "../../../views/theme/category-bar-item";
import PluginCardExtra from "../../../views/theme/plugin/plugin-card-extra";
import ThemePluginList from "../../../views/theme/plugin/theme-plugin-list";

import { RoleEnum } from "../../../enums/app.enum";
import { ThemeApis } from "../../../apis/theme.api";
import { CATEGORY_SHOW_LIMIT, SITE_PAGE_SIZE } from "../../../constants/app-constants";
import { addThemePluginCategory, deleteThemePluginCategory, editThemePluginCategory, getPluginCategoryTotal, pluginDataSelector, themePluginCategoriesSelector } from "../../../states/theme";

import {
  ExclamationCircleOutlined,
  PlusOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

import "./index.scss";
import SiteFilterDrawer from "../../../views/theme/sites-filter-drawer";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import confirmReasonDeactivated from "../../../views/theme/deactivated-modal";

const { confirm } = Modal;

const ThemePluginPage = () => {
  // ultil
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // data state
  const [filter, setFilter] = useState<any>({});
  const [keyword, setKeyword] = useState<any>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [categoryActionData, setCategoryActionData] = useState<any>(null);
  const [selectCategory, setSelectCategory] = useState<number | string>(0);
  const [categoryLimit, setCategoryLimit] = useState<number>(CATEGORY_SHOW_LIMIT);

  // recoil
  const themeCategoriesTotal = useRecoilValue(getPluginCategoryTotal);
  const editPluginCategory = useSetRecoilState(editThemePluginCategory);
  const [pluginData, setPluginData] = useRecoilState(pluginDataSelector);
  const addNewPluginCategory = useSetRecoilState(addThemePluginCategory);
  const deletePluginCategoryRecoil = useSetRecoilState(deleteThemePluginCategory);
  const [pluginCategories, setPluginCategories] = useRecoilState(themePluginCategoriesSelector);
  const [ratingFilterData, setRatingFilterData] = useState<any>(null);

  const handlePaginationListChange = (page: number) => {
    setCurrentPage(page);
  };

  const categoryPayload = useMemo(() => {
    return {
      PageIndex: 1,
      PageSize: categoryLimit,
    }
  }, [categoryLimit]);

  const isShowMoreCategories = useMemo(() => {
    return !keyword && themeCategoriesTotal > 12 && pluginCategories.length <= 12
  }, [themeCategoriesTotal, keyword, pluginCategories.length]);

  const pagination: any = useMemo(() => {
    return {
      pageSize: SITE_PAGE_SIZE,
      current: currentPage,
      total: pluginData.totalRecords,
      onChange: handlePaginationListChange,
    }
  }, [currentPage]);

  const themeSiteFilter = useMemo(() => {
    return {
      ...filter,
      categoryId: selectCategory,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
    }
  }, [pagination, selectCategory, filter]);

  const showDeactiveCategoryByRole = (isDeactivated: boolean) => {
    if (isDeactivated) {
      return [RoleEnum.Admin, RoleEnum.Mod];
    }
    return Object.values(RoleEnum);
  };

  const titleModal = useMemo(() => {
    return isEdit ? t("theme.editCategory") : t("theme.addPluginCategory");
  }, [isEdit, t]);

  const confirmButtonModalText = useMemo(() => {
    return isEdit ? t("common.save") : t("common.add");
  }, [isEdit, t]);

  const confirmCallback = useCallback((values: any) => {
    return isEdit ? handleEditCategory(values) : handleAddNewCategory(values);
  }, [isEdit, categoryActionData]);

  const categories = useMemo(() => {
    if (!keyword) {
      return pluginCategories
    }

    return pluginCategories.filter((category: any) => category.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
  }, [pluginCategories, keyword]);

  // action

  const handleShowMoreClick = () => {
    setCategoryLimit(100);
  };

  const handleSelectCategoryItem = (categoryId: string | number) => {
    if (!isEdit) {
      hanldeFilterFormReset()
      setSelectCategory(categoryId);
    };
  };

  // get categories method
  const getAllPluginCategories = async () => {
    const result = await ThemeApis.getThemePluginCategories(categoryPayload);

    if (result?.data) {
      setPluginCategories(result.data);
      setSelectCategory(result.data?.records?.[0].id)
    };
  };

  // get plugin sites by category id
  const getPluginSites = async () => {
    try {
      setLoading(true);
      const result = await ThemeApis.getThemePluginSiteByCategory(themeSiteFilter);
  
      if (result?.data) {
        setPluginData(result.data);
  
        if (openFilterForm) {
          setOpenFilterForm(false);
        };
      };
    } finally {
      setLoading(false);
    }
  };

  // edit category methods
  const handleEditCategoryItem = async (selectedCategory: any) => {
    setIsEdit(true);
    setCategoryActionData(selectedCategory)

    await form.setFieldsValue(selectedCategory);
    setIsOpenForm(true);
  };

  const handleEditCategory = async (values: any) => {
    try {
      setLoading(true);
      const payload = {
        id: categoryActionData.id,
        name: values.name,
      }
      const result = await ThemeApis.editThemePluginCategory(payload);

      if (result?.data) {
        editPluginCategory(result.data);
        setCategoryActionData(null);
        handleCloseModal();
      }

    } finally {
      setLoading(false);
    }
  };

  // active category methods
  const handleChangeStatus = (selectedCategory: any) => {
    showActiveConfirm(!selectedCategory.isDeactivated, selectedCategory);
  };

  const showActiveConfirm = (active: boolean, category: any) => {
    if (active) {
      const handleGetReason = (reason: any) => {
        onDeactiveCategory(category, reason.replaceAll('\n', '%0A'));
      }

      confirm({
        ...confirmReasonDeactivated(handleGetReason),
        okButtonProps: { loading: loading },
        okType: 'primary'
      });

      return;
    }
    onActiveCategory(category)
  };

  const onActiveCategory = async (category: any) => {
    try {
      setLoading(true);
      const payload = { id: category?.id };
      const result = await ThemeApis.activePluginCategory(payload);

      if (result?.status === 204) {
        editPluginCategory({ ...category, isDeactivated: false });
      }
    } finally {
      setLoading(false);
    }
  };

  const onDeactiveCategory = async (category: any, reason: string) => {
    try {
      setLoading(true);
      const payload = { id: category?.id, reason };
      const result = await ThemeApis.deActivePluginCategory(payload);

      if (result?.status === 204) {
        editPluginCategory({ ...category, isDeactivated: true });
      }
    } finally {
      setLoading(false);
    }
  };

  // delete category methods
  const handleDeleteCategory = (selectedCategory: any) => {
    showDeleteConfirm(selectedCategory.id)
  };

  const showDeleteConfirm = (categoryId: number | string) => {
    confirm({
      title: t("theme.deleteCategoryConfirm"),
      icon: <ExclamationCircleOutlined />,
      okButtonProps: { loading: loading },
      onOk() {
        deleteCategoryAsync(categoryId);
      },
    });
  };

  const deleteCategoryAsync = async (categoryId: any) => {
    try {
      setLoading(true);
      const result = await ThemeApis.deletePluginCategory({ id: categoryId });

      if (result?.data || result?.status === 204) {
        deletePluginCategoryRecoil(categoryId);
      };

    } finally {
      setLoading(false)
    }
  };

  // add category methods
  const handleAddNewCategory = async (values: any) => {
    try {
      setLoading(true);

      const payload = {
        name: values.name,
      };

      const result = await ThemeApis.addNewThemePluginCategory(payload);

      if (result?.data) {
        addNewPluginCategory(result.data);
        setSelectCategory(result.data.id);
        handleCloseModal()
      };
    } finally {
      setLoading(false)
    }
  };

  const handleCloseModal = () => {
    setIsOpenForm(false);
    setIsEdit(false);
    form.resetFields();
    setCategoryActionData(null);
  };

  // filter plugin sites
  const handleFilterFormSubmit = (filterValue: any) => {
    setFilter(filterValue);
  };

  const searchCategoryByName = (keyword: string) => {
    if (!keyword) {
      setKeyword('');
      return;
    }
    setKeyword(keyword);
  };

  const hanldeFilterFormReset = () => {
    setFilter({});
  };

  const handleSortType = (event: any) => {
    const { value } = event.target;

    if (value) {
      setFilter({ ...filter, themeFilterType: value });
    };
  };

  const handleSearchSampleData = (search: string) => {
    setFilter({ ...filter, name: search });
  };

  const handleFilterButtonClick = async () => {
    const response = await ThemeApis.getThemePluginSiteFilterData();
    if (response?.data) {
      setRatingFilterData(response.data);
      setOpenFilterForm(true);
    }
  };

  const handleFilterFormClose = () => {
    setOpenFilterForm(false);
  };

  // mounted
  useEffect(() => {
    if (selectCategory) {
      getPluginSites();
    }
  }, [selectCategory, themeSiteFilter]);

  useEffect(() => {
    getAllPluginCategories();
  }, [categoryPayload]);

  return (
    <div className="theme-plugin">
      <Card
        extra={<PluginCardExtra onSearch={searchCategoryByName} uploadRedirect={ROUTE_PATHS.uploadThemePlugin} />}
        title={t("theme.plugin")}
      >
        <div className="content">
          <div className="header">
            <div className="title">{t("theme.pluginCategories")}</div>

            <div className="action">
              <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
                {/* <EditButton /> */}
                <Button
                  disabled={isEdit}
                  icon={<PlusOutlined />}
                  onClick={() => setIsOpenForm(true)}
                >
                  {t("common.add")}
                </Button>
              </ProtectedAction>
            </div>
          </div>

          <div className="categories">
            <Row gutter={[16, 16]}>
              {categories.map((category: any) => (
                <ProtectedAction key={category?.id} roles={showDeactiveCategoryByRole(category?.isDeactivated)}>
                  <Col lg={4} md={6} sm={8} xs={24}>
                    <CategoriesBarItem
                      isEdit={isEdit}
                      category={category}
                      isHardCoded={category?.isDefault}
                      active={selectCategory === category?.id}
                      onEdit={() => handleEditCategoryItem(category)}
                      onDelete={() => handleDeleteCategory(category)}
                      onChangeStatus={() => handleChangeStatus(category)}
                      onClick={() => handleSelectCategoryItem(category.id)}
                    />
                  </Col>
                </ProtectedAction>
              ))}
            </Row>

            {isShowMoreCategories && <div className="show-more" onClick={handleShowMoreClick}>
              <div className="show-more-title">
                <div>{t("theme.showAll")}</div>
                <div>
                  <CaretDownOutlined />
                </div>
              </div>
            </div>}
          </div>

        </div>

        <div className="theme-plugin__filter">
          <FilterBar
            isView={false}
            onSelectType={handleSortType}
            onSearch={handleSearchSampleData}
            onFilter={handleFilterButtonClick}
          />
        </div>


        {!isEdit && <ThemePluginList data={pluginData?.records} pagination={pagination} loading={loading}/>}
      </Card>

      <SiteFilterDrawer
        open={openFilterForm}
        onClose={handleFilterFormClose}
        onReset={hanldeFilterFormReset}
        onFilter={handleFilterFormSubmit}
        ratingFilterData={ratingFilterData}
      />

      <ModalComponent
        loading={loading}
        title={titleModal}
        isOpen={isOpenForm}
        okTextColor="#8a2be2"
        setIsOpen={handleCloseModal}
        onYes={() => form.submit()}
        okText={confirmButtonModalText}
      >
        <div>
          <p>{t("theme.enterPluginCategoryName")}</p>
          <Form
            className="gx-signin-form gx-form-row0 form-hidden-label "
            form={form}
            onFinish={(values: any) => confirmCallback(values)}
          >
            <Form.Item
              rules={[
                { required: true, message: t("theme.validate.categoryName") },
              ]}
              name="name"
              label={t("forgotPassword.email")}
            >
              <Input style={{ width: "50%" }} />
            </Form.Item>
          </Form>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ThemePluginPage;
