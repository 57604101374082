import { Button } from "antd";
import { Link } from "react-router-dom";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import ProtectedAction from "../../../components/action-protected";

import { UploadOutlined } from "@ant-design/icons";

interface ButtonUploadProps {
  href: string;
  roles?: Array<any>;
}

const ButtonUpload: FunctionComponent<ButtonUploadProps> = ({ roles, href }) => {
  const { t } = useTranslation();
  
  return (
    <ProtectedAction
      roles={roles || []}
    >
      <Link to={href}>
        <Button
          className="upload-btn"
          icon={<UploadOutlined />}
          type="primary"
        >
          {t("common.upload")}
        </Button>
      </Link>
    </ProtectedAction>
  );
};

export default ButtonUpload;
