const TechnologyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={28} viewBox="0 0 40 28" fill="none">
            <path d="M24.7045 22.0546H9.09418C8.79483 22.0546 8.55225 21.812 8.55225 21.5126C8.55225 21.2133 8.79483 20.9707 9.09418 20.9707H24.7045C25.0039 20.9707 25.2464 21.2133 25.2464 21.5126C25.2464 21.812 25.0013 22.0546 24.7045 22.0546Z" fill="url(#paint0_linear_1256_26652)" />
            <path d="M3.5716 12.6325C3.27224 12.6325 3.02966 12.3899 3.02966 12.0905V1.98473C3.02966 0.924086 3.89418 0.0595703 4.95482 0.0595703H32.3174C33.3781 0.0595703 34.2426 0.924086 34.2426 1.98473V8.15247C34.2426 8.45183 34 8.69441 33.7006 8.69441C33.4013 8.69441 33.1587 8.45183 33.1587 8.15247V1.98473C33.1587 1.52022 32.7819 1.14344 32.3174 1.14344H4.95482C4.49031 1.14344 4.11353 1.52022 4.11353 1.98473V12.0905C4.11353 12.3899 3.87095 12.6325 3.5716 12.6325Z" fill="url(#paint1_linear_1256_26652)" />
            <path d="M24.7045 17.4379H9.09418C8.79483 17.4379 8.55225 17.1953 8.55225 16.8959C8.55225 16.5966 8.79483 16.354 9.09418 16.354H24.7045C25.0039 16.354 25.2464 16.5966 25.2464 16.8959C25.2464 17.1953 25.0013 17.4379 24.7045 17.4379Z" fill="url(#paint2_linear_1256_26652)" />
            <path d="M18.6348 19.871C19.004 19.871 19.3032 19.5717 19.3032 19.2026C19.3032 18.8334 19.004 18.5342 18.6348 18.5342C18.2657 18.5342 17.9664 18.8334 17.9664 19.2026C17.9664 19.5717 18.2657 19.871 18.6348 19.871Z" fill="url(#paint3_linear_1256_26652)" />
            <path d="M16.4155 25.012C16.1161 25.012 15.8735 24.7694 15.8735 24.4701V21.5126C15.8735 21.2133 16.1161 20.9707 16.4155 20.9707C16.7148 20.9707 16.9574 21.2133 16.9574 21.5126V24.4701C16.9574 24.7694 16.7148 25.012 16.4155 25.012Z" fill="url(#paint4_linear_1256_26652)" />
            <path d="M20.8568 25.012C20.5574 25.012 20.3148 24.7694 20.3148 24.4701V21.5126C20.3148 21.2133 20.5574 20.9707 20.8568 20.9707C21.1561 20.9707 21.3987 21.2133 21.3987 21.5126V24.4701C21.3987 24.7694 21.1561 25.012 20.8568 25.012Z" fill="url(#paint5_linear_1256_26652)" />
            <path d="M25.2206 27.9382H10.8361C10.5368 27.9382 10.2942 27.6956 10.2942 27.3963V27.0505C10.2942 25.4169 11.6232 24.0879 13.2568 24.0879H24.018C24.3045 24.0879 24.5884 24.1292 24.8593 24.2092C25.1458 24.2943 25.3109 24.5963 25.2258 24.8827C25.1406 25.1692 24.8387 25.3343 24.5522 25.2492C24.3793 25.1976 24.2013 25.1718 24.018 25.1718H13.2568C12.2864 25.1718 11.4864 25.9098 11.3884 26.8543H25.2206C25.52 26.8543 25.7626 27.0969 25.7626 27.3963C25.7626 27.6956 25.52 27.9382 25.2206 27.9382Z" fill="url(#paint6_linear_1256_26652)" />
            <path d="M8.68386 27.9385H1.21031C0.683863 27.9385 0.258057 27.5101 0.258057 26.9862V12.5011C0.258057 11.9746 0.686444 11.5488 1.21031 11.5488H8.68128C9.20773 11.5488 9.63354 11.9772 9.63354 12.5011V26.9837C9.63612 27.5127 9.20773 27.9385 8.68386 27.9385ZM1.34193 26.8546H8.55225V12.6327H1.34193V26.8546Z" fill="url(#paint7_linear_1256_26652)" />
            <path d="M9.09419 14.8651H0.799992C0.500637 14.8651 0.258057 14.6225 0.258057 14.3232C0.258057 14.0238 0.500637 13.7812 0.799992 13.7812H9.09419C9.39354 13.7812 9.63612 14.0238 9.63612 14.3232C9.63612 14.6225 9.39354 14.8651 9.09419 14.8651Z" fill="url(#paint8_linear_1256_26652)" />
            <path d="M9.09419 25.7064H0.799992C0.500637 25.7064 0.258057 25.4638 0.258057 25.1645C0.258057 24.8651 0.500637 24.6226 0.799992 24.6226H9.09419C9.39354 24.6226 9.63612 24.8651 9.63612 25.1645C9.63612 25.4638 9.39354 25.7064 9.09419 25.7064Z" fill="url(#paint9_linear_1256_26652)" />
            <path d="M38.6839 27.9384H25.2206C24.6374 27.9384 24.1626 27.4636 24.1626 26.8804V8.67135C24.1626 8.08812 24.6374 7.61328 25.2206 7.61328H38.6839C39.2671 7.61328 39.7419 8.08812 39.7419 8.67135V26.8804C39.7419 27.4636 39.2671 27.9384 38.6839 27.9384ZM25.2464 26.8546H38.6581V8.69457H25.2439V26.8546H25.2464Z" fill="url(#paint10_linear_1256_26652)" />
            <path d="M37.3935 25.0712H26.5084C26.209 25.0712 25.9664 24.8286 25.9664 24.5292V9.95893C25.9664 9.65957 26.209 9.41699 26.5084 9.41699H37.3935C37.6929 9.41699 37.9355 9.65957 37.9355 9.95893V24.5292C37.9355 24.8286 37.6929 25.0712 37.3935 25.0712ZM27.0529 23.9873H36.8516V10.5009H27.0503V23.9873H27.0529Z" fill="url(#paint11_linear_1256_26652)" />
            <path d="M31.951 26.4778C32.2346 26.4778 32.4645 26.2479 32.4645 25.9642C32.4645 25.6806 32.2346 25.4507 31.951 25.4507C31.6673 25.4507 31.4374 25.6806 31.4374 25.9642C31.4374 26.2479 31.6673 26.4778 31.951 26.4778Z" fill="url(#paint12_linear_1256_26652)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26652" x1="8.55225" y1="21.3751" x2="9.88862" y2="26.0746" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26652" x1="3.02966" y1="4.75095" x2="28.3066" y2="19.0781" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_26652" x1="8.55225" y1="16.7584" x2="9.88862" y2="21.4579" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_26652" x1="17.9664" y1="19.033" x2="19.3259" y2="19.3434" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_26652" x1="15.8735" y1="22.4786" x2="17.0289" y2="22.5494" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_26652" x1="20.3148" y1="22.4786" x2="21.4702" y2="22.5494" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_26652" x1="10.2942" y1="25.5246" x2="19.2828" y2="33.7693" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint7_linear_1256_26652" x1="0.258057" y1="17.6644" x2="10.1215" y2="18.9526" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint8_linear_1256_26652" x1="0.258057" y1="14.1857" x2="2.30487" y2="18.2291" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint9_linear_1256_26652" x1="0.258057" y1="25.027" x2="2.30487" y2="29.0704" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint10_linear_1256_26652" x1="24.1626" y1="15.1973" x2="40.337" y2="18.0279" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint11_linear_1256_26652" x1="25.9664" y1="15.2581" x2="38.3945" y2="17.4276" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint12_linear_1256_26652" x1="31.4374" y1="25.8339" x2="32.4819" y2="26.0724" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default TechnologyIcon;