import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Card, Col, Divider, Form, Input, Row, Select } from "antd";

import "./index.scss";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },

  className: "form__item--horizone"
};

const formItemVerticalLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },

  className: "form__item--horizone"
};

const AddNewEmailTemplate = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      autoComplete="off"
      className="add-new-email-template"
    // onFinish={handleEdit}
    >
      <Row gutter={[18, 18]}>
        <Col lg={16} md={20} sm={24} xs={24}>
          <Card className="col-left" title={t("admin.emailTemplateEditor")} bordered>
            <Form.Item
              {...formItemLayout}
              required
              label={t("admin.templateName")}
              name="editValue"
            >
              <Input placeholder={t("admin.enterName")} />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              required
              label={t("admin.systemEnvironment")}
              name="editValue"
            >
              <>
                <Select placeholder={t("common.select")}>
                  <Option value="1">1</Option>
                </Select>
                <Button>{t("common.select")}</Button>
              </>
            </Form.Item>

            <Divider />

            <div className="editor">
              <CKEditor
                editor={ClassicEditor}
                data="<p>Hello from CKEditor 5!</p>"
              />
            </div>
          </Card>
        </Col>
        <Col lg={8} md={4} sm={24} xs={24}>
          <Card className="col-right">
            <Form.Item {...formItemVerticalLayout} label={t("admin.technologyCategory")} required name="editValue" labelAlign="left">
              <Select />
            </Form.Item>

            <div className="action">
              <div>
                <Button>{t("common.preview")}</Button>
              </div>
              <div>
                <Button type="primary">{t("common.save")}</Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default AddNewEmailTemplate;
