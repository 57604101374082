import { useEffect, useRef, useState } from "react";
import "./index.scss";

interface Props {
  length: number;
  label?: string;
  loading?: boolean;
  onComplete?: (value: string) => void;
  onChange?: (value: string) => void;
  formSubmited?: boolean;
}

const InputCode = (props: Props) => {
  const { length, label, loading, onComplete, onChange, formSubmited } = props;
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef<any[]>([]);
  // Typescript
  // useRef<(HTMLInputElement | null)[]>([])

  useEffect(() => {
    if (onChange) {
      onChange(code.join(""));
    }
  }, [code]);

  const processInput = (num: string, slot: number) => {
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);

    if (num) {
      if (slot !== length - 1 && !newCode[slot + 1]) {
        inputs.current[slot + 1].focus();
      }
    } else {
      if (slot !== 0) {
        inputs.current[slot - 1].focus();
      }
    }

    if (newCode.every((num) => num !== "")) {
      if (onComplete) {
        onComplete(newCode.join(""));
      }
    }
  };

  const handleKeyDownInput = async (event: any, inputIndex: number) => {
    const { ctrlKey, key } = event;
    
    if (ctrlKey && key === 'v') {
      const selectedText = await navigator.clipboard.readText();
      if (selectedText) {
        const TextStrings = selectedText.split('').slice(0, 6);
        setCode(TextStrings);
      }
    }

  };

  const handlePaste = (event: any) => {
    const pastedValue = event.clipboardData.getData('text')
    if (/[^0-9]/.test(pastedValue)) return;

    let inputNumbers = []

    const pastedValueArr = pastedValue.toString().split('')

    for (let i = 0; i < 6; i++) {
      inputNumbers.push(pastedValueArr[i] || "")
    }

    setCode(inputNumbers)
  };

  return (
    <div className="code-input">
      {!!label && <label className="code-label">{label}</label>}
      <div className="code-inputs">
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e.target.value, idx)}
              ref={(ref) => inputs.current.push(ref)}
              style={{fontSize: 20}}
              className={`ant-input${formSubmited && !num ? " gx-border-red" : ""}`}
              onKeyDown={(event) => handleKeyDownInput(event, idx)}
              onPaste={handlePaste}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InputCode;
