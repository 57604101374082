import { RcFile } from "antd/lib/upload";
import { Modal, Upload, UploadFile, UploadProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// icon
import { PlusOutlined } from "@ant-design/icons";

import './index.scss';

const initialImages = {
    uid: 1,
    url: '',
    status: 'done',
    name: 'image.png',
};

const uploadConfig = {
    beforeUpload: () => false,
};

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

interface UpdateImageProps {
    thumbnailFiles?: any;

    [key: string]: any;
    onRemove?: (id: number | string) => void;
    onUpdate?: (file: any) => void;
}

const UploadImage: React.FunctionComponent<UpdateImageProps> = (props) => {
    const { thumbnailFiles, onUpdate, onRemove, ...uploadProps } = props;

    // state data
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [ previewImage, setPreviewImage ] = useState<string>('');
    const [ previewTitle, setPreviewTitle ] = useState<string>('');
    const [ previewOpen, setPreviewOpen ] = useState<boolean>(false);

    const { t } = useTranslation();

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (onUpdate) {
            onUpdate(newFileList);
        };
    };

    const handleDelete = (files: any) => {
        if (!files.originFileObj && onRemove) {
            onRemove(files.uid);
        };
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        };

        setPreviewImage(file.url || file.preview as string);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleCancel = () => {
        setPreviewOpen(false);
    };

    // mounted
    useEffect(() => {
        if (thumbnailFiles?.length) {
            setFileList(thumbnailFiles);
        };
    }, [thumbnailFiles]);

    return (
        <div className="upload-image-wrapper">
            <Upload
                {...uploadProps}
                {...uploadConfig}

                fileList={fileList}
                listType="picture-card"

                onChange={handleChange}
                onPreview={handlePreview}
                onRemove={handleDelete}
            >
                <div className="upload-image__description">
                    <PlusOutlined />

                    <div className="upload-image__description-text">{t('common.upload')}</div>
                </div>
            </Upload>

            <Modal
                footer={null} 
                open={previewOpen}
                title={previewTitle}
                className="upload-image__modal"

                onCancel={handleCancel}
            >
                <img alt="previewTitle" className="upload-image__preview" src={previewImage} />
            </Modal>
        </div>
    );
};

UploadImage.defaultProps = {
    thumbnailFiles: [],
};

export default UploadImage;
