import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { SiteStatusEnum } from "../../../../enums/website.enum";
import DotStatus from "../../dot-status";
import { formatDate } from "../../../../utils/date.ultis";
import { Link } from "react-router-dom";
import ImageLoad from "../../../../components/common/image-load";
import { IMAGE_DEFAULT_TYPE } from "../../../../constants/app-constants";
import { generateImage } from "../../../../utils/app.utils";

import './index.scss';

interface SiteExtraDetailProps {
  id: string | number;
  name: string;
  budget: number;
  version: string;
  webSiteLink: string;
  userOwner: string;
  createAt: string;
  planName: string;
  siteStatus: SiteStatusEnum;
  userOwnerId: number;
  templateName: string;
  image: string;
}

const SiteExtra: React.FunctionComponent<SiteExtraDetailProps> = (props) => {
  const { t } = useTranslation();

  const {
    name,
    image,
    version,
    createAt,
    userOwner,
    siteStatus,
    userOwnerId,
    webSiteLink,
    templateName,
  } = props;

  return (
    <Row>
      <Col span={21}>
        <Row className="website__detail">
          <Col className="website__image">
            <ImageLoad type={IMAGE_DEFAULT_TYPE.website} src={generateImage(image)} alt={name} />
          </Col>
          <Col span={10}>
            <div>
              <div>
                <div className="site-name">
                  <div>{<DotStatus status={siteStatus} />}</div>
                  <div>{name}</div>
                </div>
              </div>
              
              <div>
                <p className="site-info">
                  {t("website.version")}: <span>{version}</span>
                </p>
              </div>
              
              <div>
                <p className="site-info">
                  {t("website.template")}: <span>{templateName}</span>
                </p>
              </div>

              <div>
                <p className="site-info">
                  {t("website.websiteLink")}:{" "}
                  <span className="gx-link">
                    <a rel="noreferrer" target="_blank" href={webSiteLink}>
                      {webSiteLink}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{ visibility: "hidden" }}>
                <p className="site-info">.</p>
              </div>

              <div>
                <p className="site-info">
                  {t("website.userOwner")}:
                  <Link to={`/users-detail/${userOwnerId}`}>
                    <span className="gx-link"> {userOwner}</span>
                  </Link>
                </p>
              </div>

              <div>
                <p className="site-info">
                  {t("website.createdAt")}: <span>{formatDate(createAt)}</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="detail-btn" span={3}>
        <Button type="primary">{t("website.detail")}</Button>
      </Col>
    </Row>
  );
};

export default SiteExtra;
