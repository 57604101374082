import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Modal, Steps, Tooltip } from "antd";
import { createRef, useEffect, useMemo, useState } from "react";

import { RoleEnum } from "../../../../enums/app.enum";

import SitePlan from "../../../../views/websites/site-plan";
import SiteTheme from "../../../../views/websites/site-theme";
import SiteCategory from "../../../../views/websites/site-category";
import SiteComplete from "../../../../views/websites/site-complete";
import ProtectedAction from "../../../../components/action-protected";
import showNotification from "../../../../components/common/notification";
import WebsiteInfoForm from "../../../../views/websites/site-website-name";
import CreateLoadingPopup from "../../../../views/websites/create-loading-popup";

import CreateWebsiteBody from "../../../../views/websites/context";

import { WebsiteApi } from "../../../../apis/website.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

import createSiteSuccessGif from '../../../../assets/gifs/create-site-completed.gif';

import "./index.scss";

const { Step } = Steps;

const initialValue = {
  name: "",
  planId: 0,
  themeId: 0,
  site: null,
  themePage: 1,
  siteThumbnail: [],
  plan: {},
  domain: '',
  planName: '',
  categoryId: 0,
  webSiteLink: "",
  isHaveDomain: true,
  isProductionSite: true,
};

const tooltipOptions = [
  t("website.validate.category"),
  t("website.validate.theme"),
  t("website.validate.websiteName"),
  t("website.validate.plan"),
]

const WebsiteCreatePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const websiteInfoFormRef = createRef<any>();

  // state data
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [createData, setDataCreate] = useState(initialValue);
  const [messageShow, setMessageShow] = useState<boolean>(false);

  const { state } = location;

  const tooltipText = useMemo(() => {
    return tooltipOptions?.[currentStep] ?? ""
  }, [currentStep]);

  const isNextButtonDisabled = useMemo(() => {
    const haveSelectedPlanId = !!createData.planId;
    const haveSelectedThemeId = !!createData.themeId;
    const haveSelectedCategoryId = !!createData.categoryId;

    if (!!haveSelectedPlanId && currentStep === 3) {
      return false;
    };

    if (!!haveSelectedCategoryId && currentStep === 0) {
      return false;
    };

    if (!!haveSelectedThemeId && currentStep === 1) {
      return false;
    };

    if (currentStep === 2) {
      return false;
    };

    return true;
  }, [createData, currentStep]);


  const selectCategoryId = (id: any) => {
    setDataCreate({ ...createData, categoryId: id });
  };

  const selectThemeId = (id: any, site: any, page: number) => {
    setDataCreate({ ...createData, themeId: id, site: site, themePage: page });
  };

  const submitWebsiteInfo = (values: any) => {
    setDataCreate({ ...createData, ...values });

    setCurrentStep(currentStep + 1);
  };

  const selectPlanId = (value: any) => {
    setDataCreate({ ...createData, planId: value.id, planName: value.name, plan: value });
  };

  const createWebsiteAction = async () => {
    const { site, plan, siteThumbnail, themePage, ...createWebsiteData } = createData
    const siteThumbnails = siteThumbnail.map((thumbnail: any) => thumbnail.originFileObj);
    const payload = { ...createWebsiteData, webSiteLink: createData.domain, thumbnailFiles: siteThumbnails };
    const result = await WebsiteApi.createSite(payload);

    return result;
  };

  const startWebsiteAction = async (websiteId: any) => {
    const result = await WebsiteApi.startSite([websiteId]);

    return result;
  };

  const onCreateWebsite = async () => {
    try {
      setLoading(true);

      const result = await createWebsiteAction();

      if (result?.status === 200) {
        setMessageShow(true);
      }

    } finally {
      setLoading(false);
    }
  };

  const onPublishWebsite = async () => {
    try {
      setLoading(true);
      const result = await createWebsiteAction();

      if (result?.status === 200 && !result?.data?.id) {
        await showNotification("error", t("website.websiteIsPending"));
        navigate(ROUTE_PATHS.websiteSites);
        return;
      }

      const startWebsiteResult = await startWebsiteAction(result.data.id);

      if (startWebsiteResult?.data) {
        setMessageShow(true);
      }

    } finally {
      setLoading(false);
    }
  };

  const handleStepChange = (step : number) => {
    const haveSelectedPlanId = !!createData.planId;
    const haveSelectedThemeId = !!createData.themeId;
    const haveSelectedCategoryId = !!createData.categoryId;

    if (!haveSelectedPlanId && step === 3) {
      return;
    };

    if (!haveSelectedCategoryId && step === 0) {
      return;
    };

    if (!haveSelectedThemeId && step === 1) {
      return;
    };

    if (!createData.name && step === 2) {
      return;
    };

    if ((!createData.name || !haveSelectedPlanId || !haveSelectedCategoryId || !haveSelectedThemeId) && step === 4) {
      return;
    }

    setCurrentStep(step);
  };

  // Steps
  const steps = [
    {
      title: t("website.step"),
      content: (
        <SiteCategory
          categoryId={createData.categoryId}
          setDataCreate={selectCategoryId}
        />
      ),
      description: t("website.websiteCategory"),
    },
    {
      title: t("website.step"),
      content: (
        <SiteTheme
          themeId={createData.themeId}
          categoryId={createData.categoryId}
          selectedThemePage={createData.themePage}
          selectThemeId={selectThemeId}
        />
      ),
      description: t("website.theme"),
    },
    {
      title: t("website.step"),
      content: (
        <WebsiteInfoForm
          data={createData}
          formRef={websiteInfoFormRef}
          onSubmit={submitWebsiteInfo}
        />
      ),
      description: t("website.yourWebsiteName"),
    },
    {
      title: t("website.step"),
      content: (
        <SitePlan planId={createData.planId} selectPlan={selectPlanId} />
      ),
      description: t("website.yourPlan"),
    },
    {
      title: t("website.step"),
      content: (
        <SiteComplete data={createData} onCreate={onCreateWebsite} onPublish={onPublishWebsite} />
      ),
      description: t("website.complete"),
    },
  ];

  const handleNextButtonClick = () => {
    if (currentStep === 2) {
      websiteInfoFormRef.current.handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    };
  };

  const handlePrevButtonClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const closeModal = () => {
    setMessageShow(false);
    navigate(ROUTE_PATHS.websiteSites);
  };

  useEffect(() => {
    if (currentStep === 2) {
      if (websiteInfoFormRef.current) {
        websiteInfoFormRef.current.setInitialValue()
      };
    }
  }, [currentStep])

  useEffect(() => {
    if (state) {
      setDataCreate({
        ...createData,
        site: state.site,
        themeId: parseInt(state.themeSiteId),
        categoryId: state.categoryId,
      });

      setCurrentStep(2);
    }
  }, [state]);

  return (
    <ProtectedAction roles={[RoleEnum.Mod, RoleEnum.User, RoleEnum.Admin]}>
      <>
        <Card
          title={t("website.createSiteTitle")}
          className="website-create-page"
        >
          <Steps current={currentStep} onChange={handleStepChange} className="website-step">
            {steps.map((item, index) => (
              <Step
                key={item.title}
                title={item.title + " " + (index + 1).toString()}
                description={item.description}
              />
            ))}
          </Steps>

          <div className="steps-content">
            <CreateWebsiteBody>
              {steps[currentStep].content}
            </CreateWebsiteBody>
          </div>

          <div className="steps-action">
            <div className="steps-action__button">
              {currentStep > 0 && (
                <Button onClick={handlePrevButtonClick}>
                  Previous
                </Button>
              )}
            </div>

            <div className="steps-action__button">
              {currentStep < steps.length - 1 && (
                <Tooltip title={isNextButtonDisabled && tooltipText}>
                  <Button
                    disabled={isNextButtonDisabled}
                    type="primary"
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </Card>

        {loading && <CreateLoadingPopup />}

        <Modal
          open={messageShow}
          footer={false}

          onCancel={closeModal}
        >
          <div className="success-modal">
            <img src={createSiteSuccessGif} alt="create website succesfully"/>

            <div className="success-content">
              <p>{t('website.createSuccessNote')}</p>
              <p>
                {t('website.websiteUrl')}:
                <a href={createData.domain} target="blank">
                  {createData.domain}
                </a>
              </p>
            </div>
          </div>
        </Modal>
      </>
    </ProtectedAction>
  );
};

export default WebsiteCreatePage;
