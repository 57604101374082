import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { FunctionComponent, useEffect, useState } from "react";

// component
import ThemeReview from "../../../../views/theme/theme-review";
import ThemeAuthor from "../../../../views/theme/theme-author";
import ThemeCarousel from "../../../../views/theme/theme-carousel";
import ThemeDetailAction from "../../../../views/theme/overview/theme-detail-action";

// style
import "./index.scss";
// utils
import { ThemeApis } from "../../../../apis/theme.api";
import { formatDate } from "../../../../utils/date.ultis";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

// style
import './index.scss';

const ThemeDetailPage: FunctionComponent<any> = (props) => {
  // utils
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // state
  const [siteData, setSiteData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const loadThemeSiteDetail = async () => {

    const result = await ThemeApis.getThemeSiteDetail({ id: params.themeId });

    if (result?.data) {
      setSiteData(result.data);
    }
  };

  const onDeleteSite = async () => {
    const result = await ThemeApis.deleteSiteById(params.themeId);

    if (result?.status) {
      navigate(
        ROUTE_PATHS.themesCategoryDetail.replace(
          ":category",
          siteData.categoryId
        )
      );
    }
  };

  const handleDeactiveButtonClick = async (reason: any) => {
    try {
      setLoading(true);
      setIsSuccess(false);

      const result = await ThemeApis.deactiveSiteThemeOverview({id: siteData.id, reason: reason});
      if (result?.data) {
        loadThemeSiteDetail();
      };
    } finally {
      setLoading(false);
    }
  };

  const handleActiveSite = async () => {
    try {
      setLoading(true);

      const result = await ThemeApis.activeSiteThemeOverview({id: siteData.id });

      if (result?.data) {
        loadThemeSiteDetail();
      };
    } finally {
      setLoading(false);
    }
  };

  const handleApproveButtonClick = async () => {
    try {
      setLoading(true);

      const result = await ThemeApis.approveSiteThemeOverview(siteData.id);

      if (result?.status === 204) {
        loadThemeSiteDetail();
      };

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (params.themeId) {
      loadThemeSiteDetail();
    }
  }, [params.themeId]);

  return (
    <Row className="theme-detail">
      <Col xl={15} lg={13} md={13} sm={24} xs={24}>
        <Card bordered >
          <div className="gx-media-body theme-detail__thumbnail">
            <h2 className="gx-mb-1 theme-detail__thumbnail--title">
              {siteData?.name}
            </h2>
            <p className="gx-text-grey gx-mb-0 theme-detail__thumbnail--category"><span className="theme-detail__thumbnail--in-text">{t('common.in')} </span>{siteData?.categoryName}</p>

            <div className="gx-text-center gx-pt-sm-3 gx-mt-3">
              <ThemeCarousel images={siteData?.thumbnails.map((thumbnail: any) => thumbnail.name)} />

              <Button className="gx-btn-outline-primary gx-mt-3 gx-mb-0">{t('theme.livePreview')}</Button>
            </div>
          </div>
        </Card>

        <Card bordered className="theme-detail__description">
          <h4 className="gx-mb-4 theme-detail__description--title">
            {t('theme.description')}
          </h4>
          <p className="gx-mb-3 theme-detail__description--content">{siteData?.description}</p>
        </Card>

        <Card bordered >
          <ThemeReview 
            id={siteData?.id}
            postAction="postReviewThemeOverview"
            checkReview="checkReviewThemeOverview"
            getAction="getReviewThemeOverviewById" 
          />
        </Card>
      </Col>

      <Col xl={9} lg={11} md={11} sm={24} xs={24}>
        <Card bordered>
          <ThemeDetailAction
            isFree={siteData?.isThemeFree}
            loading={loading}
            isSuccess={isSuccess}
            price={siteData?.price}
            siteId={params?.themeId}
            authorId={siteData?.ownerId}
            isApprove={siteData?.isApproved}
            categoryId={siteData?.categoryId}
            isDeactivated={siteData?.isDeactivated}
            onDelete={onDeleteSite}
            onActive={handleActiveSite}
            onApprove={handleApproveButtonClick}
            onDeactive={handleDeactiveButtonClick}

          />
        </Card>

        <Card bordered>
          <ThemeAuthor id={siteData?.ownerId} name={siteData?.ownerName} avatar={siteData?.ownerAvatar} />
        </Card>

        <Card bordered >
          <div className="theme-info">
            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.lastUpdate")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">{formatDate(siteData?.published)}</div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.published")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">{formatDate(siteData?.lastUpdate)}</div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">
                  {t("theme.compatibleBrowsers")}
                </div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.compatibleBrowsers.map((browser:any) => browser.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.compatibleWith")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.compatibleWith.map((compatible: any) => compatible.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.themeFilesInclude")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.themeFilesIncluded.map((file:any) => file.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.tags")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.tags.map((tab: any) => tab.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ThemeDetailPage;
