import { Card } from "antd";
import React, { ReactNode } from "react";

interface Props {
  title?: ReactNode;
  children?: ReactNode;
  styleName?: string;
  cover?: ReactNode;
  extra?: ReactNode;
  actions?: ReactNode[];
}

const Widget = (props: Props) => {
  const { title, children, styleName, cover, extra, actions } = props;
  return (
    <Card
      title={title}
      actions={actions}
      cover={cover}
      className={`gx-card-widget ${styleName}`}
      extra={extra}
      bordered = {false}
    >
      {children}
    </Card>
  );
};

export default Widget;
