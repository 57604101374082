import React, { ReactNode } from "react";
import { Card } from "antd";
import PropTypes from "prop-types";

interface Props {
  title: JSX.Element | string;
  styleName: string;
  children: ReactNode;
}

const Metrics = (props: Props) => {
  const { title, styleName, children } = props;
  return (
    <Card title={title} className={`gx-card-metrics ${styleName}`}>
      {children}
    </Card>
  );
};

export default Metrics;
Metrics.defaultProps = {
  styleName: "",
};

Metrics.propTypes = {
  title: PropTypes.string.isRequired,
  styleName: PropTypes.string,
  children: PropTypes.node.isRequired,
};
