import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

interface props {
  avatar: string;
}

const UserAvatar: React.FunctionComponent<props> = ({ avatar }) => {
  return avatar ? (
    <Avatar size={32} className="gx-mr-2" src={avatar} />
  ) : (
    <Avatar size={32} icon={<UserOutlined />} />
  );
};

export default UserAvatar;
