import { notification } from "antd";
import { useEffect } from "react";
import { NotificationApi } from "../apis/notification.api";
import showNotification from "../components/common/notification";
import { getMessagingToken, onMessageListener } from "../firebase";
import { NotificationDeviceType } from "../models/notification.model";

function useNotification() {
  useEffect(() => {
    registerToken();
  }, []);

  useEffect(() => {
    onMessageListener().then((data: any) => {

      notification.info({
        message: data.notification.title,
        description: data.notification.body ,
        placement: "topRight"
      });
    });
  });

  async function registerToken() {
    const token = await getMessagingToken();

    if (token) {
      NotificationApi.registerToken({
        token,
        notificationDeviceType: NotificationDeviceType.Web,
      });
    }
  }
}

export default useNotification;
