import { useRecoilValue } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Switch, UploadFile } from "antd";

// component
import UploadImage from "../../../../views/theme/upload-image";
import UploadFiles from "../../../../views/theme/upload-files";
import ProtectedAction from "../../../../components/action-protected";
import MutipleSelectBox from "../../../../views/theme/multiple-select-box";
import ThemeUploadWrapper from "../../../../views/theme/theme-upload-wrapper";

// ultil
import { RoleEnum } from "../../../../enums/app.enum";
import { ThemeApis } from "../../../../apis/theme.api";
import { ROUTE_PATHS } from '../../../../constants/router.constants';
import { sampleDataCategoriesSelector, themeExtraInformationSelector, wordpressVersionsSelector } from "../../../../states/theme";

// style
import './index.scss';
import { generateDownloadData, generateImage } from "../../../../utils/app.utils";
import { t } from "i18next";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { getUserInfoSelector } from "../../../../states/auth";
import confirmReasonDeactivated from "../../../../views/theme/deactivated-modal";
import useCurrencyConfig from "../../../../hooks/useCurrencyConfig";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },

  className: "form__item--horizone"
};

const colFormItemLayout = {
  labelCol: {
    md: { span: 24 },
    sm: { span: 6 },
    xs: { span: 24 },
  },
  wrapperCol: {
    md: { span: 24 },
    sm: { span: 18 },
    xs: { span: 24 },
  },

  className: "form__item--vertical"
}

const switchThemeFreeLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 24 },
    xl: { span: 15 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    md: { span: 24 },
    xl: { span: 8 },
  },
}

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const validateSchema = (label: string) => {
  return [
    {
      required: true,
      message: t('theme.requiredMessageValidate', { label: label })
    }
  ]
};

interface UploadFormProps {
}

const UploadThemeSampleDataPage: FunctionComponent<UploadFormProps> = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { formatAmount, parserInput } = useCurrencyConfig();

  // state data
  const [loading, setLoading] = useState<boolean>(false);
  const [sampleDataDetail, setSampleDataDetail] = useState<any>(null);
  const [dataThumbnail, setDataThumbnail] = useState<UploadFile[]>([]);
  const [deleteThumbnailIds, setDeleteThumbnailIds] = useState<Array<any>>([]);

  // recoil
  const userInfo = useRecoilValue(getUserInfoSelector);
  const themeCategories = useRecoilValue(sampleDataCategoriesSelector);
  const wordpressVersions = useRecoilValue(wordpressVersionsSelector);
  const themeExtraInformation = useRecoilValue(themeExtraInformationSelector);

  const isUserRole = useMemo(() => {
    return userInfo.role === 'User';
  }, [userInfo]);

  const isReview = useMemo(() => {
    return location.pathname.includes('review');
  }, [location]);

  const isEditMode = useMemo(() => {
    if (params.siteId) {
      return true;
    };

    return false;
  }, [params.siteId]);

  const onUploadThumbnail = (files: any) => {
    setDataThumbnail(files);
  };

  const getThumbnailRemove = (id: number | string) => {
    const removeIds = [...deleteThumbnailIds];
    removeIds.push(id);
    setDeleteThumbnailIds(removeIds);
  };

  const resetStateData = () => {
    setDataThumbnail([]);
    setDeleteThumbnailIds([]);
  };

  const handleUploadButtonClick = () => {
    form.submit();
  };

  const handleFormSubmit = async (value: any) => {
    if (!isEditMode) {
      uploadNewSite(value);
      return;
    };

    editSite(value);
  };

  const uploadNewSite = async (values: any) => {
    try {
      setLoading(true);

      const thumbnailFiles = dataThumbnail.map(thumbnail => thumbnail.originFileObj);

      const payload = {
        ...values,
        thumbnailFiles: thumbnailFiles,
        templateFile: values['templateFile']?.[0].originFileObj,
        designAssetFile: values['designAssetFile']?.[0].originFileObj,
        sampleDataFiles: values['sampleDataFiles']?.[0].originFileObj,
      };

      const result = await ThemeApis.postSampleData(payload);

      if (result?.data) {
        if (isUserRole) {
          Modal.success({
            content: t('theme.uploadSuccessMessage'),
            onOk: () => navigate(ROUTE_PATHS.themesSampleData)
          })

          return
        }
        navigate(ROUTE_PATHS.themesSampleDataDetail.replace(':siteId', result?.data?.id?.toString() || ''))
      }

    } finally {
      setLoading(false);
    }
  };

  const editSite = async (values: any) => {
    try {
      setLoading(true);

      const IsDeleteDesignAssetFile = values['designAssetFile']?.length ? false : true;
      const dataThumbnailsUpload = dataThumbnail.filter((thumbnail: any) => !!thumbnail.originFileObj);

      const payload = {
        ...values,
        IsDeleteDesignAssetFile,
        id: Number(params.siteId),
        ThumbnailsToDelete: deleteThumbnailIds,
        templateFile: values['templateFile']?.[0]?.originFileObj,
        designAssetFile: values['designAssetFile']?.[0]?.originFileObj,
        sampleDataFile: values['sampleDataFiles']?.[0]?.originFileObj,

        thumbnailFiles: dataThumbnailsUpload.map(dataUpload => dataUpload.originFileObj),
      };

      const result = await ThemeApis.updateSampleDataSiteData(payload);

      if (result?.data) {
        if (isUserRole) {
          Modal.success({
            content: t('theme.uploadSuccessMessage'),
            onOk: () => navigate(ROUTE_PATHS.themesSampleData)
          })

          return
        }

        navigate(ROUTE_PATHS.themesSampleDataDetail.replace(':siteId', params.siteId?.toString() || ''))
      };

    } finally {
      setLoading(false);
    };
  };

  const handleRejectButtonClick = async () => {
    const handleGetReason = (reason: string) => rejectSite(reason.replaceAll('\n', '%0A'));
    Modal.confirm({
      ...confirmReasonDeactivated(handleGetReason),
      title: t('theme.confirmRejectTheme'),
      okButtonProps: { loading: loading },
      okText: t('common.reject'),
      okType: 'primary'
    });
  };

  const rejectSite = async (reason: string) => {
    try {
      setLoading(true);
      await ThemeApis.rejectSampleDataSite({ id: params.siteId, reason });

      navigate(ROUTE_PATHS.themesSampleData);
    } finally {
      setLoading(false);
    }
  };

  const handleAprroveButtonClick = async () => {
    try {
      setLoading(true);
      await ThemeApis.approveSampleDataSite(params.siteId);
      navigate(ROUTE_PATHS.themesSampleData);
    } finally {
      setLoading(false);
    }
  };

  const downloadTheme = async (file: any) => {
    const response = await ThemeApis.downloadSampleDataFile({ id: params.siteId, fileId: file.id });
    generateDownloadData(response.data, file.name);
  };

  const loadThemeSiteDetail = async () => {
    resetStateData();

    const result = await ThemeApis.getSampleDataSiteDetail({ id: params.siteId });

    if (!result?.data) {
      return;
    };
    setSampleDataDetail(result?.data)
    const { templateFile, compatibleBrowsers, compatibleWith, designAssetsFile, sampleDataFile, tags, thumbnails, demoURL, isFree, ...formData } = result?.data;

    const tabIds = tags?.map((tabs: any) => tabs.id);
    const compatibleWithIds = compatibleWith?.map((width: any) => width.id);
    const compatibleBrowsersIds = compatibleBrowsers?.map((browser: any) => browser.id);
    form.setFieldValue('tagIds', tabIds);
    form.setFieldValue('compatibleWithIds', compatibleWithIds);
    form.setFieldValue('compatibleBrowserIds', compatibleBrowsersIds);

    const thumbnailValues = thumbnails.map((thubnail: any, index: number) => ({
      uid: thubnail.id,
      name: t('theme.thumbnail') + (index + 1),
      status: 'done',
      url: generateImage(thubnail.name),
    }));

    setDataThumbnail(thumbnailValues);
    form.setFieldsValue(formData);

    const templateFileValue = {
      uid: templateFile?.id,
      name: templateFile?.name,
      status: 'done',
      url: '',
    };

    const sampleDataFileValue = {
      uid: sampleDataFile?.id,
      name: sampleDataFile?.name,
      status: 'done',
      url: '',
    };

    if (designAssetsFile) {
      const designAssetsFileValue = {
        uid: designAssetsFile?.id,
        name: designAssetsFile?.name,
        status: 'done',
        url: '',
      };
      form.setFieldValue('designAssetFile', [designAssetsFileValue]);
    };

    form.setFieldValue('demoUrl', demoURL);
    form.setFieldValue('isFree', isFree);
    form.setFieldValue('templateFile', [templateFileValue]);
    form.setFieldValue('sampleDataFiles', [sampleDataFileValue]);

  };

  useEffect(() => {
    if (params.siteId) {
      loadThemeSiteDetail();
    };
  }, [params.siteId]);

  return (
    <ThemeUploadWrapper type="sample-data">
      <ProtectedAction
        roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User]}
      >
        <Form form={form} className="upload__form form" onFinish={handleFormSubmit}>
          <Row gutter={[18, 18]}>

            <Col md={16} xs={24}>
              <Card>
                <div className="gx-mb-3">{t("theme.generalInformation")}</div>

                <Form.Item required {...formItemLayout} label={t('theme.dataName')} name="name" rules={validateSchema(t('theme.dataName'))}>
                  <Input
                    placeholder={t("theme.enterName")}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item required {...formItemLayout} label={t('theme.dataThumbnail')} name="thumbnailFiles" rules={!dataThumbnail.length ? validateSchema(t('theme.dataThumbnail')) : []}>
                  <UploadImage onUpdate={onUploadThumbnail} multiple thumbnailFiles={dataThumbnail} onRemove={getThumbnailRemove}
                    disabled={isReview} />
                </Form.Item>

                <Form.Item required {...formItemLayout} label={t('theme.description')} name="description" rules={validateSchema(t('theme.description'))}>
                  <Input.TextArea rows={4} placeholder={t('theme.enterDescription')}
                    disabled={isReview} />
                </Form.Item>

                <Form.Item required {...formItemLayout} label={t('theme.demoURL')} name="demoUrl" rules={validateSchema(t('theme.demoURL'))}>
                  <Input
                    placeholder={t('theme.enterURL')}
                    disabled={isReview}
                  />
                </Form.Item>

                <Divider />

                <div className="gx-mb-3">{t('theme.dataResource')}</div>

                <Form.Item
                  required
                  name="templateFile"
                  {...formItemLayout}
                  label={t('theme.data')}
                  valuePropName="fileList"
                  getValueFromEvent={event => {
                    if (Array.isArray(event)) {
                      return event;
                    }

                    return event && event.fileList;
                  }}
                  rules={validateSchema(t('theme.data'))}
                >
                  <UploadFiles
                    showUploadList={{
                      showDownloadIcon: isReview,
                    }}
                    onDownload={() => downloadTheme(sampleDataDetail.templateFile)}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item
                  required
                  {...formItemLayout}
                  label={t('theme.sampleData')}
                  name="sampleDataFiles"
                  valuePropName="fileList"
                  getValueFromEvent={event => {
                    if (Array.isArray(event)) {
                      return event;
                    }

                    return event && event.fileList;
                  }}
                  rules={validateSchema(t('theme.sampleData'))}
                >
                  <UploadFiles
                    showUploadList={{
                      showDownloadIcon: isReview,
                    }}
                    onDownload={() => downloadTheme(sampleDataDetail.sampleDataFile)}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label={t('theme.designAssets')}
                  name="designAssetFile"
                  valuePropName="fileList"
                  getValueFromEvent={event => {
                    if (Array.isArray(event)) {
                      return event;
                    }

                    return event && event.fileList;
                  }}
                >
                  <UploadFiles
                    showUploadList={{
                      showDownloadIcon: isReview,
                    }}
                    onDownload={() => downloadTheme(sampleDataDetail.designAssetsFile)}
                    disabled={isReview}
                  />
                </Form.Item>

                <Divider />

                <div className="gx-mb-3">{t('theme.extraInformation')}</div>

                <Form.Item {...formItemLayout} label={t('theme.compatibleBrowsers')} name="compatibleBrowserIds">
                  <MutipleSelectBox
                    mode="tags"
                    placeholder={t('theme.compatibleBrowsers')}
                    optionData={themeExtraInformation.compatibleBrowsers}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item {...formItemLayout} label={t('theme.compatibleWith')} name="compatibleWithIds">
                  <MutipleSelectBox
                    mode="tags"
                    placeholder={t('theme.compatibleWith')}
                    optionData={themeExtraInformation.compatibleWith}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item {...formItemLayout} label={t('theme.tags')} name="tagIds">
                  <MutipleSelectBox
                    mode="tags"
                    placeholder={t('theme.tags')}
                    optionData={themeExtraInformation.tags}
                    disabled={isReview}
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col md={8} xs={24}>
              <Card>
                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.dataCategory')} name="categoryId" rules={validateSchema(t('theme.dataCategory'))}>
                  <MutipleSelectBox
                    placeholder={t('theme.dataCategory')}
                    optionData={themeCategories}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.wordpressVersion')} name="versionId" rules={validateSchema(t('theme.wordpressVersion'))}>
                  <MutipleSelectBox
                    placeholder={t('theme.wordpressVersion')}
                    optionData={wordpressVersions.records}
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item {...switchThemeFreeLayout} labelAlign="left" label={t('theme.thisSampleDataIsFree')} name="isFree" valuePropName="checked">
                  <Switch
                    disabled={isReview} />
                </Form.Item>

                <Form.Item required {...colFormItemLayout} labelAlign="left" label={t('theme.dataPrice')} name="price" rules={validateSchema(t('theme.dataPrice'))}>
                  <InputNumber
                    parser={value => parserInput(value)}
                    formatter={value => formatAmount(value)}
                    placeholder="0 VND"
                    disabled={isReview}
                  />
                </Form.Item>

                <Form.Item {...tailLayout} className="gx-text-center gx-mb-0">
                  {!isReview && <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Button className="gx-mb-0 gx-w-100" htmlType="button" >
                        {t('common.preview')}
                      </Button>
                    </Col>

                    <Col span={12}>
                      <Button loading={loading} onClick={handleUploadButtonClick} className="gx-mb-0 gx-w-100" type="primary">
                        {!isEditMode ? t('common.upload') : t('common.update')}
                      </Button>
                    </Col>
                  </Row>}

                  {!!isReview && <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Button onClick={handleRejectButtonClick} disabled={loading} className="gx-mb-0 gx-w-100">
                        {t('common.reject')}
                      </Button>
                    </Col>

                    <Col span={12}>
                      <Button onClick={handleAprroveButtonClick} loading={loading} disabled={loading} className="gx-mb-0 gx-w-100" type="primary">
                        {t('common.approve')}
                      </Button>
                    </Col>
                  </Row>}

                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </ProtectedAction>
    </ThemeUploadWrapper>
  );
};

export default UploadThemeSampleDataPage;
