import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

interface Props {
  data: any;
}

const UserVisit = (props: Props) => {
  const { data } = props;

  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <ResponsiveContainer width="100%" height={140}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
        >
          <Tooltip labelFormatter={(index) => data[index]?.date} />
          <CartesianGrid horizontal={false} strokeDasharray="3 3" />
          <Area
            type="monotone"
            dataKey="value"
            fillOpacity={1}
            stroke="#038FDE"
            fill="#038FDE"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UserVisit;
