import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { FunctionComponent, useEffect, useState } from "react";

// component
import ThemeCarousel from "../../../views/theme/theme-carousel";

// utils
import { ThemeApis } from "../../../apis/theme.api";
import { formatDate } from "../../../utils/date.ultis";
import WebsitePriceDetail from "../theme-detail-action";

// style
import './index.scss';
import { WebsiteApi } from "../../../apis/website.api";

const WebsiteThemeDetailPage: FunctionComponent<any> = ({ themeId, planId }) => {
  // utils
  const { t } = useTranslation();

  // state
  const [siteData, setSiteData] = useState<any>(null);
  const [themeCalculateData, setThemeCalculateData] = useState<any>(null);

  const loadThemeSiteDetail = async () => {

    const [themeResponse, themeCalculateResponse] = await Promise.all([
      ThemeApis.getThemeSiteDetail({ id: themeId }),
      WebsiteApi.getCalculationSite({ themeId, planId })
    ])

    if (themeResponse?.data) {
      setSiteData(themeResponse.data);
    }

    if (themeCalculateResponse?.data) {
      setThemeCalculateData(themeCalculateResponse.data);
    }


  };

  useEffect(() => {
    if (themeId) {
      loadThemeSiteDetail();
    }
  }, [themeId]);

  return (
    <Row className="theme-detail">
      <Col xl={14} lg={24} md={24} sm={24} xs={24}>
        <Card bordered >
          <div className="gx-media-body theme-detail__thumbnail">
            <h2 className="gx-mb-1 theme-detail__thumbnail--title">
              {siteData?.name}
            </h2>
            <p className="gx-text-grey gx-mb-0 theme-detail__thumbnail--category"><span className="theme-detail__thumbnail--in-text">{t('common.in')} </span>{siteData?.categoryName}</p>

            <div className="gx-text-center gx-pt-sm-3 gx-mt-3">
              <ThemeCarousel images={siteData?.thumbnails?.map((thumbnail: any) => thumbnail.name)} />

              <Button className="gx-btn-outline-primary gx-mt-3 gx-mb-0">{t('theme.livePreview')}</Button>
            </div>
          </div>
        </Card>

        <Card bordered className="theme-detail__description">
          <h4 className="gx-mb-4 theme-detail__description--title">
            {t('theme.description')}
          </h4>
          <p className="gx-mb-3 theme-detail__description--content">{siteData?.description}</p>
        </Card>
      </Col>

      <Col xl={10} lg={24} md={24} sm={24} xs={24}>
        <Card bordered>
          <WebsitePriceDetail
            price={siteData?.price}
            siteId={themeId}
            themeFee={themeCalculateData}
          />
        </Card>

        <Card bordered >
          <div className="theme-info">
            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.lastUpdate")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">{formatDate(siteData?.published)}</div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.published")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">{formatDate(siteData?.lastUpdate)}</div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">
                  {t("theme.compatibleBrowsers")}
                </div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.compatibleBrowsers?.map((browser: any) => browser.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.compatibleWith")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.compatibleWith?.map((compatible: any) => compatible.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.themeFilesInclude")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.themeFilesIncluded?.map((file: any) => file.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="theme-info__item">
              <Col lg={10} md={8} sm={8} xs={8}>
                <div className="theme-info__title">{t("theme.tags")}</div>
              </Col>
              <Col lg={14} md={16} sm={16} xs={16}>
                <div className="theme-info__content">
                  <div className="theme-info__content theme-info__content-blue">
                    {siteData?.tags?.map((tab: any) => tab.name).join(', ')}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default WebsiteThemeDetailPage;
