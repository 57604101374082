import { FunctionComponent, useEffect, useState } from "react";
import { IMAGE_DEFAULT_TYPE } from "../../../constants/app-constants";

import defaultBank from '../../../assets/images/default-bank.png';
import defaultWebsite from "../../../assets/images/site-create.png";
import defaultCategory from '../../../assets/images/theme/category/default_category.png';
import defaultThemeCategory from '../../../assets/images/theme/category/theme_category_1.png';

import './index.scss';

interface ImageLoadProps {
    src: string | undefined;
    type: number;

    [key: string]: any;
}

const ImageLoad: FunctionComponent<ImageLoadProps> = ({src, type, ...props}) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(src);

    const handleError = () => setImageUrl((prev) => {
        if (type === IMAGE_DEFAULT_TYPE.category) {
            return defaultCategory;
        }
        else if (type === IMAGE_DEFAULT_TYPE.themePreview) {
            return defaultThemeCategory;
        }
        else if (type === IMAGE_DEFAULT_TYPE.website) {
            return defaultWebsite;
        }

        else if (type === IMAGE_DEFAULT_TYPE.bank) {
            return defaultBank;
        }
    });

    useEffect(() => {
        if (src) {
            setImageUrl(src)
        }
    }, [src]);

    return <img src={imageUrl} alt={props.alt} {...props} onError={handleError}/>;
};

export default ImageLoad;
