import Search from "antd/lib/input/Search";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { RoleEnum } from "../../../../enums/app.enum";
import ButtonUpload from "../../button-upload";

import './index.scss';

interface SampleDataCardExtraProps {
    uploadRedirect: string;
    onSearch?: (keyword: string) => void;
}

const SampleDataCardExtra: FunctionComponent<SampleDataCardExtraProps> = ({ onSearch, uploadRedirect }) => {
    const { t } = useTranslation();

    return (
        <div >
            <Search
                placeholder={t("theme.searchCategory")}
                className="search-input"
                allowClear
                onSearch={onSearch}
            />
            <ButtonUpload roles={[RoleEnum.Admin, RoleEnum.Mod, RoleEnum.User]} href={uploadRedirect}/>
        </div>
    );
};

export default SampleDataCardExtra;
