import { FunctionComponent } from "react";

interface RoundedTabProps {
    color: string;
}

const RounedeTag: FunctionComponent<RoundedTabProps> = ({ color }) => {
    return (
        <li className={`gx-text-${color}`}>
            <i className="icon icon-circle gx-fs-xxs" />
        </li>
    );
};

export default RounedeTag;
