import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { StorageIcon } from "../../../assets/icons";
import Metrics from "../metrics";
import "./index.scss";

interface Props {
  trafficData?: any[];
  title: string;
  storage: any;
}

const StorageCard = (props: Props) => {
  const {t} = useTranslation()
  return (
    <div className="storage-card">
      <Metrics styleName={`gx-card-full`} title="">
        <Row>
          <Col lg={6} md={24} sm={6} xs={6}>
            <div className="gx-pb-4 gx-pl-4 gx-pt-4">
              <StorageIcon />
            </div>
          </Col>
          <Col className="col-item gx-pt-4" lg={18} md={24} sm={18} xs={18}>
            <p className="numberStorage">{props.storage || 0}GB</p>
            <p className="storage">{t("website.storage")}</p>
          </Col>
        </Row>
      </Metrics>
    </div>
  );
};

export default StorageCard;
