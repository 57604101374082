const RealEstate = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 36 36" fill="none">
            <path d="M35.4 34.8H32.4V3.6H33.6C33.96 3.6 34.2 3.36002 34.2 3.00002V0.599977C34.2 0.239977 33.96 0 33.6 0H2.39998C2.03998 0 1.8 0.239977 1.8 0.599977V2.99995C1.8 3.35995 2.03998 3.59993 2.39998 3.59993H3.6V34.7999H0.599977C0.239977 34.7999 0 35.0399 0 35.3999C0 35.7599 0.239977 36 0.599977 36H4.19998H14.4H21.6H31.8H35.4C35.76 36 36 35.76 36 35.4C36 35.04 35.76 34.8 35.4 34.8ZM3.00002 1.20002H33V2.40005H31.8H4.19998H3.00002V1.20002ZM15 34.8V25.2H17.4V34.8H15ZM18.6 34.8V25.2H21V34.8H18.6ZM22.2 34.8V24.6C22.2 24.24 21.96 24 21.6 24H14.4C14.04 24 13.8 24.24 13.8 24.6V34.8H4.80002V3.6H31.2V34.8H22.2Z" fill="url(#paint0_linear_1256_26716)" />
            <path d="M16.2 6.00049H11.4H6.60004C6.24004 6.00049 6.00006 6.24046 6.00006 6.60046V11.4005C6.00006 11.7605 6.24004 12.0005 6.60004 12.0005H11.4001H16.2C16.56 12.0005 16.8 11.7605 16.8 11.4005V6.60053C16.8 6.24053 16.56 6.00049 16.2 6.00049ZM7.20001 7.20051H10.8V10.8005H7.20001V7.20051ZM15.6 10.8005H12V7.20051H15.6V10.8005Z" fill="url(#paint1_linear_1256_26716)" />
            <path d="M12 24.0005H6.60004C6.24004 24.0005 6.00006 24.2405 6.00006 24.6005V29.4005C6.00006 29.7605 6.24004 30.0005 6.60004 30.0005H12C12.36 30.0005 12.6 29.7605 12.6 29.4005V24.6005C12.6 24.2405 12.36 24.0005 12 24.0005ZM11.4 28.8005H7.20001V25.2005H11.4V28.8005Z" fill="url(#paint2_linear_1256_26716)" />
            <path d="M29.4 24.0005H24C23.64 24.0005 23.4 24.2405 23.4 24.6005V29.4005C23.4 29.7605 23.64 30.0005 24 30.0005H29.4C29.76 30.0005 30 29.7605 30 29.4005V24.6005C30 24.2405 29.76 24.0005 29.4 24.0005ZM28.8 28.8005H24.6V25.2005H28.8V28.8005Z" fill="url(#paint3_linear_1256_26716)" />
            <path d="M29.4 6.00049H24.5999H19.8C19.44 6.00049 19.2 6.24046 19.2 6.60046V11.4005C19.2 11.7605 19.44 12.0005 19.8 12.0005H24.6H29.4C29.76 12.0005 30 11.7605 30 11.4005V6.60053C30 6.24053 29.76 6.00049 29.4 6.00049ZM20.4 7.20051H24V10.8005H20.4V7.20051ZM28.8 10.8005H25.2V7.20051H28.8V10.8005Z" fill="url(#paint4_linear_1256_26716)" />
            <path d="M16.2 14.4004H11.4H6.60004C6.24004 14.4004 6.00006 14.6404 6.00006 15.0004V19.8004C6.00006 20.1604 6.24004 20.4004 6.60004 20.4004H11.4001H16.2C16.56 20.4004 16.8 20.1604 16.8 19.8004V15.0004C16.8 14.6404 16.56 14.4004 16.2 14.4004ZM7.20001 15.6004H10.8V19.2004H7.20001V15.6004ZM15.6 19.2004H12V15.6004H15.6V19.2004Z" fill="url(#paint5_linear_1256_26716)" />
            <path d="M29.4 14.4004H24.5999H19.8C19.44 14.4004 19.2 14.6404 19.2 15.0004V19.8004C19.2 20.1604 19.44 20.4004 19.8 20.4004H24.6H29.4C29.76 20.4004 30 20.1604 30 19.8004V15.0004C30 14.6404 29.76 14.4004 29.4 14.4004ZM20.4 15.6004H24V19.2004H20.4V15.6004ZM28.8 19.2004H25.2V15.6004H28.8V19.2004Z" fill="url(#paint6_linear_1256_26716)" />
            <defs>
                <linearGradient id="paint0_linear_1256_26716" x1="1.49519e-07" y1="13.4328" x2="36.6113" y2="21.7918" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1256_26716" x1="6.00006" y1="8.23929" x2="15.8862" y2="12.3022" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint2_linear_1256_26716" x1="6.00006" y1="26.2393" x2="12.643" y2="27.9076" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint3_linear_1256_26716" x1="23.4" y1="26.2393" x2="30.0429" y2="27.9076" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint4_linear_1256_26716" x1="19.2" y1="8.23929" x2="29.0862" y2="12.3022" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint5_linear_1256_26716" x1="6.00006" y1="16.6392" x2="15.8862" y2="20.7021" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
                <linearGradient id="paint6_linear_1256_26716" x1="19.2" y1="16.6392" x2="29.0862" y2="20.7021" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9747FF" />
                    <stop offset={1} stopColor="#9087E1" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default RealEstate;