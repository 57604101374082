import {
  ClusterOutlined,
  DashboardOutlined,
  FundProjectionScreenOutlined,
  TeamOutlined
} from "@ant-design/icons";

import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserApi } from "../../../apis/user.api";
import { RoleEnum } from "../../../enums/app.enum";
import { UserOverViewModel } from "../../../models/user.model";

import UserTable from "../../../views/users/user-table";
import UserVisit from "../../../views/users/user-visit";
import NewUserCard from "../../../views/users/new-user-card";
import WelcomeCard from "../../../components/common/welcome-card";
import ProtectedAction from "../../../components/action-protected";
import GrowthCardInUser from "../../../components/common/growth-card-user";
import IconWithTextCardInUser from "../../../components/common/icon-with-text-card-user";

const trafficData = [
  { name: "User A", value: 0 },
  { name: "User B", value: 2000 },
  { name: "User C", value: 600 },
  { name: "User D", value: 4400 },
  { name: "User D", value: 900 },
  { name: "User H", value: 4860 },
];

const defaultValues = {
  numOfMods: 0,
  numOfUsers: 0,
  totalUsers: 0,
  charModels: [],
  numOfAdmins: 0,
  numOfSellers: 0,
  userGrowthLastWeek: "",
  userGrowthLastYear: "",
  userGrowthLastMonth: "",
};

function UserOverviewPage() {
  // utils
  const { t } = useTranslation();
  
  // state data
  const [overview, setOverview] = useState<UserOverViewModel>(defaultValues);
  
  const getUserOverview = async () => {
    const res = await UserApi.getUserOverview();
    setOverview(res.data);
  };

  useEffect(() => {
    getUserOverview();
  }, []);

  return (
    <ProtectedAction roles={[RoleEnum.Admin, RoleEnum.Mod]}>
      <Row>
        <Col span={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                  <WelcomeCard unit={t("user.users")} number={overview.totalUsers} />
                </Col>

                <Col
                  xl={18}
                  lg={36}
                  md={36}
                  sm={24}
                  xs={24}
                  className="gx-visit-col"
                >
                  <UserVisit data={overview.charModels} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col xl={8} lg={24} md={8} sm={24} xs={24}>
          <GrowthCardInUser
            fromColor="#82B7E2"
            toColor="#9087E1"
            trafficData={trafficData}
            title={t("user.lastWeek")}
            id="1"
            color="#52C41A"
            percent={overview.userGrowthLastWeek}
          />
        </Col>

        <Col xl={8} lg={12} md={8} sm={24} xs={24}>
          <GrowthCardInUser
            fromColor="#81CFE4"
            toColor="#65BF98"
            trafficData={trafficData}
            title={t("user.lastMonth")}
            id="2"
            color="#52C41A"
            percent={overview.userGrowthLastMonth}
          />
        </Col>

        <Col xl={8} lg={12} md={8} sm={24} xs={24}>
          <GrowthCardInUser
            fromColor="#AC52FF"
            toColor="#FA8C16"
            trafficData={trafficData}
            title={t("user.lastYear")}
            id="3"
            color="#52C41A"
            percent={overview.userGrowthLastYear}
          />
        </Col>

        <Col lg={16} md={24} sm={24} xs={24}>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCardInUser
                cardColor="orange"
                icon={<DashboardOutlined />}
                subTitle={t("user.admin")}
                number={overview.numOfAdmins}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCardInUser
                cardColor="blue"
                icon={<ClusterOutlined />}
                subTitle={t("user.mods")}
                number={overview.numOfMods}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCardInUser
                cardColor="teal"
                icon={<FundProjectionScreenOutlined />}
                subTitle={t("user.sellers")}
                number={overview.numOfSellers}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCardInUser
                cardColor="purple"
                icon={<TeamOutlined />}
                subTitle={t("user.users")}
                number={overview.numOfUsers}
              />
            </Col>
          </Row>
          <Col>
            <UserTable />
          </Col>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24}>
          <NewUserCard />
        </Col>
      </Row>
    </ProtectedAction>
  );
}

export default UserOverviewPage;
