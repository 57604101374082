import { UserProfileModel } from "../models/user.model";
import jwt_decode from "jwt-decode";
import { AuthApi } from "../apis/auth.api";
import LocalUtils from "../utils/local.utils";
import { authState } from "../states/auth";
import { useSetRecoilState } from "recoil";
import { UserApi } from "../apis/user.api";
import { LOCAL_COOKIE_KEY } from "../constants/app-constants";
import { ROUTE_PATHS } from "../constants/router.constants";


const useUserActions = () => {
    const setAuthState = useSetRecoilState(authState);

    const signIn = async (payload: any) => {
        const { is_remember, ...fields} = payload;
        const { data } = await AuthApi.signIn(fields);
        const user: UserProfileModel = jwt_decode(data.token);

        const userInfo = { ...user, userId: data.userId }
        LocalUtils.setAuthenticatedData(data, is_remember, userInfo);
        setAuthState({ isLogined: true, user: userInfo });
    };

    const signUp = async (payload: any) => {
        await AuthApi.signUp(payload);
    };

    const logout = () => {
        LocalUtils.clear();
        window.location.replace(ROUTE_PATHS.signIn)
    };

    const resetPassword = async (payload: any) => {
        const { navigate, ...fields } = payload;
        await AuthApi.resetPassword(fields);
        navigate(ROUTE_PATHS.signIn)
    };

    const verifySignUp = async (payload: any) => {
        const { code, email, password, phoneNumber, navigate } = payload;

        await AuthApi.otpConfirmSignup({ email, phoneNumber, otp: code });
        
        const { data } = await AuthApi.signIn({ phoneNumber, email, password });
        
        LocalUtils.setCookie(LOCAL_COOKIE_KEY.ACCESS_TOKEN, data.token);
        
        navigate(ROUTE_PATHS.complete, {
            state: {
                email, password, phoneNumber
            }
        })
    };

    const completeSignUp = async (payload: any) => {
        const { email, password, fullName, file, phoneNumber } = payload
        await UserApi.changeAvatar({ fullName, file });
        await signIn({ email, password, phoneNumber });
    };

    const confirmOtp = async (payload: any) => {
        const { code , navigate, phoneNumber, email} = payload;
        await AuthApi.otpConfirmSignup({phoneNumber, email, otp: code});
    };


    const sendOtp = async (email: string, phoneNumber?: any) => {
        await AuthApi.sendOTP(email, phoneNumber);
    };

    return {
        signIn,
        signUp,
        logout,
        sendOtp,
        confirmOtp,
        verifySignUp,
        completeSignUp,
        resetPassword,
    }
}

export { useUserActions };